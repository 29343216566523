import Role from "@higher/models/Role";
import queryStateSubscriptionByFunc from "./queryStateSubscriptionByFunc";

const RolesByOrganisationId = queryStateSubscriptionByFunc<Role, string>(
  "rolesByOrgId",
  "roles",
  (query, id) => {
    return query.where("organisationId", "==", id);
  }
);

export default RolesByOrganisationId;
