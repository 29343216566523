import Event from "@higher/models/Event";
import Organisation from "@higher/models/Organisation";

import queryStateSubscriptionByFunc from "./queryStateSubscriptionByFunc";

export default queryStateSubscriptionByFunc<Event, Organisation>(
  "eventsByorg",
  (org) => `organisations/${org.id}/events`,
  (query) => {
    return query.orderBy("date", "desc").limit(20);
  }
);
