import React from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";

import Role from "@higher/models/Role";

import WrapErrorSuspense from "@app/api/WrapErrorSuspense";
import apply from "@app/api/functions/apply";
import { YesNo } from "@app/helpers/YesNo";
import useFirestoreValue from "@app/hooks/useFirestoreValue";
import ErrorFallback from "@app/modules/Error";
import RoleByIdState from "@app/state/RoleById";

import Form, { Skeleton } from "./Form.stateless";
import usePreFill from "./Prefill";

type Props = {
  role: Role;
};

const View: React.FC<Props> = ({ role }) => {
  const history = useHistory();
  const [prefill, setPrefill] = usePreFill(role.id);

  const [name, setName] = React.useState<string | undefined>(
    prefill.name || undefined
  );
  const [email, setEmail] = React.useState<string | undefined>(
    prefill.email || undefined
  );
  const [image, setImage] = React.useState<string | YesNo.No | undefined>(
    undefined
  );
  const [imageURL, setImageURL] = React.useState<string | undefined>(
    prefill.avatar || undefined
  );
  const [location, setLocation] = React.useState<string | undefined>(undefined);
  const [title, setTitle] = React.useState<string | undefined>(undefined);
  const [linkedin, setLinkedin] = React.useState<string | YesNo.No | undefined>(
    undefined
  );
  const [cvFile, setCvFile] = React.useState<
    [File, string] | YesNo.No | undefined
  >(undefined);
  const [cvUrl, setCvUrl] = React.useState<string | YesNo.No | undefined>(
    undefined
  );

  const handleContinue = async () => {
    const response = await apply({
      roleId: role.id,
      name: name || "Undefined",
      avatar: image === YesNo.No ? undefined : image,
      avatarURL: imageURL || undefined,
      email: email || "Undefined",
      location: location || "Undefined",
      linkedIn: linkedin === YesNo.No ? undefined : linkedin,
      history: title ? [title] : [],
      cvFile: cvFile && cvFile !== YesNo.No ? cvFile[1] : undefined,
      cvUrl,
    });

    setPrefill({});

    history.replace(`/dashboard/${response.dashboardId}`);
  };

  return (
    <Form
      role={role}
      prefill={prefill}
      nameState={[name, setName]}
      emailState={[email, setEmail]}
      imageState={[image, setImage]}
      imageURLState={[imageURL, setImageURL]}
      locationState={[location, setLocation]}
      titleState={[title, setTitle]}
      linkedinState={[linkedin, setLinkedin]}
      cvFileState={[cvFile, setCvFile]}
      cvUrlState={[cvUrl, setCvUrl]}
      handleContinue={handleContinue}
    />
  );
};

type DispatcherProps = RouteComponentProps<{
  id: string;
}>;

const Dispatcher: React.FC<DispatcherProps> = (props) => {
  const [role, isLoading] = useFirestoreValue(
    RoleByIdState(props.match.params.id)
  );

  if (isLoading) {
    return <Skeleton />;
  }

  return <View role={role} />;
};

export default WrapErrorSuspense<DispatcherProps>(
  Dispatcher,
  Skeleton,
  ErrorFallback
);
