import { atomFamily, AtomEffect, SerializableParam } from "recoil";

import Firebase from "@app/Firebase";

import Identifiable from "./Identifiable";

type stringFn<T> = (id: T) => string;

const queryToType = <T extends Identifiable>(
  query: Firebase.firestore.QuerySnapshot
): Array<T> => {
  const output: Array<T> = [];
  query.forEach((doc) => {
    const data = doc.data() as T;
    data.id = doc.id;
    output.push(data);
  });

  return output;
};

type QueryPrepFunction<U extends SerializableParam = string> = (
  query: Firebase.firestore.Query,
  id: U
) => Firebase.firestore.Query;

async function getByFunc<
  T extends Identifiable,
  U extends SerializableParam = string
>(
  collection: string,
  id: U,
  queryFunc: QueryPrepFunction<U>
): Promise<Array<T>> {
  const q1 = Firebase.firestore().collection(collection);
  const query = queryFunc(q1, id);

  return queryToType<T>(await query.get());
}

export function syncStorageEffect<
  T extends Identifiable,
  U extends SerializableParam = string
>(
  id: U | undefined | null
): (
  collFn: string | stringFn<U>,
  queryFunc: QueryPrepFunction<U>
) => AtomEffect<Array<T>> {
  return (collFn, queryFunc) => {
    return ({ setSelf, trigger }) => {
      if (
        !id ||
        (typeof id === "object" && Object.keys(id as Object).length === 0)
      ) {
        return;
      }

      const collection = typeof collFn === "string" ? collFn : collFn(id);

      if (trigger === "get") {
        setSelf(getByFunc<T, U>(collection, id, queryFunc));
      }

      const q1 = Firebase.firestore().collection(collection);
      const query = queryFunc(q1, id);

      const unsubscribe = query.onSnapshot((doc) => {
        setSelf(queryToType(doc));
      });

      return () => {
        unsubscribe();
      };
    };
  };
}

export const queryStateSubscriptionByFunc = <
  T extends Identifiable,
  U extends SerializableParam = string
>(
  key: string,
  collection: string | stringFn<U>,
  queryFunc: QueryPrepFunction<U>
) => {
  return atomFamily<Array<T>, U | undefined | null>({
    key,
    default: [],
    effects_UNSTABLE: (id) => [
      syncStorageEffect<T, U>(id)(collection, queryFunc),
    ],
  });
};

export default queryStateSubscriptionByFunc;
