import {
  PaymentMethod,
  Stripe,
  StripeCardElement,
  StripeCardNumberElement,
} from "@stripe/stripe-js";

import Applicant from "@higher/models/Applicant";
import Role from "@higher/models/Role";

import { Functions } from "@app/Firebase";

type CreatePaymentIntentResponse = {
  endorsementId: string;
  clientSecret: string;
};

export type MakeCandidateEndorsementPaymentResponse = {
  id?: string;
  error?: string;
};

type StripeCard =
  | StripeCardElement
  | StripeCardNumberElement
  | { token: string };

export type MakeCandidateEndorsementPaymentFn = (
  stripe: Stripe,
  card: StripeCard,
  applicant: Applicant,
  role: Role,
  amount: number,
  email: string
) => Promise<MakeCandidateEndorsementPaymentResponse>;

const createPaymentIntent = async (
  applicantId: string,
  paymentMethod: PaymentMethod,
  amount: number,
  currency: string,
  email: string
): Promise<CreatePaymentIntentResponse> => {
  const fn = Functions.httpsCallable("stripeCharge");
  const result = await fn({
    applicantId,
    paymentMethod,
    amount,
    email,
  });
  return result.data as CreatePaymentIntentResponse;
};

export const makeCandidateEndorsementPayment: MakeCandidateEndorsementPaymentFn =
  async (stripe, card, applicant, role, amount, email) => {
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card,
    });

    if (!paymentMethod) {
      if (error) {
        return { error: error.message };
      }
      return { error: "Please enter your card details" };
    }

    try {
      const pi = await createPaymentIntent(
        applicant.id,
        paymentMethod,
        amount,
        role.currency,
        email
      );
      const response = await stripe.confirmCardPayment(pi.clientSecret, {});

      return {
        id: pi.endorsementId,
        error: response.error ? response.error.message : undefined,
      };
    } catch (err) {
      return { error: err.message };
    }
  };

export default makeCandidateEndorsementPayment;
