import {
  Avatar,
  Button as MUIButton,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Container,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Typography,
} from "@material-ui/core";
import {
  makeStyles,
  Theme,
  createStyles,
  fade,
} from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/Cancel";
import ChatIcon from "@material-ui/icons/Chat";
import CodeIcon from "@material-ui/icons/Code";
import TeamIcon from "@material-ui/icons/ColorLens";
import ComputerIcon from "@material-ui/icons/Computer";
import HeartIcon from "@material-ui/icons/Favorite";
import QuestionIcon from "@material-ui/icons/Help";
import HomeIcon from "@material-ui/icons/Home";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import ToolsIcon from "@material-ui/icons/ImportantDevices";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import ReadIcon from "@material-ui/icons/MenuBook";
import MeetingIcon from "@material-ui/icons/Today";
import TwitterIcon from "@material-ui/icons/Twitter";
import { Alert, AlertTitle } from "@material-ui/lab";
import React from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Parallax, Background } from "react-parallax";

import {
  Keyword,
  Icons,
  Images,
  UpArrow,
  Landingpages,
  Faces,
} from "@app/brand";
import ScrollDown from "@app/components/ScrollDown";
import openInNewTab from "@app/helpers/openInNewTab";
import openSlaask from "@app/helpers/slaask";
import Page from "@app/layouts/Page";
import { ApplicationForm } from "@app/modules/apply/Landing.stateless";

import MetaTags from "./MetaTags";

const useStyles = makeStyles((theme: Theme) => {
  const darkBlueGradient = Images.DarkBlueGradient;
  const lightBlueGradient = Images.LightBlueGradient;
  const hillsWithNotch = Images.HillsWithWhiteNotch;
  const hillsWithNotchLarge = Images.HillsNotchExtended;

  const arrows = Images.BrandarrowsBackgroundImageTrans;
  const leftPositionX = `calc( ((100vw - ${theme.breakpoints.values.md}px)/2) - 75vh )`;
  const rightPositionX = `calc( ((100vw - ${theme.breakpoints.values.md}px)/2) + ${theme.breakpoints.values.md}px )`;

  return createStyles({
    root: {},
    sky: {
      backgroundImage: `url(${hillsWithNotch}), url(${darkBlueGradient}) `,
      backgroundSize: "auto, cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "bottom left, top left",
      paddingBottom: `calc(100vh / 4)`,

      [theme.breakpoints.up("md")]: {
        backgroundImage: `url(${hillsWithNotchLarge}), url(${darkBlueGradient}) `,
        paddingBottom: `calc(100vh / 3.5)`,

        "@media (orientation: portrait)": {
          paddingBottom: `calc(100vh / 5.5)`,
        },
      },

      [theme.breakpoints.up("lg")]: {
        backgroundImage: `url(${hillsWithNotchLarge}), url(${darkBlueGradient}) `,
        paddingBottom: `calc(100vh / 3.5)`,
      },
      [theme.breakpoints.up("xl")]: {
        backgroundImage: `url(${hillsWithNotchLarge}), url(${darkBlueGradient}) `,
        paddingBottom: `calc(100vh / 2.75)`,
      },
    },
    arrows: {
      width: "100vw",
      height: "2000px",
      backgroundImage: `url(${arrows}),url(${arrows})`,
      backgroundSize: "100vh auto",
      backgroundRepeat: "repeat-y",
      backgroundPositionX: `${leftPositionX},${rightPositionX}`,
      backgroundPositionY: `${theme.spacing(8)}px, ${theme.spacing(18)}px`,
      minHeight: "calc(var(--vh, 1vh) * 100)",
    },
    masthead: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "90vh",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        minHeight: "100vh",
      },
    },

    masteheadTitleContainer: {
      color: "white",
      textAlign: "center",
    },

    titleContainer: {
      textAlign: "center",
      marginBottom: theme.spacing(6),
    },

    subheadline: {
      color: "white",
    },

    mainheadline: {
      marginTop: theme.spacing(6),
      color: "white",
    },

    avatarGroup: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    avatar: {
      border: "2px solid white",
      width: theme.spacing(8),
      height: theme.spacing(8),
      marginRight: -10,
    },

    chipContainer: {
      marginTop: theme.spacing(4),
    },
    chip: {
      color: "white",
      margin: theme.spacing(0.5),
    },

    faces: {
      position: "relative",
      width: "100%",
      height: theme.spacing(18),

      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(3),
      },

      "&  .face": {
        margin: 0,
        width: "20%",
        height: "auto",
        position: "absolute",
        zIndex: 2,
        padding: 0,

        "& img": {
          margin: 0,
          borderRadius: "50%",
        },
      },

      "& .face1": {
        top: 0,
        left: 0,
      },
      "& .face2": {
        left: "calc( 55% - (20% /2))",
        bottom: 0,
      },
      "& .face3": {
        left: "calc( 20% - (20% /2))",
        width: "15%",
        bottom: "5%",
      },
      "& .face4": {
        right: 0,
        [theme.breakpoints.up("md")]: {
          right: theme.spacing(4),
        },
        top: 0,
        width: "25%",
      },
      "& .face5": {
        left: "35%",
        top: "10%",
        width: "13%",
      },

      "& .line": {
        position: "absolute",
        zIndex: 1,
        background: `linear-gradient(to top right, transparent calc(50% - 1.5px), ${theme.palette.secondary.main}, transparent calc(50% + 1.5px) )`,
      },

      "& .trbl": {
        background: `linear-gradient(to bottom right, transparent calc(50% - 1.5px), ${theme.palette.secondary.main}, transparent calc(50% + 1.5px) ) !important`,
      },

      "& .line1": {
        top: `20%`,
        left: "10%",
        bottom: "15%",
        width: "8%",
      },
      "& .line2": {
        top: `20%`,
        left: "41%",
        bottom: "28%",
        width: "11%",
      },
      "& .line3": {
        top: `20%`,
        left: "10%",
        bottom: "15%",
        width: "45%",
      },
      "& .line4": {
        top: `18%`,
        left: "15%",
        bottom: "20%",
        right: "15%",
        [theme.breakpoints.up("md")]: {
          right: "20%",
        },
      },
    },

    networkDiagram: {
      border: "1px solid yellow",
      overflow: "hidden",

      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(3),
      },

      "& img": {
        width: 50,
        height: 50,
        border: "1px solid red",
      },
    },

    feature: {
      padding: theme.spacing(4),
      paddingBottom: theme.spacing(8),
      color: "white",

      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(8),

        "& p": {
          fontSize: "1.25em",
        },
      },
    },
    gridTwoUpPrimary: {
      order: 1,
      textAlign: "left",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& img": {
        width: "80%",
        height: "auto",
      },

      [theme.breakpoints.up("md")]: {
        justifyContent: "flex-start",
      },

      "& .smallface": {
        width: "auto",
        height: theme.spacing(7),
      },
      "& .arrow": {
        width: "auto",
        height: theme.spacing(2.5),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      [theme.breakpoints.down("sm")]: {
        "& img": {
          marginBottom: theme.spacing(3),
        },
        "&:nth-of-type(2)": {
          order: 2,
        },
      },
    },
    gridTwoUpSecondary: {
      order: 2,
      textAlign: "left",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& img": {
        width: "60%",
        marginLeft: "20%",
      },

      [theme.breakpoints.down("sm")]: {
        "& img": {
          marginBottom: theme.spacing(3),
        },
      },
    },

    whiteSection: {
      backgroundColor: "white",
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
      overflow: "hidden",
    },

    blueSection: {
      backgroundColor: theme.palette.primary.main,
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
      overflow: "hidden",

      "& .MuiTypography-root": {
        color: "white",
      },
    },

    aboutBox: {
      width: "100%",
      padding: theme.spacing(3),
      backgroundColor: "#fafafa",
      boxShadow: theme.shadows[1],
    },

    imageBullet: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-start",
      marginBottom: theme.spacing(4),

      [theme.breakpoints.up("md")]: {
        paddingRight: theme.spacing(4),
      },

      "& img": {
        width: 80,
        marginRight: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },
    },

    stepper: {
      marginTop: theme.spacing(6),
      borderRadius: 10,
      "& .MuiTypography-root": {
        color: "rgba(0, 0, 0, 0.87)",
      },
    },

    list: {
      "& .MuiListItemIcon-alignItemsFlexStart": {
        marginTop: 6,
      },
    },

    threeUpGrid: {
      textAlign: "center",
      marginBottom: theme.spacing(0),
      [theme.breakpoints.up("lg")]: {
        marginBottom: theme.spacing(2),
      },

      "& img": {
        height: theme.spacing(20),
      },
    },

    leftAlignedText: {
      "& .MuiTypography-body1": {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        [theme.breakpoints.up("lg")]: {},
      },
    },

    socialProofSection: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(6),
      overflow: "hidden",
      [theme.breakpoints.up("lg")]: {
        paddingTop: theme.spacing(6),
        background: "linear-gradient(90deg, #c6199a 50%, transparent 50%)",
      },
    },

    pullquote: {
      backgroundColor: "#c6199a",
      color: "white",
      fontSize: theme.spacing(3),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexWrap: "nowrap",
      [theme.breakpoints.up("lg")]: {
        fontSize: theme.spacing(3.5),
      },

      "& .MuiList-root": {
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",

        "& .MuiListItem-root": {
          width: "auto",
        },
      },

      "& .MuiAvatar-root": {
        border: "2px solid white",
      },
      "& .MuiAvatar-root img": {
        height: "auto",
      },
    },

    flyout: {
      "& .flyout-left": {
        [theme.breakpoints.up("lg")]: {
          order: 1,
        },
      },
      "& .flyout-body": {
        marginBottom: theme.spacing(3),
        [theme.breakpoints.up("lg")]: {
          marginBottom: theme.spacing(0),
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
          order: 2,
        },
      },
      "& .flyout-right": {
        [theme.breakpoints.up("lg")]: {
          order: 3,
        },
      },
      "& .flyout-wing": {
        position: "relative",
        borderRadius: theme.spacing(1.5),
        paddingTop: theme.spacing(1) - 1,
        "& img": {
          width: "100%",
          filter: "grayscale(70%)",
        },

        "& .MuiTypography-root": {
          position: "absolute",
          bottom: theme.spacing(0.5),
          padding: theme.spacing(0.5),
          backgroundColor: fade(theme.palette.primary.main, 0.65),
          width: "100%",
          color: "white",
          fontWeight: "bold",
          textAlign: "center",
        },
      },
    },

    getStartedSection: {
      overflow: "hidden",
      background: `url(${lightBlueGradient})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      paddingTop: theme.spacing(12),
    },
    footerImage: {
      marginTop: theme.spacing(10),
      width: "100%",
      marginBottom: -10,
    },

    link: {
      color: theme.palette.secondary.main,
      cursor: "pointer",
    },
  });
});

type Props = {
  applyWithLinkedIn: () => void;
  applyWithGoogle: () => void;
  applyWithNothing: () => void;
};

const IntroSkeleton: React.FC<Props> = () => {
  return (
    <section>
      <Container maxWidth="lg">TODO</Container>
    </section>
  );
};

const Landing: React.FC<Props> = ({
  applyWithNothing,
  applyWithGoogle,
  applyWithLinkedIn,
}) => {
  const classes = useStyles();

  return (
    <>
      <MetaTags />
      <div className={classes.sky}>
        <Parallax strength={-500}>
          <Background className={classes.arrows}>Hello</Background>
          <section className={classes.masthead}>
            <Container
              maxWidth="md"
              className={classes.masteheadTitleContainer}
            >
              <Typography
                paragraph
                variant="h5"
                className={classes.subheadline}
              >
                We're <Keyword>Higher</Keyword>.
              </Typography>
              <div className={classes.avatarGroup}>
                <Zoom cascade duration={300}>
                  <Avatar
                    className={classes.avatar}
                    alt="Adrian Duke"
                    src="https://firebasestorage.googleapis.com/v0/b/higher-5e33a.appspot.com/o/avatars%2Fadrian-duke.jpg?alt=media&token=73350362-2feb-4f0f-b463-1eaf98e53695"
                  />
                  <Avatar
                    className={classes.avatar}
                    alt="Petros Nearchou"
                    src="https://firebasestorage.googleapis.com/v0/b/higher-5e33a.appspot.com/o/avatars%2Fapplicants%2FPetros%20Nearchou.jpeg?alt=media&token=712f96bf-e399-4a9a-84a2-15b405d9f0ca"
                  />
                  <Avatar
                    className={classes.avatar}
                    alt="Gabriela Lacaci Infante"
                    src="https://firebasestorage.googleapis.com/v0/b/higher-5e33a.appspot.com/o/avatars%2Fgabriela-lacaci-Infante.jpg?alt=media&token=977c9694-595a-4239-85d3-6b32b7f1c375"
                  />

                  <Avatar
                    className={classes.avatar}
                    alt="Toby Levy"
                    src="https://firebasestorage.googleapis.com/v0/b/higher-5e33a.appspot.com/o/avatars%2Fapplicants%2Ftoby-levy.jpeg?alt=media&token=bfafe96f-bd96-489d-ac7a-a30caf82ebb2"
                  />
                  <Avatar
                    className={classes.avatar}
                    alt="Paul M Fox"
                    src="https://firebasestorage.googleapis.com/v0/b/higher-5e33a.appspot.com/o/avatars%2Fpaul-m-fox.jpg?alt=media&token=542c65bb-2470-4afb-8d4b-099b1a82ff56"
                  />
                </Zoom>
              </div>
              <Typography
                variant="h5"
                paragraph
                className={classes.subheadline}
              >
                We invest in people like nobody else.
              </Typography>
              <Typography variant="h4" className={classes.mainheadline}>
                Join us as a <Keyword>Growth Marketer</Keyword>.
              </Typography>

              <Fade>
                <div className={classes.chipContainer}>
                  <Chip
                    className={classes.chip}
                    variant="outlined"
                    color="secondary"
                    label="Remote (GMT +/- 4)"
                  />
                  <Chip
                    className={classes.chip}
                    variant="outlined"
                    color="secondary"
                    label="£45k - £65k"
                  />
                  <Chip
                    className={classes.chip}
                    variant="outlined"
                    color="secondary"
                    label="0.5% equity"
                  />
                </div>
              </Fade>
            </Container>
            <ScrollDown />
          </section>
          <section className={classes.feature}>
            <Container maxWidth="sm">
              <Grid container>
                <Grid item xs={12} md={6} className={classes.gridTwoUpPrimary}>
                  <div className={classes.faces}>
                    <div className="face face1">
                      <Zoom duration={250}>
                        <img src={Faces.Face1} alt="" />
                      </Zoom>
                    </div>
                    <div className="face face2">
                      <Zoom delay={100}>
                        <img src={Faces.Face2} alt="" />
                      </Zoom>
                    </div>
                    <div className="face face3">
                      <Zoom delay={200}>
                        <img src={Faces.Face3} alt="" />
                      </Zoom>
                    </div>
                    <div className="face face4">
                      <Zoom delay={300}>
                        <img src={Faces.Face4} alt="" />
                      </Zoom>
                    </div>
                    <div className="face face5">
                      <Zoom delay={400}>
                        <img src={Faces.Face6} alt="" />
                      </Zoom>
                    </div>
                    <Fade delay={400} cascade duration={600}>
                      <div className="line line1"></div>
                      <div className="line line4 trbl"></div>
                      <div className="line line2"></div>
                      <div className="line line3"></div>
                    </Fade>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  className={classes.gridTwoUpSecondary}
                >
                  <Fade>
                    <div>
                      <Typography>
                        Higher is a{" "}
                        <Keyword>new way of connecting people</Keyword> who can
                        help each other do great things.
                      </Typography>
                    </div>
                  </Fade>
                </Grid>
              </Grid>
            </Container>
          </section>
          <section className={classes.feature}>
            <Container maxWidth="sm">
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={6}
                  className={classes.gridTwoUpSecondary}
                >
                  <Zoom>
                    <img
                      src={Landingpages.FixRecruiting}
                      alt="Fix recruiting"
                    />
                  </Zoom>
                </Grid>
                <Grid item xs={12} md={6} className={classes.gridTwoUpPrimary}>
                  <Fade>
                    <Typography>
                      We're starting by fixing recruiting. The whole industry,
                      all of it. Think of Higher like{" "}
                      <Keyword>Kickstarter for careers</Keyword>.
                    </Typography>
                  </Fade>
                </Grid>
              </Grid>
            </Container>
          </section>

          <section className={classes.feature}>
            <Container maxWidth="sm">
              <Grid container>
                <Grid item xs={12} md={6} className={classes.gridTwoUpPrimary}>
                  <Zoom cascade duration={400}>
                    <img src={Faces.Face1} className="smallface" alt="" />
                    <img src={UpArrow.ArrowRight} className="arrow" alt="" />
                    <img src={Faces.Face3} className="smallface" alt="" />
                    <img src={UpArrow.ArrowRight} className="arrow" alt="" />
                    <img src={Faces.Face4} className="smallface" alt="" />
                  </Zoom>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  className={classes.gridTwoUpSecondary}
                >
                  <Fade>
                    <Typography>
                      Using a pioneering{" "}
                      <Keyword>peer-to-peer social investment platform</Keyword>
                      , we help professionals like you to recognise and promote
                      each other's awesomeness in the workplace.
                    </Typography>
                  </Fade>
                </Grid>
              </Grid>
            </Container>
          </section>

          <section className={classes.feature}>
            <Container maxWidth="sm">
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={6}
                  className={classes.gridTwoUpSecondary}
                >
                  <Zoom>
                    <img src={Images.BeHuman} alt="Be human" />
                  </Zoom>
                </Grid>
                <Grid item xs={12} md={6} className={classes.gridTwoUpPrimary}>
                  <Fade>
                    <div>
                      <Typography paragraph>
                        <Keyword>We do things the human way</Keyword>. No AI or
                        complicated, impersonal systems. Just <em>us</em>,
                        working together.
                      </Typography>
                      <Typography>
                        Oh, and we recycle system failure into charitable
                        donations. We turn losses into wins.
                      </Typography>
                    </div>
                  </Fade>
                </Grid>
              </Grid>
            </Container>
          </section>
        </Parallax>
      </div>
      <section className={classes.whiteSection}>
        <Container maxWidth="sm" className={classes.titleContainer}>
          <Typography variant="h4" paragraph>
            We're making something special. Help us tell the world.
          </Typography>
        </Container>
        <Container maxWidth="lg">
          <Grid
            container
            spacing={6}
            className={[classes.threeUpGrid, classes.leftAlignedText].join(" ")}
          >
            <Grid item xs={12} md={4}>
              <Zoom duration={400}>
                <img
                  src={Icons.Customisation}
                  alt="B2B SaaS product seeks evil genius"
                />
              </Zoom>
              <Typography variant="h6" paragraph>
                B2B SaaS product seeks evil genius
              </Typography>
              <Typography>
                <Keyword>
                  We're currently missing an exceptional, self-motivated
                  marketing lead
                </Keyword>{" "}
                who'll be responsible for our digital marketing and community
                growth.
              </Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Zoom delay={200} duration={400}>
                <img src={Icons.AutomatedShorlisting} alt="Be a supervillain" />
              </Zoom>
              <Typography variant="h6" paragraph>
                Be a supervillain
              </Typography>
              <Typography>
                <Keyword>
                  This is a permanent, full-time, remote position
                </Keyword>{" "}
                which we expect to evolve into a managerial and, eventually,
                C-level position.
              </Typography>
            </Grid>

            <Grid item xs={12} md={4}>
              <Zoom delay={320} duration={400}>
                <img src={Icons.ChatBlue} alt="The world is yours" />
              </Zoom>
              <Typography variant="h6" paragraph>
                The world is yours
              </Typography>
              <Typography>
                <Keyword>Your input will be critical</Keyword>, and it will help
                determine everything about our future. No pressure, but we're
                counting on you &mdash; and you can count on us.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </section>
      <section className={classes.socialProofSection}>
        <Container maxWidth="lg">
          <Grid container spacing={6}>
            <Grid item xs={12} md={6} className={classes.pullquote}>
              <div>
                <blockquote>
                  "I may be new to my Higher journey but it feels like I've been
                  here for <strong>much</strong> longer... Adrian and Paul know
                  their strengths and are{" "}
                  <strong>focused on hiring people</strong> who have strengths
                  that are different to their own"
                </blockquote>
                <List>
                  <ListItem>
                    <ListItemAvatar>
                      <Zoom duration={300}>
                        <Avatar
                          alt="Toby Levy"
                          src="https://firebasestorage.googleapis.com/v0/b/higher-5e33a.appspot.com/o/avatars%2Fapplicants%2Ftoby-levy.jpeg?alt=media&token=bfafe96f-bd96-489d-ac7a-a30caf82ebb2"
                        />
                      </Zoom>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography variant="h6">
                          Toby, Business Developer
                        </Typography>
                      }
                      secondary={
                        <Typography variant="body2">
                          Statement given of his own free will
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card>
                <CardHeader
                  style={{ textAlign: "center" }}
                  title="Learn more about Higher"
                />
                <CardContent>
                  <List>
                    <ListItem>
                      <ListItemIcon>
                        <HomeIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography>
                            Take a look at our{" "}
                            <LinkTo href="/">customer-facing website</LinkTo>
                          </Typography>
                        }
                      />
                    </ListItem>

                    <ListItem>
                      <ListItemIcon>
                        <ReadIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography>
                            Read an{" "}
                            <LinkTo href="https://medium.com/get-higher/talent-ranking-evolved-introducing-higher-29a2589a694d">
                              introductory article
                            </LinkTo>{" "}
                            about the platform
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <ComputerIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography>
                            Try out our{" "}
                            <LinkTo href="/apply/demo">application</LinkTo>,{" "}
                            <LinkTo href="/dashboard/demo">dashboard</LinkTo>{" "}
                            and{" "}
                            <LinkTo href="/endorse/demo">endorsement</LinkTo>{" "}
                            product demos
                          </Typography>
                        }
                      />
                    </ListItem>

                    <ListItem>
                      <ListItemIcon>
                        <LinkedInIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography>
                            Connect with us on{" "}
                            <LinkTo href="https://www.linkedin.com/company/72450643/admin/">
                              LinkedIn
                            </LinkTo>
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <TwitterIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography>
                            Follow us on{" "}
                            <LinkTo href="https://twitter.com/higher_ats">
                              Twitter
                            </LinkTo>
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <ChatIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography>
                            Ask us any questions on our{" "}
                            <LinkToLiveChat>live chat</LinkToLiveChat>
                          </Typography>
                        }
                      />
                    </ListItem>
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </section>

      <section className={classes.whiteSection}>
        <Container
          maxWidth="sm"
          className={classes.titleContainer}
          style={{ marginTop: 40 }}
        >
          <Typography variant="h5">How we roll</Typography>
        </Container>
        <Container maxWidth="lg">
          <List>
            <Grid container>
              <Grid xs={12} md={4}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <TeamIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="h6">
                        <Keyword>Small</Keyword> (but mighty){" "}
                        <Keyword>team</Keyword>
                      </Typography>
                    }
                    secondary={
                      <Typography>Based in the UK and Dubai</Typography>
                    }
                  />
                </ListItem>
              </Grid>

              <Grid xs={12} md={4}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <MeetingIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="h6">
                        <Keyword>Daily standups</Keyword>
                      </Typography>
                    }
                    secondary={<Typography>Currently at 9am GMT</Typography>}
                  />
                </ListItem>
              </Grid>

              <Grid xs={12} md={4}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <HomeWorkIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="h6">
                        <Keyword>Fully remote</Keyword>
                      </Typography>
                    }
                    secondary={
                      <Typography>
                        Work from home, WeWork or anywhere
                      </Typography>
                    }
                  />
                </ListItem>
              </Grid>

              <Grid xs={12} md={4}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <CodeIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="h6">
                        <Keyword>Technical founders</Keyword>
                      </Typography>
                    }
                    secondary={
                      <Typography>
                        Software engineering, blockchain world
                      </Typography>
                    }
                  />
                </ListItem>
              </Grid>
              <Grid xs={12} md={4}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <ToolsIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="h6">
                        <Keyword>Modern tools</Keyword>
                      </Typography>
                    }
                    secondary={
                      <Typography>
                        Slack, Hangouts, MixMax and Firebase
                      </Typography>
                    }
                  />
                </ListItem>
              </Grid>
              <Grid xs={12} md={4}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <HeartIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Typography variant="h6">
                        <Keyword>We use our own product</Keyword>
                      </Typography>
                    }
                    secondary={
                      <Typography>We'll use it to hire you!</Typography>
                    }
                  />
                </ListItem>
              </Grid>
            </Grid>
          </List>
        </Container>
      </section>
      <section className={classes.blueSection}>
        <Container maxWidth="sm" className={classes.titleContainer}>
          <Typography variant="h4" paragraph>
            Are we a <Keyword>great match</Keyword>, or what?
          </Typography>
          <Typography variant="h6" paragraph style={{ marginTop: 32 }}>
            Let's do a quick, totally scientific quiz.
          </Typography>
        </Container>
        <Container maxWidth="sm">
          <Quiz />
        </Container>
        <Container maxWidth="sm" className={classes.titleContainer}>
          <Typography
            variant="h6"
            paragraph
            style={{ marginTop: 40, padding: 16 }}
          >
            <Keyword>But wait!</Keyword> We're probably not a good match if
            you...
          </Typography>
          <List className={classes.list}>
            <ListItem alignItems="flex-start">
              <ListItemIcon>
                <CancelIcon color="secondary" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography>
                    Aren’t comfortable with <Keyword>ambiguity</Keyword>,
                    devising hypotheses or thinking from first principles.
                  </Typography>
                }
              />
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListItemIcon>
                <CancelIcon color="secondary" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography>
                    Don’t enjoy working in a{" "}
                    <Keyword>fast-paced, remote environment</Keyword>.
                  </Typography>
                }
              />
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListItemIcon>
                <CancelIcon color="secondary" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography>
                    Aren’t happy taking an <Keyword>MVP approach</Keyword>{" "}
                    &mdash; doing what’s good enough for now, with iterative
                    changes to follow.
                  </Typography>
                }
              />
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListItemIcon>
                <CancelIcon color="secondary" />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography>
                    <Keyword>Are uncomfortable with money</Keyword>. We use
                    stakes and collateral as part of our product.
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Container>
      </section>
      <section className={classes.whiteSection}>
        <Container maxWidth="sm" className={classes.titleContainer}>
          <Typography variant="h4" paragraph>
            Why choose us?
          </Typography>
        </Container>
        <Container maxWidth="lg">
          <Grid container spacing={6} className={classes.threeUpGrid}>
            <Grid item xs={12} md={4}>
              <Zoom duration={400}>
                <img
                  src={Icons.LeanestFeesPossible}
                  alt="We'll pay you, obviously"
                />
              </Zoom>
              <Typography variant="h6" paragraph>
                We'll pay you, obviously
              </Typography>
              <Typography>
                We're offering <Keyword>£45,000 - £65,000</Keyword> (depending
                on experience) and up to <Keyword>0.5%</Keyword> of the company.
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Zoom delay={200} duration={400}>
                <img src={Icons.CalendarBlue} alt="Genuine flexibility" />
              </Zoom>
              <Typography variant="h6" paragraph>
                Genuine flexibility, your way
              </Typography>
              <Typography>
                Spend time with your kids, live your life. Take{" "}
                <Keyword>unlimited holidays</Keyword>, as long as the work gets
                done.
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Zoom delay={300} duration={400}>
                <img src={Icons.ChatBlue} alt="We want to say Yes!" />
              </Zoom>
              <Typography variant="h6" paragraph>
                We want to say <Keyword>Yes!</Keyword>
              </Typography>
              <Typography>
                We love new viewpoints and approaches. All the best ideas start
                with, <Keyword>Now this might sound crazy, but...</Keyword>
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </section>
      <section className={classes.whiteSection}>
        <Container maxWidth="sm" className={classes.titleContainer}>
          <Typography variant="h5" paragraph>
            About the founders
          </Typography>
        </Container>
        <Container maxWidth="md" className={classes.flyout}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} className="flyout-body">
              <Typography variant="body1" paragraph>
                We’ve been working together since 2015, building products for
                the likes of <Keyword>BBC</Keyword>, <Keyword>MTV</Keyword>,{" "}
                <Keyword>Cancer Research UK</Keyword>, <Keyword>UNICEF</Keyword>{" "}
                and <Keyword>PayPal</Keyword>. Between us we’ve started 4
                start-ups and successfully helped many others.
              </Typography>
              <Typography variant="body1">
                We also run a profitable digital agency called{" "}
                <Keyword>Endian</Keyword>, which works with some of the largest
                companies in the world, including <Keyword>Shell</Keyword> and{" "}
                <Keyword>Google</Keyword>. Our services include software
                development, consulting and technical recruitment &mdash; which
                is where Higher was born.
              </Typography>
            </Grid>
            <Grid item xs={6} md={3} className="flyout-left">
              <div className="flyout-wing">
                <img
                  alt="Adrian Duke"
                  src="https://firebasestorage.googleapis.com/v0/b/higher-5e33a.appspot.com/o/higher%2Fadrian.jpg?alt=media&token=7fa6d2c2-2318-42fa-b24e-cf8e5c405205"
                />
                <Fade>
                  <Typography>
                    Adrian Duke <Keyword>CEO</Keyword>
                  </Typography>
                </Fade>
              </div>
            </Grid>
            <Grid item xs={6} md={3} className=" flyout-right">
              <div className="flyout-wing">
                <img
                  alt="Paul M Fox"
                  src="https://firebasestorage.googleapis.com/v0/b/higher-5e33a.appspot.com/o/higher%2Fpaul.jpg?alt=media&token=6d2cc8d0-f174-4ad8-a234-57d6ff42aa5e"
                />
                <Fade delay={500}>
                  <Typography>
                    Paul M Fox <Keyword>CTO</Keyword>
                  </Typography>
                </Fade>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>
      <section className={classes.getStartedSection}>
        <Container maxWidth="sm" className={classes.titleContainer}>
          <Typography variant="h4" paragraph>
            Let's make this happen
          </Typography>
        </Container>
        <Container maxWidth="sm">
          <Card>
            <CardContent>
              <Typography paragraph>
                <Keyword>We use Higher to hire for Higher</Keyword> (try saying
                that five times fast) so you’ll be entered into the process to
                qualify for this role.
              </Typography>
              <Typography variant="body2">
                We’re keen to use our own product from the start, which will
                make you a <Keyword>beta tester</Keyword>. Bonus points for
                feedback on the process! We love to hear anything about it, good
                or bad.
              </Typography>
            </CardContent>
          </Card>
        </Container>
        <ApplicationForm
          applyWithNothing={applyWithNothing}
          applyWithGoogle={applyWithGoogle}
          applyWithLinkedIn={applyWithLinkedIn}
        />
        <img
          className={classes.footerImage}
          src={Images.HillsNoNotch}
          alt="Higher"
        />
      </section>
    </>
  );
};

type LinkToProps = {
  href: string;
};

const LinkTo: React.FC<LinkToProps> = ({ href, children }) => {
  const classes = useStyles();

  const handleClick = () => {
    openInNewTab(href);
  };

  return (
    <span className={classes.link} onClick={handleClick}>
      {children}
    </span>
  );
};

const LinkToLiveChat: React.FC = ({ children }) => {
  const classes = useStyles();

  const handleClick = () => {
    openSlaask();
  };

  return (
    <span className={classes.link} onClick={handleClick}>
      {children}
    </span>
  );
};

type Question = {
  label: JSX.Element;
  details: JSX.Element;
  buttonLabel: string;
};

/*
 **Can deliver value for early stage startup(s)**. Have been in the first 10 hires for a startup.
 */

const steps: Array<Question> = [
  {
    label: (
      <Typography>
        Can you{" "}
        <Keyword>
          plan, execute and own effective, multi-channel marketing campaigns
        </Keyword>
        ?
      </Typography>
    ),
    details: (
      <Typography paragraph variant="body2">
        You'll have experience brainstorming, planning, developing campaign
        strategies and executing campaigns that result in user growth.
      </Typography>
    ),
    buttonLabel: "Yes, absolutely",
  },
  {
    label: (
      <Typography>
        Can you <Keyword>market B2B SaaS products</Keyword>?
      </Typography>
    ),
    details: (
      <Typography paragraph variant="body2">
        You'll have experience in B2B SaaS Marketing or B2B Demand Generation
        Marketing.
      </Typography>
    ),
    buttonLabel: "Of course",
  },
  {
    label: (
      <Typography>
        Can you <Keyword>collborate with a sales team</Keyword>?
      </Typography>
    ),
    details: (
      <Typography paragraph variant="body2">
        You will have experience integrating feedback and offering insights to
        create cohesive and effective user-acquisition pipelines.
      </Typography>
    ),
    buttonLabel: "I'll rock their world",
  },
  {
    label: (
      <Typography>
        Can you <Keyword>own and build a brand</Keyword>?
      </Typography>
    ),
    details: (
      <Typography paragraph variant="body2">
        This requires experience building and growing brands from (almost)
        nothing.
      </Typography>
    ),
    buttonLabel: "That's what I do",
  },
  {
    label: (
      <Typography>
        Can you <Keyword>deliver value for an early stage startup?</Keyword>
      </Typography>
    ),
    details: (
      <Typography paragraph variant="body2">
        You'll have been in the first 10 hires for a startup previously.
      </Typography>
    ),
    buttonLabel: "It's not my first rodeo",
  },
];

const Quiz: React.FC = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Stepper
      className={classes.stepper}
      activeStep={activeStep}
      orientation="vertical"
    >
      {steps.map((step, index) => (
        <Step key={index}>
          <StepLabel>{step.label}</StepLabel>
          <StepContent>
            {step.details}

            <div>
              <div>
                <MUIButton disabled={activeStep === 0} onClick={handleBack}>
                  Back
                </MUIButton>
                <MUIButton
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                >
                  {step.buttonLabel}
                </MUIButton>
              </div>
            </div>
          </StepContent>
        </Step>
      ))}
      <Step>
        <StepLabel
          icon={
            activeStep >= steps.length ? (
              <HeartIcon color="secondary" />
            ) : (
              <QuestionIcon color="primary" />
            )
          }
        >
          <Keyword>Results</Keyword>
        </StepLabel>
        <StepContent>
          <Alert icon={false} severity="success">
            <AlertTitle>Looks like we're a match!</AlertTitle> You can't argue
            with science. 60% of the time, it works every time.
          </Alert>
          <MUIButton disabled={activeStep === 0} onClick={handleBack}>
            Back
          </MUIButton>
        </StepContent>
      </Step>
    </Stepper>
  );
};

export const LandingSkeleton: React.FC = () => (
  <Page>
    <IntroSkeleton
      applyWithLinkedIn={() => {}}
      applyWithGoogle={() => {}}
      applyWithNothing={() => {}}
    />
  </Page>
);

export default Landing;
