import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Container, LinearProgress } from "@material-ui/core";

import Applicant from "@higher/models/Applicant";
import Role from "@higher/models/Role";

import { Images, Logos } from "@app/brand";

import ParticipantAvatars from "./ParticipantAvatars";

const useStyles = makeStyles((theme: Theme) => {
  const balloon = Images.BrandballoonPrimary;
  const arrows = Images.BrandarrowsBackgroundImageTrans;
  const curve = Images.CurveTop;
  const logo = Logos.PrimaryCenteredOpenbeta;

  return createStyles({
    root: {
      backgroundImage: `url(${balloon}), url(${arrows}), url(${arrows}), url(${curve}), url(${logo})`,
      backgroundSize: "81vh, cover, 100vh, 160vw 20vh, 20vw",
      backgroundRepeatX: "no-repeat",
      backgroundRepeat: "no-repeat, repeat, no-repeat, no-repeat",
      backgroundPositionX: `-16vw, -50px, 0%, right, 75vw`,
      backgroundPositionY: `-25vh, -80px, 100px, 100%, 5vh`,
      height: 628,
      width: 1200,
      display: "flex",
      alignContent: "center",
    },
    inner: {
      paddingTop: theme.spacing(4),
      alignSelf: "center",
      justifySelf: "center",
      transform: "scale(3)",
    },
  });
});

type Props = {
  applicant: Applicant;
  role: Role;
};

const Social: React.FC<Props> = ({ applicant, role }) => {
  const classes = useStyles();
  return (
    <Container maxWidth={false} className={classes.root}>
      <Container maxWidth="sm" className={classes.inner}>
        <ParticipantAvatars applicant={applicant} role={role} />
      </Container>
    </Container>
  );
};

export const SocialSkeleton: React.FC = () => (
  <LinearProgress color="secondary" />
);

export default Social;
