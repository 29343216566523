import { Button as MUIButton } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import React, { FC } from "react";

import useTracking from "@app/hooks/useTracking";

import { UpArrow } from "../imagery";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: "1em",
      padding: "1em",
      width: "100%",
    },
    cta: {
      fontSize: "1.2em",
    },
    smallText: {
      fontSize: "1rem",
    },
    small: {
      fontSize: "1rem",
      width: "auto",
      paddingTop: "0.5rem",
      paddingBottom: "0.5rem",
    },
    medium: {
      marginBottom: "1em",
      [theme.breakpoints.up("lg")]: {},
    },
    compact: {
      margin: "0",
      padding: "0",
    },
  })
);

type Variant = "text" | "contained" | "outlined" | undefined;
type Colour = "primary" | "secondary";

export type Props = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  cta?: boolean;
  secondary?: boolean;
  small?: boolean;
  medium?: boolean;
  className?: string;
  disabled?: boolean;
  compact?: boolean;
  icon?: JSX.Element;
  startIcon?: JSX.Element;
  tertiary?: boolean;
  smallText?: boolean;
  trackingId?: string;
};

const Button: FC<Props> = ({
  cta,
  children,
  onClick,
  secondary,
  small,
  medium,
  className,
  disabled,
  compact,
  icon,
  startIcon,
  tertiary,
  smallText,
  trackingId,
}) => {
  const classes = useStyles();
  const { logButtonClick } = useTracking();
  const classNames = [classes.root];
  let startIconEl = null;
  let endIcon = null;
  let variant: Variant = "contained";
  let colour: Colour = "primary";

  if (cta) {
    classNames.push(classes.cta);
    endIcon = <img src={UpArrow.ArrowRight} style={{ height: "1em" }} alt="" />;
  }

  if (icon) {
    endIcon = icon;
  }

  if (startIcon) {
    startIconEl = startIcon;
  }

  if (secondary) {
    variant = "text";
    colour = "secondary";
  }

  if (tertiary) {
    variant = "outlined";
    colour = "secondary";
  }

  if (small) {
    classNames.push(classes.small);
  }

  if (medium) {
    classNames.push(classes.medium);
  }

  if (className) {
    classNames.push(className);
  }

  if (compact) {
    classNames.push(classes.compact);
  }

  if (smallText) {
    classNames.push(classes.smallText);
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (trackingId) {
      logButtonClick(trackingId);
    }

    if (onClick) {
      onClick(event);
    }
  };

  return (
    <MUIButton
      variant={variant}
      color={colour}
      className={classNames.join(" ")}
      onClick={handleClick}
      size="large"
      endIcon={endIcon}
      startIcon={startIconEl}
      disabled={disabled}
    >
      {children}
    </MUIButton>
  );
};

export default Button;
