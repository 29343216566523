import {
  Avatar,
  Box,
  Container,
  Grid,
  Typography,
  Card,
  CardHeader,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import { useRecoilValue } from "recoil";

import Applicant from "@higher/models/Applicant";
import Organisation from "@higher/models/Organisation";
import Role from "@higher/models/Role";

import { Accent, Link } from "@app/brand";
import Markdown from "@app/components/Markdown";
import asyncComponent from "@app/components/asyncComponent";
import useFirestoreValue from "@app/hooks/useFirestoreValue";
import ApplicantById from "@app/state/ApplicantById";
import EventsByOrganisation from "@app/state/EventsByOrganisation";
import RoleById from "@app/state/RoleById";
import StagesByRole from "@app/state/StagesByRole";

import MessageDeliveryStatus from "./MessageDeliveryStatus";
import Section from "./Section";
import Timeline, {
  EventProps,
  EventContentViewProps,
  TimeAgo,
  Username,
  StageName,
} from "./Timeline";

type Props = {
  org: Organisation;
  roles: Array<Role>;
};

const useStyles = makeStyles((theme: Theme) => {
  const blockHeight = 2;
  return createStyles({
    card: {},
    projects: {
      backgroundColor: theme.palette.primary.main,
      backgroundSize: "100%",
      backgroundRepeat: "no-repeat",
      backgroundPositionY: "bottom",
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    diagram: {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      backgroundColor: "#efefef",
      height: theme.spacing(blockHeight * 5 + 6),
    },
    box: {
      width: "100%",
      height: theme.spacing(blockHeight),
      marginBottom: theme.spacing(0.5),
      backgroundColor: "#ccc",
    },
    ellipsis: {
      height: theme.spacing(blockHeight),
      textAlign: "center",
      fontSize: "1em",
      color: "#aaa",
    },
    column: {
      padding: 4,
    },
    timelineComment: {
      border: "1px solid #efefef",
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(1.5),
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
      width: "100%",
      backgroundColor: "#fdfdfd",

      "& p": {
        fontSize: "1em",
        "&:last-of-type": {
          marginBottom: 0,
        },
      },
      "& ol, ul": {
        marginBottom: theme.spacing(1.5),
      },
    },
    timelineDelivery: {
      position: "absolute",
      right: 0,
      top: theme.spacing(1),
    },
    loading: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  });
});

const Home: React.FC<Props> = ({ roles, org }) => {
  const classes = useStyles();
  return (
    <>
      <Section title="Active roles" hideTitle noPadding>
        <div className={classes.projects}>
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              {roles.map((role) => (
                <Grid item xs={12} md={4}>
                  <Link to={`/dashboard/roles/${role.id}`}>
                    <RoleCard role={role} />
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Container>
        </div>
      </Section>
      <Container maxWidth="lg">
        <Section title="Recent activity" noPadding />
        <Events org={org} />
      </Container>
    </>
  );
};

type CardProps = {
  role: Role;
};

const RoleCard: React.FC<CardProps> = ({ role }) => {
  const classes = useStyles();
  return (
    <Card onClick={() => {}} className={classes.card}>
      <CardHeader
        avatar={<Avatar aria-label={role.employer} src={role.avatar} />}
        title={
          <Link to={`/dashboard/roles/${role.id}`}>
            <Typography variant="h6">{role.title}</Typography>
          </Link>
        }
        subheader={`${role.location} · ${role.salary}`}
      />
      <BoardDiagram role={role} />
    </Card>
  );
};

const Loading: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.loading}>
      <CircularProgress />
    </div>
  );
};

const BoardDiagram = asyncComponent<CardProps>(
  ({ role }) => {
    const classes = useStyles();
    const stages = useRecoilValue(StagesByRole(role));
    return (
      <div className={classes.diagram}>
        <Grid container spacing={1}>
          {stages.map((stage) => {
            const applicants = [];
            const count = stage.applicantCount;
            const limit = Math.min(count, 4);
            for (let i = 0; i < limit; i++) {
              applicants.push(<ApplicantDigram key={i} />);
            }
            return (
              <Grid item xs>
                {applicants}
                {count > 4 && (
                  <Box className={classes.ellipsis}>+{count - 4}</Box>
                )}
              </Grid>
            );
          })}
        </Grid>
      </div>
    );
  },
  () => {
    const classes = useStyles();
    return (
      <div className={classes.diagram}>
        <Loading />
      </div>
    );
  }
);

const ApplicantDigram: React.FC = () => {
  const classes = useStyles();
  return <Box className={classes.box} />;
};

type ApplicantProps = {
  applicant: Applicant;
};

const ApplicantLink: React.FC<ApplicantProps> = ({ applicant }) => {
  return (
    <Link to={`/dashboard/roles/${applicant.roleId}/${applicant.id}`}>
      {applicant.name}
    </Link>
  );
};

type RoleProps = {
  role: Role;
};

const RoleLink: React.FC<RoleProps> = ({ role }) => {
  return <Link to={`/dashboard/roles/${role.id}`}>{role.title}</Link>;
};

const EventContentAdded: React.FC<EventProps> = ({ event }) => {
  const [applicant] = useFirestoreValue(ApplicantById(event.subjectId));
  const [role] = useFirestoreValue(RoleById(applicant.roleId));
  return (
    <Typography variant="body2" color="textSecondary">
      <Username id={event.initiatorId} /> <Accent>manually added</Accent>{" "}
      <ApplicantLink applicant={applicant} /> to <RoleLink role={role} />{" "}
      {event.date && <TimeAgo date={event.date.toDate()} />}
    </Typography>
  );
};

const EventContentApplication: React.FC<EventProps> = ({ event }) => {
  const [applicant] = useFirestoreValue(ApplicantById(event.subjectId));
  const [role] = useFirestoreValue(RoleById(applicant.roleId));
  return (
    <Typography variant="body2" color="textSecondary">
      <ApplicantLink applicant={applicant} /> <Accent>applied</Accent> for{" "}
      <RoleLink role={role} />{" "}
      {event.date && <TimeAgo date={event.date.toDate()} />}
    </Typography>
  );
};

const EventContentEndorsement: React.FC<EventProps> = ({ event }) => {
  const [applicant] = useFirestoreValue(ApplicantById(event.subjectId));
  const [role] = useFirestoreValue(RoleById(applicant.roleId));
  return (
    <Typography variant="body2" color="textSecondary">
      <ApplicantLink applicant={applicant} /> was <Accent>endorsed</Accent> for{" "}
      <RoleLink role={role} />{" "}
      {event.date && <TimeAgo date={event.date.toDate()} />}
    </Typography>
  );
};

const EventContentComment: React.FC<EventProps> = ({ event }) => {
  const classes = useStyles();
  const [applicant] = useFirestoreValue(ApplicantById(event.subjectId));
  const [role] = useFirestoreValue(RoleById(applicant.roleId));
  return (
    <>
      <Typography variant="body2" color="textSecondary">
        <Username id={event.initiatorId} /> <Accent>commented</Accent> on{" "}
        <ApplicantLink applicant={applicant} />
        's application for <RoleLink role={role} />{" "}
        {event.date && <TimeAgo date={event.date.toDate()} />}
      </Typography>

      <Container maxWidth={false} className={classes.timelineComment}>
        <Markdown source={event.content || ""} />
      </Container>
    </>
  );
};

const EventContentStageChange: React.FC<EventProps> = ({ event }) => {
  const [applicant] = useFirestoreValue(ApplicantById(event.subjectId));
  const [role] = useFirestoreValue(RoleById(applicant.roleId));
  return (
    <Typography variant="body2" color="textSecondary">
      <Username id={event.initiatorId} /> moved{" "}
      <ApplicantLink applicant={applicant} /> to{" "}
      {event.content ? (
        <StageName
          organisationId={applicant.organisationId}
          roleId={applicant.roleId}
          id={event.content}
        />
      ) : (
        "Unknown stage"
      )}{" "}
      for <RoleLink role={role} />{" "}
      {event.date && <TimeAgo date={event.date.toDate()} />}
    </Typography>
  );
};

const EventContentRankChange: React.FC<EventProps> = ({ event }) => {
  const [applicant] = useFirestoreValue(ApplicantById(event.subjectId));
  const [role] = useFirestoreValue(RoleById(applicant.roleId));

  if (!event.content || event.content === "undefined") {
    return (
      <Typography variant="body2" color="textSecondary">
        <ApplicantLink applicant={applicant} /> <Accent>lost their rank</Accent>{" "}
        for <RoleLink role={role} />{" "}
        {event.date && <TimeAgo date={event.date.toDate()} />}
      </Typography>
    );
  }

  return (
    <Typography variant="body2" color="textSecondary">
      <ApplicantLink applicant={applicant} /> was{" "}
      <Accent>ranked #{event.content}</Accent> for <RoleLink role={role} />{" "}
      {event.date && <TimeAgo date={event.date.toDate()} />}
    </Typography>
  );
};

const EventContentWithdrawl: React.FC<EventProps> = ({ event }) => {
  const [applicant] = useFirestoreValue(ApplicantById(event.subjectId));
  const [role] = useFirestoreValue(RoleById(applicant.roleId));
  return (
    <Typography variant="body2" color="textSecondary">
      <ApplicantLink applicant={applicant} /> <Accent>withdrew</Accent> their
      application for <RoleLink role={role} />{" "}
      {event.date && <TimeAgo date={event.date.toDate()} />}
    </Typography>
  );
};

const EventContentReview: React.FC<EventProps> = ({ event }) => {
  const [applicant] = useFirestoreValue(ApplicantById(event.subjectId));
  const [role] = useFirestoreValue(RoleById(applicant.roleId));
  return (
    <Typography variant="body2" color="textSecondary">
      <Username id={event.initiatorId} /> gave{" "}
      <ApplicantLink applicant={applicant} /> a <Accent>{event.content}</Accent>{" "}
      for <RoleLink role={role} />{" "}
      {event.date && <TimeAgo date={event.date.toDate()} />}
    </Typography>
  );
};

const EventIncomingMessage: React.FC<EventProps> = ({ event }) => {
  const classes = useStyles();
  const [applicant] = useFirestoreValue(ApplicantById(event.subjectId));
  const [role] = useFirestoreValue(RoleById(applicant.roleId));
  return (
    <>
      <Typography variant="body2" color="textSecondary">
        <ApplicantLink applicant={applicant} /> <Accent>sent a message</Accent>{" "}
        about their application for <RoleLink role={role} />{" "}
        {event.date && <TimeAgo date={event.date.toDate()} />}
      </Typography>

      <Container maxWidth={false} className={classes.timelineComment}>
        <Markdown source={event.content || ""} />
      </Container>
    </>
  );
};

const EventOutgoingMessage: React.FC<EventProps> = ({ event }) => {
  const classes = useStyles();
  const [applicant] = useFirestoreValue(ApplicantById(event.subjectId));
  const [role] = useFirestoreValue(RoleById(applicant.roleId));
  return (
    <>
      <Typography variant="body2" color="textSecondary">
        <Username id={event.initiatorId} /> <Accent>sent a message</Accent> to{" "}
        <ApplicantLink applicant={applicant} /> about their application for{" "}
        <RoleLink role={role} />{" "}
        {event.date && <TimeAgo date={event.date.toDate()} />}
      </Typography>

      <Container maxWidth={false} className={classes.timelineComment}>
        <Markdown source={event.content || ""} />
      </Container>
      {event.entityId && (
        <div className={classes.timelineDelivery}>
          <MessageDeliveryStatus
            orgId={applicant.organisationId}
            messageId={event.entityId}
          />
        </div>
      )}
    </>
  );
};

const EventRemoval = asyncComponent<EventProps>(
  ({ event }) => {
    const [applicant] = useFirestoreValue(ApplicantById(event.subjectId));
    const [role] = useFirestoreValue(RoleById(applicant.roleId));

    return (
      <Typography variant="body2" color="textSecondary">
        <Username id={event.initiatorId} /> <Accent>removed</Accent>{" "}
        <ApplicantLink applicant={applicant} />
        's <RoleLink role={role} /> application
        {event.content && (
          <>
            {" "}
            (Reason: <Accent>{event.content}</Accent>)
          </>
        )}{" "}
        {event.date && <TimeAgo date={event.date.toDate()} />}
      </Typography>
    );
  },
  () => null
);

const EventRestoration = asyncComponent<EventProps>(
  ({ event }) => {
    const [applicant] = useFirestoreValue(ApplicantById(event.subjectId));
    const [role] = useFirestoreValue(RoleById(applicant.roleId));

    return (
      <Typography variant="body2" color="textSecondary">
        <Username id={event.initiatorId} /> <Accent>re-added</Accent>{" "}
        <ApplicantLink applicant={applicant} />
        's <RoleLink role={role} /> application{" "}
        {event.date && <TimeAgo date={event.date.toDate()} />}
      </Typography>
    );
  },
  () => null
);

const TimelineContentViews: EventContentViewProps = {
  AddedView: EventContentAdded,
  ApplicationView: EventContentApplication,
  EndorsementView: EventContentEndorsement,
  CommentView: EventContentComment,
  StageChangeView: EventContentStageChange,
  RankChangeView: EventContentRankChange,
  WithdrawlView: EventContentWithdrawl,
  ReviewView: EventContentReview,
  IncomingMessageView: EventIncomingMessage,
  OutgoingMessageView: EventOutgoingMessage,
  RemovalMessageView: EventRemoval,
  RestorationMessageView: EventRestoration,
};

type EventsProps = {
  org: Organisation;
};

const Events = asyncComponent<EventsProps>(({ org }) => {
  const events = useRecoilValue(EventsByOrganisation(org));
  return <Timeline events={events} {...TimelineContentViews} />;
}, Loading);

export const HomeSkeleton: React.FC = () => {
  return (
    <div>
      <Skeleton variant="rect" />
    </div>
  );
};

export default Home;
