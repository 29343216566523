import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { useRecoilValue } from "recoil";

import Endorsement from "@higher/models/Endorsement";

import WrapErrorSuspense from "@app/api/WrapErrorSuspense";
import useFirestoreValue from "@app/hooks/useFirestoreValue";
import ErrorFallback from "@app/modules/Error";
import ApplicantByIdState from "@app/state/ApplicantById";
import EndorsementByIdState from "@app/state/EndorsementById";
import RoleState from "@app/state/RoleById";

import Complete, { CompleteSkeleton } from "./Complete.stateless";

type Props = {
  endorsement: Endorsement;
};

const View: React.FC<Props> = ({ endorsement }) => {
  const [applicant, isAppLoading] = useFirestoreValue(
    ApplicantByIdState(endorsement.applicantId)
  );
  const [role, isRoleLoading] = useFirestoreValue(RoleState(applicant?.roleId));

  if (isAppLoading || isRoleLoading) {
    return <CompleteSkeleton />;
  }

  return (
    <Complete applicant={applicant} role={role} endorsement={endorsement} />
  );
};

type DispatcherProps = RouteComponentProps<{
  id: string;
}>;

const Dispatcher: React.FC<DispatcherProps> = (props) => {
  const endorsement = useRecoilValue(
    EndorsementByIdState(props.match.params.id)
  );

  if (!endorsement) {
    throw new Error("Something went wrong, that endorsement doesn't exist");
  }

  return <View endorsement={endorsement} />;
};

export default WrapErrorSuspense<DispatcherProps>(
  Dispatcher,
  CompleteSkeleton,
  ErrorFallback
);
