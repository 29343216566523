import React from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";

import Applicant, { ApplicationStatus } from "@higher/models/Applicant";

import WrapErrorSuspense from "@app/api/WrapErrorSuspense";
import useFirestoreValue from "@app/hooks/useFirestoreValue";
import ErrorFallback from "@app/modules/Error";
import ApplicantByIdState from "@app/state/ApplicantById";
import RoleState from "@app/state/RoleById";

import Landing, { LandingSkeleton } from "./Landing.stateless";

type Props = {
  applicant: Applicant;
};

const LandingView: React.FC<Props> = ({ applicant }) => {
  const [role, isLoading] = useFirestoreValue(RoleState(applicant.roleId));
  const history = useHistory();

  if (isLoading) {
    return <LandingSkeleton />;
  }

  const next = () => {
    history.push(`/endorse/form/${applicant.id}`);
  };

  if (applicant.status !== ApplicationStatus.Pending) {
    throw new Error("That application has been withdrawn");
  }

  if (role.id !== "demo" && role.id !== "lorem-labs-contract-senior-solidity-engineer") {
    throw new Error("This role is no longer open for endorsements");
  }

  return <Landing applicant={applicant} role={role} next={next} />;
};

type DispatcherProps = RouteComponentProps<{
  id: string;
}>;

const Dispatcher: React.FC<DispatcherProps> = (props) => {
  const applicant = useRecoilValue(ApplicantByIdState(props.match.params.id));

  if (!applicant) {
    throw new Error("That application doesn't exist");
  }
  return <LandingView applicant={applicant} />;
};

export default WrapErrorSuspense<DispatcherProps>(
  Dispatcher,
  LandingSkeleton,
  ErrorFallback
);
