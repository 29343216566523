import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Container, Link, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

import { openSlassk } from "@app/helpers/slaask";
import User from "@higher/models/User";
import { LayoutConfig } from "./Layout";

type Props = {
  user: User;
  signOut: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: `calc(100% - ${LayoutConfig.TotalHeadingSize}px)`,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignContent: "center",
    },
    link: {
      fontWeight: 600,
    },
  })
);

export const NoOrganisations: React.FC<Props> = (props) => {
  const classes = useStyles();
  return (
    <Container maxWidth="sm" className={classes.root}>
      <Typography variant="h5" component="h1" paragraph>
        You're not in any organisations
      </Typography>
      <Alert severity="info">
        <AlertTitle>Higher is currently in Open Beta</AlertTitle>
        New employer memberships are by invite only, so you'll need an
        invitation. If you're seeing this message in error, please contact us
        via{" "}
        <Link href="mailto:support@gethigher.io" className={classes.link}>
          support@gethigher.io
        </Link>{" "}
        or using{" "}
        <Link className={classes.link} onClick={() => openSlassk()}>
          live chat
        </Link>
        .
      </Alert>
    </Container>
  );
};

export default NoOrganisations;
