import { EditorState, convertToRaw } from "draft-js";
import { draftToMarkdown, markdownToDraft } from "markdown-draft-js";
import MUIRichTextEditor, { TMUIRichTextEditorRef } from "mui-rte";
import React from "react";

export type Props = {
  markdown?: string;
  setMarkdown: (value?: string) => void;
  label?: string;
  disabled?: boolean;
};

export const EditMarkdown: React.FC<Props> = ({
  markdown,
  setMarkdown,
  label,
  disabled,
}) => {
  const empty = convertToRaw(EditorState.createEmpty().getCurrentContent());
  const options = {
    blockStyles: {
      del_open: "STRIKETHROUGH",
    },
    preserveNewlines: true,
  };
  const [content, setContent] = React.useState(
    markdown ? markdownToDraft(markdown, options) : empty
  );

  React.useEffect(() => {
    if (markdown === undefined) {
      setContent(empty);
    }
  }, [markdown, empty]);

  const ref = React.useRef<TMUIRichTextEditorRef>(null);

  return (
    <div onClick={() => ref.current?.focus()}>
      <MUIRichTextEditor
        label={label || "Leave a comment"}
        defaultValue={JSON.stringify(content)}
        ref={ref}
        readOnly={disabled}
        controls={[
          "title",
          "bold",
          "italic",
          "strikethrough",
          "undo",
          "redo",
          "link",
          "numberList",
          "bulletList",
          "quote",
          "code",
        ]}
        onChange={(state) => {
          const content = state.getCurrentContent();
          const rawObject = convertToRaw(content);
          const markdownString = draftToMarkdown(rawObject, {
            preserveNewlines: true,
          });
          setMarkdown(markdownString);
        }}
      />
    </div>
  );
};

export default EditMarkdown;
