import Invitation from "@higher/models/Invitation";
import Organisation from "@higher/models/Organisation";

import queryStateSubscriptionByFunc from "./queryStateSubscriptionByFunc";

const collection = "invitations";

export default queryStateSubscriptionByFunc<Invitation, Organisation>(
  "invitationsByOrganisation",
  collection,
  (query, org) => {
    return query
      .where("organisationId", "==", org.id)
      .where("expired", "!=", true)
      .orderBy("expired", "asc")
      .orderBy("date", "asc");
  }
);
