import React from "react";

import Header, { HeaderProps } from "@app/modules/Header";
import Footer from "@app/modules/Footer";
import ScrollDown from "@app/components/ScrollDown";

type Props = {
  className?: string;
  headerProps?: HeaderProps;
  noFooter?: boolean;
  showScrollDown?: boolean;
};

const Page: React.FC<Props> = ({
  className,
  headerProps,
  noFooter,
  children,
  showScrollDown,
}) => {
  return (
    <div className={className}>
      <Header {...headerProps} />
      {children}
      {!noFooter && <Footer />}
      {showScrollDown && <ScrollDown />}
    </div>
  );
};

export default Page;
