import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

import { Navigation, Sitemap } from "@app/configs/navigation";
import NavLinks from "@app/components/NavLinks";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      textDecoration: "none",
      color: theme.palette.primary.dark,
      fontFamily: theme.typography.fontFamily,
      fontSize: "1.2em",
      marginLeft: "2em",
    },
  })
);

const LargeNavMenu: React.FC = () => {
  const classes = useStyles();

  return (
    <div>
      <NavLinks items={Navigation} className={classes.link} onlyFeatured />
      <NavLinks items={Sitemap} className={classes.link} onlyFeatured />
    </div>
  );
};

export default LargeNavMenu;
