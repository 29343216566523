import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

import ResponsiveFC from "@app/helpers/responsiveness";
import LeftColumnLayout, {
  MarginWidths,
} from "@app/helpers/responsiveness/LeftColumnLayout";

import Header, { HeaderProps } from "@app/modules/Header";
import { Images } from "@app/brand";

export const DashboardContentWidth = 1280;

type Props = {
  className?: string;
  headerProps?: HeaderProps;
  content: JSX.Element;
  menu: JSX.Element;
};

const useStyles = makeStyles((theme: Theme) => {
  const arrows = Images.BrandarrowsBackgroundImageTrans;

  return createStyles({
    largeRoot: {
      backgroundImage: `url(${arrows})`,
      backgroundSize: `calc(100vw - ${DashboardContentWidth}px + 40px)`,
      backgroundPositionX: DashboardContentWidth,
      backgroundPositionY: `38px`,
      minHeight: "100vh",
      backgroundRepeat: "no-repeat",
    },
    largeContent: {
      maxWidth: `calc(${DashboardContentWidth}px - ${MarginWidths.md}px)`,
      overflow: "hidden",
      paddingLeft: MarginWidths.md,
      [theme.breakpoints.up("lg")]: {
        paddingLeft: MarginWidths.lg,
        maxWidth: `calc(${DashboardContentWidth}px - ${MarginWidths.lg}px)`,
      },
      [theme.breakpoints.up("xl")]: {
        paddingLeft: MarginWidths.xl,
        maxWidth: `calc(${DashboardContentWidth}px - ${MarginWidths.xl}px)`,
      },
    },
  });
});

export const Small: React.FC<Props> = ({ headerProps, content }) => {
  return (
    <>
      <Header {...headerProps} />
      {content}
    </>
  );
};

export const Large: React.FC<Props> = ({ menu, content, headerProps }) => {
  const classes = useStyles();
  return (
    <LeftColumnLayout
      left={menu}
      main={
        <div className={classes.largeRoot}>
          <Header border dashboard {...headerProps} />
          <main className={classes.largeContent}>{content}</main>
        </div>
      }
    />
  );
};

const Inner = ResponsiveFC<Props>({
  small: Small,
  large: Large,
});

const Dashboard: React.FC<Props> = (props) => {
  return (
    <div className={props.className}>
      <Inner {...props} />
    </div>
  );
};

export default Dashboard;
