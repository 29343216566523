import React from "react";

import Applicant from "@higher/models/Applicant";
import Role from "@higher/models/Role";

import PopupDialog from "@app/components/PopupDialog";
import { RolePopup } from "@app/modules/endorse/RoleCard";

type Props = {
  applicant: Applicant;
  role: Role;
  open: boolean;
  setOpen: (open: boolean) => void;
};

const Popup: React.FC<Props> = ({ applicant, role, open, setOpen }) => {
  return (
    <PopupDialog
      title={`${role.title} @ ${role.employer}`}
      subtitle={`${role.location} · ${role.salary}`}
      avatar={role.avatar}
      open={open}
      setOpen={setOpen}
      maxWidth="md"
      fullWidth
      trackingId="role popup"
    >
      <RolePopup applicant={applicant} role={role} forceHeading />
    </PopupDialog>
  );
};

export default Popup;
