import React, { FC } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

import { Link, LinkProps } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      color: theme.palette.secondary.dark,
      textDecoration: "none",
      fontWeight: 400,
    },
  })
);

const BrandLink: FC<LinkProps> = ({ to, replace, children }) => {
  const classes = useStyles();
  return (
    <Link to={to} replace={replace} className={classes.link}>
      {children}
    </Link>
  );
};

export default BrandLink;
