import { useEffect, useState } from "react";
import { RecoilValue, useRecoilValueLoadable } from "recoil";

export function useLoadable<T>(
  recoilLoadable: RecoilValue<T | null | undefined>,
  defaultValue?: T
): [T, "loading" | "hasValue" | "hasError"] {
  const [value, setValue] = useState<T | undefined>(defaultValue);
  const recoilValue = useRecoilValueLoadable<T | null | undefined>(
    recoilLoadable
  );

  let returnValue: T = defaultValue || (value as T);

  useEffect(() => {
    if (
      recoilValue.state === "hasValue" &&
      recoilValue.contents &&
      recoilValue.contents !== value
    ) {
      setValue(recoilValue.contents);
    }
  }, [recoilValue.contents, recoilValue.state, value]);

  if (recoilValue.state === "loading" && !value) {
    throw recoilValue.toPromise();
  }

  if (recoilValue.state === "hasError") {
    throw recoilValue.contents;
  }

  if (recoilValue.state !== "hasValue" && value) {
    returnValue = value;
  }

  if (recoilValue.state === "hasValue" && recoilValue.contents) {
    returnValue = recoilValue.contents;
  }

  return [returnValue, recoilValue.state];
}

export default useLoadable;
