import SvgIcon from "@material-ui/core/SvgIcon";
import {
  Home,
  Code,
  Policy,
  OpenInNew as Work,
  HelpOutline as WhhatIsHigher,
} from "@material-ui/icons";

type SvgIconComponent = typeof SvgIcon;

export type LinkProps = {
  title: string;
  link: string;
  icon?: SvgIconComponent;
  onClick?: () => void;
  featured?: boolean;
  external?: boolean;
};

export type NavigationProps = {
  navigate: (to: string) => void;
};

export type LinkListProps<T = LinkProps> = NavigationProps & {
  items: Array<T>;
};

const hiringURL = "/careers/growth-marketer";
const introBlogURL = "https://high.hr/intro";

export const linkInNewWindow = (url: string): (() => void) => {
  return () => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
};

export const linkToHiringURL = linkInNewWindow(hiringURL);
export const linkToIntroBlogURL = linkInNewWindow(introBlogURL);

export const Navigation: Array<LinkProps> = [
  {
    title: "Home",
    link: "/",
    icon: Home,
  },
  {
    title: "Endorsement demo",
    link: "/endorse/demo",
    icon: Code,
  },
  {
    title: "Dashboard demo",
    link: "/dashboard/demo",
    icon: Code,
  },
];

export const Sitemap: Array<LinkProps> = [
  {
    title: "What is Higher?",
    link: introBlogURL,
    icon: WhhatIsHigher,
    onClick: linkToIntroBlogURL,
    featured: true,
    external: true,
  },
  {
    title: "We're hiring a Growth Marketer!",
    link: hiringURL,
    icon: Work,
    onClick: linkToHiringURL,
    featured: true,
    external: true,
  },
  {
    title: "Privacy statement",
    link: "/privacy",
    icon: Policy,
  },
];

export default Navigation;
