import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { useMediaQuery, Fade } from "@material-ui/core";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

import { UpArrow } from "@app/brand";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "fixed",
      bottom: theme.spacing(0.5),
      textAlign: "center",
      width: "100%",
      color: "pink",

      [theme.breakpoints.up("xl")]: {
        bottom: theme.spacing(1),
      },
    },
    arrow: {
      display: "inline-block",
      width: theme.spacing(4),
      height: theme.spacing(4),
      [theme.breakpoints.up("xl")]: {
        width: theme.spacing(8),
        height: theme.spacing(8),
      },
    },
  })
);

const ScrollDownForLarge: React.FC = () => {
  const classes = useStyles();
  const [show, setShow] = useState(true);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      console.log(currPos.y);
      const isShow = currPos.y >= 0;
      if (isShow !== show) setShow(isShow);
    },
    [show]
  );

  return (
    <div className={classes.root}>
      <Fade in={show} timeout={300}>
        <object
          className={classes.arrow}
          data={UpArrow.ArrowDown}
          aria-label="Scroll down for more"
        />
      </Fade>
    </div>
  );
};

const ScrollDown: React.FC = () => {
  const isLarge = useMediaQuery<Theme>((theme) => theme.breakpoints.up("lg"));
  return isLarge ? <ScrollDownForLarge /> : null;
};

export default ScrollDown;
