import React from "react";
import { InView } from "react-intersection-observer";

import useTracking from "@app/hooks/useTracking";

type Props = {
  trackingId: string;
};

const TrackedSection: React.FC<Props> = ({ trackingId, children }) => {
  const { logContentView } = useTracking();

  const handleChange = (inView: boolean, entry: any) => {
    if (inView) {
      logContentView(trackingId);
    }
  };

  return <InView onChange={handleChange}>{children}</InView>;
};

export default TrackedSection;
