type SlaaskOptions = {
  view: "meetings" | "homepage";
};

interface Slaask {
  show: (opts?: SlaaskOptions) => void;
}

declare global {
  interface Window {
    _slaask: Slaask;
  }
}

export const openSlassk = () => {
  window._slaask.show();
};

export default openSlassk;
