import { CircularProgress, Container, Typography } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { useRecoilState } from "recoil";

import * as firebaseui from "firebaseui";

import firebase, { Auth } from "@app/Firebase";
import { Partners } from "@app/brand";
import { IsSignedIn } from "@app/state/SignIn";

type Props = {
  loadingSkeleton?: JSX.Element;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: "center",
      height: `100vh`,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignContent: "center",
    },
    circularProgress: {
      margin: "0 auto",
      display: "block",
      color: theme.palette.grey.A100,
    },
  })
);

const uiConfig: firebaseui.auth.Config = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    {
      provider: "linkedin.com",
      providerName: "LinkedIn",
      buttonColor: "#1a81b9",
      iconUrl: Partners.LinkedinTextonlyWhite,
    },
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
  privacyPolicyUrl: "https://gethigher.io/privacy",
};

export const AuthRequired: React.FC<Props> = ({
  loadingSkeleton,
  children,
}) => {
  const classes = useStyles();
  const [isSignedIn, setIsSignedIn] = useRecoilState(IsSignedIn);

  useEffect(() => {
    const unregisterAuthObserver = Auth.onAuthStateChanged((user) => {
      setIsSignedIn(!!user);
    });
    return () => unregisterAuthObserver();
  }, [setIsSignedIn]);

  if (isSignedIn === undefined) {
    return (
      loadingSkeleton || (
        <Container maxWidth="sm" className={classes.root}>
          <CircularProgress className={classes.circularProgress} />
        </Container>
      )
    );
  }

  if (!isSignedIn) {
    return (
      <Container maxWidth="sm" className={classes.root}>
        <Typography variant="h5" component="h1" paragraph>
          Sign in to continue
        </Typography>

        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={Auth} />
      </Container>
    );
  }
  return <>{children}</>;
};

export const WrapAuthRequired = <P extends {} = {}>(
  Inner: React.FC<P>,
  loadingSkeleton?: JSX.Element
): React.FC<P> => {
  return (props: P) => (
    <AuthRequired>
      <Inner {...props} />
    </AuthRequired>
  );
};

export default AuthRequired;
