import E2ETestShim from "@e2e/shim";

import "firebase/analytics";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

import Environment, { isProduction, EnvironmentMap } from "./Environment";

type FirebaseConfig = Object;

const configs = new EnvironmentMap<FirebaseConfig>([
  [
    Environment.Production,
    {
      apiKey: "AIzaSyBCzZ9ugLYq6iNXGoPaTTW6cYoLowire7k",
      authDomain: "gethigher.io",
      databaseURL: "https://higher-5e33a.firebaseio.com",
      projectId: "higher-5e33a",
      storageBucket: "higher-5e33a.appspot.com",
      messagingSenderId: "1039166118659",
      appId: "1:1039166118659:web:8990d03590def2ab46a736",
      measurementId: "G-D9322GN3GK",
    },
  ],
  [
    Environment.Staging,
    {
      apiKey: "AIzaSyBk6MZVCvG0ih69_8brMcYL0rol7msLCts",
      authDomain: "staging.gethigher.io",
      projectId: "higher-staging",
      storageBucket: "higher-staging.appspot.com",
      messagingSenderId: "811296356778",
      appId: "1:811296356778:web:26918f56044412ff7093d3",
    },
  ],
]);

firebase.initializeApp(configs.current());

export const Analytics = firebase.analytics();
export const Auth = firebase.auth();
export const Functions = firebase.functions();

declare global {
  interface Window {
    firebaseShim: E2ETestShim;
  }
}

if (!isProduction()) {
  window.firebaseShim = {
    login: async (uid: string, opt: any) => {
      await Auth.signInWithCustomToken(JSON.stringify({ uid }));
      await Auth.currentUser?.updateProfile(opt);

      if (opt.email) {
        await Auth.currentUser?.updateEmail(opt.email);
      }
    },
  };
}

if (process.env.REACT_APP_TESTMODE) {
  firebase.firestore().useEmulator("firebase.gethigher.io", 8080);
  firebase.auth().useEmulator("http://firebase.gethigher.io:9099");
  firebase.functions().useEmulator("firebase.gethigher.io", 5001);
} else if (window.location.hostname === "localhost") {
  firebase.firestore().useEmulator("localhost", 8080);
  firebase.auth().useEmulator("http://localhost:9099");
  firebase.functions().useEmulator("localhost", 5001);
}

export default firebase;
