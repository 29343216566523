import React from "react";
import { useHistory } from "react-router-dom";

import { WrapAuthRequired } from "@app/components/AuthRequired";
import CurrentOrganisation from "@app/state/CurrentOrganisation";
import RolesByOrganisationId from "@app/state/RolesByOrganisationId";
import useFirestoreValue from "@app/hooks/useFirestoreValue";

import Home, { HomeSkeleton } from "./Home.stateless";
import Layout from "./Layout";

const HomeView: React.FC = () => {
  const [currentOrg, isOrgLoading] = useFirestoreValue(CurrentOrganisation);
  const [roles, isRolesLoading] = useFirestoreValue(
    RolesByOrganisationId(currentOrg?.id)
  );

  if (isRolesLoading || isOrgLoading) {
    return <HomeSkeleton />;
  }

  return <Home roles={roles} org={currentOrg} />;
};

const Dispatcher: React.FC = (props) => {
  const history = useHistory();
  if (history.location.pathname.replace(/\/$/, "") === "/dashboard") {
    history.replace("/dashboard/roles");
  }

  return (
    <Layout loading={HomeSkeleton}>
      <HomeView />
    </Layout>
  );
};

export default WrapAuthRequired(Dispatcher);
