import React, { FC } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accent: {
      fontWeight: 600,
    },
  })
);

const Accent: FC = ({ children }) => {
  const classes = useStyles();
  return <strong className={classes.accent}>{children}</strong>;
};

export default Accent;
