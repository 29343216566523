import React from "react";
import { Helmet } from "react-helmet";

import Role from "@higher/models/Role";

type Props = {
  role: Role;
};

const MetaTags: React.FC<Props> = ({ role }) => {
  const title = `${role.title} @ ${role.employer} - Higher`;
  const description = `${role.title} @ ${role.employer}`;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
    </Helmet>
  );
};

export default MetaTags;
