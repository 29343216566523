import {
  Avatar,
  Chip,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  useMediaQuery,
} from "@material-ui/core";
import {
  makeStyles,
  Theme,
  createStyles,
  useTheme,
} from "@material-ui/core/styles";
import {
  Code as CodeIcon,
  Storage as InfrastructureIcon,
  Language as LanguageIcon,
  Check as UnknownIcon,
  Info as LinkIcon,
  TrendingUp as SalesIcon,
  AttachMoney as ProductSalesIcon,
  Work as BusinessIcon,
  Group as SoftIcon,
} from "@material-ui/icons";
import React from "react";

import Applicant from "@higher/models/Applicant";
import Role, { SkillType } from "@higher/models/Role";

import { Button } from "@app/brand";
import Markdown from "@app/components/Markdown";
import PopupCard from "@app/components/PopupCard";
import Interpolate, {
  InterpolationReplacementMap,
} from "@app/helpers/interpolation";

type Props = {
  applicant: Applicant;
  role: Role;
  focused?: boolean;
  open?: boolean;
  forceHeading?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    skillsCompact: {
      "& > *": {
        marginRight: theme.spacing(0.5),
      },
    },
    list: {
      marginBottom: "1em",
    },
  })
);

const SkillIcon = (type: SkillType): JSX.Element => {
  switch (type) {
    case SkillType.Technical:
      return <CodeIcon />;

    case SkillType.Operations:
      return <InfrastructureIcon />;

    case SkillType.Language:
      return <LanguageIcon />;

    case SkillType.Sales:
      return <SalesIcon />;

    case SkillType.ProductSales:
      return <ProductSalesIcon />;

    case SkillType.Business:
      return <BusinessIcon />;

    case SkillType.Soft:
      return <SoftIcon />;
  }

  return <UnknownIcon />;
};

export const RolePopup: React.FC<Props> = ({
  applicant,
  role,
  forceHeading,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up("lg"));

  const openInNewTab = (url: string) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const replacements = new InterpolationReplacementMap([
    ["CandidateFirstName", applicant.firstName],
  ]);

  return (
    <>
      {(!isLarge || forceHeading) && (
        <Typography paragraph variant="body1">
          {role.heading}
        </Typography>
      )}

      <Typography variant="overline">Description</Typography>

      <Markdown source={role.description} replacements={replacements} />

      {role.requiredSkills && (
        <>
          <Typography variant="overline">
            Required skills and experience
          </Typography>
          <List disablePadding className={classes.list}>
            {role.requiredSkills.map((skill) => (
              <ListItem alignItems="flex-start" disableGutters>
                <ListItemAvatar>
                  <Avatar>{SkillIcon(skill.type)}</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={skill.name}
                  secondary={Interpolate(skill.description, replacements)}
                />
              </ListItem>
            ))}
          </List>
        </>
      )}

      {role.desiredSkills && (
        <>
          <Typography variant="overline">
            Advantageous skills and experience
          </Typography>
          <List disablePadding className={classes.list}>
            {role.desiredSkills.map((skill) => (
              <ListItem alignItems="flex-start" disableGutters>
                <ListItemAvatar>
                  <Avatar>{SkillIcon(skill.type)}</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={skill.name}
                  secondary={Interpolate(skill.description, replacements)}
                />
              </ListItem>
            ))}
          </List>
        </>
      )}

      <Typography variant="overline">Probation period</Typography>
      <Typography paragraph variant="body1">
        The probation period for this role is{" "}
        <strong>{role.probation} days</strong>, and will begin on the first day
        of {applicant.firstName}'s employment at {role.employer}.
      </Typography>

      {role.originalUrl && (
        <Button
          tertiary
          medium
          startIcon={<LinkIcon />}
          onClick={() => openInNewTab(role.originalUrl as string)}
        >
          Original specification
        </Button>
      )}
    </>
  );
};

const RoleCard: React.FC<Props> = ({ applicant, role, focused, open }) => {
  const classes = useStyles();
  return (
    <>
      <PopupCard
        title={`${role.title} @ ${role.employer}`}
        subtitle={`${role.location} · ${role.salary}`}
        avatar={role.avatar}
        focused={focused}
        popup={<RolePopup applicant={applicant} role={role} />}
        trackingId="role card"
      >
        <Typography variant="body2" paragraph>
          {role.heading}
        </Typography>
        {role.requiredSkills && (
          <div className={classes.skillsCompact}>
            {role.requiredSkills.map((skill) => (
              <Chip
                key={skill.name}
                label={skill.name}
                size="small"
                variant="outlined"
              />
            ))}
          </div>
        )}
      </PopupCard>
    </>
  );
};

export default RoleCard;
