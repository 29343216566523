import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import readingTime from "@danieldietrich/reading-time";
import { Container, Typography } from "@material-ui/core";

import Markdown from "@app/components/Markdown";

import Header from "./Header";

const policy = `
## Welcome to Higher!

Thank you for choosing to be part of Higher ("**we**", "**us**", "**our**").
Higher is operated by UK company called GetHigher.io Ltd. We offer services
that faciliate recruitment via an open-source, automatable, on-demand talent
sourcing and ranking system powered by a fairly-incentivised gig economy.

We are committed to protecting your personal information and your right to
privacy. If you have any questions or concerns about this privacy notice, or
our practices with regards to your personal information, please contact us at
[support@gethigher.io](mailto:support@gethigher.io).

When you  visit our website  [gethigher.io](https://gethigher.io)  (the
"**Website**"),  and more generally, use any of our services (the
"**Services**", which include the Website), we appreciate that you are trusting
us with your personal information. We take your privacy very seriously. In this
privacy notice, we seek to explain to you in the clearest way possible what
information we collect, how we use it and what rights you have in relation to
it. We hope you take some time to read through it carefully. If there are any
terms in this privacy notice that you do not agree with, please discontinue use
of our Services immediately.

This privacy notice applies to all information collected through our Services
(which, as described above, includes our Website), as well as, any related
services, sales, marketing or events.

**Please read this privacy notice carefully. It will help you understand what
we do with the information that we collect.**

## Table of contents

- [What information do we collect?](#1-what-information-do-we-collect) 

- [How do we use your information?](#2-how-do-we-use-your-information) 

- [Will your information be shared with anyone?](#3-will-your-information-be-shared-with-anyone) 

- [Who will your information be shared with?](#4-who-will-your-information-be-shared-with)

- [How long do we keep your information?](#5-how-long-do-we-keep-your-information) 

- [How do we keep your information safe?](#6-how-do-we-keep-your-information-safe) 

- [Do we collect information from minors?](#7-do-we-collect-information-from-minors) 

- [What are your privacy rights?](#8-what-are-your-privacy-rights) 

- [Controls for do-not-track features](#9-controls-for-do-not-track-features) 

- [Do california residents have specific privacy rights?](#10-do-california-residents-have-specific-privacy-rights) 

- [Do we make updates to this notice?](#11-do-we-make-updates-to-this-notice) 

- [How can you contact us about this notice?](#12-how-can-you-contact-us-about-this-notice) 

- [How can you review, update or delete the data we collect from you?](#13-how-can-you-review-update-or-delete-the-data-we-collect-from-you)

## 1. What information do we collect?

### Personal information you disclose to us

**_In Short:_** _We collect personal information that you provide to us._

We collect personal information that you voluntarily provide to us when you use
the Website, express an interest in obtaining information about us or our
products and Services, when you participate in activities on the Website or
otherwise when you contact us.

The personal information that we collect depends on the context of your
interactions with us and the Website, the choices you make and the products and
features you use. The personal information we collect may include the
following:

1. **Personal Information Provided by You.**  We may collect personal
   information, including your name, occupation, general location, work history
   and email addresses.

2. **Payment Data.**  We may collect data necessary to process your endorsement
   payment, such as your payment instrument number (such as a credit card
   number), and the security code associated with your payment instrument. All
   payment data is stored by Stripe Payments Europe Ltd. You may find their
   privacy notice link here:
   [https://stripe.com/gb/privacy](https://stripe.com/gb/privacy).

All personal information that you provide to us must be true, complete and
accurate, and you must notify us of any changes to such personal information.

### Information automatically collected

**_In Short:_** _Some information — such as your Internet Protocol (IP) address
and/or browser and device characteristics — is collected automatically when you
visit our  _Website_._

We automatically collect certain information when you visit, use or navigate
the  Website. This information does not reveal your specific identity (like
your name or contact information) but may include device and usage information,
such as your IP address, browser and device characteristics, operating system,
language preferences, referring URLs, device name, country, location,
information about how and when you use our  Website  and other technical
information. This information is primarily needed to maintain the security and
operation of our  Website, and for our internal analytics and reporting
purposes.

The information we collect includes:

-   **Log and Usage Data.**  Log and usage data is service-related, diagnostic,
	usage and performance information our servers automatically collect when
	you access or use our  Website  and which we record in log files. Depending
	on how you interact with us, this log data may include your IP address,
	device information, browser type and settings and information about your
	activity in the  Website (such as the date/time stamps associated with your
	usage, pages and files viewed, searches and other actions you take such as
	which features you use), device event information (such as system activity,
	error reports (sometimes called 'crash dumps') and hardware settings).

-   **Device Data.**  We collect device data such as information about your
	computer, phone, tablet or other device you use to access the  Website.
	Depending on the device used, this device data may include information such
	as your IP address (or proxy server), device and application identification
	numbers, location, browser type, hardware model Internet service provider
	and/or mobile carrier, operating system and system configuration
	information.

-   **Location Data.**  We collect location data such as information about your
	device's location, which can be either precise or imprecise. How much
	information we collect depends on the type and settings of the device you
	use to access the  Website. For example, we may use GPS and other
	technologies to collect geolocation data that tells us your current
	location (based on your IP address). You can opt out of allowing us to
	collect this information either by refusing access to the information or by
	disabling your Location setting on your device. Note however, if you choose
	to opt out, you may not be able to use certain aspects of the Services.

## 2. How do we use your information?

**_In Short:_** _We process your information for purposes based on legitimate
business interests, the fulfilment of our contract with you, compliance with
our legal obligations, and/or your consent._

We use personal information collected via our Website for a variety of business
purposes described below. We process your personal information for these
purposes in reliance on our legitimate business interests, in order to enter
into or perform a contract with you, with your consent, and/or for compliance
with our legal obligations. We indicate the specific processing grounds we rely
on next to each purpose listed below.

We use the information we collect or receive:

-   **To facilitate account creation and logon process.**  If you choose to
	link your account with us to a third-party account (such as your Google or
	Facebook account), we use the information you allowed us to collect from
	those third parties to facilitate account creation and logon process for
	the performance of the contract.

-   **To post testimonials.**  We post testimonials on our  Website  that may
	contain personal information. Prior to posting a testimonial, we will
	obtain your consent to use your name and the content of the testimonial. If
	you wish to update, or delete your testimonial, please contact us at
	[support@gethigher.io](mailto:support@gethigher.io)  and be sure to include your name, testimonial
	location, and contact information.

-   **To enable user-to-user communications.**  We may use your information in
	order to enable user-to-user communications with each user's consent.

-   **To manage user accounts.** We may use your information for the purposes
	of managing our account and keeping it in working order.

-   **To send administrative information to you.** We may use your personal
	information to send you product, service and new feature information and/or
	information about changes to our terms, conditions, and policies.

-   **To protect our Services.** We may use your information as part of our
	efforts to keep our  Website  safe and secure (for example, for fraud
	monitoring and prevention).

-   **To enforce our terms, conditions and policies for business purposes, to
	comply with legal and regulatory requirements or in connection with our
	contract.**

-   **To respond to legal requests and prevent harm.** If we receive a subpoena
	or other legal request, we may need to inspect the data we hold to
	determine how to respond.

-   **To deliver and facilitate delivery of services to the user.**  We may use
	your information to provide you with the requested service.

-   **To respond to user inquiries/offer support to users.**  We may use your
	information to respond to your inquiries and solve any potential issues you
	might have with the use of our Services.

## 3. Will your information be shared with anyone?

**_In Short:_** _We only share information with your consent, to comply with
laws, to provide you with services, to protect your rights, or to fulfill
business obligations._

We may process or share your data that we hold based on the following legal
basis:

-   **Consent:**  We may process your data if you have given us specific
	consent to use your personal information for a specific purpose.

-   **Legitimate Interests:**  We may process your data when it is reasonably
	necessary to achieve our legitimate business interests.

-   **Performance of a Contract:**  Where we have entered into a contract with
	you, we may process your personal information to fulfill the terms of our
	contract.

-   **Legal Obligations:**  We may disclose your information where we are
	legally required to do so in order to comply with applicable law,
	governmental requests, a judicial proceeding, court order, or legal
	process, such as in response to a court order or a subpoena (including in
	response to public authorities to meet national security or law enforcement
	requirements).

-   **Vital Interests:**  We may disclose your information where we believe it
	is necessary to investigate, prevent, or take action regarding potential
	violations of our policies, suspected fraud, situations involving potential
	threats to the safety of any person and illegal activities, or as evidence
	in litigation in which we are involved.

More specifically, we may need to process your data or share your personal
information in the following situations:

-   **Business Transfers.**  We may share or transfer your information in
	connection with, or during negotiations of, any merger, sale of company
	assets, financing, or acquisition of all or a portion of our business to
	another company.

-   **Vendors, Consultants and Other Third-Party Service Providers.**  We may
	share your data with third-party vendors, service providers, contractors or
	agents who perform services for us or on our behalf and require access to
	such information to do that work. Examples include: payment processing,
	data analysis, email delivery, hosting services, customer service and
	marketing efforts. We may allow selected third parties to use tracking
	technology on the  Website, which will enable them to collect data on our
	behalf about how you interact with our  Website  over time. This
	information may be used to, among other things, analyze and track data,
	determine the popularity of certain content, pages or features, and better
	understand online activity. Unless described in this notice, we do not
	share, sell, rent or trade any of your information with third parties for
	their promotional purposes.  We have contracts in place with our data
	processors, which are designed to help safeguard your personal information.
	This means that they cannot do anything with your personal information
	unless we have instructed them to do it. They will also not share your
	personal information with any organisation apart from us. They also commit
	to protect the data they hold on our behalf and to retain it for the period
	we instruct.
  

## 4. Who will your information be shared with?

**_In Short:_** _We only share information with the following third parties._

We only share and disclose your information with the following third parties.
We have categorized each party so that you may be easily understand the purpose
of our data collection and processing practices. If we have processed your data
based on your consent and you wish to revoke your consent, please contact us
using the contact details provided in the section below titled "[How can you
contact us about this notice?](#12-how-can-you-contact-us-about-this-notice)".

### Web and Mobile Analytics

Google Analytics and MixPanel

## 5. How long do we keep your information?

**_In Short:_** _We keep your information for as long as necessary to fulfill
the purposes outlined in this privacy notice unless otherwise required by law._

We will only keep your personal information for as long as it is necessary for
the purposes set out in this privacy notice, unless a longer retention period
is required or permitted by law (such as tax, accounting or other legal
requirements). No purpose in this notice will require us keeping your personal
information for longer than  the period of time in which users have an account
with us.

When we have no ongoing legitimate business need to process your personal
information, we will either delete or anonymize such information, or, if this
is not possible (for example, because your personal information has been stored
in backup archives), then we will securely store your personal information and
isolate it from any further processing until deletion is possible.
  
## 6. How do we keep your information safe?

**_In Short:_** _We aim to protect your personal information through a system
of organisational and technical security measures._

We have implemented appropriate technical and organisational security measures
designed to protect the security of any personal information we process.
However, despite our safeguards and efforts to secure your information, no
electronic transmission over the Internet or information storage technology can
be guaranteed to be 100% secure, so we cannot promise or guarantee that
hackers, cyber criminals, or other unauthorised third parties will not be able
to defeat our security, and improperly collect, access, steal, or modify your
information. Although we will do our best to protect your personal information,
transmission of personal information to and from our  Website is at your own
risk. You should only access the  Website within a secure environment.

## 7. Do we collect information from minors?

**_In Short:_** _We do not knowingly collect data from or market to children
under 18 years of age._

We do not knowingly solicit data from or market to children under 18 years of
age. By using the Website, you represent that you are at least 18 or that you
are the parent or guardian of such a minor and consent to such minor
dependent’s use of the  Website. If we learn that personal information from
users less than 18 years of age has been collected, we will deactivate the
account and take reasonable measures to promptly delete such data from our
records. If you become aware of any data we may have collected from children
under age 18, please contact us at  [support@gethigher.io](mailto:support@gethigher.io).

## 8. What are your privacy rights?

 
**_In Short:_** _In some regions, such as the European Economic Area, you have
rights that allow you greater access to and control over your personal
information.  You may review, change, or terminate your account at any time._ 

In some regions (like the European Economic Area), you have certain rights
under applicable data protection laws. These may include the right (i) to
request access and obtain a copy of your personal information, (ii) to request
rectification or erasure; (iii) to restrict the processing of your personal
information; and (iv) if applicable, to data portability. In certain
circumstances, you may also have the right to object to the processing of your
personal information. To make such a request, please use the  [contact
details](#contact)  provided below. We will consider and act upon any request
in accordance with applicable data protection laws.

If we are relying on your consent to process your personal information, you
have the right to withdraw your consent at any time. Please note however that
this will not affect the lawfulness of the processing before its withdrawal,
nor will it affect the processing of your personal information conducted in
reliance on lawful processing grounds other than consent.

If you are a resident in the European Economic Area and you believe we are
unlawfully processing your personal information, you also have the right to
complain to your local data protection supervisory authority. You can find
their contact details here:
[http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm](http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm).

If you are a resident in Switzerland, the contact details for the data
protection authorities are available here:
[https://www.edoeb.admin.ch/edoeb/en/home.html](https://www.edoeb.admin.ch/edoeb/en/home.html).

If you have questions or comments about your privacy rights, you may email us
at  [support@gethigher.io](mailto:support@gethigher.io).

### Account Information

If you would at any time like to review or change the information in your
account or terminate your account, you can contact us at [support@gethigher.io](mailto:support@gethigher.io).

Upon your request to terminate your account, we will deactivate or delete your
account and information from our active databases. However, we may retain some
information in our files to prevent fraud, troubleshoot problems, assist with
any investigations, enforce our Terms of Use and/or comply with applicable
legal requirements.  

## 9. Controls for do-not-track features

Most web browsers and some mobile operating systems and mobile applications
include a Do-Not-Track ("DNT") feature or setting you can activate to signal
your privacy preference not to have data about your online browsing activities
monitored and collected. At this stage no uniform technology standard for
recognise and implementing DNT signals has been finalised. As such, we do not
currently respond to DNT browser signals or any other mechanism that
automatically communicates your choice not to be tracked online. If a standard
for online tracking is adopted that we must follow in the future, we will
inform you about that practice in a revised version of this privacy notice.

## 10. Do california residents have specific privacy rights?

**_In Short:_** _Yes, if you are a resident of California, you are granted
specific rights regarding access to your personal information._

California Civil Code Section 1798.83, also known as the "Shine The Light" law,
permits our users who are California residents to request and obtain from us,
once a year and free of charge, information about categories of personal
information (if any) we disclosed to third parties for direct marketing
purposes and the names and addresses of all third parties with which we shared
personal information in the immediately preceding calendar year. If you are a
California resident and would like to make such a request, please submit your
request in writing to us using the contact information provided below.

If you are under 18 years of age, reside in California, and have a registered
account with  the Website, you have the right to request removal of unwanted
data that you publicly post on the  Website. To request removal of such data,
please contact us using the contact information provided below, and include the
email address associated with your account and a statement that you reside in
California. We will make sure the data is not publicly displayed on the
Website, but please be aware that the data may not be completely or
comprehensively removed from all our systems (e.g. backups, etc.).  

## 11. Do we make updates to this notice?

_**In Short:** Yes, we will update this notice as necessary to stay compliant
with relevant laws._

We may update this privacy notice from time to time. The updated version will
be indicated by an updated "Revised" date and the updated version will be
effective as soon as it is accessible. If we make material changes to this
privacy notice, we may notify you either by prominently posting a notice of
such changes or by directly sending you a notification. We encourage you to
review this privacy notice frequently to be informed of how we are protecting
your information. 

## 12. How can you contact us about this notice?
  
If you have questions or comments about this notice, you may email us at
[support@gethigher.io](mailto:support@gethigher.io).

## 13. How can you review, update, or delete the data we collect from you?

Based on the applicable laws of your country, you may have the right to request
access to the personal information we collect from you, change that
information, or delete it in some circumstances. To request to review, update,
or delete your personal information, please email us at [support@gethigher.io](mailto:support@gethigher.io).
We will respond to your request within 30 days.
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: "1rem",
    },
    header: {
      fontSize: "2.5rem",
      marginButton: "0.5rem",
    },
    content: {
      "& > h2": {
        fontSize: "1.5rem",
        marginTop: "1.5em",
        fontWeight: "bold",
      },
      "& > h3": {
        fontSize: "1.3rem",
        fontWeight: "normal",
      },
      "& > ul, & > ol": {
        paddingLeft: "1rem",
      },
      "& > ul > li > p": {
        marginBottom: "0.5rem",
      },
    },
  })
);

const Home: React.FC = () => {
  const classes = useStyles();
  const { minutes } = readingTime(policy);
  return (
    <>
      <Header />
      <Container maxWidth="md" className={classes.root}>
        <Typography variant="h1" className={classes.header}>
          PRIVACY NOTICE
        </Typography>
        <Typography variant="body2" paragraph>
          <strong>{minutes} min reading time. </strong>
          Last updated February 15, 2021
        </Typography>
        <section className={classes.content}>
          <Markdown source={policy} />
        </section>
      </Container>
    </>
  );
};

export default Home;
