import { Currency, Currencies } from "../payments/currency";
import { PaymentMethod } from "../payments/methods";

export type Role = {
  id: string;
  organisationId: string;
  employer: string;
  location: string;
  salary: string;
  title: string;
  heading: string;
  avatar: string;
  description: string;
  probation: number;
  requiredSkills?: Array<Skill>;
  desiredSkills?: Array<Skill>;
  originalUrl?: string;
  currency: Currency;
  minStake: number;
  maxStake: number;
  maxEndorsers: number;
  shortlistThreshold: number;
  leaderThreshold: number;
  paymentMethods: Array<PaymentMethod>;
};

export type Skill = {
  name: string;
  type: SkillType;
  description: string;
};

export enum SkillType {
  General = "general",
  Technical = "technical",
  Operations = "operations",
  Language = "language",
  Sales = "sales",
  ProductSales = "product sales",
  Business = "business",
  Soft = "soft",
}

export const UnknownSkill: Skill = {
  name: "unknown",
  type: SkillType.General,
  description: "unknown",
};
export const UnknownRole: Role = {
  id: "unknown",
  organisationId: "unknown",
  employer: "unknown",
  location: "unknown",
  salary: "unknown",
  title: "unknown",
  heading: "unknown",
  avatar: "unknown",
  description: "unknown",
  probation: 0,
  requiredSkills: [UnknownSkill],
  currency: Currencies.GBP,
  minStake: 0,
  maxStake: 0,
  maxEndorsers: 0,
  shortlistThreshold: 3,
  leaderThreshold: 5,
  paymentMethods: [],
};

export default Role;
