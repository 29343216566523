import OrganisationBrand from "@higher/models/OrganisationBrand";

import stateSubscriptionByObject from "./stateSubscriptionByObject";

export type OrganisationBrandAddress = {
  organisationId: string;
  id: string;
};

export const MainBrandAddress = (orgId: string): OrganisationBrandAddress => ({
  organisationId: orgId,
  id: "main",
});

export default stateSubscriptionByObject<
  OrganisationBrand,
  OrganisationBrandAddress
>("orgBrandByBrandAddress", (address) => {
  return `organisations/${address.organisationId}/brands`;
});
