import React from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";

function WrapErrorSuspense<P extends {} = {}>(
  Inner: React.FC<P>,
  Loading: React.FC,
  Fallback: React.FC<FallbackProps>
): React.FC<P> {
  return (props: P) => (
    <ErrorBoundary FallbackComponent={Fallback}>
      <React.Suspense fallback={<Loading />}>
        <Inner {...props} />
      </React.Suspense>
    </ErrorBoundary>
  );
}

export default WrapErrorSuspense;
