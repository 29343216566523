import React, { FC } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Container, Drawer } from "@material-ui/core";

type Props = {
  left: JSX.Element;
  main: JSX.Element;
};

export type MenuWidthSpecs = {
  md: number;
  lg: number;
  xl: number;
};

export const MenuWidths: MenuWidthSpecs = {
  md: 240,
  lg: 270,
  xl: 350,
};

export const MarginWidths: MenuWidthSpecs = {
  md: 0,
  lg: 16,
  xl: 30,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    drawer: {
      flexShrink: 0,
      width: "240px",
      [theme.breakpoints.up("lg")]: {
        width: "270px",
      },
      [theme.breakpoints.up("xl")]: {
        width: "350px",
      },
    },
    largeContent: {
      flexGrow: 1,
    },
    leftColumn: {
      width: MenuWidths.md,
      backgroundColor: "white",
      padding: "0.75em",

      [theme.breakpoints.up("lg")]: {
        width: MenuWidths.lg,
      },
      [theme.breakpoints.up("xl")]: {
        width: MenuWidths.xl,
      },
    },
  })
);

const LeftColumnLayout: FC<Props> = ({ left, main }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Drawer variant="permanent" anchor="left" className={classes.drawer}>
        <Container className={classes.leftColumn}>{left}</Container>
      </Drawer>
      <div className={classes.largeContent}>{main}</div>
    </div>
  );
};

export default LeftColumnLayout;
