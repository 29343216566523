import { SnackbarProvider } from "notistack";
import React from "react";

import { WrapAuthRequired } from "@app/components/AuthRequired";
import useFirestoreValue from "@app/hooks/useFirestoreValue";
import CurrentOrganisation from "@app/state/CurrentOrganisation";
import OrganisationById from "@app/state/OrganisationById";

import Layout from "./Layout";
import Team, { TeamSkeleton } from "./Team.stateless";

const View: React.FC = () => {
  const [currentOrg, isCurrentOrgLoading] =
    useFirestoreValue(CurrentOrganisation);
  const [org, isOrgLoading] = useFirestoreValue(
    OrganisationById(currentOrg?.id)
  );

  if (isCurrentOrgLoading || isOrgLoading) {
    return <TeamSkeleton />;
  }

  return <Team organisation={org} />;
};

const Dispatcher: React.FC = (props) => {
  return (
    <SnackbarProvider maxSnack={3}>
      <Layout loading={TeamSkeleton}>
        <View />
      </Layout>
    </SnackbarProvider>
  );
};

export default WrapAuthRequired(Dispatcher);
