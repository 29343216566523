import {
  Avatar,
  CircularProgress,
  Container,
  Typography,
  Link,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { AvatarGroup } from "@material-ui/lab";
import React from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";

import Invitation from "@higher/models/Invitation";

import acceptInvitation from "@app/api/functions/acceptInvitation";
import { Accent, Button } from "@app/brand";
import { WrapAuthRequired } from "@app/components/AuthRequired";
import asyncComponent from "@app/components/asyncComponent";
import useAuth from "@app/hooks/useAuth";
import useFirestoreValue from "@app/hooks/useFirestoreValue";
import InvitationById from "@app/state/InvitationById";
import OrganisationBrandById, {
  MainBrandAddress,
} from "@app/state/OrganisationBrandById";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: "center",
      height: `100vh`,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignContent: "center",
    },
    avatarGroup: {
      marginBottom: theme.spacing(4),
      display: "flex",
      justifyContent: "center",
    },
    avatar: {
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
    circularProgress: {
      margin: "0 auto",
      display: "block",
      color: theme.palette.grey.A100,
    },
  })
);

const Loading: React.FC = () => {
  const classes = useStyles();
  return (
    <Container maxWidth="sm" className={classes.root}>
      <CircularProgress />
    </Container>
  );
};

const Error: React.FC = () => {
  const classes = useStyles();
  return (
    <Container maxWidth="sm" className={classes.root}>
      <Typography variant="h5" component="h1" paragraph>
        Uh oh!
      </Typography>
      <Typography variant="h6" component="h2" paragraph>
        That invitation wasn't found. If you're seeing this in error, please
        contact{" "}
        <Link color="secondary" href="mailto:support@gethigher.io">
          support@gethigher.io
        </Link>
        .
      </Typography>
    </Container>
  );
};

const Expired: React.FC = () => {
  const classes = useStyles();
  return (
    <Container maxWidth="sm" className={classes.root}>
      <Typography variant="h5" component="h1" paragraph>
        Uh oh!
      </Typography>
      <Typography variant="h6" component="h2" paragraph>
        That invitation has already been used. If you're seeing this in error,
        please contact{" "}
        <Link color="secondary" href="mailto:support@gethigher.io">
          support@gethigher.io
        </Link>
        .
      </Typography>
    </Container>
  );
};

type AcceptControlsProps = {
  invitation: Invitation;
};

const AcceptControls = asyncComponent<AcceptControlsProps>(({ invitation }) => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useAuth();
  const [orgBrand] = useFirestoreValue(
    OrganisationBrandById(MainBrandAddress(invitation.organisationId))
  );

  if (!user) {
    return null;
  }

  const handleClick = async () => {
    await acceptInvitation(invitation.id);
    history.replace(`/dashboard`);
  };

  return (
    <Container maxWidth="sm" className={classes.root}>
      <AvatarGroup className={classes.avatarGroup}>
        <Avatar
          src={orgBrand.avatar}
          alt={orgBrand.name}
          className={classes.avatar}
        />
        <Avatar
          src={user.photoURL || ""}
          alt={user.displayName || ""}
          className={classes.avatar}
        />
      </AvatarGroup>
      <Typography variant="h5" component="h1" paragraph>
        Hey <Accent>{user.displayName}</Accent>! You've been invited to join{" "}
        <Accent>{orgBrand.name}</Accent> on Higher.
      </Typography>
      <Button cta small onClick={handleClick}>
        Accept invitation
      </Button>
    </Container>
  );
}, Loading);

type DispatcherProps = RouteComponentProps<{
  id: string;
}>;

const Dispatcher = asyncComponent<DispatcherProps>(
  ({ match }) => {
    const [invite, isInviteLoading] = useFirestoreValue(
      InvitationById(match.params.id)
    );
    if (isInviteLoading) {
      return null;
    }

    if (invite.expired) {
      return <Expired />;
    }

    return <AcceptControls invitation={invite} />;
  },
  Loading,
  Error
);

export default WrapAuthRequired<DispatcherProps>(Dispatcher);
