import MessageThread from "@higher/models/MessageThread";
import Organisation from "@higher/models/Organisation";

import queryStateSubscriptionByFunc from "./queryStateSubscriptionByFunc";

export default queryStateSubscriptionByFunc<MessageThread, Organisation>(
  "messageThreadsByOrg",
  (org) => `organisations/${org.id}/messageThreads`,
  (query) => {
    return query.orderBy("lastUpdated", "desc");
  }
);
