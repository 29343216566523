import Applicant from "@higher/models/Applicant";

import { Functions } from "@app/Firebase";

export type DemoPaymentResponse = {
  endorsementId: string;
};

export type MakeDemoPaymentFn = (
  applicant: Applicant,
  amount: number
) => Promise<DemoPaymentResponse>;

export const payment: MakeDemoPaymentFn = async (
  applicant,
  amount
): Promise<DemoPaymentResponse> => {
  const fn = Functions.httpsCallable("demoCharge");
  const result = await fn({
    applicantId: applicant.id,
    amount,
  });
  return result.data as DemoPaymentResponse;
};

export default payment;
