import Stage from "@higher/models/Stage";

import stateSubscriptionByObject from "./stateSubscriptionByObject";

export type StageAddress = {
  organisationId: string;
  roleId: string;
  id: string;
};

export default stateSubscriptionByObject<Stage, StageAddress>(
  "stageByStageAddress",
  (address) => {
    return `organisations/${address.organisationId}/roles/${address.roleId}/stages`;
  }
);
