export enum AccessLevel {
  Disabled = "disabled",
  Member = "member",
  Owner = "owner",
}

type Organisation = {
  id: string;
  name: string;
  members: Record<string, AccessLevel>;
};

export default Organisation;
