import { Functions } from "@app/Firebase";

export const resendInviteEmail = async (id: string): Promise<void> => {
  const fn = Functions.httpsCallable("resendInviteEmail");
  await fn({
    id,
  });
};

export default resendInviteEmail;
