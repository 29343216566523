import {
  useMediaQuery,
  Avatar,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Typography,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import NotHiredIcon from "@material-ui/icons/Cancel";
import LockIcon from "@material-ui/icons/Lock";
import HelpIcon from "@material-ui/icons/QuestionAnswer";
import AnonIcon from "@material-ui/icons/VerifiedUser";
import { Alert, AlertTitle } from "@material-ui/lab";
import React from "react";

import Applicant from "@higher/models/Applicant";
import Role from "@higher/models/Role";
import { FormatCurrency } from "@higher/payments/currency";

import { Accent, Callout as CalloutImages, Images, Keyword } from "@app/brand";
import Callout from "@app/components/Callout";
import PopupDialog from "@app/components/PopupDialog";

type Props = {
  applicant: Applicant;
  role: Role;
  selfEndorse: () => void;
  open: boolean;
  setOpen: (open: boolean) => void;
};

const useStyles = makeStyles((theme: Theme) => {
  const arrows = Images.BrandarrowsBackgroundImageTrans;
  return createStyles({
    link: {
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
    shareLink: {
      backgroundColor: "white",
      marginTop: theme.spacing(1),
      "& input": {
        color: theme.palette.secondary.dark,
        fontSize: "1.1rem",
      },
    },
    dialogHeaderContainer: {
      marginTop: theme.spacing(1),
      textAlign: "center",
    },
    dialogHeader: {
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(3),
        fontSize: "1.5rem",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "1.55rem",
      },
    },
    calloutContainer: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(1),
      [theme.breakpoints.up("lg")]: {
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(8),
      },
    },
    summary: {
      textAlign: "center",
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
      background: `url(${arrows})`,
      position: "relative",
    },
    alert: {
      marginTop: theme.spacing(3),
    },
  });
});

export const HowEndorsementsworkPopup: React.FC<Props> = ({
  role,
  applicant,
  selfEndorse,
  open,
  setOpen,
}) => {
  const classes = useStyles();
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const isHigher = role.employer === "Higher";

  return (
    <PopupDialog
      title="How this works"
      open={open}
      setOpen={setOpen}
      maxWidth="lg"
      fullWidth
      trackingId="how endorsements work"
    >
      <Container maxWidth="sm" className={classes.dialogHeaderContainer}>
        <Typography variant="h4" className={classes.dialogHeader}>
          Smart employers like <Keyword>{role.employer}</Keyword> base their
          hiring decisions on <Accent>trusted endorsements</Accent>
        </Typography>
      </Container>

      <Grid
        container
        spacing={isSmall ? 3 : 6}
        className={classes.calloutContainer}
      >
        <Grid item xs={12} md={4}>
          <Callout title="Send strong signals" image={CalloutImages.Bulb}>
            <>
              Endorsers signal confidence and earn trust by puting down between{" "}
              <Keyword>{FormatCurrency(role.currency, role.minStake)}</Keyword>{" "}
              and{" "}
              <Keyword>{FormatCurrency(role.currency, role.maxStake)}</Keyword>{" "}
              of their own money.
            </>
          </Callout>
        </Grid>
        <Grid item xs={12} md={4}>
          <Callout title="Reward your friends" image={CalloutImages.LevelUp}>
            <>
              If you're hired and pass probation, all endorsers will earn a{" "}
              <Keyword>100% return</Keyword>. You'll double their money.
            </>
          </Callout>
        </Grid>
        <Grid item xs={12} md={4}>
          <Callout title="A win-win scenario" image={CalloutImages.Heart}>
            <>
              If you fail probation, we'll recycle all endorsements into
              charitable donations.
            </>
          </Callout>
        </Grid>
      </Grid>
      <Container maxWidth="sm" disableGutters className={classes.summary}>
        <Typography variant="h5" component="h5">
          It's <Accent>Kickstarter for your career</Accent>. Get{" "}
          <Keyword>endorsements</Keyword> and get <Keyword>hired</Keyword>.
        </Typography>
      </Container>
      <Container maxWidth="sm" disableGutters>
        <List>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar>
                <NotHiredIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <>
                  <Accent>If you're not hired,</Accent> or if you and{" "}
                  {role.employer} decide to end probation early, we'll return
                  all endorsers' money.
                </>
              }
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar>
                <AnonIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <>
                  <Accent>Endorsements are anonymous</Accent> and private by
                  default, and you can endorse yourself if you want to.
                </>
              }
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar>
                <LockIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <>
                  <Accent>
                    There is no situation in which {role.employer}{" "}
                    {!isHigher && "or Higher"} end{isHigher && "s"} up keeping
                    your endorsers' money.
                  </Accent>
                </>
              }
            />
          </ListItem>
        </List>
        <Alert
          severity="info"
          icon={<HelpIcon fontSize="inherit" />}
          className={classes.alert}
        >
          <AlertTitle>We're here to help</AlertTitle>
          If you have any questions or comments, the Higher team is available
          via live chat or email.
        </Alert>
      </Container>
    </PopupDialog>
  );
};

export default HowEndorsementsworkPopup;
