import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import React, { useState } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: "1em",
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    focus: {
      border: `1px solid ${theme.palette.secondary.main}`,
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    content: {
      paddingTop: 0,
    },
    footer: {
      backgroundColor: "#fafafa",
    },
  })
);

type Props = {
  title: string;
  subtitle?: string;
  avatar: string;
  open?: boolean;
  focused?: boolean;
  actions?: JSX.Element;
};

const ExpandableCard: React.FC<Props> = ({
  title,
  subtitle,
  avatar,
  open,
  focused,
  children,
  actions,
}) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(!!open);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const cardClasses = [classes.root];
  if (focused) {
    cardClasses.push(classes.focus);
  }

  return (
    <Card className={cardClasses.join(" ")}>
      <CardHeader
        avatar={<Avatar alt={title} src={avatar} />}
        title={title}
        subheader={subtitle}
        action={
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent className={classes.content}>{children}</CardContent>
        {actions && (
          <CardActions className={classes.footer} disableSpacing>
            {actions}
          </CardActions>
        )}
      </Collapse>
    </Card>
  );
};

export default ExpandableCard;
