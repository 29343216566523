import {
  Badge,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import PeopleIcon from "@material-ui/icons/PeopleAlt";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import React from "react";

import Applicant from "@higher/models/Applicant";
import Role from "@higher/models/Role";
import Stage, { FixedStageID, IsFixedStageID } from "@higher/models/Stage";

import { Keyword, UpArrow } from "@app/brand";
import asyncComponent from "@app/components/asyncComponent";
import useFirestoreValue from "@app/hooks/useFirestoreValue";
import StagesByRole from "@app/state/StagesByRole";

type Props = {
  role: Role;
  applicant: Applicant;
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    cards: {
      "& .MuiCard-root::after": {
        content: "''",
        display: "block",
        height: theme.spacing(2.5),
        width: theme.spacing(2.5),
        position: "absolute",
        backgroundImage: `url(${UpArrow.ArrowRight})`,
        transform: "rotate(90deg)",
        backgroundSize: "50% 50%",
        backgroundColor: "white",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        zIndex: 1,
        borderRadius: "50%",
        boxShadow: theme.shadows[1],
        right: `calc(50% - ${theme.spacing(1.25)}px)`,
        top: `calc(100% - 6px)`,

        [theme.breakpoints.up("md")]: {
          top: "55%",
          right: -theme.spacing(1.5),
          backgroundImage: `url(${UpArrow.ArrowRight})`,
          transform: "none",
        },
      },
      "& .MuiGrid-item:last-of-type .MuiCard-root::after": {
        content: "",
        display: "none",
      },
    },
    card: {
      height: "100%",
      position: "relative",
      overflow: "visible",
    },
    inactiveCard: {
      "& .MuiTypography-caption": {
        color: "#999",
      },
    },
    header: {
      backgroundColor: "#efefef",
      color: "#888",
    },
    activeHeader: {
      backgroundColor: theme.palette.primary.main,
      color: "white",
      borderRadius: "4px 4px 0px 0px",
    },
  });
});

export type TooltipStatusProps = {
  applicant: Applicant;
  stage: Stage;
  totalNotInLongList: number;
};

const interviewingId = "interviewing";
export const PersonOrPeople = (count: number) =>
  count === 1 ? "person" : "people";
export const HaveOrHas = (count: number) => (count === 1 ? "has" : "have");
export const AreOrIs = (count: number) => (count === 1 ? "is" : "are");

export const ActiveStatus: React.FC<TooltipStatusProps> = ({
  applicant,
  stage,
  totalNotInLongList,
}) => {
  const people = PersonOrPeople(stage.applicantCount - 1);
  switch (applicant.stageId) {
    case FixedStageID.Applied:
      if (stage.applicantCount === 1) {
        return (
          <>
            <Typography variant="caption">
              You're the first to apply. You might get some competition soon!
            </Typography>
          </>
        );
      }

      if (!totalNotInLongList) {
        return (
          <Typography variant="caption">
            <Keyword>You are here</Keyword> with{" "}
            <Keyword>
              {stage.applicantCount - 1} other {people}
            </Keyword>
            . Get an endorsement and be the first onto the shortlist!
          </Typography>
        );
      }

      return (
        <Typography variant="caption">
          <Keyword>You are here</Keyword> with{" "}
          <Keyword>
            {stage.applicantCount - 1} other {people}
          </Keyword>
          . Get an endorsement to filter yourself into the shortlist!
        </Typography>
      );

    case FixedStageID.Endorsed:
      if (stage.applicantCount <= 1) {
        return (
          <>
            <Typography variant="caption">
              You're the first to be shortlisted. Expect some competition soon!
            </Typography>
          </>
        );
      }

      return (
        <>
          <Typography variant="caption">
            <Keyword>You're outstanding!</Keyword> Only{" "}
            <Keyword>{totalNotInLongList - 1}</Keyword> other {people} got this
            far.
          </Typography>
        </>
      );

    case FixedStageID.Hired:
      return (
        <>
          <Typography variant="caption">
            Awesome, you've been hired! We'll get your endorsers paid.
          </Typography>
        </>
      );

    case FixedStageID.Probation:
      return (
        <>
          <Typography variant="caption">
            Congratulations! Just probation to go. Too easy.
          </Typography>
        </>
      );

    default:
      return (
        <>
          <Typography variant="caption">
            You are here with <Keyword>{stage.applicantCount - 1}</Keyword>{" "}
            other {people}. Keep going, you've got this!
          </Typography>
        </>
      );
  }
};

export const PassiveStatus: React.FC<TooltipStatusProps> = ({
  applicant,
  stage,
}) => {
  const people = PersonOrPeople(stage.applicantCount);
  const have = HaveOrHas(stage.applicantCount);
  const are = AreOrIs(stage.applicantCount);

  switch (stage.id) {
    case FixedStageID.Applied:
      return (
        <Typography variant="caption">
          {stage.applicantCount} {people} {are} on the longlist.
        </Typography>
      );

    case FixedStageID.Endorsed:
      if (!stage.applicantCount) {
        return (
          <Typography variant="caption">
            No one is on the shortlist at the moment.
          </Typography>
        );
      }
      return (
        <Typography variant="caption">
          {stage.applicantCount} {people} {are} on the shortlist.
        </Typography>
      );

    case FixedStageID.Hired:
      if (!stage.applicantCount) {
        return (
          <Typography variant="caption">No one has been hired yet.</Typography>
        );
      }
      return (
        <Typography variant="caption">
          {stage.applicantCount} {people} {have} been hired.
        </Typography>
      );

    case FixedStageID.Probation:
      if (!stage.applicantCount) {
        return (
          <Typography variant="caption">
            No one has started probation yet.
          </Typography>
        );
      }
      return (
        <Typography variant="caption">
          {stage.applicantCount} {people} {have} been hired.
        </Typography>
      );

    default:
      if (!stage.applicantCount) {
        return (
          <Typography variant="caption">No one is interviewing yet.</Typography>
        );
      }
      return (
        <>
          <Typography variant="caption">
            {stage.applicantCount} {people} {are} being interviewed.
          </Typography>
        </>
      );
  }
};

export const LoadingSkeleton: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={1} className={classes.cards}>
      <Grid item xs={12} md>
        <Card className={clsx(classes.card)}>
          <CardHeader
            title={
              <Skeleton>
                <Typography variant="body2">Some title</Typography>
              </Skeleton>
            }
            className={classes.header}
          />
          <CardContent>
            <Skeleton>
              <Typography variant="caption">You are here with 63</Typography>
            </Skeleton>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md>
        <Card className={clsx(classes.card)}>
          <CardHeader
            title={
              <Skeleton>
                <Typography variant="body2">Another Stage title</Typography>
              </Skeleton>
            }
            className={classes.header}
          />
          <CardContent>
            <Skeleton>
              <Typography variant="caption">Some goes here</Typography>
            </Skeleton>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md>
        <Card className={clsx(classes.card)}>
          <CardHeader
            title={
              <Skeleton>
                <Typography variant="body2">Stage B title</Typography>
              </Skeleton>
            }
            className={classes.header}
          />
          <CardContent>
            <Skeleton>
              <Typography variant="caption">Some other content here</Typography>
            </Skeleton>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md>
        <Card className={clsx(classes.card)}>
          <CardHeader
            title={
              <Skeleton>
                <Typography variant="body2">Stage title</Typography>
              </Skeleton>
            }
            className={classes.header}
          />
          <CardContent>
            <Skeleton>
              <Typography variant="caption">Some content goes here</Typography>
            </Skeleton>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md>
        <Card className={clsx(classes.card)}>
          <CardHeader
            title={
              <Skeleton>
                <Typography variant="body2">Stage title</Typography>
              </Skeleton>
            }
            className={classes.header}
          />
          <CardContent>
            <Skeleton>
              <Typography variant="caption">Some content goes here</Typography>
            </Skeleton>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export const Status = asyncComponent<Props>(({ role, applicant }) => {
  const classes = useStyles();
  const [allStages] = useFirestoreValue(StagesByRole(role));

  const stages = allStages.filter((stage) => IsFixedStageID(stage.id));
  const interviewStages = allStages.filter(
    (stage) => !IsFixedStageID(stage.id)
  );
  const interviewingStage: Stage = {
    id: interviewingId,
    title: "Interviewing",
    index: 2,
    applicantCount: interviewStages.reduce<number>(
      (prev, current) => prev + current.applicantCount,
      0
    ),
  };

  stages.splice(
    stages.findIndex((stage) => stage.id === FixedStageID.Endorsed) + 1,
    0,
    interviewingStage
  );

  const nonLonglistStages = allStages.filter(
    (stage) => stage.id !== FixedStageID.Applied
  );
  const totalNotInLongList = nonLonglistStages.reduce<number>(
    (prev, current) => prev + current.applicantCount,
    0
  );

  return (
    <Grid container spacing={1} className={classes.cards}>
      {stages.map((stage, index) => {
        const isActive =
          applicant.stageId === stage.id ||
          (!IsFixedStageID(applicant.stageId) && stage.id === interviewingId);

        const applicantCount = stage.applicantCount;
        const content = isActive ? (
          <ActiveStatus
            applicant={applicant}
            stage={stage}
            totalNotInLongList={totalNotInLongList}
          />
        ) : (
          <PassiveStatus
            applicant={applicant}
            stage={stage}
            totalNotInLongList={totalNotInLongList}
          />
        );

        return (
          <Grid item xs={12} md>
            <Card
              className={clsx(classes.card, !isActive && classes.inactiveCard)}
            >
              <CardHeader
                className={clsx(
                  classes.header,
                  isActive && classes.activeHeader
                )}
                title={<Typography variant="body2">{stage.title}</Typography>}
                action={
                  <Badge
                    badgeContent={applicantCount}
                    color={isActive ? "secondary" : "primary"}
                    showZero
                    max={99}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <PeopleIcon fontSize="small" />
                  </Badge>
                }
              />
              <CardContent>{content}</CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
}, LoadingSkeleton);

export default Status;
