export enum EventType {
  Added = "manually-added",
  Application = "application",
  Endorsement = "endorsement",
  RankChange = "rank-change",
  Comment = "comment",
  StageChange = "stage-change",
  Withdrawl = "withdrawl",
  Review = "review",
  IncomingMessage = "incoming-message",
  OutgoingMessage = "outgoing-message",
  CandidateRemoval = "candidate-removal",
  CandidateRestoration = "candidate-restoration",
}

export enum EventUserType {
  Applicant = "applicant",
  User = "user",
}

export enum EventEntityType {
  Message = "Message",
}

export interface FirebaseTimestamp {
  toDate: () => Date;
}

/*eslint-disable*/
export type Event = {
  id: string;
  date: FirebaseTimestamp | any;
  type: EventType;
  content?: string;
  subjectType: EventUserType;
  subjectId: string;
  initiatorType: EventUserType;
  initiatorId: string;
  entityType?: EventEntityType;
  entityId?: string;
};

export default Event;
