import React from "react";
import { Helmet } from "react-helmet";

import Applicant from "@higher/models/Applicant";
import Role from "@higher/models/Role";

type Props = {
  applicant: Applicant;
  role: Role;
};

const MetaTags: React.FC<Props> = ({ applicant, role }) => {
  const title = `${applicant.name} + ${role.employer} - Higher`;
  const url = `https://gethigher.io/endorse/${applicant.id}`;
  const description = `${applicant.firstName} is applying to be a ${role.title} at ${role.employer} and needs your support`;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />

      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={applicant.socialSharingImage} />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={applicant.socialSharingImage} />
    </Helmet>
  );
};

export default MetaTags;
