import { Container, Link } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import React from "react";

import NavLinks from "@app/components/NavLinks";
import { Navigation, Sitemap } from "@app/configs/navigation";
import { openSlassk } from "@app/helpers/slaask";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      color: "white",
      textAlign: "center",
      backgroundColor: theme.palette.primary.main,
    },
    link: {
      textDecoration: "none",
      margin: theme.spacing(2),
      fontSize: "1.0rem",
      color: "white",
      cursor: "pointer",

      [theme.breakpoints.down("sm")]: {
        margin: 0,
        padding: theme.spacing(1),
        fontSize: "1.1rem",
        display: "block",
        width: "100%",
      },
    },
    contact: {
      marginTop: theme.spacing(3),
    },
  })
);

const Footer: React.FC = () => {
  const classes = useStyles();

  return (
    <footer className={classes.root}>
      <Container maxWidth="lg">
        <section>
          <NavLinks items={Navigation} className={classes.link} />
          <NavLinks items={Sitemap} className={classes.link} />
        </section>
        <section className={classes.contact}>
          <Link className={classes.link} onClick={openSlassk}>
            Live chat
          </Link>
          <Link className={classes.link} href="mailto:support@gethigher.io">
            support@gethigher.io
          </Link>
        </section>
      </Container>
    </footer>
  );
};

export default Footer;
