import { atomFamily, AtomEffect, SerializableParam } from "recoil";

import Firebase from "@app/Firebase";

import Identifiable from "./Identifiable";

type stringFn<T> = (id: T) => string;

async function getById<T extends Identifiable>(
  collection: string,
  id: string
): Promise<T | undefined | null> {
  const doc = await Firebase.firestore().collection(collection).doc(id).get();

  if (!doc.exists) {
    return null;
  }

  const record = doc.data() as T;
  record.id = id;

  return record;
}

export function syncStorageEffect<
  T extends Identifiable,
  U extends Identifiable & SerializableParam
>(
  ref: U | undefined
): (collFn: string | stringFn<U>) => AtomEffect<T | undefined | null> {
  return (collFn) => {
    return ({ setSelf, trigger }) => {
      if (!ref || !ref.id) {
        return;
      }

      const collection = typeof collFn === "string" ? collFn : collFn(ref);
      if (trigger === "get") {
        setSelf(getById<T>(collection, ref.id));
      }

      const unsubscribe = Firebase.firestore()
        .collection(collection)
        .doc(ref.id)
        .onSnapshot((doc) => {
          if (!doc.exists) {
            setSelf(null);
            return;
          }
          const record = doc.data() as T;
          record.id = ref.id;
          setSelf(record);
        });

      return () => {
        unsubscribe();
      };
    };
  };
}

export function stateSubscriptionByObject<
  T extends Identifiable,
  U extends Identifiable & SerializableParam
>(key: string, collection: string | stringFn<U>) {
  return atomFamily<T | undefined | null, U>({
    key,
    default: undefined,
    effects_UNSTABLE: (id) => [syncStorageEffect<T, U>(id)(collection)],
  });
}

export default stateSubscriptionByObject;
