import Applicant from "@higher/models/Applicant";
import Dashboard from "@higher/models/Dashboard";

import queryStateSubscriptionByFunc from "./queryStateSubscriptionByFunc";

const collection = "dashboards";

export default queryStateSubscriptionByFunc<Dashboard, Applicant>(
  "dashboardForApplicant",
  collection,
  (query, applicant) => {
    return query
      .where("organisationId", "==", applicant.organisationId)
      .where("applicantId", "==", applicant.id)
      .limit(1);
  }
);
