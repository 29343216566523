import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

import {
  FormControl,
  FormControlLabel,
  Switch,
  Typography,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

import { Button } from "@app/brand";

import PopupDialog from "@app/components/PopupDialog";

import Applicant from "@higher/models/Applicant";
import Role from "@higher/models/Role";

type Props = {
  applicant: Applicant;
  role: Role;
  open: boolean;
  setOpen: (open: boolean) => void;
  withdrawApplication: () => void;
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    alert: {
      marginBottom: theme.spacing(4),
    },
    dangerZone: {
      borderRadius: theme.spacing(1),
    },
  });
});

export const WithdrawPopup: React.FC<Props> = ({
  role,
  applicant,
  open,
  setOpen,
  withdrawApplication,
}) => {
  const classes = useStyles();
  const [understood, setUnderstood] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUnderstood(event.target.checked);
  };

  const onWithdraw = () => {
    withdrawApplication();
    setOpen(false);
  };

  return (
    <PopupDialog
      title="Withdraw your application"
      open={open}
      setOpen={setOpen}
      maxWidth="sm"
      fullWidth
      trackingId="withdraw popup"
    >
      <Alert severity="warning" className={classes.alert}>
        <AlertTitle>Careful, this can't be undone!</AlertTitle>
        If you withdraw your application for the role at {role.employer}, we'll{" "}
        <strong>refund any endorsement payments</strong> and{" "}
        <strong>cancel any pending interviews</strong>.
      </Alert>
      <Typography variant="h6" component="h2" paragraph>
        Are you sure?
      </Typography>
      <Alert severity="error" icon={false} className={classes.dangerZone}>
        <FormControl>
          <FormControlLabel
            control={<Switch checked={understood} onChange={handleChange} />}
            label="Yes, I know what I'm doing"
          />
        </FormControl>
        <Button cta smallText disabled={!understood} onClick={onWithdraw}>
          Withdraw my application
        </Button>
      </Alert>
    </PopupDialog>
  );
};

export default WithdrawPopup;
