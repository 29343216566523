import React from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { ApplicationStatus } from "@higher/models/Applicant";
import Dashboard from "@higher/models/Dashboard";

import WrapErrorSuspense from "@app/api/WrapErrorSuspense";
import useFirestoreValue from "@app/hooks/useFirestoreValue";
import ErrorFallback from "@app/modules/Error";
import demoPayment from "@app/payments/demo";
import candidatepayment from "@app/payments/stripe";
import ApplicantByIdState from "@app/state/ApplicantById";
import DashboardByIdState from "@app/state/DashboardById";
import RoleState from "@app/state/RoleById";

import Questions, { QuestionsSkeleton } from "./Questions.stateless";
import TooManyEndorsements from "./TooManyEndorsements";

type ViewProps = {
  dashboard: Dashboard;
};

const View: React.FC<ViewProps> = ({ dashboard }) => {
  const { applicantId } = dashboard;
  const [applicant] = useFirestoreValue(ApplicantByIdState(applicantId));
  const [role] = useFirestoreValue(RoleState(applicant?.roleId));
  const history = useHistory();

  const next = (id: string) => {
    history.replace(`/dashboard/${dashboard.id}/?endorsed=${id}`);
  };

  if (applicant.status !== ApplicationStatus.Pending) {
    throw new Error("Your application has been withdrawn");
  }

  if (role.maxEndorsers && applicant.endorsements >= role.maxEndorsers) {
    return <TooManyEndorsements applicant={applicant} role={role} />;
  }

  if (role.id !== "demo" && role.id !== "lorem-labs-contract-senior-solidity-engineer") {
    throw new Error("This role is no longer open for endorsements");
  }

  return (
    <Questions
      applicant={applicant}
      role={role}
      self
      makeCandidateEndorsementPayment={candidatepayment}
      makeDemoPayment={demoPayment}
      next={next}
      emailPrefill={dashboard.email}
    />
  );
};

type DispatcherProps = RouteComponentProps<{
  id: string;
}>;

const Dispatcher: React.FC<DispatcherProps> = (props) => {
  const dashboard = useRecoilValue(DashboardByIdState(props.match.params.id));

  if (!dashboard) {
    throw new Error("Something went wrong, that application doesn't exist");
  }
  return <View dashboard={dashboard} />;
};

export default WrapErrorSuspense<DispatcherProps>(
  Dispatcher,
  QuestionsSkeleton,
  ErrorFallback
);
