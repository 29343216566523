import React from "react";

import User from "@higher/models/User";

import { UpdateApplicantsFn } from "@app/state/UpdateApplicants";
import { UpdateEventsFn } from "@app/state/UpdateEvents";
import { UpdateStagesFn } from "@app/state/UpdateStages";
import { UpdateReviewsFn } from "@app/state/UpdateReviews";

type UpdateFns = {
  user: User;
  updateApplicants: UpdateApplicantsFn;
  updateEvents: UpdateEventsFn;
  updateStages: UpdateStagesFn;
  updateReviews: UpdateReviewsFn;
};

export const StateUpdateContext = React.createContext<UpdateFns>(
  {} as UpdateFns
);

export default StateUpdateContext;
