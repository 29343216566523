import {
  useMediaQuery,
  Button as MUIButton,
  Chip,
  Container,
  Grid,
  IconButton,
  Link,
  Paper,
  Step,
  Stepper,
  StepLabel,
  StepContent,
  Typography,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  ArrowRightAlt as MoreIcon,
  ExpandMore as ExpandIcon,
  Share as ShareIcon,
} from "@material-ui/icons";
import { Alert, AlertTitle, Skeleton } from "@material-ui/lab";
import _ from "lodash";
import React from "react";
import {
  buildStyles,
  CircularProgressbar,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import Applicant, { ApplicationStatus } from "@higher/models/Applicant";
import Role from "@higher/models/Role";
import { FormatCurrency } from "@higher/payments/currency";

import { Accent, Button, Emphasis, Images, Keyword } from "@app/brand";
import PopupDialog from "@app/components/PopupDialog";
import TrackedSection from "@app/components/TrackedSection";
import ResponsiveFC from "@app/helpers/responsiveness";
import { openSlassk } from "@app/helpers/slaask";
import useLocalStorage from "@app/hooks/useLocalStorage";
import useTracking from "@app/hooks/useTracking";
import Dashboard from "@app/layouts/Dashboard";

import FAQs from "./FAQs";
import HowEndorsementsworkPopup from "./HowEndorsementsWorkPopup";
import HeaderMenu, {
  HeaderMenuSkeleton,
  LeftMenu,
  LeftMenuSkeleton,
} from "./Menu";
import RolePopup from "./RolePopup";
import ShareLink from "./ShareLink";
import Status, { LoadingSkeleton as StatusLoadingSkeleton } from "./Status";

type BasicProps = {
  applicant: Applicant;
  role: Role;
};

type Props = BasicProps & {
  selfEndorse: () => void;
  selfEndorsementComplete?: boolean;
  withdrawApplication: () => void;
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    section: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(2),
    },
    link: {
      color: theme.palette.primary.main,
      cursor: "pointer",
    },
    linkPink: {
      color: theme.palette.secondary.main,
      cursor: "pointer",
    },

    stepper: {
      padding: theme.spacing(1.5),
      [theme.breakpoints.up("lg")]: {
        padding: 0,
      },
      backgroundColor: "transparent",
      marginBottom: theme.spacing(4),

      "& .MuiStepLabel-active": {
        color: theme.palette.secondary.main,
      },
    },
    stepDone: {},
    divider: {
      display: "inline-block",
    },
    endorsements: {
      [theme.breakpoints.up("md")]: {
        marginTop: theme.spacing(4),
      },
    },
    popupLink: {
      padding: 0,
      marginBottom: theme.spacing(2),
    },
    expandIcon: {
      position: "absolute",
      top: theme.spacing(1),
      color: "rgba(0, 0, 0, 0.42)",
    },

    endorsementStatus: {
      [theme.breakpoints.up("lg")]: {
        marginTop: -theme.spacing(1),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      },
    },
    nextEndorsement: {
      [theme.breakpoints.up("lg")]: {},
    },
    endorsementComplete: {
      textAlign: "center",
    },
    endorsementCompleteImage: {
      margin: theme.spacing(4),
      width: "30%",
    },
    withdrawn: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      padding: theme.spacing(2),
    },
    targets: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    gridCentered: {
      display: "flex",
      height: "100%",
      flexDirection: "column",
      justifyContent: "center",
      alignContent: "center",
      textAlign: "center",
    },

    avatarGroup: {
      "& .selected": {
        border: `2px solid ${theme.palette.secondary.main}`,
      },
    },

    skeleton: {
      marginBottom: theme.spacing(2),
    },
  });
});

type SectionProps = {
  title?: string;
  chip?: number;
};

export const Section: React.FC<SectionProps> = ({ title, chip, children }) => {
  const classes = useStyles();
  return (
    <TrackedSection trackingId={title || "untitled section"}>
      <section className={classes.section}>
        {title && (
          <Typography variant="h5" component="h1" paragraph>
            {title} {chip && <Chip label={chip} />}
          </Typography>
        )}
        {children}
      </section>
    </TrackedSection>
  );
};

export const SectionSkeleton: React.FC = () => {
  const classes = useStyles();
  return (
    <section className={classes.section}>
      <Skeleton variant="text">
        <Typography variant="h5" component="h1" paragraph>
          This is the section skeleton
        </Typography>
      </Skeleton>
      <Skeleton
        className={classes.skeleton}
        width="80%"
        variant="rect"
        height={200}
      />
      <Skeleton
        className={classes.skeleton}
        width="60%"
        variant="rect"
        height={100}
      />
    </section>
  );
};

export const MoreAboutEndorsements: React.FC<Props> = ({
  role,
  applicant,
  selfEndorse,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useLocalStorage("moreAboutEndorsementsOpen", true);
  const { logButtonClick } = useTracking();

  const handleButtonClick = () => {
    logButtonClick("more about endorsements");
    setOpen(true);
  };

  return (
    <>
      <Typography variant="body1" color="textSecondary" paragraph>
        <MUIButton
          className={classes.popupLink}
          color="primary"
          endIcon={<MoreIcon />}
          variant="text"
          onClick={handleButtonClick}
        >
          More about endorsements
        </MUIButton>
      </Typography>
      <HowEndorsementsworkPopup
        role={role}
        applicant={applicant}
        selfEndorse={selfEndorse}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
};

const EndorsementComplete: React.FC = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  return (
    <PopupDialog
      title="Endorsement complete!"
      open={open}
      setOpen={setOpen}
      maxWidth="sm"
      fullWidth
      trackingId="endorsement complete"
    >
      <div className={classes.endorsementComplete}>
        <img
          src={Images.Success}
          className={classes.endorsementCompleteImage}
          alt="Endorsement complete!"
        />
        <Typography variant="body1" paragraph>
          You should get an email receipt shortly, if you haven't already.
        </Typography>

        <Typography variant="body1" paragraph>
          If you have any issues, please contact us a{" "}
          <Accent>support@gethigher.io</Accent>.
        </Typography>
      </div>
    </PopupDialog>
  );
};

export const EndorsementsSectionInvisible: React.FC<Props> = ({
  role,
  applicant,
  selfEndorse,
  selfEndorsementComplete,
  withdrawApplication,
}) => {
  const classes = useStyles();
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  return (
    <>
      <Section title="Get started">
        <Container
          maxWidth={false}
          className={classes.endorsements}
          disableGutters
        >
          <Grid container spacing={isSmall ? 2 : 10}>
            <Grid item xs={12} md={6}>
              <Stepper
                className={classes.stepper}
                activeStep={1}
                orientation="vertical"
                connector={undefined}
              >
                <Step>
                  <StepLabel>
                    <Typography
                      variant="body2"
                      color="primary"
                      className={classes.stepDone}
                    >
                      Apply for the role
                    </Typography>
                  </StepLabel>
                </Step>
                <Step>
                  <StepLabel icon={<ShareIcon color="secondary" />}>
                    Get your first endorsement
                  </StepLabel>
                  <StepContent>
                    <Typography paragraph>
                      Stand out from the crowd with recommendations from your
                      peers. We'll pay them to support you. You can also endorse
                      yourself.
                    </Typography>
                    <MUIButton
                      color="secondary"
                      variant="contained"
                      onClick={selfEndorse}
                    >
                      Endorse youself
                    </MUIButton>
                  </StepContent>
                </Step>
                <Step>
                  <StepLabel>Interview</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Get hired</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Pass probation</StepLabel>
                </Step>
                <Step>
                  <StepLabel>Make your endorsers some cash</StepLabel>
                </Step>
              </Stepper>
            </Grid>
            <Grid item xs={12} md={6}>
              <div
                className={[
                  classes.endorsementStatus,
                  classes.nextEndorsement,
                ].join(" ")}
              >
                <Typography variant="body1" color="textSecondary" paragraph>
                  <Keyword>How to get your first endorsement</Keyword>
                </Typography>
                <Typography variant="body1" color="textSecondary" paragraph>
                  Share this link with your professional network to get going!
                </Typography>

                <ShareLink applicant={applicant} role={role} />
              </div>
            </Grid>
          </Grid>
        </Container>
      </Section>

      <Section title="Endorsements">
        <Container
          maxWidth={false}
          className={classes.endorsements}
          disableGutters
        >
          <Grid container spacing={isSmall ? 2 : 10}>
            <Grid item xs={12} md={6}>
              <div className={classes.endorsementStatus}>
                <Typography variant="body1" color="textSecondary" paragraph>
                  <Keyword>You don't stand out yet</Keyword>
                </Typography>
                <Typography variant="body1" color="textSecondary" paragraph>
                  You'll need at least {role.shortlistThreshold} endorsement
                  {role.shortlistThreshold === 1 ? "" : "s"} to be considered
                  for the role.
                </Typography>
                <Typography variant="body1" color="textSecondary" paragraph>
                  Get endorsements from colleagues, employers or anyone else who
                  knows you've got the chops.
                </Typography>
                <Typography variant="body1" color="textSecondary" paragraph>
                  If you get hired and pass probation, we'll pay anyone who
                  endorsed you up to{" "}
                  <Keyword>
                    {FormatCurrency(role.currency, role.maxStake)}
                  </Keyword>{" "}
                  &mdash; and you can{" "}
                  <Link onClick={selfEndorse} className={classes.link}>
                    endorse yourself
                  </Link>
                  .
                </Typography>
                <MoreAboutEndorsements
                  applicant={applicant}
                  role={role}
                  selfEndorse={selfEndorse}
                  withdrawApplication={withdrawApplication}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <Alert severity="info">
                <AlertTitle>You can endorse yourself!</AlertTitle>
                <Typography variant="body2" paragraph>
                  Get the ball rolling and learn about the process by
                  self-endorsing. You'll instantly stand out to {role.employer}.
                </Typography>
                <Typography variant="body2" paragraph>
                  When you get hired and pass probation, we'll pay you up to{" "}
                  <Keyword>
                    {FormatCurrency(role.currency, role.maxStake)}
                  </Keyword>{" "}
                  just for being confident in your own abilities.
                </Typography>
                <Typography variant="body2" paragraph>
                  If you withdraw your application for any reason before being
                  hired, you'll get a full and immediate refund.
                </Typography>
                <div>
                  <MUIButton
                    color="secondary"
                    variant="contained"
                    onClick={selfEndorse}
                  >
                    Endorse youself
                  </MUIButton>
                </div>
              </Alert>
            </Grid>
          </Grid>
        </Container>
      </Section>
    </>
  );
};

export const EndorsementsSectionVisible: React.FC<Props> = ({
  role,
  applicant,
  selfEndorse,
  selfEndorsementComplete,
  withdrawApplication,
}) => {
  const classes = useStyles();
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  return (
    <Section title="Endorsements">
      <Container
        maxWidth={false}
        className={classes.endorsements}
        disableGutters
      >
        <Grid container spacing={isSmall ? 2 : 10}>
          <Grid item xs={12} md={6}>
            <div className={classes.endorsementStatus}>
              <Typography variant="body1" color="textSecondary" paragraph>
                <Keyword>You're on {role.employer}'s radar!</Keyword>
              </Typography>
              <Targets
                total={applicant.endorsements}
                max={role.maxEndorsers}
                required={role.leaderThreshold}
                inOrder="to get to the top of the list"
              />
              <Typography variant="body1" color="textSecondary" paragraph>
                More endorsements will significantly increase your chances of
                being interviewed for the role.
              </Typography>
              <Typography variant="body1" color="textSecondary" paragraph>
                If you get hired and pass probation, we'll pay anyone who
                endorsed you up to{" "}
                <Keyword>
                  {FormatCurrency(role.currency, role.maxStake)}
                </Keyword>{" "}
                &mdash; and you can{" "}
                <Link onClick={selfEndorse} className={classes.linkPink}>
                  endorse yourself
                </Link>
                .
              </Typography>
              <MoreAboutEndorsements
                applicant={applicant}
                role={role}
                selfEndorse={selfEndorse}
                withdrawApplication={withdrawApplication}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div
              className={[
                classes.endorsementStatus,
                classes.nextEndorsement,
              ].join(" ")}
            >
              <Typography variant="body1" color="textSecondary" paragraph>
                <Keyword>Get your next endorsement</Keyword>
              </Typography>
              <Typography variant="body1" color="textSecondary" paragraph>
                One is great, but more is better. Keep spreading the word!
              </Typography>

              <ShareLink applicant={applicant} role={role} />
            </div>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
};

function Separator(props: any) {
  return (
    <div
      style={{
        position: "absolute",
        height: "101%",
        transform: `rotate(${props.turns}turn)`,
      }}
    >
      <div style={props.style} />
    </div>
  );
}

const RadialSeparators: React.FC<any> = (props) => {
  const turns = 1 / props.count;
  return (
    <>
      {_.range(props.count).map((index) => (
        <Separator turns={index * turns} style={props.style} />
      ))}
    </>
  );
};

type TargetProps = {
  total: number;
  max: number;
  required: number;
  inOrder?: string;
};

export const Targets: React.FC<TargetProps> = ({
  total,
  max,
  required,
  inOrder,
}) => {
  const classes = useStyles();
  const currentPer = Math.floor((total / max) * 100);
  const requiredPer = Math.floor((required / max) * 100);

  const need = required - total;
  const more = !total ? " " : " more ";

  return (
    <div className={classes.targets}>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <CircularProgressbarWithChildren
            value={requiredPer}
            text={`${total}/${required}`}
            strokeWidth={10}
            styles={buildStyles({
              strokeLinecap: "butt",
              trailColor: "#efefef",
              pathColor: "#D6D6D6",
            })}
          >
            <>
              <CircularProgressbar
                value={currentPer}
                strokeWidth={10}
                styles={buildStyles({
                  trailColor: "transparent",
                  strokeLinecap: "butt",
                  pathColor: "#D625A8",
                })}
              />
              <RadialSeparators
                count={max}
                style={{
                  background: "#fff",
                  width: "5px",
                  // This needs to be equal to props.strokeWidth
                  height: `${12}%`,
                }}
              />
            </>
          </CircularProgressbarWithChildren>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.gridCentered}>
            <Typography>
              Get{" "}
              <Accent>
                {need}
                {more}endorsement{need === 1 ? "" : "s"}
              </Accent>{" "}
              {inOrder}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export const EndorsementsSectionSubsequentEndorsements: React.FC<Props> = ({
  role,
  applicant,
  selfEndorse,
  selfEndorsementComplete,
  withdrawApplication,
}) => {
  const classes = useStyles();
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  return (
    <Section title="Endorsements" chip={applicant.endorsements}>
      <Container
        maxWidth={false}
        className={classes.endorsements}
        disableGutters
      >
        <Grid container spacing={isSmall ? 2 : 10}>
          <Grid item xs={12} md={6}>
            <div className={classes.endorsementStatus}>
              <Typography variant="body1" color="textSecondary" paragraph>
                <Keyword>
                  Awesome, you're top of {role.employer}'s shortlist!
                </Keyword>
              </Typography>
              <Targets
                total={applicant.endorsements}
                max={role.maxEndorsers}
                required={role.maxEndorsers}
                inOrder="to max out your endorsements"
              />
              <Typography variant="body1" color="textSecondary" paragraph>
                You're highly visible, and being seriously considered for the
                role. There's a good chance {role.employer} will want to
                interview you.
              </Typography>
              <Typography variant="body1" color="textSecondary" paragraph>
                Remember, if you get hired and pass probation, we'll pay anyone
                who endorsed you up to{" "}
                <Keyword>
                  {FormatCurrency(role.currency, role.maxStake)}
                </Keyword>{" "}
                &mdash; and you can{" "}
                <Link onClick={selfEndorse} className={classes.link}>
                  endorse yourself
                </Link>
                .
              </Typography>
              <MoreAboutEndorsements
                applicant={applicant}
                role={role}
                selfEndorse={selfEndorse}
                withdrawApplication={withdrawApplication}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <div
              className={[
                classes.endorsementStatus,
                classes.nextEndorsement,
              ].join(" ")}
            >
              <Typography variant="body1" color="textSecondary" paragraph>
                <Keyword>Keep going!</Keyword>
              </Typography>
              <Typography variant="body1" color="textSecondary" paragraph>
                There's still room for more endorsements... and more payouts for
                your supporters.
              </Typography>

              <ShareLink applicant={applicant} role={role} />
            </div>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
};

export const MaximumEndorsements: React.FC<BasicProps> = ({
  role,
  applicant,
}) => {
  const classes = useStyles();

  return (
    <Section title="Endorsements" chip={applicant.endorsements}>
      <Container
        maxWidth={false}
        className={classes.endorsements}
        disableGutters
      >
        <div className={classes.endorsementStatus}>
          <Typography variant="h6" color="textSecondary" paragraph>
            <Keyword>
              Amazing work, you've reached the maximum level of ensdorsement!
            </Keyword>
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            You're top of {role.employer}'s list and being seriously considered
            for the role. There's a very good chance {role.employer} will want
            to interview you.
          </Typography>
          <Typography variant="body1" color="textSecondary" paragraph>
            Remember, if you get hired and pass probation, we'll pay everyone
            who endorsed you up to{" "}
            <Keyword>{FormatCurrency(role.currency, role.maxStake)}</Keyword>.
          </Typography>
        </div>
      </Container>
    </Section>
  );
};

export const EndorsementsSection: React.FC<Props> = (props) => {
  if (
    props.role.maxEndorsers &&
    props.applicant.endorsements >= props.role.maxEndorsers
  ) {
    return (
      <MaximumEndorsements applicant={props.applicant} role={props.role} />
    );
  }

  if (props.applicant.endorsements < props.role.shortlistThreshold) {
    return <EndorsementsSectionInvisible {...props} />;
  }

  if (props.applicant.endorsements < props.role.leaderThreshold) {
    return <EndorsementsSectionVisible {...props} />;
  }

  return <EndorsementsSectionSubsequentEndorsements {...props} />;
};

export const RecruitContentPending: React.FC<Props> = ({
  role,
  applicant,
  selfEndorse,
  selfEndorsementComplete,
  withdrawApplication,
}) => {
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  return (
    <>
      {selfEndorsementComplete && <EndorsementComplete />}
      {selfEndorsementComplete}
      <Section>
        <Status role={role} applicant={applicant} />
      </Section>
      <EndorsementsSection
        role={role}
        applicant={applicant}
        selfEndorse={selfEndorse}
        withdrawApplication={withdrawApplication}
      />
      <Section title="Questions?">
        <FAQs applicant={applicant} role={role} />
        <Button
          tertiary
          small={!isSmall}
          onClick={openSlassk}
          trackingId="I have another question"
        >
          I have another question...
        </Button>
      </Section>
    </>
  );
};

export const RecruitContentWithdrawn: React.FC = (props) => {
  const classes = useStyles();
  return (
    <Container maxWidth="sm">
      <Paper className={classes.withdrawn}>
        <Typography variant="h5" component="h1" paragraph>
          You have withdrawn your application
        </Typography>
        <Typography paragraph>
          All endorsement payments will be refunded, and you will no longer be
          considered for the role.
        </Typography>
        <Typography>
          If you think this is in error, please contact us via{" "}
          <Link href="mailto:support@gethigher.io">support@gethigher.io</Link>.
        </Typography>
      </Paper>
    </Container>
  );
};

export const RecruitContentRemoved: React.FC = (props) => {
  const classes = useStyles();
  return (
    <Container maxWidth="sm">
      <Paper className={classes.withdrawn}>
        <Typography variant="h5" component="h1" paragraph>
          Your application has not been successful
        </Typography>
        <Typography paragraph>
          All endorsement payments will be refunded, and you will no longer be
          considered for the role.
        </Typography>
        <Typography>
          If you think this is in error, please contact us via{" "}
          <Link href="mailto:support@gethigher.io">support@gethigher.io</Link>.
        </Typography>
      </Paper>
    </Container>
  );
};

export const RecruitContent: React.FC<Props> = (props) => {
  if (props.applicant.status === ApplicationStatus.Withdrawn) {
    return <RecruitContentWithdrawn />;
  }
  if (props.applicant.status === ApplicationStatus.Removed) {
    return <RecruitContentRemoved />;
  }

  return <RecruitContentPending {...props} />;
};

export const RecruitContentSkeleton: React.FC = () => {
  const classes = useStyles();
  return (
    <>
      <section className={classes.section}>
        <StatusLoadingSkeleton />
      </section>
      <SectionSkeleton />
      <SectionSkeleton />
    </>
  );
};

const RoleTitleLong: React.FC<Props> = ({ applicant, role }) => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const onClick = () => setOpen(true);

  return (
    <>
      <Typography variant="body1">
        <Accent>{role.title}</Accent> @ <Keyword>{role.employer}</Keyword>
        &nbsp;&middot;&nbsp;
        <Emphasis>
          {role.salary}
          &nbsp;&middot;&nbsp;
          {role.location}
        </Emphasis>
        <IconButton className={classes.expandIcon} onClick={onClick}>
          <ExpandIcon />
        </IconButton>
        <RolePopup
          applicant={applicant}
          role={role}
          open={open}
          setOpen={setOpen}
        />
      </Typography>
    </>
  );
};

const RoleTitleShort: React.FC<Props> = ({ role }) => {
  return (
    <Typography variant="body1">
      <Accent>{role.title}</Accent> @ <Keyword>{role.employer}</Keyword>
    </Typography>
  );
};

const RoleTitle = ResponsiveFC<Props>({
  small: RoleTitleShort,
  large: RoleTitleLong,
});

const RoleTitleSkeleton: React.FC = () => (
  <Skeleton width={200} variant="text" />
);

export const Home: React.FC<Props> = ({
  role,
  applicant,
  selfEndorse,
  selfEndorsementComplete,
  withdrawApplication,
}) => {
  const { tracking } = useTracking();
  tracking.logEvent("page_view", {});

  return (
    <Dashboard
      headerProps={{
        menu: (
          <HeaderMenu
            applicant={applicant}
            role={role}
            selfEndorse={selfEndorse}
            withdrawApplication={withdrawApplication}
          />
        ),
        border: true,
        logo: (
          <RoleTitle
            applicant={applicant}
            role={role}
            selfEndorse={selfEndorse}
            withdrawApplication={withdrawApplication}
          />
        ),
      }}
      menu={
        <LeftMenu
          applicant={applicant}
          role={role}
          selfEndorse={selfEndorse}
          withdrawApplication={withdrawApplication}
        />
      }
      content={
        <RecruitContent
          applicant={applicant}
          role={role}
          selfEndorse={selfEndorse}
          selfEndorsementComplete={selfEndorsementComplete}
          withdrawApplication={withdrawApplication}
        />
      }
    />
  );
};

export const HomeSkeleton: React.FC = () => {
  return (
    <Dashboard
      headerProps={{
        menu: <HeaderMenuSkeleton />,
        logo: <RoleTitleSkeleton />,
        border: true,
      }}
      menu={<LeftMenuSkeleton />}
      content={<RecruitContentSkeleton />}
    />
  );
};

export default Home;
