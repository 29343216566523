import { ApplyPayload, ApplyResponse } from "@higher/api/apply";

import { Functions } from "@app/Firebase";

export type ApplyFn = (input: ApplyPayload) => Promise<ApplyResponse>;

export const apply: ApplyFn = async (input) => {
  const fn = Functions.httpsCallable("apply");

  if (document.referrer && document.referrer !== "") {
    input.source = document.referrer;
  } else {
    input.source = "";
  }

  const result = await fn(input);
  return result.data as ApplyResponse;
};

export default apply;
