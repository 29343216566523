import { Functions } from "@app/Firebase";

export const acceptInvitation = async (invitationId: string): Promise<void> => {
  const fn = Functions.httpsCallable("acceptInvitation");
  await fn({
    invitationId,
  });
};

export default acceptInvitation;
