import { Alert, AlertTitle } from "@material-ui/lab";
import React from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";

const Fallback: React.FC<FallbackProps> = ({ error }) => {
  return (
    <Alert severity="error">
      <AlertTitle>That didn't work</AlertTitle>
      {error?.message}
    </Alert>
  );
};

function asyncComponent<P extends {} = {}>(
  Inner: React.FC<P>,
  Loading: React.FC,
  CustomFallback?: React.FC<FallbackProps>
): React.FC<P> {
  return (props: P) => (
    <ErrorBoundary FallbackComponent={CustomFallback || Fallback}>
      <React.Suspense fallback={<Loading />}>
        <Inner {...props} />
      </React.Suspense>
    </ErrorBoundary>
  );
}

export default asyncComponent;
