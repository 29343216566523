export enum YesNo {
  Yes = "yes",
  No = "no",
}

export type YesOrNo = YesNo.Yes | YesNo.No;

export const YesNoOrUndefined = (
  input: YesOrNo | undefined
): boolean | undefined => {
  if (input === YesNo.Yes) {
    return true;
  }
  if (input === YesNo.No) {
    return false;
  }

  return undefined;
};
