import Applicant from "@higher/models/Applicant";
import Role from "@higher/models/Role";

import FAQs, { Question } from "@app/components/FAQs";

type Props = {
  applicant: Applicant;
  role: Role;
};

const questions: Array<Question<Props>> = [
  {
    title: "How much return will I get?",
    summary: ({ applicant, role }) => `
100%. We'll double your money.
`,
    content: ({ applicant, role }) => `
Assuming ${applicant.firstName} is hired and passes probation, you'll get a return in two parts:

1. We'll refund your original payment to the original payment method.
2. We'll pay the same amount to you via bank transfer.

The refund will be made as soon as ${applicant.firstName}'s probation ends. 

The payment will be made as soon as we have your bank details. We'll ask you for those the day after ${applicant.firstName}'s probation ends.
`,
  },
  {
    title: "Do I have to make a payment now?",
    summary: ({ applicant, role }) => `
Yes.
`,
    content: ({ applicant, role }) => `
Your endorsement only carries weight if you have skin in the game. ${role.employer} need strong, clear signals of confidence &mdash; and that means you putting something valuable at risk. 

We take payment via Stripe, and we accept all major payment methods. We'll give you a payment receipt, and you may cancel your endorsement any time before ${applicant.firstName} is hired.

If ${applicant.firstName} is hired, then we'll put your money into escrow until the process is complete.
`,
  },

  {
    title: "How long will my money be tied up?",
    summary: ({ applicant, role }) => `
Assuming ${applicant.firstName} is hired and passes probation, **${
      role.probation + 10
    }-${role.probation + 70} days**.
`,
    content: ({ applicant, role }) => `
It depends on what happens with ${applicant.firstName} and ${role.employer}.

**If ${applicant.firstName} is not hired**, either because ${
      role.employer
    } hire someone else or don't hire anyone, then your money will be refunded immediately.

**If ${
      applicant.firstName
    } is hired**, then your money will be held in escrow until ${
      applicant.firstName
    }'s probation ends or either party terminate the employment early.

There are four factors that will influence the total duration of your money being held:

1. The interviewing process takes some time. Higher makes things much quicker, but we'd still expect it to take about **10 days** on average.
1. Once hired, ${applicant.firstName} and ${
      role.employer
    } have up **30 days** to negotiate a start date. (We'll give you a refund if they don't agree on a start date in time).
2. ${
      applicant.firstName
    } may have to give notice at their current place of employment. This varies, but it's typically around **30 days**.
3. The probation period for this role is **${
      role.probation
    } days**, which will begin on ${applicant.firstName}'s start date.

Adding that all up, if you endorse ${
      applicant.firstName
    } today, you're looking at a potential **100% return** in **${
      role.probation + 10
    } - ${role.probation + 70} days**, assuming ${
      applicant.firstName
    } passes probation.

**If ${
      applicant.firstName
    } fails probation** your money will be donated to charity.

**If either party terminates the employment early and it's not ${
      applicant.firstName
    }'s fault**, then we'll refund your money immediately, and we may pay you a pro-rata reward.
`,
  },
  {
    title: "Where will my money be held during escrow?",
    summary: ({ applicant, role }) => `
We use Stripe as our escrow provider.
`,
    content: ({ applicant, role }) => `
**If ${role.employer} hire ${applicant.firstName}** then we'll put your payment into escrow. We will use [Stripe Payments Europe Ltd](http://www.stripe.com), a regulated third-party service, as our escrow provider.
`,
  },

  {
    title: "What happens if I don't agree with a termination?",
    summary: ({ applicant, role }) => `
If ${applicant.firstName} is hired and fails probation, we'll give you the chance to appeal.
`,
    content: ({ applicant, role }) => `
If ${role.employer} decide to fail ${applicant.firstName} during probation due to a lack of skills or experience, they'll have to tell us why and provide evidence. We'll tell you the reason, and if you don't agree then you may appeal the decision.

We can't do much about ${applicant.firstName} being fired, but we will give you your money back if we find that the firing was unjustified. We may also pay you a pro-rata reward.
`,
  },
  {
    title: "Is there a pro-rata return?",
    summary: ({ applicant, role }) => `
Yes, if ${role.employer} end ${applicant.firstName}'s probation early or unjustly.
`,
    content: ({ applicant, role }) => `
We'll pay you a partial reward if ${applicant.firstName} has worked at ${
      role.employer
    } for more than 14 days and either:

- ${role.employer} end ${applicant.firstName}'s employment early and it's not ${
      applicant.firstName
    }'s fault, or
- ${role.employer} decide to fail ${
      applicant.firstName
    } during probation and you successfully appeal the termination

For example, let's say you endorse ${applicant.firstName} for £100. If ${
      role.employer
    } end ${applicant.firstName}'s employment after ${
      role.probation / 2
    } days (half way through probation) we'll pay you a partial endorsement reward of £50.

`,
  },

  {
    title: "How involved in the process do I have to be?",
    summary: ({ applicant, role }) => `
After you endorse ${applicant.firstName}, you don't have to do anything.
`,
    content: ({ applicant, role }) => `
Finding more people who are qualified and willing to endorse ${applicant.firstName} may help their chances of getting hired, but it's not necessary.

If ${applicant.firstName} is hired and passes probation, we'll need your bank account details so we can pay you your **100% return**.
`,
  },

  {
    title: "Can I cancel or withdraw my endorsement?",
    content: ({ applicant, role }) => `
You may cancel anytime until ${applicant.firstName} is hired. After that, your money will be held in escrow until ${applicant.firstName} passes probation, fails probation, or their employment is terminated.
`,
  },
  {
    title: "Which charities do you give to?",
    summary: ({ applicant, role }) => `
We give to the Effective Altruism Funds.
`,
    content: ({ applicant, role }) => `
**If ${applicant.firstName} fails probation at ${role.employer} due to a lack of skills and/or experience** we'll donate your payment to charity.

We'll split the money between the 4 main [Effective Altruism Funds](https://app.effectivealtruism.org/funds). 

Effective Altruism Funds operate like a mutual funds, except they maximise the effectiveness of  donations, rather than investment returns. The programme is administered by the  [Centre for Effective Altruism](https://www.centreforeffectivealtruism.org/), a 501(c)(3) tax-exempt organisation in the US, and a registered charity in England and Wales.
`,
  },
];

export default FAQs<Props>(questions);
