import Role from "@higher/models/Role";
import Stage from "@higher/models/Stage";
import batchUpdate, { BatchUpdateFn, UpdateCallback } from "./batchUpdate";

export type UpdateStagesFn = BatchUpdateFn<Stage>;

export const UpdateStages = (role: Role) => (callback: UpdateCallback<Stage>) =>
  batchUpdate<Stage>(
    `organisations/${role.organisationId}/roles/${role.id}/stages`,
    callback
  );

export default UpdateStages;
