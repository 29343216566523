import Applicant from "@higher/models/Applicant";

import { stateSubscriptionById } from "./stateSubscriptionById";

const ApplicantsCollection = "applicants";

export default stateSubscriptionById<Applicant>(
  "applicantById",
  ApplicantsCollection
);
