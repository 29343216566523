export type Stage = {
  id: string;
  title: string;
  index?: number;
  applicantCount: number;
};

export enum FixedStageID {
  Applied = "applied",
  Endorsed = "endorsed",
  Probation = "probation",
  Hired = "hired",
  Archive = "archive",
}

export const FixedStageIDs = [
  FixedStageID.Applied,
  FixedStageID.Endorsed,
  FixedStageID.Probation,
  FixedStageID.Hired,
  FixedStageID.Archive,
];

export const ArchivedStageId = FixedStageID.Archive;

export const IsFixedStageID = (id: FixedStageID | string): boolean =>
  FixedStageIDs.includes(id as FixedStageID);

export default Stage;
