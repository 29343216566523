import {
  useMediaQuery,
  Card,
  CardMedia,
  CardContent,
  CardHeader,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Share as ShareIcon, Close as CloseIcon } from "@material-ui/icons";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@material-ui/lab";
import React from "react";
import {
  EmailShareButton,
  FacebookMessengerShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinIcon,
  WhatsappIcon,
  TwitterIcon,
  FacebookMessengerIcon,
  TelegramIcon,
  EmailIcon,
} from "react-share";

import useTracking from "@app/hooks/useTracking";

import CopyLink from "./CopyLink";

type ShareProps = {
  title: string;
  description: string;
  url: string;
  fullWidth?: boolean;
  image?: string;
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    speedDial: {
      marginTop: theme.spacing(2),
      "& button": {
        backgroundColor: theme.palette.secondary.main,
      },
      "& button:hover": {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    speedDialButton: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      borderRadius: "50%",
      padding: 0,
      overflow: "hidden",
      backgroundColor: "red",
      "& svg": {
        width: theme.spacing(5),
        height: theme.spacing(5),
      },
    },
    media: {
      height: 200,
      [theme.breakpoints.up("xl")]: {
        height: 270,
      },
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    shareLink: {
      backgroundColor: "white",
      marginTop: theme.spacing(1),
      "& input": {
        color: theme.palette.secondary.dark,
        fontSize: "1.1rem",
      },
    },
    socialSharingPreview: {
      marginTop: theme.spacing(2),
      boxShadow: theme.shadows[3],
      width: "100%",
    },
  });
});

export const Share: React.FC<ShareProps> = ({
  title,
  description,
  url,
  fullWidth,
  image,
}) => {
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const classes = useStyles();
  const { tracking } = useTracking();
  const [open, setOpen] = React.useState(false);
  const shareAvailable = !!navigator.share;

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    if (shareAvailable) {
      tracking.logEvent("share", { method: "mobile" });
      navigator.share({
        title,
        text: description,
        url,
      });
      return;
    }
    setOpen(true);
  };

  const track = (method: string): (() => void) => {
    return () => tracking.logEvent("share", { method });
  };

  const LinkedIn = (
    <LinkedinShareButton
      url={url}
      title={title}
      smin-widthummary={description}
      source="Higher"
      className={classes.speedDialButton}
      beforeOnClick={track("linkedin")}
    >
      <LinkedinIcon />
    </LinkedinShareButton>
  );

  const Whatsapp = (
    <WhatsappShareButton
      url={url}
      title={title}
      className={classes.speedDialButton}
      beforeOnClick={track("whatsapp")}
    >
      <WhatsappIcon />
    </WhatsappShareButton>
  );

  const Twitter = (
    <TwitterShareButton
      url={url}
      title={title}
      className={classes.speedDialButton}
      beforeOnClick={track("twitter")}
    >
      <TwitterIcon />
    </TwitterShareButton>
  );

  const Facebook = (
    <FacebookMessengerShareButton
      appId="730534374279028"
      url={url}
      className={classes.speedDialButton}
      beforeOnClick={track("facebook")}
    >
      <FacebookMessengerIcon />
    </FacebookMessengerShareButton>
  );

  const Telegram = (
    <TelegramShareButton
      url={url}
      title={title}
      className={classes.speedDialButton}
      beforeOnClick={track("telegram")}
    >
      <TelegramIcon />
    </TelegramShareButton>
  );

  const Email = (
    <EmailShareButton
      url={url}
      subject={title}
      body={description}
      className={classes.speedDialButton}
      beforeOnClick={track("email")}
    >
      <EmailIcon />
    </EmailShareButton>
  );

  let shareImage = (
    <CardMedia className={classes.media} title="Social sharing preview">
      <CircularProgress color="secondary" />
    </CardMedia>
  );

  if (image && image.length) {
    shareImage = (
      <CardMedia
        className={classes.media}
        image={image}
        title="Social sharing preview"
      />
    );
  }

  return (
    <>
      <Card>
        <CardHeader action={<CopyLink url={url} />} subheader={url} />
        {shareImage}
        <CardContent>
          <Typography gutterBottom variant="h6" component="h6">
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {description}
          </Typography>
        </CardContent>
      </Card>

      <SpeedDial
        className={classes.speedDial}
        ariaLabel="Share with professional supporters"
        icon={<SpeedDialIcon icon={<ShareIcon />} openIcon={<CloseIcon />} />}
        direction={isSmall ? "left" : "right"}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        <SpeedDialAction
          key="linkedin"
          icon={LinkedIn}
          onClick={handleClose}
          tooltipTitle="LinkedIn"
          tooltipPlacement="bottom"
        />
        <SpeedDialAction
          key="telegram"
          icon={Telegram}
          onClick={handleClose}
          tooltipTitle="Telegram"
          tooltipPlacement="bottom"
        />
        <SpeedDialAction
          key="twitter"
          icon={Twitter}
          onClick={handleClose}
          tooltipTitle="Twitter"
          tooltipPlacement="bottom"
        />
        <SpeedDialAction
          key="facebook"
          icon={Facebook}
          onClick={handleClose}
          tooltipTitle="Facebook"
          tooltipPlacement="bottom"
        />
        {!isSmall && (
          <SpeedDialAction
            key="whatsapp"
            icon={Whatsapp}
            onClick={handleClose}
            tooltipTitle="Whatsapp"
            tooltipPlacement="bottom"
          />
        )}

        {!isSmall && (
          <SpeedDialAction
            key="email"
            icon={Email}
            onClick={handleClose}
            tooltipTitle="Email"
            tooltipPlacement="bottom"
          />
        )}
      </SpeedDial>
    </>
  );
};

export default Share;
