export class InterpolationReplacementMap extends Map<string, string> {}

const decorateVariable = (input: string): string => `\${${input}}`;

const Interpolate = (
  input: string,
  replace: InterpolationReplacementMap
): string => {
  replace.forEach((to, from) => {
    input = input.replace(decorateVariable(from), to);
    console.log(decorateVariable(from), to, input);
  });
  return input;
};

export default Interpolate;
