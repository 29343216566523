import React from "react";
import { Link } from "react-router-dom";

import { LinkProps } from "@app/configs/navigation";

type Props = {
  items: Array<LinkProps>;
  className?: string;
  onlyFeatured?: boolean;
};

export const generateOnClick = (item: LinkProps): ((e?: any) => void) => {
  if (!item.onClick) return () => {};

  return (e) => {
    e.preventDefault();
    item.onClick && item.onClick();
  };
};

const NavLinks: React.FC<Props> = ({ items, className, onlyFeatured }) => {
  const filtered = items.filter((item) =>
    onlyFeatured ? !!item.featured : true
  );
  return (
    <>
      {filtered.map((item) =>
        item.external ? (
          <a
            href={item.link}
            className={className}
            onClick={generateOnClick(item)}
          >
            {item.title}
          </a>
        ) : (
          <Link
            className={className}
            key={item.title}
            to={item.link}
            onClick={generateOnClick(item)}
          >
            {item.title}
          </Link>
        )
      )}
    </>
  );
};

export default NavLinks;
