import {
  useMediaQuery,
  Avatar,
  Container,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import {
  LinkedInLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";

import Role, { UnknownRole } from "@higher/models/Role";

import { Accent, Button, Emphasis, Keyword, Images } from "@app/brand";
import TrackedSection from "@app/components/TrackedSection";
import articleForRoleTitle from "@app/helpers/articleForRoleTitle";
import useMountEffect from "@app/hooks/useMountEffect";
import useTracking from "@app/hooks/useTracking";
import Page from "@app/layouts/Page";

import MetaTags from "./MetaTags";

const useStyles = makeStyles((theme: Theme) => {
  const balloon = Images.BrandballoonPrimary;
  const arrows = Images.BrandarrowsBackgroundImageTrans;
  const curve = Images.HillsTop;
  const leftPositionX = `calc( ((100vw - ${theme.breakpoints.values.md}px)/2) - 75vh )`;
  const rightPositionX = `calc( ((100vw - ${theme.breakpoints.values.md}px)/2) + ${theme.breakpoints.values.md}px )`;

  return createStyles({
    topContainer: {
      backgroundImage: `url(${balloon}), url(${arrows}), url(${arrows}), url(${curve})`,
      backgroundSize: `75vh auto, 75vh auto,75vh auto, 100% auto`,
      backgroundRepeatX: "no-repeat",
      backgroundRepeat: "no-repeat, repeat, no-repeat",
      backgroundPositionX: `${leftPositionX}, ${leftPositionX}, ${rightPositionX}, left`,
      backgroundPositionY: `-${theme.spacing(
        3
      )}px, 2px, 100px,calc(100% + 1px)`,
    },
    topSection: {
      marginTop: theme.spacing(6),
      paddingBottom: theme.spacing(16),
      [theme.breakpoints.up("lg")]: {
        marginTop: 0,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        height: `calc( (var(--vh, 1vh) * 100) - ${theme.spacing(8)}px)`,
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
      },
    },
    topSectionInner: {
      alignSelf: "center",
    },

    title: {
      textAlign: "center",
      padding: 0,
    },
    mainHeader: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.5rem",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "1.55rem",
      },
    },
    avatar: {
      width: theme.spacing(7),
      height: theme.spacing(7),
      margin: "0 auto",
      marginBottom: theme.spacing(4),
      marginTop: theme.spacing(4),
      [theme.breakpoints.only("lg")]: {
        width: theme.spacing(8),
        height: theme.spacing(8),
        marginTop: theme.spacing(0),
      },
    },
    socialContainer: {
      [theme.breakpoints.only("lg")]: {
        minWidth: theme.breakpoints.values.sm,
      },
    },
    prepop: {
      marginTop: theme.spacing(4),
    },
    socialButton: {
      fontFamily: theme.typography.fontFamily,
      fontSize: `${theme.typography.fontSize * 1.2}px !important`,
      width: "100% !important",
      margin: "0 !important",
      boxShadow: `${theme.shadows[1]} !important`,
      height: `${theme.spacing(6)}px !important`,
      padding: `0 ${theme.spacing(2)}px !important`,
    },
    or: {
      textAlign: "center",
      margin: theme.spacing(2.5),
      [theme.breakpoints.only("lg")]: {
        margin: theme.spacing(2.5),
      },
    },
    secondSection: {
      backgroundColor: theme.palette.primary.main,
      paddingTop: "12vw",
      paddingBottom: theme.spacing(10),
      backgroundImage: `url(${Images.HillsBottom})`,
      backgroundSize: `100% auto`,
      backgroundRepeatX: "no-repeat",
      backgroundRepeat: "no-repeat",
      backgroundPositionX: `left`,
      backgroundPositionY: `top`,
    },
    skeleton: {
      margin: "0 auto",
    },
  });
});

type ApplicationProps = {
  applyWithLinkedIn: () => void;
  applyWithGoogle: () => void;
  applyWithNothing: () => void;
};

type Props = ApplicationProps & {
  role: Role;
};

const Intro: React.FC<Props> = ({
  role,
  applyWithNothing,
  applyWithGoogle,
  applyWithLinkedIn,
}) => {
  const classes = useStyles();

  const article = articleForRoleTitle(role.title);

  return (
    <>
      <Container className={classes.topContainer} maxWidth={false}>
        <section className={classes.topSection}>
          <div className={classes.topSectionInner}>
            <Container maxWidth="md" className={classes.title}>
              <Avatar
                src={role.avatar}
                alt={role.employer}
                className={classes.avatar}
              />
              <Typography
                variant="h4"
                component="h1"
                paragraph
                className={classes.mainHeader}
              >
                Apply to be {article} <Accent>{role.title}</Accent> at{" "}
                <Keyword>{role.employer}</Keyword>
              </Typography>
              <Typography variant="h6" component="h2" paragraph>
                {role.heading}
              </Typography>
              <Typography component="h3" paragraph>
                <Emphasis>{role.location}</Emphasis> &middot;{" "}
                <Emphasis>{role.salary}</Emphasis>
              </Typography>
            </Container>
            <ApplicationForm
              applyWithNothing={applyWithNothing}
              applyWithGoogle={applyWithGoogle}
              applyWithLinkedIn={applyWithLinkedIn}
            />
          </div>
        </section>
      </Container>
      <section className={classes.secondSection}>&nbsp;</section>
    </>
  );
};

export const ApplicationForm: React.FC<ApplicationProps> = ({
  applyWithNothing,
  applyWithGoogle,
  applyWithLinkedIn,
}) => {
  const classes = useStyles();
  const isLarge = useMediaQuery<Theme>((theme) => theme.breakpoints.up("lg"));

  return (
    <Container maxWidth="sm" className={classes.socialContainer}>
      <Grid container spacing={isLarge ? 1 : 2} className={classes.prepop}>
        <Grid item xs={12} lg={6}>
          <LinkedInLoginButton
            onClick={applyWithLinkedIn}
            iconSize="22px"
            className={classes.socialButton}
          >
            Apply with LinkedIn
          </LinkedInLoginButton>
        </Grid>
        <Grid item xs={12} lg={6}>
          <GoogleLoginButton
            onClick={applyWithGoogle}
            iconSize="22px"
            className={classes.socialButton}
          >
            Apply with Google
          </GoogleLoginButton>
        </Grid>
        <Grid item xs={12}>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Divider />
            </Grid>
            <Grid item>
              <Typography variant="body2" className={classes.or}>
                <Emphasis>OR</Emphasis>
              </Typography>
            </Grid>
            <Grid item xs>
              <Divider />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button
            cta
            onClick={applyWithNothing}
            className={classes.socialButton}
          >
            Apply
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

const IntroSkeleton: React.FC<Props> = ({ role }) => {
  const classes = useStyles();
  const isLarge = useMediaQuery<Theme>((theme) => theme.breakpoints.up("lg"));
  return (
    <>
      <Container maxWidth={false} className={classes.topContainer}>
        <section className={classes.topSection}>
          <div className={classes.topSectionInner}>
            <Container maxWidth="sm" className={classes.title}>
              <Skeleton variant="circle" className={classes.avatar} />
              <Skeleton variant="rect" className={classes.skeleton}>
                <Typography
                  variant="h4"
                  component="h1"
                  paragraph
                  className={classes.mainHeader}
                >
                  Apply to be the <Accent>{role.title}</Accent> at{" "}
                  <Keyword>{role.employer}</Keyword>
                </Typography>
              </Skeleton>
              <Skeleton variant="rect" width="80%" className={classes.skeleton}>
                <Typography variant="h6" component="h2" paragraph>
                  {role.heading}
                </Typography>
              </Skeleton>
              <Skeleton variant="rect" className={classes.skeleton}>
                <Typography component="h3" paragraph>
                  <Emphasis>{role.location}</Emphasis> &middot;{" "}
                  <Emphasis>{role.salary}</Emphasis>
                </Typography>
              </Skeleton>
            </Container>
            <Container maxWidth="sm" className={classes.socialContainer}>
              <Grid
                container
                spacing={isLarge ? 1 : 2}
                className={classes.prepop}
              >
                <Grid item xs={12} lg={6}>
                  <Skeleton
                    variant="rect"
                    width="100%"
                    className={classes.skeleton}
                  >
                    <LinkedInLoginButton
                      iconSize="22px"
                      className={classes.socialButton}
                    >
                      Apply with LinkedIn
                    </LinkedInLoginButton>
                  </Skeleton>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Skeleton
                    variant="rect"
                    width="100%"
                    className={classes.skeleton}
                  >
                    <GoogleLoginButton
                      iconSize="22px"
                      className={classes.socialButton}
                    >
                      Apply with Google
                    </GoogleLoginButton>
                  </Skeleton>
                </Grid>
                <Grid item xs={12}>
                  <Skeleton
                    variant="rect"
                    width="70%"
                    className={classes.skeleton}
                  >
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs>
                        <Divider />
                      </Grid>
                      <Grid item>
                        <Typography variant="body2" className={classes.or}>
                          <Emphasis>OR</Emphasis>
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Divider />
                      </Grid>
                    </Grid>
                  </Skeleton>
                </Grid>
                <Grid item xs={12}>
                  <Skeleton
                    variant="rect"
                    width="80%"
                    className={classes.skeleton}
                  >
                    <Button cta className={classes.socialButton}>
                      Apply
                    </Button>
                  </Skeleton>
                </Grid>
              </Grid>
            </Container>
          </div>
        </section>
      </Container>
      <section className={classes.secondSection}>&nbsp;</section>
    </>
  );
};

const Landing: React.FC<Props> = ({
  role,
  applyWithNothing,
  applyWithGoogle,
  applyWithLinkedIn,
}) => {
  const { tracking } = useTracking();
  useMountEffect(() => {
    tracking.logEvent("page_view", {});
  });

  return (
    <Page headerProps={{ menu: <></> }}>
      <MetaTags role={role} />
      <TrackedSection trackingId="apply intro">
        <Intro
          role={role}
          applyWithNothing={applyWithNothing}
          applyWithGoogle={applyWithGoogle}
          applyWithLinkedIn={applyWithLinkedIn}
        />
      </TrackedSection>
    </Page>
  );
};

export const LandingSkeleton: React.FC = () => (
  <Page>
    <IntroSkeleton
      role={UnknownRole}
      applyWithLinkedIn={() => {}}
      applyWithGoogle={() => {}}
      applyWithNothing={() => {}}
    />
  </Page>
);

export default Landing;
