import { useEffect, useState } from "react";
import firebase, { Auth } from "@app/Firebase";

export type useAuthResponse = {
  user: firebase.User | null;
  isLoading: boolean;
  signOut: () => void;
};

const useAuth = (): useAuthResponse => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [user, setUser] = useState<firebase.User | null>(null);

  useEffect(() => {
    const unregisterAuthObserver = Auth.onAuthStateChanged((user) => {
      setIsLoading(false);
      setUser(user);
    });
    return () => unregisterAuthObserver();
  }, []);

  return {
    user,
    isLoading,
    signOut: () => Auth.signOut(),
  };
};

export default useAuth;
