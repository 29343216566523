import {
  Avatar,
  Container,
  Divider,
  Drawer,
  Grid,
  Typography,
  CircularProgress,
  Collapse,
  LinearProgress,
  Link as MUILink,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import DescriptionIcon from "@material-ui/icons/Description";
import RankIcon from "@material-ui/icons/Grade";
import LocationIcon from "@material-ui/icons/LocationOn";
import LinkIcon from "@material-ui/icons/OpenInNew";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import { useRecoilValue } from "recoil";

import Applicant from "@higher/models/Applicant";
import Message, { Direction } from "@higher/models/Message";
import MessageThread from "@higher/models/MessageThread";

import { Accent, Button, Link } from "@app/brand";
import EditMarkdown from "@app/components/EditMarkdown";
import Markdown from "@app/components/Markdown";
import asyncComponent from "@app/components/asyncComponent";
import openInNewTab from "@app/helpers/openInNewTab";
import useAuth from "@app/hooks/useAuth";
import useFirestoreValue from "@app/hooks/useFirestoreValue";
import ApplicantById from "@app/state/ApplicantById";
import DashboardForApplicant from "@app/state/DashboardForApplicant";
import MessagesByApplicant from "@app/state/MessagesByApplicant";
import RoleById from "@app/state/RoleById";
import UpdateEvents, {
  createApplicantMessageEvent,
} from "@app/state/UpdateEvents";
import UserById from "@app/state/UserById";

import { LayoutConfig } from "./Layout";
import Section from "./Section";
import { TimeAgo } from "./Timeline";

type Props = {
  threads: Array<MessageThread>;
};

type ThreadProps = {
  thread: MessageThread;
};

type ApplicantProps = {
  applicant: Applicant;
};

type MessageProps = {
  message: Message;
};

const useStyles = makeStyles((theme: Theme) => {
  const drawerWidth = 417;
  return createStyles({
    root: {
      display: "flex",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      "&::-webkit-scrollbar": {
        width: 0,
      },
    },
    link: {
      cursor: "pointer",
    },
    drawerPaper: {
      width: drawerWidth,
      top: "auto",
      height: `calc(100% - ${LayoutConfig.TotalHeadingSize}px)`,
      borderTop: "1px solid #ddd",
    },
    candidateMeta: {
      backgroundColor: "#f9f9f9",
      "& svg": {
        color: "#aaa",
        marginRight: theme.spacing(0.5),
        width: theme.spacing(2),
        height: theme.spacing(2),
      },
    },
    metadataGrid: {
      "& :last-child": {
        marginLeft: "auto",
        "& svg": {
          color: theme.palette.secondary.dark,
        },
      },
    },
    content: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(3),
      height: `calc(100vh - ${LayoutConfig.TotalHeadingSize}px)`,
      overflow: "auto",
      borderTop: "1px solid #ddd",

      "&::-webkit-scrollbar": {},
    },
    noConversations: {
      padding: theme.spacing(2),
      alignSelf: "center",
    },
    timelineAvatar: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      marginRight: theme.spacing(3),
    },
    messageContent: {
      marginBottom: theme.spacing(4),
    },
    sendMessage: {
      margin: 0,
    },
    cta: {
      width: theme.spacing(20),
      float: "right",
    },
    progress: {
      position: "absolute",
      zIndex: 1000,
      width: "100%",
      bottom: 0,
      borderBottomLeftRadius: theme.spacing(0.5),
      borderBottomRightRadius: theme.spacing(0.5),
    },
    messageSkeleton: {
      width: 500,
      maxWidth: 500,
      marginBottom: theme.spacing(3),
    },
  });
});

const Conversations: React.FC<Props> = ({ threads }) => {
  const classes = useStyles();
  const [selectedThread, setSelectedThred] = React.useState(threads[0]);
  return (
    <Section title="Active roles" hideTitle noPadding>
      <div className={classes.root}>
        <Drawer
          variant="permanent"
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <List disablePadding>
            {threads.map((thread) => (
              <>
                <ListItem
                  selected={thread.id === selectedThread.id}
                  button
                  onClick={() => setSelectedThred(thread)}
                >
                  <ApplicantListItem thread={thread} />
                </ListItem>
                <Collapse in={thread.id === selectedThread.id}>
                  <ListItem className={classes.candidateMeta}>
                    <CandidateMeta thread={thread} />
                  </ListItem>
                </Collapse>
                <Divider />
              </>
            ))}
          </List>
        </Drawer>
        <main className={classes.content}>
          <ApplicantMessages thread={selectedThread} />
          <SendMessage thread={selectedThread} />
        </main>
      </div>
    </Section>
  );
};

const Spacer: React.FC = () => {
  return <div style={{ width: 24 }} />;
};

const CandidateMeta = asyncComponent<ThreadProps>(
  ({ thread }) => {
    const classes = useStyles();
    const [applicant] = useFirestoreValue(ApplicantById(thread.id));
    const dashboards = useRecoilValue(DashboardForApplicant(applicant));
    if (!dashboards?.length) {
      return null;
    }

    const dashboard = dashboards[0];

    const handleOpenProfile = () => {
      const url = `/dashboard/roles/${applicant.roleId}/${applicant.id}`;
      openInNewTab(url);
    };

    return (
      <Grid
        container
        alignItems="center"
        justify="flex-start"
        direction="row"
        className={classes.metadataGrid}
      >
        {applicant.rank && (
          <>
            <RankIcon />
            <Typography variant="body2" color="textSecondary">
              {applicant.rank}
            </Typography>
            <Spacer />
          </>
        )}
        <LocationIcon />
        <Typography variant="body2" color="textSecondary">
          {applicant.location}
        </Typography>

        {dashboard.cv && (
          <>
            <Spacer />
            <DescriptionIcon />
            <Typography variant="body2" color="textSecondary">
              <MUILink
                className={classes.link}
                color="textSecondary"
                onClick={() => dashboard.cv && openInNewTab(dashboard.cv)}
              >
                CV
              </MUILink>
            </Typography>
          </>
        )}
        <Spacer />
        <Button small compact secondary onClick={handleOpenProfile}>
          <LinkIcon />
        </Button>
      </Grid>
    );
  },
  () => (
    <Skeleton>
      <Typography variant="body2">Some details will appear here</Typography>
    </Skeleton>
  )
);

const SendMessage = asyncComponent<ThreadProps>(
  ({ thread }) => {
    const classes = useStyles();

    const [markdown, setMarkdown] = React.useState<string | undefined>(
      undefined
    );
    const [processing, setProcessing] = React.useState(false);
    const [applicant] = useFirestoreValue(ApplicantById(thread.id));
    const { user } = useAuth();

    const updateEvents = UpdateEvents(applicant.organisationId);

    const onClick = async () => {
      if (!markdown || !user) {
        return;
      }

      setProcessing(true);

      await updateEvents(({ create }) => {
        createApplicantMessageEvent(create, applicant, user, markdown);
      });

      setProcessing(false);
      setMarkdown(undefined);
    };

    return (
      <Container maxWidth="md" disableGutters className={classes.sendMessage}>
        <EditMarkdown
          markdown={markdown}
          setMarkdown={setMarkdown}
          label={`Send a ${applicant.firstName} a message`}
        />
        <Button
          small
          cta
          disabled={processing || !markdown || markdown === ""}
          onClick={onClick}
          className={classes.cta}
        >
          Send
          {processing && (
            <LinearProgress className={classes.progress} color="secondary" />
          )}
        </Button>
      </Container>
    );
  },
  () => {
    const classes = useStyles();
    return (
      <Container
        maxWidth="md"
        disableGutters
        className={classes.sendMessage}
        style={{ textAlign: "center" }}
      >
        <CircularProgress />
      </Container>
    );
  }
);

const ApplicantListItemSkeleton: React.FC = () => {
  return (
    <>
      <ListItemAvatar>
        <Avatar />
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            <Skeleton>
              <Typography component="span">Name of applicant</Typography>
            </Skeleton>
          </>
        }
        secondary={
          <Skeleton>
            <Typography
              component="span"
              variant="body2"
              color="textPrimary"
              paragraph
            >
              Role title
            </Typography>
          </Skeleton>
        }
      />
    </>
  );
};

const ApplicantListItem = asyncComponent<ThreadProps>(({ thread }) => {
  const [applicant] = useFirestoreValue(ApplicantById(thread.id));
  const [role] = useFirestoreValue(RoleById(thread.roleId));

  return (
    <>
      <ListItemAvatar>
        <Avatar alt={applicant.name} src={applicant.avatar} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            <Typography component="span">
              <Accent>{applicant.name}</Accent>
            </Typography>
            <Typography component="span" variant="body2" color="textSecondary">
              {" "}
              &mdash; <TimeAgo date={thread.lastUpdated.toDate()} />
            </Typography>
          </>
        }
        secondary={
          <Typography
            component="span"
            variant="body2"
            color="textPrimary"
            paragraph
          >
            Applying for {role.title}
          </Typography>
        }
      />
    </>
  );
}, ApplicantListItemSkeleton);

const ApplicantMessagesSkeleton: React.FC = () => {
  const classes = useStyles();
  return (
    <Grid container alignItems="flex-start" justify="flex-start">
      <ListItemAvatar>
        <Avatar />
      </ListItemAvatar>
      <Skeleton variant="rect" className={classes.messageSkeleton}>
        <div className={classes.messageContent}>
          <Typography>name of author</Typography>
          <Typography variant="body2" color="textSecondary" paragraph>
            Time
          </Typography>

          <Markdown source="A bunch of content" />
        </div>
      </Skeleton>
    </Grid>
  );
};

const ApplicantMessages = asyncComponent<ThreadProps>(({ thread }) => {
  const [applicant] = useFirestoreValue(ApplicantById(thread.id));
  const [messages] = useFirestoreValue(MessagesByApplicant(applicant));

  return (
    <>
      {messages.map((message) => (
        <ApplicantMessage applicant={applicant} message={message} />
      ))}
    </>
  );
}, ApplicantMessagesSkeleton);

export const MessageAvatarForApplicant: React.FC<ApplicantProps> = ({
  applicant,
}) => {
  const classes = useStyles();
  return (
    <Avatar
      className={classes.timelineAvatar}
      alt={applicant.name}
      src={applicant.avatar}
    />
  );
};

export const MessageAvatarForUser: React.FC<MessageProps> = ({ message }) => {
  const classes = useStyles();
  const [user, isLoading] = useFirestoreValue(UserById(message.userId));
  if (isLoading) {
    return <Avatar className={classes.timelineAvatar} />;
  }
  return (
    <Avatar
      className={classes.timelineAvatar}
      alt={user.displayName || undefined}
      src={user.photoURL || undefined}
    />
  );
};

export const Username = asyncComponent<MessageProps>(
  ({ message }) => {
    const user = useRecoilValue(UserById(message.userId));
    return <Accent>{user ? user.displayName : "Someone"}</Accent>;
  },
  () => (
    <Skeleton>
      <Accent>Someone</Accent>
    </Skeleton>
  ),
  () => <Accent>Someone</Accent>
);

const ApplicantMessage: React.FC<ApplicantProps & MessageProps> = ({
  applicant,
  message,
}) => {
  const classes = useStyles();

  const avatar =
    message.direction === Direction.ToApplicant ? (
      <MessageAvatarForUser message={message} />
    ) : (
      <MessageAvatarForApplicant applicant={applicant} />
    );

  const name =
    message.direction === Direction.ToApplicant ? (
      <Username message={message} />
    ) : (
      <Accent>{applicant.name}</Accent>
    );

  return (
    <Grid container>
      <Grid item>{avatar}</Grid>
      <Grid item xs={11}>
        <div className={classes.messageContent}>
          <Typography>{name}</Typography>
          <Typography variant="body2" color="textSecondary" paragraph>
            <TimeAgo date={message.date.toDate()} />
          </Typography>

          <Markdown source={message.content} />
        </div>
      </Grid>
    </Grid>
  );
};

export const ConversationsSkeleton: React.FC = () => {
  const classes = useStyles();
  return (
    <Section title="Active roles" hideTitle noPadding>
      <div className={classes.root}>
        <Drawer
          variant="permanent"
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <List disablePadding>
            <ListItem>
              <ApplicantListItemSkeleton />
            </ListItem>
          </List>
        </Drawer>
        <main className={classes.content}>
          <ApplicantMessagesSkeleton />
        </main>
      </div>
    </Section>
  );
};

export const NoConversations: React.FC = () => {
  const classes = useStyles();
  return (
    <Section title="Active roles" hideTitle noPadding>
      <div className={classes.root}>
        <Drawer
          variant="permanent"
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
        />
        <main className={classes.content} style={{ justifyContent: "center" }}>
          <div className={classes.noConversations}>
            <Typography variant="body2">
              <Accent>No conversations happening yet!</Accent>
            </Typography>
            <Typography variant="body2">
              Start one by sending a message to a candidate in the{" "}
              <Link to="/dashboard/roles">Roles</Link> tab.
            </Typography>
          </div>
        </main>
      </div>
    </Section>
  );
};

export default Conversations;
