import React from "react";
import { Box, createStyles, makeStyles, Typography } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useDropzone } from "react-dropzone";

export type FileUploadProps = {
  label?: string;
  onChange: (file: File, base64URL: string) => void;
};

const useStyle = makeStyles((theme) => {
  return createStyles({
    root: {
      cursor: "pointer",
      textAlign: "center",
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
      flexDirection: "column",
      width: "100%",
      "&:hover p,&:hover svg,& img": {
        opacity: 1,
        transition: "opacity .25s ease-in-out",
      },
      "& p, svg": {
        opacity: 0.4,
        transition: "opacity .25s ease-in-out",
      },
    },
  });
});

export const FileUpload: React.FC<FileUploadProps> = ({
  label = "Click or drag to upload file",
  onChange,
}) => {
  const classes = useStyle();

  const onDrop = React.useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();

        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          onChange(file, reader.result as string);
        };

        reader.readAsDataURL(file);
      });
    },
    [onChange]
  );
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div className={classes.root}>
      <input {...getInputProps()} />
      <label htmlFor="file-upload" {...getRootProps()}>
        <Box>
          <CloudUploadIcon fontSize="large" />
          <Typography>{label}</Typography>
        </Box>
      </label>
    </div>
  );
};

export default FileUpload;
