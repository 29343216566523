import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from "@material-ui/lab";

import Endorsement from "@higher/models/Endorsement";
import Applicant from "@higher/models/Applicant";
import Role from "@higher/models/Role";

import { FormatCurrency } from "@higher/payments/currency";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    timeline: {
      color: "white",
      width: "100%",
      paddingLeft: 0,
      paddingRight: 0,
      margin: 0,
    },
    timelineContent: {
      paddingTop: "4px",
    },
    timelineDate: {
      fontFamily: "Inter",
      fontSize: "1em",
      color: theme.palette.secondary.dark,
    },
    timelineEvent: {
      fontFamily: "Inter",
      fontSize: "1em",
    },
  });
});

type Props = {
  applicant: Applicant;
  role: Role;
  endorsement: Endorsement;
  provisional?: boolean;
};

const EndorsementTimeline: React.FC<Props> = ({
  applicant,
  role,
  endorsement,
  provisional,
}) => {
  const classes = useStyles();

  return (
    <Timeline className={classes.timeline}>
      <TimelineItem>
        <TimelineOppositeContent className={classes.timelineContent}>
          <Typography className={classes.timelineDate}>
            {provisional ? "Today" : "Now"}
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent className={classes.timelineContent}>
          <Typography className={classes.timelineEvent}>
            {provisional &&
              `You endorse ${applicant.firstName} by making an endorsement payment. `}
            {!provisional && `We'll send you a receipt`}
          </Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent className={classes.timelineContent}>
          <Typography className={classes.timelineDate}>
            In less than 10 days (on average)
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent className={classes.timelineContent}>
          <Typography paragraph className={classes.timelineEvent}>
            {role.employer} will decide if they want to hire{" "}
            {applicant.firstName}
          </Typography>
          <Typography variant="body2" paragraph>
            If {applicant.firstName} isn't hired, you get your money back.
          </Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent className={classes.timelineContent}>
          <Typography className={classes.timelineDate}>
            Up to 30 days later
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent className={classes.timelineContent}>
          <Typography paragraph className={classes.timelineEvent}>
            If {applicant.firstName} is hired, {role.employer} and{" "}
            {applicant.firstName} have 30 days to negotiate a start date
          </Typography>
          <Typography variant="body2" paragraph>
            If no arrangement is made in 30 days, you get your money back.
          </Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent className={classes.timelineContent}>
          <Typography paragraph className={classes.timelineDate}>
            After {applicant.firstName}'s notice period (if any)
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent className={classes.timelineContent}>
          <Typography paragraph className={classes.timelineEvent}>
            {applicant.firstName} starts work at {role.employer}
          </Typography>
          <Typography variant="body2" paragraph>
            If {applicant.firstName} decides to quit before probation ends, you
            get your money back.
          </Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent className={classes.timelineContent}>
          <Typography className={classes.timelineDate}>
            {role.probation} days later
          </Typography>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineDot />
        </TimelineSeparator>
        <TimelineContent className={classes.timelineContent}>
          <Typography paragraph className={classes.timelineEvent}>
            {applicant.firstName} passes probation, and you earn{" "}
            {provisional && "up to "}
            {FormatCurrency(role.currency, endorsement.amount)}
          </Typography>
          <Typography variant="body2">
            If {applicant.firstName} is fired during probation, we'll give your
            endorsement to charity. If {applicant.firstName} and {role.employer}{" "}
            call it quits early for any other reason, we'll pay you a pro-rata
            reward and give the remainder to charity.
          </Typography>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
};

export default EndorsementTimeline;
