import { useMediaQuery, IconButton, Tooltip } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { Link as CopyLinkButton } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import copy from "copy-to-clipboard";
import React from "react";

type Props = {
  url: string;
  placement?: "left" | "right" | "bottom";
};

const CopyLink: React.FC<Props> = ({ url, placement }) => {
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const copyShortLink = () => {
    copy(url);
    setTooltipOpen(true);
  };

  const handleOnTooltipClose = () => setTooltipOpen(false);

  return (
    <Tooltip
      title="Link copied"
      open={tooltipOpen}
      placement={placement || (isSmall ? "left" : "right")}
      leaveDelay={1000}
      onClose={handleOnTooltipClose}
      arrow
    >
      <IconButton onClick={copyShortLink}>
        <CopyLinkButton color="secondary" />
      </IconButton>
    </Tooltip>
  );
};

export const CopyLinkSkeleton: React.FC = () => {
  return (
    <Tooltip title="Link copied">
      <Skeleton variant="text">
        <IconButton>
          <CopyLinkButton />
        </IconButton>
      </Skeleton>
    </Tooltip>
  );
};

export default CopyLink;
