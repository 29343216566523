import React from "react";
import { Helmet } from "react-helmet";

const MetaTags: React.FC = () => {
  const title = `Growth Marketer @ Higher`;
  const description = `Join Higher as a Growth Marketer. We invest in people like nobody else.`;
  const url = `https://gethigher.io/careers/growth-marketer`;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="title" content={title} />

      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta
        property="og:image"
        content="https://gethigher.io/social-sharing-image-marketer.png"
      />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta
        property="twitter:image"
        content="https://gethigher.io/social-sharing-image-marketer.png"
      />
    </Helmet>
  );
};

export default MetaTags;
