import {
  Avatar,
  Breadcrumbs,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import OrgSwitcherIcon from "@material-ui/icons/ExpandMore";
import { Skeleton } from "@material-ui/lab";
import { SnackbarProvider } from "notistack";
import React from "react";
import { FallbackProps } from "react-error-boundary";
import { Link, useHistory } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";

import Organisation from "@higher/models/Organisation";

import WrapErrorSuspense from "@app/api/WrapErrorSuspense";
import { UpArrow } from "@app/brand";
import asyncComponent from "@app/components/asyncComponent";
import useAuth from "@app/hooks/useAuth";
import useFirestoreValue from "@app/hooks/useFirestoreValue";
import useLocalStorage from "@app/hooks/useLocalStorage";
import Page from "@app/layouts/Page";
import ErrorFallback from "@app/modules/Error";
import CurrentOrganisation from "@app/state/CurrentOrganisation";
import OrganisationsByMemberId from "@app/state/OrganisationsByMemberId";

import NoOrganisations from "./NoOrganisations.stateless";
import useBreadcrumbs from "./useBreadcrumbs";

export const LayoutConfig = {
  // The combined height of the top header, main menu and breadcrumb menu
  TotalHeadingSize: 140,

  // The combined size of the top header and main menu
  MainHeadingSize: 64,
};

type LoadingProps = {
  loading?: React.FC;
};

type TitleBarProps = {
  controls?: JSX.Element;
};

type Props = LoadingProps &
  TitleBarProps & {
    error?: React.FC<FallbackProps>;
  };

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainLogo: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
    menu: {
      minWidth: "200px",
    },
    avatar: {
      width: "1.5em",
      height: "1.5em",
    },
    navigation: {},
    navLink: {
      textDecoration: "none",
    },
    tabs: {
      height: 54,
    },
    logoContainer: {
      maxWidth: "50%",
      minWidth: 10,
      paddingRight: theme.spacing(5),
      height: "100%",
      display: "flex",
      alignItems: "flex-end",
    },
    navContainer: {
      maxWidth: "50%",
      height: "100%",
      paddingLeft: theme.spacing(5),
      display: "flex",
      alignItems: "flex-end",
    },
    tab: {
      textTransform: "none",
      minWidth: theme.spacing(0),
      marginRight: theme.spacing(2.5),
    },
    breadcrumb: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "left",
      alignContent: "center",
      fontSize: "1em",
      padding: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    controls: {
      height: "100%",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(2),
    },
  })
);

export const AvatarMenu: React.FC<Props> = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { user, signOut } = useAuth();

  if (!user || !signOut) {
    return null;
  }

  const handleMenuClick = (event: React.MouseEvent<HTMLImageElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    signOut();
  };

  return (
    <>
      <Avatar
        alt={user.displayName || undefined}
        src={user.photoURL || undefined}
        onClick={handleMenuClick}
        className={classes.avatar}
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {user.displayName && (
          <MenuItem disabled className={classes.menu}>
            {user.displayName}
          </MenuItem>
        )}
        <Divider />
        <MenuItem className={classes.menu} onClick={handleLogout}>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export const Header = asyncComponent(
  () => {
    const classes = useStyles();
    return (
      <Grid container spacing={4} alignItems="center" justify="flex-start">
        <div className={classes.logoContainer}>
          <Grid
            container
            alignItems="center"
            justify="flex-start"
            style={{ height: "100%" }}
          >
            <Link to="/dashboard/roles">
              <img
                src={UpArrow.ArrowMargin}
                alt="Higher"
                className={classes.mainLogo}
              />
            </Link>
            <CurrentOrgView />
          </Grid>
        </div>
        <div className={classes.navContainer}>
          <Navigation />
        </div>
      </Grid>
    );
  },
  () => null
);

export const CurrentOrgView = asyncComponent(
  () => {
    const [currentOrg, isLoading] = useFirestoreValue(CurrentOrganisation);

    if (isLoading) {
      return (
        <Skeleton variant="text">
          <p>Loading...</p>
        </Skeleton>
      );
    }

    return (
      <>
        <Link
          className="MuiTypography-root MuiTypography-body1"
          style={{
            textDecoration: "none",
            color: "rgba(0, 0, 0, 0.87)",
            marginTop: -4,
          }}
          to="/dashboard/roles"
        >
          {currentOrg.name}
        </Link>
        <OrgSelector />
      </>
    );
  },
  () => null
);

const useCurrentOrgId = () =>
  useLocalStorage<string | undefined>("current-org-id", undefined);

export const OrgSelector = asyncComponent(
  () => {
    const { user } = useAuth();
    const [, setCurrentOrg] = useRecoilState(CurrentOrganisation);
    const [, setCurrentOrgId] = useCurrentOrgId();
    const orgs = useRecoilValue(OrganisationsByMemberId(user?.uid));
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleSelect = (org: Organisation) => {
      handleClose();
      setCurrentOrgId(org.id);
      setCurrentOrg(org);
    };

    if (orgs.length < 2) {
      return null;
    }

    return (
      <>
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          style={{ padding: 0, paddingLeft: 8 }}
        >
          <OrgSwitcherIcon />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {orgs.map((org) => {
            return (
              <MenuItem onClick={() => handleSelect(org)}>{org.name}</MenuItem>
            );
          })}
        </Menu>
      </>
    );
  },
  () => null
);

export const Navigation: React.FC = () => {
  const classes = useStyles();
  const crumbs = useBreadcrumbs();
  const history = useHistory();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    history.push(newValue);
  };

  if (!crumbs || !crumbs.length) {
    return null;
  }

  return (
    <div className={classes.navigation}>
      <Tabs
        className={classes.tabs}
        indicatorColor="secondary"
        textColor="primary"
        value={crumbs[0].link}
        onChange={handleChange}
      >
        <Tab
          className={classes.tab}
          label="Roles"
          value="/dashboard/roles"
          disableRipple
        />
        <Tab
          className={classes.tab}
          label="Conversations"
          value="/dashboard/conversations"
          disableRipple
        />
        <Tab
          className={classes.tab}
          label="Team"
          disableRipple
          value="/dashboard/team"
        />
        <Tab className={classes.tab} label="Settings" disabled disableRipple />
      </Tabs>
    </div>
  );
};

export const TitleBar: React.FC<TitleBarProps> = ({ controls }) => {
  const classes = useStyles();
  const segments = useBreadcrumbs();

  return (
    <Grid container>
      <Grid xs={controls ? 6 : 12}>
        <Breadcrumbs
          className={classes.breadcrumb}
          aria-label="breadcrumb"
          maxItems={5}
        >
          {segments.map((segment, idx) => (
            <Link
              to={segment.link}
              className={classes.navLink}
              key={segment.link}
            >
              <Typography
                color={
                  idx === segments.length - 1 ? "textPrimary" : "textSecondary"
                }
                variant="h6"
              >
                {segment.label}
              </Typography>
            </Link>
          ))}
        </Breadcrumbs>
      </Grid>
      {controls && (
        <Grid xs={6} justify="flex-end">
          <div className={classes.controls}>{controls}</div>
        </Grid>
      )}
    </Grid>
  );
};

const DefaultLoading: React.FC = () => <p>Loading...</p>;

const Content: React.FC<LoadingProps> = ({ loading, children }) => {
  const { user, signOut } = useAuth();
  const [currentOrg, setCurrentOrg] = useRecoilState(CurrentOrganisation);
  const [currentOrgId, setCurrentOrgId] = useCurrentOrgId();
  const orgs = useRecoilValue(OrganisationsByMemberId(user?.uid));
  const Loading = loading || DefaultLoading;

  if (!user) {
    return <Loading />;
  }

  if (!orgs.length) {
    return <NoOrganisations user={user} signOut={signOut} />;
  }

  if (!currentOrg) {
    if (currentOrgId) {
      const org = orgs.find((org) => org.id === currentOrgId);

      if (org) {
        setCurrentOrg(org);
      }
    } else {
      setCurrentOrg(orgs[0]);
      setCurrentOrgId(orgs[0].id);
    }
  }

  return <>{children}</>;
};

const Suspense = (loading: React.FC, error: React.FC<FallbackProps>) =>
  WrapErrorSuspense<LoadingProps>(Content, loading, error);

export const Layout: React.FC<Props> = ({
  loading,
  error,
  controls,
  children,
}) => {
  const loadingView = loading || DefaultLoading;
  const SuspenseWrapper = Suspense(loadingView, error || ErrorFallback);
  return (
    <SnackbarProvider maxSnack={3}>
      <Page
        headerProps={{
          menu: <AvatarMenu />,
          logo: <Header />,
        }}
        noFooter
      >
        <TitleBar controls={controls} />
        <SuspenseWrapper loading={loadingView}>{children}</SuspenseWrapper>
      </Page>
    </SnackbarProvider>
  );
};

export default Layout;
