import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { RecoilRoot } from "recoil";

import Environment, { EnvironmentMap } from "./Environment";
import theme from "./Theme";
import Body from "./modules/Body";

const stripeKeys = new EnvironmentMap<string>([
  [
    Environment.Production,
    "pk_live_51HEFsfB34GLz1BThgOvLVpKLa4O0tyYdWKwNCzsuiG1vw7e2yTFYpvjek7yhHW5Y0LM9NAoWY5JEMoMHAn7MRaAM00rf4hJVfM",
  ],
  [
    Environment.Staging,
    "pk_test_51HEFsfB34GLz1BTh6qZnlqrXQdBhpyw6LGmxtZjA4RBaUucszfy6M2jDM4DclMSFC0W0XrOg68JI7gnLi1Rak59v00CiuC3DXb",
  ],
]);

const stripe = loadStripe(stripeKeys.current());

const elementsStyles = {
  fonts: [
    {
      cssSrc:
        "https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap",
    },
  ],
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RecoilRoot>
        <Router>
          <Elements stripe={stripe} options={elementsStyles}>
            <Body />
          </Elements>
        </Router>
      </RecoilRoot>
    </ThemeProvider>
  );
}

export default App;
