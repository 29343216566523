import React, { FC } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    emphasis: {
      fontWeight: "bold",
      color: theme.palette.secondary.dark,
      fontStyle: "normal",
    },
  })
);

const Keyword: FC = ({ children }) => {
  const classes = useStyles();
  return <span className={classes.emphasis}>{children}</span>;
};

export default Keyword;
