import {
  Card,
  CardContent,
  Typography,
  LinearProgress,
} from "@material-ui/core";
import {
  makeStyles,
  Theme,
  createStyles,
  useTheme,
} from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import { CardElement, useStripe } from "@stripe/react-stripe-js";
import { StripeCardElementOptions } from "@stripe/stripe-js";
import React from "react";

import Applicant from "@higher/models/Applicant";

import { Button } from "@app/brand";
import useTracking from "@app/hooks/useTracking";
import { MakeDemoPaymentFn } from "@app/payments/demo";

export type Props = {
  applicant: Applicant;
  amount: number;
  makeDemoPayment: MakeDemoPaymentFn;
  next: (id: string) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      boxShadow: theme.shadows[1],
      marginTop: theme.spacing(1),
    },
    cardContent: {
      "&:last-child": {
        paddingBottom: theme.spacing(2),
      },
    },
    progress: {
      position: "absolute",
      zIndex: 1000,
      width: "100%",
      bottom: 0,
      borderBottomLeftRadius: theme.spacing(0.5),
      borderBottomRightRadius: theme.spacing(0.5),
    },
    error: {
      marginTop: theme.spacing(2),
    },
    button: {
      marginBottom: theme.spacing(2),
    },
  })
);

const useCardStyles = (theme: Theme): StripeCardElementOptions => {
  return {
    iconStyle: "solid",
    style: {
      base: {
        iconColor: theme.palette.secondary.dark,
        fontWeight: 500,
        fontFamily: "Source Sans Pro, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "black",
        },
        "::placeholder": {
          color: "#aaa",
        },
      },
      invalid: {
        iconColor: "#ffc7ee",
        color: "#ffc7ee",
      },
    },
  };
};

const PaymentCardDemo: React.FC<Props> = ({
  applicant,
  amount,
  makeDemoPayment,
  next,
}) => {
  const classes = useStyles();
  const stripe = useStripe();
  const cardStyles = useCardStyles(useTheme());
  const { tracking } = useTracking();

  const [errorMessage] = React.useState<string | undefined>(undefined);
  const [processing, setProcessing] = React.useState(false);

  const handleSubmit = async () => {
    setProcessing(true);

    const { endorsementId } = await makeDemoPayment(applicant, amount);
    setProcessing(false);

    tracking.logEvent("purchase", {
      value: amount,
      transaction_id: endorsementId,
      demo: "true",
    });

    next(endorsementId);
  };

  return (
    <>
      <Card className={classes.card}>
        <CardContent>
          <Alert severity="info">
            In this demo we won't take payment, so you can ignore this bit!
          </Alert>
        </CardContent>

        <CardContent className={classes.cardContent}>
          <CardElement options={cardStyles} />
        </CardContent>
      </Card>

      {errorMessage && (
        <Alert className={classes.error} severity="warning">
          <Typography>{errorMessage}</Typography>
        </Alert>
      )}

      <Button
        cta
        disabled={!stripe || processing}
        onClick={handleSubmit}
        trackingId="Confirm and pay"
        className={classes.button}
      >
        Confirm and pay
        {processing && (
          <LinearProgress className={classes.progress} color="secondary" />
        )}
      </Button>
    </>
  );
};

export default PaymentCardDemo;
