import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  useMediaQuery,
} from "@material-ui/core";
import {
  makeStyles,
  Theme,
  createStyles,
  useTheme,
} from "@material-ui/core/styles";
import UpDownIcon from "@material-ui/icons/ExpandMore";
import ExpandIcon from "@material-ui/icons/UnfoldMore";
import clsx from "clsx";
import React, { useState } from "react";

import { Button } from "@app/brand";
import useTracking from "@app/hooks/useTracking";

import PopupDialog from "./PopupDialog";

type Props = {
  title: string;
  subtitle?: string;
  avatar?: string;
  focused?: boolean;
  trackingId?: string;
  popup: JSX.Element;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: "1em",
    },
    expand: {
      transform: "rotate(45deg)",
    },
    showMore: {
      transform: "rotate(0deg)",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    showLess: {
      transform: "rotate(180deg)",
    },
    focus: {
      border: `1px solid ${theme.palette.secondary.main}`,
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
    content: {
      paddingTop: 0,
    },
    footer: {
      backgroundColor: "#fafafa",
    },
  })
);

const PopupCard: React.FC<Props> = ({
  title,
  subtitle,
  avatar,
  focused,
  popup,
  trackingId,
  children,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up("lg"));
  const { logPopupOpen, logPopupClose } = useTracking();

  const [expanded, setExpanded] = useState(false);
  const toggleOpen = () => {
    if (trackingId) {
      expanded ? logPopupClose(trackingId) : logPopupOpen(trackingId);
    }
    setExpanded(!expanded);
  };

  const cardClasses = [classes.root];
  if (focused) {
    cardClasses.push(classes.focus);
  }

  let icon: JSX.Element | undefined;
  if (isLarge) {
    icon = (
      <UpDownIcon
        className={clsx(classes.showMore, {
          [classes.showLess]: expanded,
        })}
      />
    );
  }
  const expandCTA = isLarge && expanded ? "hide details" : "show details";

  return (
    <>
      <Card className={cardClasses.join(" ")}>
        <CardHeader
          avatar={avatar && <Avatar alt={title} src={avatar} />}
          title={title}
          subheader={subtitle}
          titleTypographyProps={{ variant: "body2" }}
          subheaderTypographyProps={{
            variant: "body2",
            color: "textSecondary",
          }}
          action={
            !isLarge && (
              <IconButton
                className={classes.expand}
                onClick={toggleOpen}
                aria-expanded={expanded}
                aria-label="show details"
              >
                <ExpandIcon />
              </IconButton>
            )
          }
        />
        {children && (
          <CardContent className={classes.content}>{children}</CardContent>
        )}

        {isLarge && (
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent className={classes.content}>{popup}</CardContent>
          </Collapse>
        )}

        <CardActions className={classes.footer}>
          <Button secondary compact onClick={toggleOpen} icon={icon}>
            {expandCTA}
          </Button>
        </CardActions>
      </Card>

      {!isLarge && (
        <PopupDialog
          title={title}
          subtitle={subtitle}
          avatar={avatar}
          open={expanded}
          setOpen={setExpanded}
        >
          {popup}
        </PopupDialog>
      )}
    </>
  );
};

export default PopupCard;
