import React from "react";
import { Container } from "@material-ui/core";

import { Alert, AlertTitle } from "@material-ui/lab";

import Applicant from "@higher/models/Applicant";
import Role from "@higher/models/Role";

import Page from "@app/layouts/Page";

import useTracking from "@app/hooks/useTracking";
import useMountEffect from "@app/hooks/useMountEffect";

type Props = {
  applicant: Applicant;
  role: Role;
};

const TooManyEndorsements: React.FC<Props> = ({ applicant, role }) => {
  const { tracking } = useTracking();
  useMountEffect(() => {
    tracking.logEvent("page_view", {});
  });

  return (
    <Page>
      <Container maxWidth="sm" style={{ marginTop: "2em" }}>
        <Alert>
          <AlertTitle>
            Good news! {applicant.firstName} already has the maximum number of
            endorsements
          </AlertTitle>
          The bad news is that we can't let you endorse right now.
          <br />
          <br />
          Thanks for trying out Higher, and for supporting your peers. This
          platform only works if awesome people like you step up for their
          friends and colleagues. We hope to see you again soon!
        </Alert>
      </Container>
    </Page>
  );
};

export default TooManyEndorsements;
