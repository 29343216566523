import { useRef } from "react";

import "firebase/analytics";

import { Analytics } from "@app/Firebase";

type Tracking = {
  tracking: typeof Analytics;
  logButtonClick: (label: string) => void;
  logFAQOpen: (question: string) => void;
  logFAQClose: (question: string) => void;
  logContentView: (desc: string) => void;
  logCheckoutStep: (step: number, value?: number) => void;
  logPopupOpen: (id: string) => void;
  logPopupClose: (id: string) => void;
};

function useTracking(): Tracking {
  const analytics = useRef(Analytics);

  const logCustomEvent = (type: string, args?: { [key: string]: any }) => {
    analytics.current.logEvent<typeof type>(type, args);
  };

  return {
    tracking: analytics.current,
    logButtonClick: (label) => logCustomEvent("click_button", { label }),
    logFAQOpen: (question) => logCustomEvent("open_faq", { question }),
    logFAQClose: (question) => logCustomEvent("close_faq", { question }),
    logContentView: (desc) => logCustomEvent("view_content", { desc }),
    logCheckoutStep: (step, value) =>
      analytics.current.logEvent("checkout_progress", {
        checkout_step: step,
        value,
      }),
    logPopupOpen: (id) => logCustomEvent("open_popup", { id }),
    logPopupClose: (id) => logCustomEvent("close_popup", { id }),
  };
}

export default useTracking;
