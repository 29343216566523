import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Card, CardContent } from "@material-ui/core";
import { Link } from "react-router-dom";

import { MarginWidths } from "@app/helpers/responsiveness/LeftColumnLayout";
import { Logos } from "@app/brand";
import { DashboardContentWidth } from "@app/layouts/Dashboard";

import NavMenu from "./header/NavMenu";

export type HeaderProps = {
  logo?: JSX.Element;
  menu?: JSX.Element;
  border?: boolean;
  dashboard?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: theme.spacing(8),
      boxShadow: theme.shadows[0],
      borderRadius: 0,
    },
    withBorder: {
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    },
    content: {
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      height: theme.spacing(8),
      paddingBottom: `${theme.spacing(2)}px !important`,
      "& > p": {
        margin: "auto 0",
      },
    },
    dashboard: {
      maxWidth: DashboardContentWidth,
      marginLeft: MarginWidths.md,
      [theme.breakpoints.up("lg")]: {
        marginLeft: MarginWidths.lg,
      },
      [theme.breakpoints.up("xl")]: {
        marginLeft: MarginWidths.xl,
      },
    },
    logo: {
      height: "2em",
      justifySelf: "center",
      alignSelf: "center",
    },
    nav: {
      marginLeft: "auto",
      alignSelf: "center",
    },
  })
);

const Header: React.FC<HeaderProps> = ({ menu, border, logo, dashboard }) => {
  const classes = useStyles();
  const Logo = logo || (
    <Link to="/">
      <img
        className={classes.logo}
        src={Logos.PrimaryCenteredOpenbeta}
        alt="Higher"
      />
    </Link>
  );

  const classNames = [classes.root];
  if (border) {
    classNames.push(classes.withBorder);
  }

  const contentClassNames = [classes.content];
  if (dashboard) {
    contentClassNames.push(classes.dashboard);
  }

  return (
    <Card className={classNames.join(" ")}>
      <CardContent className={contentClassNames.join(" ")}>
        {Logo}
        <nav className={classes.nav}>{menu || <NavMenu />}</nav>
      </CardContent>
    </Card>
  );
};

export default Header;
