import {
  Box,
  Container,
  Grid,
  LinearProgress,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import HelpIcon from "@material-ui/icons/Help";
import { Skeleton } from "@material-ui/lab";
import React from "react";

import Applicant, { UnknownApplicant } from "@higher/models/Applicant";
import Role, { UnknownRole } from "@higher/models/Role";
import { FormatCurrency } from "@higher/payments/currency";

import {
  Accent,
  Button,
  Keyword,
  Icons,
  Images,
  Callout as CalloutImages,
} from "@app/brand";
import Callout, { CalloutSkeleton } from "@app/components/Callout";
import TrackedSection from "@app/components/TrackedSection";
import articleForRoleTitle from "@app/helpers/articleForRoleTitle";
import { openSlassk } from "@app/helpers/slaask";
import useMountEffect from "@app/hooks/useMountEffect";
import useTracking from "@app/hooks/useTracking";
import Page from "@app/layouts/Page";

import FAQAccordion from "./FAQs";
import MetaTags from "./MetaTags";
import ParticipantAvatars, {
  ParticipantAvatarsSkeleton,
} from "./ParticipantAvatars";
import Timeline from "./Timeline";

const useStyles = makeStyles((theme: Theme) => {
  const balloon = Images.BrandballoonPrimary;
  const arrows = Images.BrandarrowsBackgroundImageTrans;
  const arrowsWhite = Images.BrandarrowsBackgroundImageWhite;
  const lightBlueGradient = Images.LightBlueGradient;
  const leftPositionX = `calc( ((100vw - ${theme.breakpoints.values.md}px)/2) - 75vh )`;
  const rightPositionX = `calc( ((100vw - ${theme.breakpoints.values.md}px)/2) + ${theme.breakpoints.values.md}px )`;

  return createStyles({
    topContainer: {
      backgroundImage: `url(${balloon}), url(${arrows}), url(${arrows})`,
      backgroundSize: "75vh auto",
      backgroundRepeatX: "no-repeat",
      backgroundRepeat: "no-repeat, repeat, no-repeat",
      backgroundPositionX: `${leftPositionX}, ${leftPositionX}, ${rightPositionX}`,
      backgroundPositionY: `-${theme.spacing(3)}px, 2px, 100px`,
    },
    howItWorksContainer: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),

      backgroundImage: ` url(${arrowsWhite}), url(${arrowsWhite})`,
      backgroundSize: "75vh auto",
      backgroundRepeatX: "no-repeat",
      backgroundRepeat: "no-repeat",
      backgroundPositionX: `${leftPositionX}, ${rightPositionX}`,
      backgroundPositionY: `2px,  center`,

      [theme.breakpoints.up("lg")]: {
        paddingTop: theme.spacing(12),
        paddingBottom: theme.spacing(12),
      },
      backgroundColor: theme.palette.primary.main,
      color: "white",

      "& span": {
        color: theme.palette.secondary.main,
      },
    },
    endorsementProcessContainer: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),

      backgroundImage: ` url(${arrows}), url(${arrows})`,
      backgroundSize: "75vh auto",
      backgroundRepeatX: "no-repeat",
      backgroundRepeat: "no-repeat",
      backgroundPositionX: `${leftPositionX}, ${rightPositionX}`,
      backgroundPositionY: `center,  top`,

      [theme.breakpoints.up("lg")]: {
        paddingTop: theme.spacing(12),
        paddingBottom: theme.spacing(12),
      },
      backgroundColor: theme.palette.primary.dark,
      color: "white",

      "& span": {
        color: theme.palette.secondary.main,
      },
    },

    questionsContainer: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),

      backgroundImage: ` url(${arrows}), url(${arrows})`,
      backgroundSize: "75vh auto",
      backgroundRepeatX: "no-repeat",
      backgroundRepeat: "no-repeat",
      backgroundPositionX: `${leftPositionX}, ${rightPositionX}`,
      backgroundPositionY: `2px,  center`,

      [theme.breakpoints.up("lg")]: {
        paddingTop: theme.spacing(12),
        paddingBottom: theme.spacing(12),
      },
      backgroundColor: "white",
    },
    greyBackground: {
      backgroundColor: "#fafafa",
    },

    title: {
      textAlign: "center",
    },
    howItWorksTitle: {
      textAlign: "center",
      color: "white",
    },
    questionsTitle: {
      textAlign: "center",
      fontFamily: "Inter",
      marginBottom: theme.spacing(6),
    },
    additionalQuestion: {
      marginTop: theme.spacing(4),
    },
    logo: {
      height: "2em",
      marginBottom: "1em",
    },
    mainHeader: {
      marginBottom: theme.spacing(4),
      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(3),
        fontSize: "1.5rem",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "1.55rem",
      },
    },
    topSection: {
      marginTop: theme.spacing(3),
      paddingBottom: theme.spacing(8),
      [theme.breakpoints.up("lg")]: {
        marginTop: 0,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        height: `calc( (var(--vh, 1vh) * 100) - ${theme.spacing(8)}px)`,
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
      },
    },
    topSectionInner: {
      alignSelf: "center",
    },
    icon: {
      backgroundColor: "transparent",
      color: theme.palette.secondary.dark,
      border: `2px solid ${theme.palette.secondary.light}`,
    },
    calloutContainer: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(1),
      [theme.breakpoints.up("lg")]: {
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(8),
      },
    },
    calloutContainerWhite: {},
    cta: {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
        [theme.breakpoints.down("sm")]: {
          margin: 0,
        },
      },
    },
    stepsContainer: {
      marginTop: theme.spacing(4),
    },
    step: {
      textAlign: "center",
    },
    stepTitle: {
      fontWeight: "bold",
      fontFamily: "Inter",
    },
    stepIcon: {
      width: "40%",
      [theme.breakpoints.up("lg")]: {
        width: "60%",
      },
    },
    timeline: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
    },

    wrapUp: {
      background: `url(${lightBlueGradient})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      paddingTop: theme.spacing(12),
      textAlign: "center",
      marginBottom: -6,
    },
    footerImage: {
      marginTop: theme.spacing(10),
      width: "100%",
      marginBottom: 0,
    },
  });
});

type Props = {
  applicant: Applicant;
  role: Role;
  next: () => void;
};

const Intro: React.FC<Props> = ({ applicant, role, next }) => {
  const classes = useStyles();
  const anchor = "how-it-works";

  const article = articleForRoleTitle(role.title);

  const handleClick = () => {
    const element = document.getElementById(anchor);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const isLarge = useMediaQuery<Theme>((theme) => theme.breakpoints.up("lg"));
  return (
    <Container maxWidth={false} className={classes.topContainer}>
      <section className={classes.topSection}>
        <div className={classes.topSectionInner}>
          <ParticipantAvatars applicant={applicant} role={role} />
          <Container maxWidth="sm" className={classes.title}>
            <Typography
              variant="h4"
              component="h1"
              paragraph
              className={classes.mainHeader}
            >
              <Keyword>{applicant.name}</Keyword> is applying to be {article}{" "}
              <Accent>{role.title}</Accent> at{" "}
              <Keyword>{role.employer}</Keyword> and needs your support
            </Typography>
          </Container>
          <Container maxWidth="md">
            <Grid
              container
              spacing={4}
              className={[
                classes.calloutContainer,
                classes.calloutContainerWhite,
              ].join(" ")}
            >
              <Grid item xs={12} md={4}>
                <Callout
                  title="Monetise your knowledge"
                  image={CalloutImages.Bulb}
                >
                  <>
                    Earn up to{" "}
                    <Keyword>
                      {FormatCurrency(role.currency, role.maxStake)}
                    </Keyword>{" "}
                    for endorsing {applicant.firstName}. Anonymously, if you
                    like.
                  </>
                </Callout>
              </Grid>
              <Grid item xs={12} md={4}>
                <Callout
                  title={`Lift-off ${applicant.firstName}'s career`}
                  image={CalloutImages.LevelUp}
                >
                  <>
                    Be a matchmaker. Make {applicant.firstName} more visible to{" "}
                    {role.employer}.
                  </>
                </Callout>
              </Grid>
              <Grid item xs={12} md={4}>
                <Callout title="A win-win scenario" image={CalloutImages.Heart}>
                  <>
                    If it doesn't work out, we'll recycle your endorsement into
                    a charitable donation.
                  </>
                </Callout>
              </Grid>
            </Grid>

            <div className={classes.cta}>
              {isLarge && (
                <Button
                  tertiary
                  small
                  startIcon={<HelpIcon />}
                  onClick={handleClick}
                  trackingId="Tell me more (top)"
                >
                  Tell me more
                </Button>
              )}
              <Button
                cta
                small={isLarge}
                onClick={() => next()}
                trackingId="Get started (top)"
              >
                Get started
              </Button>
            </div>
          </Container>
        </div>
      </section>
    </Container>
  );
};

const IntroSkeleton: React.FC<Props> = ({ applicant, role }) => {
  const classes = useStyles();
  const isLarge = useMediaQuery<Theme>((theme) => theme.breakpoints.up("lg"));

  return (
    <Container maxWidth={false} className={classes.topContainer}>
      <section className={classes.topSection}>
        <div className={classes.topSectionInner}>
          <ParticipantAvatarsSkeleton applicant={applicant} role={role} />
          <Container maxWidth="sm" className={classes.title}>
            <Skeleton variant="rect">
              <Typography
                variant="h4"
                component="h1"
                paragraph
                className={classes.mainHeader}
              >
                <Keyword>{applicant.name}</Keyword> is applying to be a{" "}
                <Accent>{role.title}</Accent> at{" "}
                <Keyword>{role.employer}</Keyword> and needs your support
              </Typography>
            </Skeleton>
          </Container>
          <Container maxWidth="md">
            <Grid
              container
              spacing={4}
              className={[
                classes.calloutContainer,
                classes.calloutContainerWhite,
              ].join(" ")}
            >
              <Grid item xs={12} md={4}>
                <CalloutSkeleton
                  title="Monetise your knowledge"
                  image={CalloutImages.Bulb}
                >
                  <>
                    Earn up to{" "}
                    <Keyword>
                      {FormatCurrency(role.currency, role.maxStake)}
                    </Keyword>{" "}
                    for anonymously endorsing {applicant.firstName}
                  </>
                </CalloutSkeleton>
              </Grid>
              <Grid item xs={12} md={4}>
                <CalloutSkeleton
                  title={`Lift-off ${applicant.firstName}'s career`}
                  image={CalloutImages.LevelUp}
                >
                  <>
                    Make {applicant.firstName} more visible to {role.employer}.
                    Connect great people.
                  </>
                </CalloutSkeleton>
              </Grid>
              <Grid item xs={12} md={4}>
                <CalloutSkeleton
                  title="Support good causes"
                  image={CalloutImages.Heart}
                >
                  <>Recycle system failure into charitable donations</>
                </CalloutSkeleton>
              </Grid>
            </Grid>

            <div className={classes.cta}>
              <Skeleton variant="rect" width="100%">
                <Button cta small={isLarge}>
                  Get started
                </Button>
              </Skeleton>
            </div>
          </Container>
        </div>
      </section>
    </Container>
  );
};

type StepProps = {
  icon: string;
  title: string;
};

const Step: React.FC<StepProps> = ({ icon, title, children }) => {
  const classes = useStyles();
  return (
    <Box className={classes.step}>
      <img src={icon} alt={title} className={classes.stepIcon} />
      <Typography
        variant="h6"
        component="h4"
        paragraph
        className={classes.stepTitle}
      >
        {title}
      </Typography>
      {children}
    </Box>
  );
};

const HowItWorks: React.FC<Props> = ({ applicant, role, next }) => {
  const classes = useStyles();

  const isLarge = useMediaQuery<Theme>((theme) => theme.breakpoints.up("lg"));
  return (
    <Container
      maxWidth={false}
      className={classes.howItWorksContainer}
      id="how-it-works"
    >
      <Container maxWidth="sm">
        <Typography
          variant="h4"
          component="h2"
          paragraph
          className={classes.howItWorksTitle}
        >
          How this works
        </Typography>
        <Typography
          variant="h5"
          component="h3"
          className={classes.howItWorksTitle}
        >
          Smart employers like {role.employer} base their hiring decisions on{" "}
          <Keyword>trusted recommendations</Keyword>. You're an expert on{" "}
          {applicant.firstName}.
        </Typography>
      </Container>
      <Container maxWidth="md" className={classes.stepsContainer}>
        <Grid
          container
          spacing={isLarge ? 6 : 2}
          className={classes.calloutContainer}
        >
          <Grid item xs={12} md={4}>
            <Step icon={Icons.ClipboardReversed} title="Matchmake">
              <Typography variant="body1" paragraph>
                <Keyword>Evaluate {applicant.firstName}'s suitability</Keyword>{" "}
                for the role at {role.employer}.
              </Typography>
              <Typography variant="body1">
                If you know {applicant.firstName} professionally, and the role
                is in your field, this should be easy.
              </Typography>
            </Step>
          </Grid>
          <Grid item xs={12} md={4}>
            <Step icon={Icons.PadlockReversed} title="Earn trust">
              <Typography variant="body1" paragraph>
                Put your skin in the game by placing between{" "}
                <Keyword>
                  {FormatCurrency(role.currency, role.minStake)}
                </Keyword>{" "}
                and{" "}
                <Keyword>
                  {FormatCurrency(role.currency, role.maxStake)}
                </Keyword>{" "}
                into escrow.
              </Typography>
              <Typography variant="body1">
                If {applicant.firstName} is hired, these funds will be held
                until {applicant.firstName} passes or fails probation.
              </Typography>
            </Step>
          </Grid>
          <Grid item xs={12} md={4}>
            <Step icon={Icons.RoiReversed} title="Reap rewards">
              <Typography variant="body1" paragraph>
                If {applicant.firstName}'s passes probation, you'll get{" "}
                <Keyword>100% return</Keyword> on your money.
              </Typography>
              <Typography variant="body1">
                If {applicant.firstName} is fired during probation, your
                endorsement will be donated to charity.
              </Typography>
            </Step>
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="md">
        <div className={classes.cta}>
          <Button
            secondary
            cta
            small={isLarge}
            onClick={() => next()}
            trackingId="Let's go (how this works)"
          >
            Let's go
          </Button>
        </div>
      </Container>
    </Container>
  );
};

const EndorsementProcess: React.FC<Props> = ({ applicant, role, next }) => {
  const classes = useStyles();

  const isLarge = useMediaQuery<Theme>((theme) => theme.breakpoints.up("lg"));
  return (
    <Container
      maxWidth={false}
      className={classes.endorsementProcessContainer}
      id="endorsement-process"
    >
      <Container maxWidth="sm">
        <Typography
          variant="h4"
          component="h2"
          paragraph
          className={classes.howItWorksTitle}
        >
          What happens to your money
        </Typography>
        <Typography
          variant="h5"
          component="h3"
          className={classes.howItWorksTitle}
          paragraph
        >
          <Keyword>We'll hold your money</Keyword> until the hiring process is
          complete. <Accent>You can withdraw any time</Accent> until{" "}
          {applicant.firstName} is hired by {role.employer}.
        </Typography>
      </Container>
      <Container maxWidth="md" className={classes.timeline}>
        <Timeline
          provisional
          applicant={applicant}
          role={role}
          endorsement={{
            id: "",
            applicantId: "",
            amount: role.maxStake,
            status: "pending",
            email: "",
            paymentMethod: "stripe",
          }}
        />
      </Container>
      <Container maxWidth="md">
        <div className={classes.cta}>
          <Button
            secondary
            cta
            small={isLarge}
            onClick={() => next()}
            trackingId="Understood (what happens)"
          >
            Understood, let's begin
          </Button>
        </div>
      </Container>
    </Container>
  );
};

const FAQs: React.FC<Props> = ({ applicant, role, next }) => {
  const classes = useStyles();

  const isLarge = useMediaQuery<Theme>((theme) => theme.breakpoints.up("lg"));
  return (
    <Container maxWidth={false} className={classes.questionsContainer}>
      <Container maxWidth="sm">
        <Typography
          variant="h4"
          component="h2"
          paragraph
          className={classes.questionsTitle}
        >
          Frequently-asked questions
        </Typography>
      </Container>
      <Container maxWidth="md">
        <FAQAccordion applicant={applicant} role={role} />
      </Container>
      <Container maxWidth="md">
        <div className={[classes.cta, classes.additionalQuestion].join(" ")}>
          <Button
            tertiary
            small={isLarge}
            onClick={openSlassk}
            trackingId="I have another questions (FAQs)"
          >
            I have another question...
          </Button>
        </div>
      </Container>
    </Container>
  );
};

const WrapUp: React.FC<Props> = ({ applicant, role, next }) => {
  const classes = useStyles();

  const isLarge = useMediaQuery<Theme>((theme) => theme.breakpoints.up("lg"));
  return (
    <div className={classes.wrapUp}>
      <Container maxWidth="md">
        <div className={[classes.cta, classes.additionalQuestion].join(" ")}>
          <Button
            cta
            small={isLarge}
            onClick={() => next()}
            trackingId="I'm ready (wrap up)"
          >
            I'm ready, let's get started
          </Button>
        </div>
      </Container>
      <img
        className={classes.footerImage}
        src={Images.HillsNoNotch}
        alt="Higher"
      />
    </div>
  );
};

const Landing: React.FC<Props> = ({ applicant, role, next }) => {
  const { tracking } = useTracking();
  useMountEffect(() => {
    tracking.logEvent("page_view", {});
  });

  return (
    <Page showScrollDown headerProps={{ menu: <></> }}>
      <MetaTags applicant={applicant} role={role} />
      <TrackedSection trackingId="endorsement intro">
        <Intro applicant={applicant} role={role} next={next} />
      </TrackedSection>
      <TrackedSection trackingId="how endorsement works">
        <HowItWorks applicant={applicant} role={role} next={next} />
      </TrackedSection>
      <TrackedSection trackingId="endorsement process">
        <EndorsementProcess applicant={applicant} role={role} next={next} />
      </TrackedSection>
      <TrackedSection trackingId="faqs">
        <FAQs applicant={applicant} role={role} next={next} />
      </TrackedSection>
      <TrackedSection trackingId="endorsement wrap">
        <WrapUp applicant={applicant} role={role} next={next} />
      </TrackedSection>
    </Page>
  );
};

export const LandingSkeleton: React.FC = () => (
  <Page>
    <IntroSkeleton
      applicant={UnknownApplicant}
      role={UnknownRole}
      next={() => {}}
    />
    <LinearProgress color="secondary" />
  </Page>
);

export default Landing;
