export enum ApplicantSource {
  ManuallyAdded = "manually-added",
}

export type ApplicantMetadata = {
  id: string;
  rankContributionFromEndorsements: number; // Given as a percentage
  rankContributionFromReviews: number; // Given as a percentage
  reviewCount: number;
  commentCount: number;
  rankIndex: number;
  source?: ApplicantSource | string;
  addedByUserId?: string;
};

export const WasManuallyAdded = (metadata: ApplicantMetadata): boolean => {
  return !!metadata.source && metadata.source === ApplicantSource.ManuallyAdded;
};

export default ApplicantMetadata;
