import {
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogProps,
  IconButton,
  Grow,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import {
  makeStyles,
  Theme,
  createStyles,
  useTheme,
} from "@material-ui/core/styles";
import { TransitionProps } from "@material-ui/core/transitions";
import CloseIcon from "@material-ui/icons/UnfoldLess";
import React from "react";

import { Button } from "@app/brand";
import useTracking from "@app/hooks/useTracking";

type Props = {
  title: string;
  subtitle?: string;
  avatar?: string;
  open?: boolean;
  setOpen: (value: boolean) => void;
  maxWidth?: DialogProps["maxWidth"];
  trackingId?: string;
  fullWidth?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: "hidden",
    },
    title: {
      display: "flex",
      alignItems: "center",
      overflow: "hidden",
    },
    headerImage: {
      marginRight: "1em",
      width: "2.5em",
      height: "2.5em",
    },
    headerTitle: {
      fontWeight: "bold",
    },
    headerCloseIcon: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      transform: "rotate(45deg)",

      [theme.breakpoints.up("lg")]: {
        right: theme.spacing(1.5),
      },
    },
    actions: {
      paddingTop: "1em",
      paddingBottom: "1em",
      overflow: "hidden",
    },
    paper: {
      [theme.breakpoints.up("lg")]: {
        maxHeight: "90vh",
      },
    },
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Grow ref={ref} {...props} />;
});

const PopupDialog: React.FC<Props> = ({
  title,
  subtitle,
  avatar,
  open,
  setOpen,
  children,
  fullWidth,
  maxWidth,
  trackingId,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { logPopupOpen, logPopupClose } = useTracking();

  const handleOpen = () => {
    if (trackingId) {
      logPopupOpen(trackingId);
    }
  };

  const handleClose = () => {
    if (trackingId) {
      logPopupClose(trackingId);
    }

    setOpen(false);
  };

  return (
    <Dialog
      open={!!open}
      onClose={handleClose}
      onEnter={handleOpen}
      fullScreen={fullScreen}
      TransitionComponent={Transition}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      className={classes.root}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle disableTypography className={classes.title}>
        {avatar && <Avatar src={avatar} className={classes.headerImage} />}
        <div>
          <Typography
            component="h2"
            display="block"
            className={classes.headerTitle}
          >
            {title}
          </Typography>
          {subtitle && (
            <Typography variant="body2" display="block">
              {subtitle}
            </Typography>
          )}
        </div>
        <IconButton className={classes.headerCloseIcon} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{children}</DialogContentText>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button secondary compact onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PopupDialog;
