export enum Environment {
  Production = "production",
  Staging = "staging",
}

export class EnvironmentMap<T> extends Map<Environment, T> {
  public lookup(name = Environment.Production): T {
    const candidate = this.get(name);
    if (!candidate) {
      throw new Error(`Config ${name} not found!`);
    }

    return candidate;
  }

  public current(): T {
    return this.lookup(
      process.env.REACT_APP_HIGHER_ENV as Environment | undefined
    );
  }
}

export const isProduction = () => {
  // Default to production
  if (!process.env.REACT_APP_HIGHER_ENV) {
    return true;
  }

  return process.env.REACT_APP_HIGHER_ENV === Environment.Production;
};

export default Environment;
