import React from "react";
import { useHistory } from "react-router-dom";

import Firebase from "@app/Firebase";
import WrapErrorSuspense from "@app/api/WrapErrorSuspense";
import ErrorFallback from "@app/modules/Error";

import usePreFill from "../apply/Prefill";
import Landing, { LandingSkeleton as Skeleton } from "./Marketer.stateless";
import MetaTags from "./MetaTags";

type Props = {};

const View: React.FC<Props> = () => {
  const history = useHistory();
  const [, setPrefill] = usePreFill("higher-growth-marketer");

  const applyWithLinkedIn = async () => {
    const provider = new Firebase.auth.OAuthProvider("linkedin.com");
    provider.addScope("r_emailaddress");
    provider.addScope("r_liteprofile");
    try {
      const result = await Firebase.auth().signInWithPopup(provider);
      if (result.user) {
        setPrefill({
          name: result.user.displayName,
          email: result.user.email,
          avatar: result.user.photoURL,
        });
      }
      next();
    } catch (e) {
      console.log(e);
    }
  };

  const applyWithGoogle = async () => {
    const provider = new Firebase.auth.GoogleAuthProvider();
    try {
      const result = await Firebase.auth().signInWithPopup(provider);
      if (result.user) {
        setPrefill({
          name: result.user.displayName,
          email: result.user.email,
          avatar: result.user.photoURL,
        });
      }
      next();
    } catch (e) {
      console.log(e);
    }
  };

  const applyWithNothing = () => {
    setPrefill({});
    next();
  };

  const next = async () => {
    history.push(`/apply/form/higher-growth-marketer`);
  };

  return (
    <Landing
      applyWithLinkedIn={applyWithLinkedIn}
      applyWithGoogle={applyWithGoogle}
      applyWithNothing={applyWithNothing}
    >
      Hi
    </Landing>
  );
};

const Dispatcher: React.FC = () => {
  return (
    <>
      <MetaTags />
      <View />
    </>
  );
};

export default WrapErrorSuspense(Dispatcher, Skeleton, ErrorFallback);
