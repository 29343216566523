import React from "react";
import { useRecoilValue, RecoilValue } from "recoil";

export default function useFirestoreValue<T>(state: RecoilValue<T>): [
  NonNullable<T>,
  boolean // isLoading
] {
  const value = useRecoilValue(state);
  const [loading, setLoading] = React.useState<boolean>(!value);

  React.useEffect(() => {
    switch (value) {
      case undefined:
        setLoading(true);
        break;

      case null:
        throw new Error("That record doesn't exist");

      default:
        setLoading(false);
    }
  }, [value]);

  return [value as NonNullable<T>, loading];
}
