import Review, { Rating } from "@higher/models/Review";
import Role from "@higher/models/Role";
import User from "@higher/models/User";

import Firebase from "@app/Firebase";

import { IdRequired } from "./Identifiable";
import batchUpdate, {
  BatchUpdateFn,
  Creator,
  UpdateCallback,
} from "./batchUpdate";

export type UpdateReviewsFn = BatchUpdateFn<Review>;
export type RawReview = Omit<Review, "id" | "date">;
type IdentifiableApplicant = IdRequired;

export const UpdateReviews =
  (role: Role) => (callback: UpdateCallback<Review>) =>
    batchUpdate<Review>(
      `organisations/${role.organisationId}/roles/${role.id}/reviews`,
      callback
    );

export const createReview = (
  create: Creator<Review>,
  applicant: IdentifiableApplicant,
  user: User,
  rating: Rating
) => {
  create({
    date: Firebase.firestore.FieldValue.serverTimestamp(),
    applicantId: applicant.id,
    reviewerId: user.uid,
    rating,
  });
};

export default UpdateReviews;
