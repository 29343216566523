import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Box, Container, Typography } from "@material-ui/core";

import { FallbackProps } from "react-error-boundary";

import { Images } from "@app/brand";

import Page from "@app/layouts/Page";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundImage: `url(${Images.BrandarrowsBackgroundImageTrans})`,
      backgroundSize: "cover",
      backgroundPosition: "bottom right",
    },
    container: {
      marginTop: 0,
      paddingTop: 0,
      height: `calc( (var(--vh, 1vh) * 100) - ${theme.spacing(8)}px)`,
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
    },
    box: {
      margin: "auto",
      textAlign: "center",
      paddingBottom: theme.spacing(8),
    },
  })
);

const ErrorFallback: React.FC<FallbackProps> = ({ error }) => {
  const classes = useStyles();
  return (
    <Page className={classes.root}>
      <Container maxWidth="sm" className={classes.container}>
        <Box className={classes.box}>
          <Typography paragraph variant="h5" component="h1">
            Uh oh!
          </Typography>
          <Typography variant="h4" component="h2">
            {error?.message}
          </Typography>
        </Box>
      </Container>
    </Page>
  );
};

export default ErrorFallback;
