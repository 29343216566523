import { RemovalReason } from "@higher/models/Applicant";
import Event, { EventType, EventUserType } from "@higher/models/Event";
import User from "@higher/models/User";

import Firebase from "@app/Firebase";

import { IdRequired } from "./Identifiable";
import batchUpdate, {
  BatchUpdateFn,
  Creator,
  UpdateCallback,
} from "./batchUpdate";

export type UpdateEventsFn = BatchUpdateFn<Event>;
export type RawEvent = Omit<Event, "id" | "date">;
export type IdentifiableApplicant = IdRequired;

export const UpdateEvents =
  (orgId: string) => (callback: UpdateCallback<Event>) =>
    batchUpdate<Event>(`organisations/${orgId}/events/`, callback);

export const createEvent = (create: Creator<Event>, event: RawEvent) => {
  create({
    date: Firebase.firestore.FieldValue.serverTimestamp(),
    ...event,
  });
};

export const createApplicantCommentEvent = (
  create: Creator<Event>,
  applicant: IdentifiableApplicant,
  user: User,
  content: string | undefined
) => {
  createEvent(create, {
    type: EventType.Comment,
    subjectType: EventUserType.Applicant,
    subjectId: applicant.id,
    initiatorType: EventUserType.User,
    initiatorId: user.uid,
    content,
  });
};

export const createApplicantStageMoveEvent = (
  create: Creator<Event>,
  applicant: IdentifiableApplicant,
  user: User,
  stage: string
) => {
  createEvent(create, {
    type: EventType.StageChange,
    subjectType: EventUserType.Applicant,
    subjectId: applicant.id,
    initiatorType: EventUserType.User,
    initiatorId: user.uid,
    content: stage,
  });
};

export const createApplicantMessageEvent = (
  create: Creator<Event>,
  applicant: IdentifiableApplicant,
  user: User,
  content: string
) => {
  createEvent(create, {
    type: EventType.OutgoingMessage,
    subjectType: EventUserType.Applicant,
    subjectId: applicant.id,
    initiatorType: EventUserType.User,
    initiatorId: user.uid,
    content,
  });
};

export const createApplicantRemoveEvent = (
  create: Creator<Event>,
  applicant: IdentifiableApplicant,
  user: User,
  reason?: RemovalReason
) => {
  const payload: RawEvent = {
    type: EventType.CandidateRemoval,
    subjectType: EventUserType.Applicant,
    subjectId: applicant.id,
    initiatorType: EventUserType.User,
    initiatorId: user.uid,
  };

  if (reason) {
    payload.content = reason;
  }

  createEvent(create, payload);
};

export const createApplicantRestoreEvent = (
  create: Creator<Event>,
  applicant: IdentifiableApplicant,
  user: User
) => {
  createEvent(create, {
    type: EventType.CandidateRestoration,
    subjectType: EventUserType.Applicant,
    subjectId: applicant.id,
    initiatorType: EventUserType.User,
    initiatorId: user.uid,
  });
};

export default UpdateEvents;
