import React from "react";
import { Typography, Link } from "@material-ui/core";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";

import Interpolate, {
  InterpolationReplacementMap,
} from "@app/helpers/interpolation";

type Props = {
  source: string | Array<string>;
  replacements?: InterpolationReplacementMap;
};

type HeadingVariant = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

function flatten(text: string, child: any): string {
  return typeof child === "string"
    ? text + child
    : React.Children.toArray(child.props.children).reduce(flatten, text);
}

const Markdown: React.FC<Props> = ({ source, replacements }) => {
  let value: string = Array.isArray(source) ? source.join("\n\n") : source;

  if (replacements) {
    value = Interpolate(value, replacements);
  }

  return (
    <ReactMarkdown
      source={value}
      plugins={[gfm]}
      renderers={{
        heading: ({ children, level }) => {
          let variant: HeadingVariant;
          let component: HeadingVariant;
          switch (level) {
            case 1:
              variant = "h3";
              component = "h1";
              break;

            case 2:
              variant = "h4";
              component = "h2";
              break;

            case 3:
              variant = "h5";
              component = "h3";
              break;

            default:
              variant = "h6";
              component = "h6";
              break;
          }
          const c = React.Children.toArray(children);
          const text = c.reduce(flatten, "");
          const slug = text
            .toLowerCase()
            .replace(/\W/g, "-")
            .replace(/--/g, "-")
            .replace(/-$/g, "");
          return (
            <Typography
              id={slug}
              paragraph
              variant={variant}
              component={component}
            >
              {children}
            </Typography>
          );
        },
        paragraph: ({ children }) => (
          <Typography paragraph variant="body1">
            {children}
          </Typography>
        ),
        link: ({ children, href, title }) => {
          const isAnchor = href.startsWith("#");
          const handleClick = (e: any) => {
            if (!isAnchor) {
              return;
            }
            e.preventDefault();
            const id = href.replace("#", "");
            const element = document.getElementById(id);
            if (element) {
              element.scrollIntoView({ behavior: "smooth" });
            }
            window.location.hash = href;
          };

          return (
            <Link
              href={href}
              target={!isAnchor ? "_blank" : undefined}
              onClick={(e: any) => handleClick(e)}
              rel="noopener"
              color="primary"
              title={title}
            >
              {children}
            </Link>
          );
        },
      }}
    />
  );
};

export default Markdown;
