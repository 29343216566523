import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";

import NotFound from "@app/modules/NotFound";

import Home from "./Home";
import Privacy from "./Privacy";
import { Form as ApplyForm, Landing as ApplyLanding } from "./apply";
import MarketerLanding from "./careers/Marketer";
import { Home as ApplicantDashboardHome } from "./dashboard/applicant";
import {
  Home as EmployerDashboardHome,
  Role as EmployerDashboardRole,
  Conversations as EmployerDashboardConversations,
  Team as EmployerTeam,
} from "./dashboard/employer";
import {
  Landing as EndorseLanding,
  Questions as EndorseForm,
  Complete as EndorsementComplete,
  Self as EndorseSelf,
  Social as EndorseSocial,
} from "./endorse";
import Invite from "./invite";

const Body = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash === "") {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        const id = location.hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    }
  }, [location.pathname, location.hash]);

  return (
    <Switch location={location}>
      <Route path="/apply/form/:id" component={ApplyForm} />
      <Route path="/careers/growth-marketer" component={MarketerLanding} />
      <Route path="/apply/:id" component={ApplyLanding} />
      <Route
        path="/dashboard/roles/:id/:applicantId?"
        exact
        component={EmployerDashboardRole}
      />
      <Route path="/dashboard/roles/" exact component={EmployerDashboardHome} />
      <Route
        path="/dashboard/conversations/"
        exact
        component={EmployerDashboardConversations}
      />
      <Route path="/dashboard/team/" exact component={EmployerTeam} />
      <Route path="/dashboard" exact component={EmployerDashboardHome} />
      <Route path="/dashboard/:id" component={ApplicantDashboardHome} />
      <Route path="/endorse/form/:id/" component={EndorseForm} />
      <Route path="/endorse/self/:id/" component={EndorseSelf} />
      <Route path="/endorse/complete/:id/" component={EndorsementComplete} />
      <Route path="/endorse/social/:id" component={EndorseSocial} />
      <Route path="/endorse/:id" component={EndorseLanding} />
      <Route path="/invite/:id" component={Invite} />
      <Route exact path="/privacy" component={Privacy} />
      <Route exact path="/" component={Home} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default Body;
