import readingTime from "@danieldietrich/reading-time";
import React from "react";

import Applicant from "@higher/models/Applicant";
import Role from "@higher/models/Role";
import { FormatCurrency } from "@higher/payments/currency";

import Markdown from "@app/components/Markdown";
import PopupCard from "@app/components/PopupCard";

type Props = {
  applicant: Applicant;
  role: Role;
  amount: number;
  focused?: boolean;
  self?: boolean;
};

const terms = (applicant: Applicant, role: Role, amount: number): string => {
  return `
### Your payment of ${FormatCurrency(role.currency, amount)}

**To complete your endorsement of ${
    applicant.name
  }, you must make a payment of ${FormatCurrency(
    role.currency,
    amount
  )} to Higher.** Higher is operated by a UK company called GetHigher.io Ltd., who will receive the payment.

**If ${role.employer} choose not to hire ${
    applicant.name
  }** then we will refund your payment of ${FormatCurrency(
    role.currency,
    amount
  )} to your original payment method. 

This could happen in the following ways:

1. **${
    role.employer
  } decide to hire someone else for the role.** In this case we will refund your payment when ${
    role.employer
  } tell us that they've hired someone else.

2. **${
    role.employer
  } decide not to hire anyone.** In this case we will refund your payment immediately.
    
**If ${role.employer} hire ${
    applicant.name
  }** then we'll put your payment of ${FormatCurrency(
    role.currency,
    amount
  )} into escrow. We will use [Stripe Payments Europe Ltd](http://www.stripe.com), a regulated third-party service, as our escrow provider for fiat payments and [Coinbase Commerce](https://commerce.coinbase.com), another regulated third-party service, for crypto payments.

The money will remain in escrow until either:

1. **${applicant.name} completes probation at ${
    role.employer
  }**. In this case, we will withdraw your payment of ${FormatCurrency(
    role.currency,
    amount
  )} from escrow and refund it to your original payment method. We'll also pay you an endorsement reward of ${FormatCurrency(
    role.currency,
    amount
  )}.

2. **${applicant.name} fails probation at ${
    role.employer
  } due to a lack of skills and/or experience**. In this case, we'll withdraw your payment of ${FormatCurrency(
    role.currency,
    amount
  )} from escrow and donate it to charity.

3. **${role.employer} end ${
    applicant.name
  }'s employment during probation, and ${
    applicant.name
  } is not at fault**. In this case, we will withdraw your payment of ${FormatCurrency(
    role.currency,
    amount
  )} from escrow and refund it to your original payment method. Depending on the length of ${
    applicant.firstName
  }'s employment, we may also pay you a partial endorsement reward.

4. **${
    applicant.name
  } ends their employment during probation and is not at fault**. In this case, we will withdraw your payment of ${FormatCurrency(
    role.currency,
    amount
  )} from escrow and refund it to your original payment method.

### Endorsement rewards

**If ${
    applicant.name
  } is hired and passes probation, we'll refund your payment and pay you an endorsement reward of ${FormatCurrency(
    role.currency,
    amount
  )}.** 

The day after ${
    applicant.firstName
  }'s probation ends, we'll withdraw your payment of ${FormatCurrency(
    role.currency,
    amount
  )} from escrow and refund it to your original payment method.

We'll also ask you for your bank account details and any necessary identifying information. Once we have your details, we'll transfer your endorsement reward of ${FormatCurrency(
    role.currency,
    amount
  )} to your bank account immediately.

### Partial endorsement rewards

**If ${role.employer} hire ${applicant.name} and decide to end ${
    applicant.firstName
  }'s employment before probation is due to end, and ${
    applicant.firstName
  } is not at fault**, we will:

1. Refund your payment to your original payment method.

2. If ${applicant.firstName} has worked at ${
    role.employer
  } for more than 14 days, we'll pay you a pro-rata endorsement reward.

   For example, if ${role.employer} end ${
    applicant.firstName
  }'s employment after ${
    role.probation / 2
  } days (half way through probation) we'll pay you a partial endorsement reward of ${FormatCurrency(
    role.currency,
    amount / 2
  )}. This will be paid by bank transfer, as described above.
  
### Charitable donations

**If ${applicant.name} fails probation at ${
    role.employer
  } due to a lack of skills and/or experience** we'll donate your payment of ${FormatCurrency(
    role.currency,
    amount
  )} to charity.

We'll split the money between the 4 main [Effective Altruism Funds](https://app.effectivealtruism.org/funds). 

Effective Altruism Funds operate like a mutual funds, except they maximise the effectiveness of  donations, rather than investment returns. The programme is administered by the  [Centre for Effective Altruism](https://www.centreforeffectivealtruism.org/), a 501(c)(3) tax-exempt organisation in the US, and a registered charity in England and Wales.

### Unexpected outcomes

The Higher platform is in an 'open beta' phase. It's a new product adapting to complex problems.

The scenarios above may not cover every possibility. In some exceptional cases we may have to mediate between parties. If there's a dispute, we will endeavour to refund your payment of ${FormatCurrency(
    role.currency,
    amount
  )} when possible. If that's not possible, we'll donate your payment of ${FormatCurrency(
    role.currency,
    amount
  )} to charity.

**There is no scenario in which we keep your money. It will always be either returned to you or donated to charity.**

### Withdrawing your endorsement

**You may withdraw your endorsement at any time before ${role.employer} hire ${
    applicant.name
  }.** In this case, we will refund your payment of ${FormatCurrency(
    role.currency,
    amount
  )} to your original payment method. We will not pay you any endorsement rewards, even if ${
    applicant.firstName
  } passes probation.

**If ${role.employer} hire ${
    applicant.name
  }, then we'll put your payment of ${FormatCurrency(
    role.currency,
    amount
  )} into escrow as described above. In this circumstance, you will not be able to withdraw your endorsement.**

### Start date

If ${role.employer} hire ${
    applicant.name
  }, they will negotiate a start date. We will notify you of the date by email. 

If no start date is agreed within 30 days, then we'll cancel your endorsement and refund your payment of ${FormatCurrency(
    role.currency,
    amount
  )} to your original payment method.

### Probation length

Probation will last up to **${role.probation} days**, beginning on ${
    applicant.name
  }'s start date at ${role.employer}. 

Either ${role.employer} or ${
    applicant.name
  } may end probation early, as described above.
    

### How we handle your data

We'll ask you for your email address as part of the endorsement process. We will email you with updates to ${
    applicant.name
  }'s progress with ${
    role.employer
  }. We'll also respond to, and follow up on, any support questions you ask us. Those are the only times we'll contact you.

We will have a small amount of data from the payment you make to us. This may include your name and in some cases the last few digits of your payment card. We won't use this information for any purpose other than refunding your payment.

We do not keep your data longer than is necessary, and we will not share it with anyone else, unless required to by law. For more information, see our [privacy policy](/privacy). 
`;
};

const termsSelf = (role: Role, amount: number): string => {
  return `
### Your payment of ${FormatCurrency(role.currency, amount)}

**To complete your endorsement, you must make a payment of ${FormatCurrency(
    role.currency,
    amount
  )} to Higher.** Higher is operated by a UK company called GetHigher.io Ltd., who will receive the payment.

**If ${
    role.employer
  } choose not to hire you** then we will refund your payment of ${FormatCurrency(
    role.currency,
    amount
  )} to your original payment method. 

This could happen in the following ways:

1. **${
    role.employer
  } decide to hire someone else for the role.** In this case we will refund your payment when ${
    role.employer
  } tell us that they've hired someone else.

2. **${
    role.employer
  } decide not to hire anyone.** In this case we will refund your payment immediately.
    
**If ${
    role.employer
  } hire you** then we'll put your payment of ${FormatCurrency(
    role.currency,
    amount
  )} into escrow. We will use [Stripe Payments Europe Ltd](http://www.stripe.com), a regulated third-party service, as our escrow provider.
  )} into escrow. We will use [Stripe Payments Europe Ltd](http://www.stripe.com), a regulated third-party service, as our escrow provider for fiat payments and [Coinbase Commerce](https://commerce.coinbase.com), another regulated third-party service, for crypto payments.

The money will remain in escrow until either:

1. **You complete probation at ${
    role.employer
  }**. In this case, we will withdraw your payment of ${FormatCurrency(
    role.currency,
    amount
  )} from escrow and refund it to your original payment method. We'll also pay you an endorsement reward of ${FormatCurrency(
    role.currency,
    amount
  )}.

2. **You fail probation at ${
    role.employer
  } due to a lack of skills and/or experience**. In this case, we'll withdraw your payment of ${FormatCurrency(
    role.currency,
    amount
  )} from escrow and donate it to charity.

3. **${
    role.employer
  } end your employment during probation, and are not at fault**. In this case, we will withdraw your payment of ${FormatCurrency(
    role.currency,
    amount
  )} from escrow and refund it to your original payment method. Depending on the length of your employment, we may also pay you a partial endorsement reward.

4. **You end your employment during probation and you are not at fault**. In this case, we will withdraw your payment of ${FormatCurrency(
    role.currency,
    amount
  )} from escrow and refund it to your original payment method.

### Endorsement rewards

**If you are hired and pass probation, we'll refund your payment and pay you an endorsement reward of ${FormatCurrency(
    role.currency,
    amount
  )}.** 

The day after your  probation ends, we'll withdraw your payment of ${FormatCurrency(
    role.currency,
    amount
  )} from escrow and refund it to your original payment method.

We'll also ask you for your bank account details and any necessary identifying information. Once we have your details, we'll transfer your endorsement reward of ${FormatCurrency(
    role.currency,
    amount
  )} to your bank account immediately.

### Partial endorsement rewards

**If ${
    role.employer
  } hire you and decide to end your employment before probation is due to end, and you are not at fault**, we will:

1. Refund your payment to your original payment method.

2. If you have worked at ${
    role.employer
  } for more than 14 days, we'll pay you a pro-rata endorsement reward.

   For example, if ${role.employer} end your employment after ${
    role.probation / 2
  } days (half way through probation) we'll pay you a partial endorsement reward of ${FormatCurrency(
    role.currency,
    amount / 2
  )}. This will be paid by bank transfer, as described above.
  
### Charitable donations

**If you fail probation at ${
    role.employer
  } due to a lack of skills and/or experience** we'll donate your payment of ${FormatCurrency(
    role.currency,
    amount
  )} to charity.

We'll split the money between the 4 main [Effective Altruism Funds](https://app.effectivealtruism.org/funds). 

Effective Altruism Funds operate like a mutual funds, except they maximise the effectiveness of  donations, rather than investment returns. The programme is administered by the  [Centre for Effective Altruism](https://www.centreforeffectivealtruism.org/), a 501(c)(3) tax-exempt organisation in the US, and a registered charity in England and Wales.

### Unexpected outcomes

The Higher platform is in an 'open beta' phase. It's a new product adapting to complex problems.

The scenarios above may not cover every possibility. In some exceptional cases we may have to mediate between parties. If there's a dispute, we will endeavour to refund your payment of ${FormatCurrency(
    role.currency,
    amount
  )} when possible. If that's not possible, we'll donate your payment of ${FormatCurrency(
    role.currency,
    amount
  )} to charity.

**There is no scenario in which we keep your money. It will always be either returned to you or donated to charity.**

### Withdrawing your endorsement

**You may withdraw your endorsement at any time before ${
    role.employer
  } hire you.** If you do that, we will refund your payment of ${FormatCurrency(
    role.currency,
    amount
  )} to your original payment method. We will not pay you any endorsement rewards, even if you pass probation.

**If ${role.employer} hire you then we'll put your payment of ${FormatCurrency(
    role.currency,
    amount
  )} into escrow as described above. In this circumstance, you will not be able to withdraw your endorsement.**

### Start date

If ${role.employer} hire you, then you and ${
    role.employer
  } must negotiate a start date within 30 days. 

If no start date is agreed within 30 days, then we'll cancel your endorsement and refund your payment of ${FormatCurrency(
    role.currency,
    amount
  )} to your original payment method.

### Probation length

Probation will last up to **${
    role.probation
  } days**, beginning on your start date at ${role.employer}. 

Either you or ${role.employer} may end probation early, as described above.

### How we handle your data

We've captured  your email address as part of the application process. We'll email you with updates to your progress. We'll also respond to, and follow up on, any support questions you ask us. Those are the only times we'll contact you.

We will have a small amount of data from the payment you make to us. This may include your name and in some cases the last few digits of your payment card. We won't use this information for any purpose other than refunding your payment.

We do not keep your data longer than is necessary, and we will not share it with anyone else, unless required to by law. For more information, see our [privacy policy](/privacy). 
`;
};

const TermsCard: React.FC<Props> = ({
  applicant,
  role,
  amount,
  focused,
  self,
}) => {
  const text = self ? termsSelf(role, amount) : terms(applicant, role, amount);
  const { minutes } = readingTime(text);

  return (
    <>
      <PopupCard
        title="Full terms and conditions"
        subtitle={`In plain English. ${minutes} minute read`}
        focused={focused}
        popup={<Markdown source={text} />}
        trackingId="terms card"
      />
    </>
  );
};

export default TermsCard;
