import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Avatar, Box, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

type Props = {
  title: string;
  highlight?: boolean;
  avatar?: string;
  image?: string;
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    callout: {
      height: "100%",
      display: "flex",
      alignContent: "center",
      position: "relative",

      [theme.breakpoints.up("lg")]: {
        paddingTop: theme.spacing(4),
        display: "block",
        paddingBottom: theme.spacing(2),
      },
    },
    highlight: {
      [theme.breakpoints.up("lg")]: {
        backgroundColor: `${theme.palette.primary.main}`,
        borderRadius: "4px",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        "& *": {
          color: "white",
        },
        "& span": {
          color: `${theme.palette.secondary.main}`,
          fontWeight: "bold",
        },
      },
    },
    calloutTitle: {
      fontFamily: "Inter",
      fontWeight: "bold",
      fontSize: "1.25rem",

      [theme.breakpoints.up("lg")]: {
        textAlign: "center",
      },
    },
    calloutBody: {
      [theme.breakpoints.up("lg")]: {
        textAlign: "center",
      },
    },

    calloutImage: {
      width: theme.spacing(6),
      height: theme.spacing(6),
      marginTop: "4px",
      [theme.breakpoints.up("lg")]: {
        width: theme.spacing(8),
        height: theme.spacing(8),
        position: "absolute",
        top: -theme.spacing(4),
        marginTop: "0px",
      },
    },
    calloutImageContainer: {
      marginRight: theme.spacing(3),

      [theme.breakpoints.up("lg")]: {
        display: "flex",
        justifyContent: "center",
        margin: "0",
        marginBottom: theme.spacing(1),
      },
    },
  });
});

export const Callout: React.FC<Props> = ({
  highlight,
  title,
  image,
  avatar,
  children,
}) => {
  const classes = useStyles();
  let avatarEl: JSX.Element | null = null;

  const classNames: Array<string> = [classes.callout];

  if (image) {
    avatarEl = <img alt={title} src={image} className={classes.calloutImage} />;
  } else if (avatar) {
    avatarEl = (
      <Avatar alt={title} src={avatar} className={classes.calloutImage} />
    );
  }

  if (highlight) {
    classNames.push(classes.highlight);
  }

  return (
    <Box className={classNames.join(" ")}>
      <div className={classes.calloutImageContainer}>{avatarEl}</div>
      <div>
        <Typography
          variant="subtitle1"
          color="primary"
          className={classes.calloutTitle}
        >
          {title}
        </Typography>
        <Typography
          variant="body1"
          color="textPrimary"
          className={classes.calloutBody}
        >
          {children}
        </Typography>
      </div>
    </Box>
  );
};

export const CalloutSkeleton: React.FC<Props> = ({ title, children }) => {
  const classes = useStyles();

  return (
    <Box className={classes.callout}>
      <div className={classes.calloutImageContainer}>
        <Skeleton variant="circle" className={classes.calloutImage} />
      </div>
      <div>
        <Skeleton variant="rect" width="100%">
          <Typography
            variant="subtitle1"
            color="primary"
            className={classes.calloutTitle}
          >
            {title}
          </Typography>
        </Skeleton>
        <Skeleton variant="text" width="100%">
          <Typography
            variant="body1"
            color="textPrimary"
            className={classes.calloutBody}
          >
            {children}
          </Typography>
        </Skeleton>
      </div>
    </Box>
  );
};

export default Callout;
