import {
  Card,
  CardActions,
  CardContent,
  Chip,
  Collapse,
  Container,
  FormControl,
  FormControlLabel,
  LinearProgress,
  RadioGroup,
  Radio,
  Switch,
  Typography,
  IconButton,
  TextField,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";
import ImageUploading from "react-images-uploading";
import ScrollToBottom from "react-scroll-to-bottom";

import Role, { UnknownRole } from "@higher/models/Role";

import { Button, Images, UpArrow, Keyword } from "@app/brand";
import DialogForm, {
  MessageTree,
  useQuestionState,
} from "@app/components/DialogForm";
import FileUpload from "@app/components/FileUpload";
import SpeechBubble, {
  SpeechBubbleSkeleton,
} from "@app/components/SpeechBubble";
import { YesNo } from "@app/helpers/YesNo";
import articleForRoleTitle from "@app/helpers/articleForRoleTitle";

import HeadingCard, { HeadingCardSkeleton } from "./HeadingCard";
import ImageCropperModal from "./ImageCropperModal";
import MetaTags from "./MetaTags";
import { Prefill } from "./Prefill";

type Props = {
  role: Role;
};

type NamedState<K extends string, T> = {
  [key in K]: [T | undefined, (set: T | undefined) => void];
};

type State = NamedState<"nameState", string> &
  NamedState<"emailState", string> &
  NamedState<"imageState", string | YesNo.No> &
  NamedState<"imageURLState", string> &
  NamedState<"locationState", string> &
  NamedState<"titleState", string> &
  NamedState<"linkedinState", string | YesNo.No> &
  NamedState<"cvFileState", [File, string] | YesNo.No> &
  NamedState<"cvUrlState", string | YesNo.No>;

type Controller = {
  handleContinue: () => void;
};

type PrefillState = {
  prefill: Prefill;
};

type QuestionProps<T = string> = Props & {
  value: T | undefined;
  setValue: (valid: T | undefined) => void;
};

const useStyles = makeStyles((theme: Theme) => {
  const arrows = Images.BrandarrowsBackgroundImageTrans;
  const leftPositionX = `calc( ((100vw - ${theme.breakpoints.values.md}px)/2) - 75vh )`;
  const rightPositionX = `calc( ((100vw - ${theme.breakpoints.values.md}px)/2) + ${theme.breakpoints.values.md}px )`;

  return createStyles({
    root: {},
    content: {
      paddingTop: "2em",
      paddingBottom: "2em",
    },
    contentWrapper: {
      backgroundImage: `url(${arrows}),url(${arrows})`,
      backgroundSize: "75vh auto",
      backgroundRepeat: "no-repeat",
      backgroundPositionX: `${leftPositionX},${rightPositionX}`,
      backgroundPositionY: `${theme.spacing(8)}px, ${theme.spacing(18)}px`,
      minHeight: "calc(var(--vh, 1vh) * 100)",
    },
    scrollContainer: {
      position: "fixed",
      height: "calc(var(--vh, 1vh) * 100)",
    },
    header: {
      color: "white",
      lineHeight: "1.3em",
    },
    radio: {
      color: "white",
    },
    textInput: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      borderColor: "white",
      width: "100%",
      "& input, & p": {
        color: "white",
      },

      "& label": {
        color: "#ddd",
      },
    },
    textInputAutofill: {
      "&:-webkit-autofill": {
        WebkitBoxShadow: `0 0 0 1000px ${theme.palette.primary.light} inset`,
        WebkitTextFillColor: "white",
        borderRadius: 0,
      },
    },
    imagePreviewContainer: {},
    imagePreviewContent: {
      padding: theme.spacing(0),
      paddingBottom: 0,
    },
    imagePreviewActions: {
      padding: theme.spacing(0),
      paddingTop: 0,
      justifyContent: "flex-end",
    },
    imagePreview: {
      width: theme.spacing(20),
      height: theme.spacing(20),
      borderRadius: theme.spacing(0.25),
      margin: 0,
    },
    deleteButton: {
      width: "100px",
      margin: 0,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },

    iconButton: {
      color: "white",
      "& img": {
        height: "1em",
      },
    },
    input: {
      backgroundColor: "white",
      width: "100%",
      padding: "0.5em",
      paddingLeft: "1em",
      borderRadius: "0.5em",
    },
    error: {
      marginTop: "1em",
    },
    cta: {
      marginTop: "3em",
    },
    progress: {
      position: "absolute",
      zIndex: 1000,
      width: "100%",
      bottom: 0,
      borderBottomLeftRadius: theme.spacing(0.5),
      borderBottomRightRadius: theme.spacing(0.5),
    },
    fullWidth: {
      width: "100%",
    },
    uploadContainer: {
      backgroundColor: "rgba(0, 0, 0, 0.09)",
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
      borderRadius: 4,

      "& .MuiChip-root": {
        color: "white",
        borderColor: "white",
      },
    },
  });
});

type WithOptionalName<T> = T & {
  name?: string;
};

const IntroBubble: React.FC<WithOptionalName<Props>> = ({ role, name }) => {
  const classes = useStyles();
  const article = articleForRoleTitle(role.title);

  const greeting = name ? `Hi ${name.split(/\s+/)[0]}! ` : null;
  return (
    <SpeechBubble header>
      <>
        {greeting && (
          <Typography paragraph className={classes.header} variant="h6">
            {greeting}
          </Typography>
        )}
        <Typography paragraph className={classes.header} variant="h6">
          Let's get you started on your journey to be {article}{" "}
          <Keyword>{role.title}</Keyword> at <Keyword>{role.employer}</Keyword>.
        </Typography>

        <Typography variant="body1">
          We have a few questions for you. This won't take long.
        </Typography>
      </>
    </SpeechBubble>
  );
};

const WhatIsYourName: React.FC<QuestionProps<string>> = ({
  value,
  setValue,
}) => {
  const classes = useStyles();
  const [current, setCurrent] = React.useState(value);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const v = (event.target as HTMLInputElement).value;
    setCurrent(v);
  };

  const handleComplete = () => {
    if (current) {
      inputRef.current?.blur();
      setValue(current);
    } else {
      setValue(undefined);
    }
  };

  const inputRef = React.useRef<HTMLInputElement>();

  return (
    <>
      <SpeechBubble fromHigher>
        <Typography variant="body1">First, what's your name?</Typography>
      </SpeechBubble>
      <SpeechBubble>
        <TextField
          inputRef={inputRef}
          type="text"
          color="secondary"
          name="name"
          className={classes.textInput}
          inputProps={{ className: classes.textInputAutofill }}
          value={current}
          variant="filled"
          onBlur={handleComplete}
          onKeyPress={(ev) => {
            if (ev.key === "Enter") {
              handleComplete();
              ev.preventDefault();
            }
          }}
          onChange={handleChange}
          label="Your name"
          InputProps={{
            endAdornment: (
              <IconButton
                type="submit"
                className={classes.iconButton}
                onClick={handleComplete}
                aria-label="continue"
              >
                <img
                  src={UpArrow.ArrowRightWhite}
                  alt="Continue with this email address"
                />
              </IconButton>
            ),
          }}
        />
      </SpeechBubble>
    </>
  );
};

const validEmail =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i;

const WhatIsYourEmail: React.FC<QuestionProps<string | undefined>> = ({
  value,
  setValue,
}) => {
  const classes = useStyles();
  const [isValid, setIsValid] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [current, setCurrent] = React.useState(value || "");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const v = (event.target as HTMLInputElement).value;
    setCurrent(v);
    if (validEmail.test(v)) {
      setShowError(false);
      setIsValid(true);
      setIsError(false);
    } else {
      setIsError(true);
      setValue(undefined);
      setIsValid(false);
    }
  };

  const handleComplete = () => {
    if (isValid) {
      inputRef.current?.blur();
      setValue(current);
      return;
    }
    setShowError(true);
  };

  const inputRef = React.useRef<HTMLInputElement>();

  return (
    <>
      <SpeechBubble fromHigher>
        <Typography paragraph variant="body1">
          What's your email address?
        </Typography>
        <Typography variant="body1">
          We need an email address so we can keep you up to date on your
          application. We won't contact you about anything else.
        </Typography>
      </SpeechBubble>
      <SpeechBubble>
        <TextField
          inputRef={inputRef}
          type="email"
          color="secondary"
          name="email"
          className={classes.textInput}
          inputProps={{ className: classes.textInputAutofill }}
          value={current}
          variant="filled"
          onBlur={handleComplete}
          onKeyPress={(ev) => {
            if (ev.key === "Enter") {
              handleComplete();
              ev.preventDefault();
            }
          }}
          onChange={handleChange}
          label="Your email address"
          InputProps={{
            endAdornment: (
              <IconButton
                type="submit"
                className={classes.iconButton}
                onClick={handleComplete}
                aria-label="continue"
              >
                <img
                  src={UpArrow.ArrowRightWhite}
                  alt="Continue with this email address"
                />
              </IconButton>
            ),
          }}
          error={isError && showError}
          helperText={
            isError && showError
              ? "Please enter a valid email address"
              : undefined
          }
        />
      </SpeechBubble>
    </>
  );
};

const AddAnImage: React.FC<QuestionProps<string | YesNo.No>> = ({
  value,
  setValue,
}) => {
  const classes = useStyles();
  const [images, setImages] = React.useState([]);
  const [image, setImage] = React.useState<any | undefined>(undefined);
  const [modalOpen, setModalOpen] = React.useState(false);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    const v = (event.target as HTMLInputElement).value;
    if (v) {
      setValue(v);
    }
  };

  const onChange = (imageList: any, addUpdateIndex?: number[]) => {
    setImages(imageList);
    setImage(imageList[0]);
    setModalOpen(true);
  };

  const deleteImage = () => {
    setValue(undefined);
  };

  let attachment;
  if (value && value !== YesNo.No) {
    attachment = (
      <Card className={classes.imagePreviewContainer}>
        <CardContent className={classes.imagePreviewContent}>
          <img
            src={value as string}
            className={classes.imagePreview}
            alt="your upload"
          />
        </CardContent>
        <CardActions disableSpacing className={classes.imagePreviewActions}>
          <IconButton onClick={deleteImage}>
            <DeleteIcon />
          </IconButton>
        </CardActions>
      </Card>
    );
  }

  return (
    <>
      <SpeechBubble fromHigher>
        <Typography paragraph variant="body1">
          Would you like to add a picture of yourself?
        </Typography>
        <Typography variant="body1">
          An image will help other people identify you. It's useful, but you can
          skip it if you like.
        </Typography>
      </SpeechBubble>
      <SpeechBubble attachment={attachment}>
        <ImageUploading
          value={images}
          onChange={onChange}
          dataURLKey="data_url"
        >
          {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
          }) => (
            <>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="Do you want to an an image"
                  name="do-you-want-to-add-an-image"
                  value={value}
                  onChange={handleRadioChange}
                >
                  <FormControlLabel
                    control={
                      <Radio
                        className={classes.radio}
                        checked={!!value && value !== YesNo.No}
                      />
                    }
                    label="Yes, I'll add one"
                    onClick={(
                      e: React.MouseEvent<HTMLLabelElement, MouseEvent>
                    ) => {
                      e.preventDefault();
                      onImageUpload();
                    }}
                    value={undefined}
                  />
                  <FormControlLabel
                    value={YesNo.No}
                    control={
                      <Radio
                        className={classes.radio}
                        checked={value === YesNo.No}
                      />
                    }
                    label="I'll skip this for now"
                  />
                </RadioGroup>
              </FormControl>
            </>
          )}
        </ImageUploading>
      </SpeechBubble>
      <ImageCropperModal
        image={image ? image.data_url : ""}
        open={modalOpen}
        setOpen={setModalOpen}
        setImage={setValue}
      />
    </>
  );
};

const WhatIsYourLocation: React.FC<QuestionProps<string>> = ({
  value,
  setValue,
}) => {
  const classes = useStyles();
  const [current, setCurrent] = React.useState(value);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const v = (event.target as HTMLInputElement).value;
    setCurrent(v);
  };

  const handleComplete = () => {
    if (current) {
      inputRef.current?.blur();
      setValue(current);
    } else {
      setValue(undefined);
    }
  };

  const inputRef = React.useRef<HTMLInputElement>();

  return (
    <>
      <SpeechBubble fromHigher>
        <Typography variant="body1">Where are you located?</Typography>
      </SpeechBubble>
      <SpeechBubble>
        <TextField
          inputRef={inputRef}
          type="text"
          color="secondary"
          name="location"
          className={classes.textInput}
          inputProps={{ className: classes.textInputAutofill }}
          value={current}
          variant="filled"
          onBlur={handleComplete}
          onKeyPress={(ev) => {
            if (ev.key === "Enter") {
              handleComplete();
              ev.preventDefault();
            }
          }}
          onChange={handleChange}
          label="Your location"
          placeholder="For example, London or New York"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: (
              <IconButton
                type="submit"
                className={classes.iconButton}
                onClick={handleComplete}
                aria-label="continue"
              >
                <img
                  src={UpArrow.ArrowRightWhite}
                  alt="Continue with this location"
                />
              </IconButton>
            ),
          }}
        />
      </SpeechBubble>
    </>
  );
};

const HowWouldPeopleKnowYou: React.FC<QuestionProps<string>> = ({
  role,
  value,
  setValue,
}) => {
  const classes = useStyles();
  const [current, setCurrent] = React.useState(value);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const v = (event.target as HTMLInputElement).value;
    setCurrent(v);
  };

  const handleComplete = () => {
    if (current) {
      inputRef.current?.blur();
      setValue(current);
    } else {
      setValue(undefined);
    }
  };

  const inputRef = React.useRef<HTMLInputElement>();

  return (
    <>
      <SpeechBubble fromHigher>
        <Typography variant="body1" paragraph>
          How would you describe the work you do?
        </Typography>
        <Typography variant="body1">
          This could be a current or previous job title, the name of your
          business or a short description of your freelance work. It's mostly
          for other people to identify you, but it will also give{" "}
          {role.employer} a sense of what you do.
        </Typography>
      </SpeechBubble>
      <SpeechBubble>
        <TextField
          inputRef={inputRef}
          type="text"
          color="secondary"
          name="your-work"
          className={classes.textInput}
          inputProps={{ className: classes.textInputAutofill }}
          value={current}
          variant="filled"
          onBlur={handleComplete}
          onKeyPress={(ev) => {
            if (ev.key === "Enter") {
              handleComplete();
              ev.preventDefault();
            }
          }}
          onChange={handleChange}
          label="Your work"
          placeholder="For example, 'Engineer @ Google' or 'Founder of Higher'"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: (
              <IconButton
                type="submit"
                className={classes.iconButton}
                onClick={handleComplete}
                aria-label="continue"
              >
                <img
                  src={UpArrow.ArrowRightWhite}
                  alt="Continue with this email address"
                />
              </IconButton>
            ),
          }}
        />
      </SpeechBubble>
    </>
  );
};

const validLinkedIn = /^https?:\/\/([a-z]{2,3}\.)?linkedin\.com\/.*$/i;

const DoYouHaveALinkedIn: React.FC<QuestionProps<string | YesNo.No>> = ({
  role,
  value,
  setValue,
}) => {
  const classes = useStyles();
  const [isValid, setIsValid] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [current, setCurrent] = React.useState(value);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const v = (event.target as HTMLInputElement).value;

    if (v === "") {
      setCurrent(undefined);
      setShowError(false);
      setIsValid(true);
      setIsError(false);
      return;
    }

    setCurrent(v);
    if (validLinkedIn.test(v)) {
      setShowError(false);
      setIsValid(true);
      setIsError(false);
    } else {
      setIsError(true);
      setValue(undefined);
      setIsValid(false);
    }
  };

  const handleComplete = () => {
    setShowError(true);
    if (current && isValid) {
      inputRef.current?.blur();
      setValue(current);
    }
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setValue(YesNo.No);
    } else {
      setValue(current && validLinkedIn.test(current) ? current : undefined);
    }
  };

  const inputRef = React.useRef<HTMLInputElement>();

  const whiteArrow = (
    <IconButton
      type="submit"
      className={classes.iconButton}
      onClick={handleComplete}
      aria-label="continue"
    >
      <img src={UpArrow.ArrowRightWhite} alt="Continue with this profile URL" />
    </IconButton>
  );

  return (
    <>
      <SpeechBubble fromHigher>
        <Typography variant="body1" paragraph>
          Do you have a LinkedIn profile?
        </Typography>
        <Typography variant="body1">
          You don't need one, but providing more professional information allows
          your interviews with {role.employer} to be more focused.
        </Typography>
      </SpeechBubble>
      <SpeechBubble>
        <TextField
          inputRef={inputRef}
          type="text"
          color="secondary"
          name="linked-url"
          className={classes.textInput}
          inputProps={{ className: classes.textInputAutofill }}
          value={current === YesNo.No ? "" : current}
          variant="filled"
          onBlur={handleComplete}
          onKeyPress={(ev) => {
            if (ev.key === "Enter") {
              handleComplete();
              ev.preventDefault();
            }
          }}
          onChange={handleChange}
          label="LinkedIn profile"
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="https://linkedin.com/in/yourname"
          InputProps={{
            endAdornment: whiteArrow,
          }}
          error={isError && showError && value !== YesNo.No}
          helperText={
            isError && showError && value !== YesNo.No
              ? "That doesn't look like a LinkedIn profile URL"
              : undefined
          }
        />
        <FormControlLabel
          control={
            <Switch
              checked={value === YesNo.No}
              name="no-linkedin"
              color="secondary"
              onChange={handleSwitchChange}
            />
          }
          label="I don't use LinkedIn"
        />
      </SpeechBubble>
    </>
  );
};

const validURL = (url: string) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(url);
};

enum UploadOrLink {
  Upload = "upload",
  Link = "link",
}

type UploadCvProps = Props & {
  file: [File, string] | YesNo.No | undefined;
  setFile: (valid: [File, string] | YesNo.No | undefined) => void;
  link: string | undefined;
  setLink: (valid: string | YesNo.No | undefined) => void;
};

const UploadCV: React.FC<UploadCvProps> = ({
  file,
  setFile,
  link,
  setLink,
}) => {
  const classes = useStyles();

  const [radioValue, setRadioValue] = React.useState<
    UploadOrLink | YesNo.No | null
  >(
    file === YesNo.No
      ? YesNo.No
      : file
      ? UploadOrLink.Upload
      : link && link !== YesNo.No
      ? UploadOrLink.Link
      : null
  );
  const [isValid, setIsValid] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [current, setCurrent] = React.useState<string | undefined>(link);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    const v = (event.target as HTMLInputElement).value as
      | UploadOrLink
      | YesNo.No;
    if (v) {
      setRadioValue(v);

      switch (v) {
        case YesNo.No:
          setFile(YesNo.No);
          setCurrent("");
          setLink(undefined);
          break;

        case UploadOrLink.Link:
          if (!link) {
            setFile(undefined);
          }
          break;

        case UploadOrLink.Upload:
          if (!file) {
            setCurrent("");
            setLink(undefined);
          }
          break;

        default:
          break;
      }
    }
  };

  const onChangeUpload = (file: File, imageURL: string) => {
    setFile([file, imageURL]);
    setLink(undefined);
    setCurrent("");
  };

  const deleteFile = () => {
    setFile(undefined);
  };

  const handleLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const v = (event.target as HTMLInputElement).value;

    if (v === "") {
      setCurrent(undefined);
      setShowError(false);
      setIsValid(true);
      setIsError(false);
      return;
    }
    setCurrent(v);
    if (validURL(v)) {
      setShowError(false);
      setIsValid(true);
      setIsError(false);
    } else {
      setIsError(true);
      setFile(undefined);
      setIsValid(false);
    }
  };

  const handleLinkComplete = () => {
    setShowError(true);

    if (current && isValid) {
      setFile(undefined);
      inputRef.current?.blur();
      setLink(current);
    }
  };

  const whiteArrow = (
    <IconButton
      type="submit"
      className={classes.iconButton}
      onClick={handleLinkComplete}
      aria-label="continue"
    >
      <img src={UpArrow.ArrowRightWhite} alt="Continue with this profile URL" />
    </IconButton>
  );

  const inputRef = React.useRef<HTMLInputElement>();

  return (
    <>
      <SpeechBubble fromHigher>
        <Typography variant="body1">
          Would you like to add a CV or a profile link?
        </Typography>
      </SpeechBubble>
      <SpeechBubble>
        <FormControl
          component="fieldset"
          classes={{
            root: classes.fullWidth,
          }}
        >
          <RadioGroup
            aria-label="Do you want to an an image"
            name="do-you-want-to-add-an-image"
            value={radioValue}
            onChange={handleRadioChange}
          >
            <FormControlLabel
              value={UploadOrLink.Upload}
              control={
                <Radio
                  className={classes.radio}
                  checked={radioValue === UploadOrLink.Upload}
                />
              }
              label="Yes, I'll attach a file"
            />
            <Collapse in={radioValue === UploadOrLink.Upload}>
              <div className={classes.uploadContainer}>
                {file && file !== YesNo.No ? (
                  <Chip
                    label={file[0].name}
                    onDelete={deleteFile}
                    color="secondary"
                    variant="outlined"
                  />
                ) : (
                  <FileUpload onChange={onChangeUpload} />
                )}
              </div>
            </Collapse>
            <FormControlLabel
              value={UploadOrLink.Link}
              control={
                <Radio
                  className={classes.radio}
                  checked={radioValue === UploadOrLink.Link}
                />
              }
              label="Yes, I have a link"
            />
            <Collapse in={radioValue === UploadOrLink.Link}>
              <TextField
                inputRef={inputRef}
                type="text"
                color="secondary"
                name="profile-url"
                className={classes.textInput}
                style={{ marginTop: 0 }}
                inputProps={{
                  className: classes.textInputAutofill,
                }}
                value={current === YesNo.No ? "" : current}
                variant="filled"
                onBlur={handleLinkComplete}
                onKeyPress={(ev) => {
                  if (ev.key === "Enter") {
                    handleLinkComplete();
                    ev.preventDefault();
                  }
                }}
                onChange={handleLinkChange}
                label="Profile URL"
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="https://your-profile.com"
                InputProps={{
                  endAdornment: whiteArrow,
                }}
                error={isError && showError}
                helperText={
                  isError && showError
                    ? "That doesn't look like a valid URL"
                    : undefined
                }
              />
            </Collapse>
            <FormControlLabel
              value={YesNo.No}
              control={
                <Radio
                  className={classes.radio}
                  checked={radioValue === YesNo.No}
                />
              }
              label="I'll skip this for now"
            />
          </RadioGroup>
        </FormControl>
      </SpeechBubble>
    </>
  );
};

const Apply: React.FC<Controller> = ({ handleContinue }) => {
  const classes = useStyles();
  const [processing, setProcessing] = React.useState(false);

  const handleClick = async () => {
    setProcessing(true);
    await handleContinue();
    setProcessing(false);
  };

  return (
    <>
      <SpeechBubble fromHigher>
        <Typography paragraph variant="body1">
          That's all the questions!
        </Typography>
        <Typography variant="body1">
          Please check that everything above is correct before continuing.
        </Typography>
      </SpeechBubble>
      <Button cta onClick={handleClick} disabled={processing}>
        Continue
        {processing && (
          <LinearProgress className={classes.progress} color="secondary" />
        )}
      </Button>
    </>
  );
};

enum QuestionID {
  WhatIsYourName = 0,
  WhatIsYourEmail,
  AddAnImage,
  WhatIsYourLocation,
  HowWouldPeopleKnowYou,
  DoYouHaveALinkedIn,
  UploadCV,
  Apply,
}

const Form: React.FC<Props & State & Controller & PrefillState> = ({
  role,
  prefill,
  nameState: [name, setName],
  emailState: [email, setEmail],
  imageState: [image, setImage],
  imageURLState: [imageURL, setImageURL],
  locationState: [location, setLocation],
  titleState: [title, setTitle],
  linkedinState: [linkedin, setLinkedin],
  cvFileState: [cvFile, setCvFile],
  cvUrlState: [cvLink, setCvLink],
  handleContinue,
}) => {
  const classes = useStyles();

  const allQuestions: Array<MessageTree<QuestionID>> = [
    {
      id: QuestionID.WhatIsYourName,
      question: <WhatIsYourName role={role} value={name} setValue={setName} />,
    },
    {
      id: QuestionID.WhatIsYourEmail,
      question: (
        <WhatIsYourEmail role={role} value={email} setValue={setEmail} />
      ),
    },
    {
      id: QuestionID.AddAnImage,
      question: <AddAnImage role={role} value={image} setValue={setImage} />,
    },
    {
      id: QuestionID.WhatIsYourLocation,
      question: (
        <WhatIsYourLocation
          role={role}
          value={location}
          setValue={setLocation}
        />
      ),
    },
    {
      id: QuestionID.HowWouldPeopleKnowYou,
      question: (
        <HowWouldPeopleKnowYou role={role} value={title} setValue={setTitle} />
      ),
    },
    {
      id: QuestionID.DoYouHaveALinkedIn,
      question: (
        <DoYouHaveALinkedIn
          role={role}
          value={linkedin}
          setValue={setLinkedin}
        />
      ),
    },
    {
      id: QuestionID.UploadCV,
      question: (
        <UploadCV
          role={role}
          file={cvFile}
          setFile={setCvFile}
          link={cvLink}
          setLink={setCvLink}
        />
      ),
    },
    {
      id: QuestionID.Apply,
      question: <Apply handleContinue={handleContinue} />,
      skeleton: {
        from: true,
        button: true,
      },
    },
  ];

  const excluded: Array<QuestionID> = [];
  if (prefill.name) {
    excluded.push(QuestionID.WhatIsYourName);
  }
  if (prefill.email) {
    excluded.push(QuestionID.WhatIsYourEmail);
  }
  if (prefill.avatar) {
    excluded.push(QuestionID.AddAnImage);
  }

  const questions = allQuestions.filter((v) => {
    return !excluded.includes(v.id);
  });

  const [states, , updateStates] = useQuestionState<QuestionID>(questions);

  React.useEffect(() => {
    updateStates(!!name, QuestionID.WhatIsYourName);
  }, [name]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    updateStates(!!email, QuestionID.WhatIsYourEmail);
  }, [email]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    updateStates(!!image, QuestionID.AddAnImage);
  }, [image]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    updateStates(!!location, QuestionID.WhatIsYourLocation);
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    updateStates(!!title, QuestionID.HowWouldPeopleKnowYou);
  }, [title]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    updateStates(!!linkedin, QuestionID.DoYouHaveALinkedIn);
  }, [linkedin]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    updateStates(!!cvFile || !!cvLink, QuestionID.UploadCV);
  }, [cvFile, cvLink]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <MetaTags role={role} />
      <div className={classes.root}>
        <ScrollToBottom className={classes.scrollContainer}>
          <div className={classes.contentWrapper}>
            <HeadingCard role={role} />
            <DialogForm
              questions={questions}
              questionsState={states}
              className={classes.content}
            >
              <IntroBubble role={role} name={prefill.name || undefined} />
            </DialogForm>
          </div>
        </ScrollToBottom>
      </div>
    </>
  );
};

export const Skeleton: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <HeadingCardSkeleton />
      <Container maxWidth="md" className={classes.content}>
        <SpeechBubbleSkeleton header from to>
          <IntroBubble role={UnknownRole} />
          <WhatIsYourName role={UnknownRole} value="" setValue={() => {}} />,
        </SpeechBubbleSkeleton>
      </Container>
    </div>
  );
};

export default Form;
