import Message from "@higher/models/Message";
import Applicant from "@higher/models/Applicant";

import queryStateSubscriptionByFunc from "./queryStateSubscriptionByFunc";

export default queryStateSubscriptionByFunc<Message, Applicant>(
  "applicantsByRoleID",
  (applicant) => `organisations/${applicant.organisationId}/messages`,
  (query, applicant) => {
    return query
      .where("applicantId", "==", applicant.id)
      .orderBy("date", "asc");
  }
);
