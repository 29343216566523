import Event, { EventUserType } from "@higher/models/Event";
import Applicant from "@higher/models/Applicant";

import queryStateSubscriptionByFunc from "./queryStateSubscriptionByFunc";

export default queryStateSubscriptionByFunc<Event, Applicant>(
  "eventsByApplicant",
  (applicant) => `organisations/${applicant.organisationId}/events`,
  (query, applicant) => {
    return query
      .where("subjectType", "==", EventUserType.Applicant)
      .where("subjectId", "==", applicant.id)
      .orderBy("date", "asc");
  }
);
