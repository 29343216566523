const DATE_WITH_MONTH_THRESHOLD_IN_DAYS = 6;
const NOW_THRESHOLD_IN_SECONDS = 10;
const TODAY_AT_THRESHOLD_IN_HOURS = 12;

const MILLISECONDS_TO_SECONDS = 0.001;
const SECONDS_IN_YEAR = 31557600;
const SECONDS_IN_MONTH = 2629800;
const SECONDS_IN_DAY = 86400;
const SECONDS_IN_HOUR = 3600;
const SECONDS_IN_MINUTE = 60;

type Locale = "en";

type PluralFormKey = "1" | "2" | "3-10" | "other";

type PluralForms = {
  "1"?: string;
  "2"?: string;
  "3-10"?: string;
  other: string;
};

type Translations = {
  [locale in Locale]: {
    [key: string]: string | PluralForms;
  };
};

type DateTimeComponents = {
  years: number;
  months: number;
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

type DateFormatOptions = {
  includeYear?: boolean;
};

const translations: Translations = {
  en: {
    posted: "posted",
    "just now": "just now",
    "today at": "today at",
    yesterday: "yesterday",
    "hours ago": {
      "1": "{count} hour ago",
      other: "{count} hours ago",
    },
    "minutes ago": {
      "1": "{count} minute ago",
      other: "{count} minutes ago",
    },
    "seconds ago": {
      "1": "{count} second ago",
      other: "{count} seconds ago",
    },
  },
};

/**
 * Retrieve a localized string by key
 */
function __(key: string, locale: Locale = "en"): string {
  return translations[locale][key] as string;
}

/**
 * Retrieve a plural form for a phrase by the phrase's key
 */
function _p(key: string, count: number, locale: Locale = "en"): string {
  const forms = translations[locale][key] as PluralForms;

  const { other, ...definiteForms } = forms;

  const sortedDefiniteForms = Object.keys(definiteForms).sort();

  let hit = "";

  for (let i = 0; i < sortedDefiniteForms.length; i += 1) {
    const currentFormKey: PluralFormKey = sortedDefiniteForms[
      i
    ] as PluralFormKey;

    if (currentFormKey.includes("-")) {
      const [lowerLimit, upperLimit] = currentFormKey
        .split("-")
        .map((s) => parseInt(s.trim(), 10));

      if (lowerLimit <= count && count <= upperLimit) {
        hit = forms[currentFormKey]!;
      }
    } else if (count === parseInt(currentFormKey, 10)) {
      hit = forms[currentFormKey]!;
    }
  }

  if (hit === "") {
    hit = other || key;
  }

  const normalized: string = hit.replace("{count}", count.toString());

  return normalized;
}

function humanFriendlyDate(date: Date, currentLocale: Locale = "en"): string {
  const unixTimestamp: number = millisecondsToSeconds(date.valueOf());

  const now: number = millisecondsToSeconds(Date.now());

  const diffComponents: DateTimeComponents = getDateTimeComponents(
    now - unixTimestamp
  );

  const { years, months, days, hours, minutes, seconds } = diffComponents;

  if (years > 0) {
    return `on ${formatLocalizedDateWithOrdinal(currentLocale, date, {
      includeYear: true,
    })}`;
  }

  if (months > 0 || days > DATE_WITH_MONTH_THRESHOLD_IN_DAYS) {
    return `on ${formatLocalizedDateWithOrdinal(currentLocale, date, {
      includeYear: false,
    })}`;
  }

  if (days > 4) {
    return `last ${date.toLocaleDateString(currentLocale, {
      weekday: "long",
    })}`;
  }
  if (days > 1) {
    return `on ${date.toLocaleDateString(currentLocale, { weekday: "long" })}`;
  }

  if (days === 1 || (days === 0 && hours >= TODAY_AT_THRESHOLD_IN_HOURS)) {
    return __("yesterday");
  }

  if (hours < TODAY_AT_THRESHOLD_IN_HOURS) {
    return `${__("today at")} ${date.toLocaleTimeString(currentLocale, {
      hour: "numeric",
      minute: "2-digit",
    })}`;
  }

  if (hours > 0) {
    return _p("hours ago", hours);
  }

  if (minutes > 0) {
    return _p("minutes ago", minutes);
  }

  if (seconds > NOW_THRESHOLD_IN_SECONDS) {
    return _p("seconds ago", seconds);
  }

  return __("just now");
}

/**
 * For English, format a date with given options, adding an ordinal
 * e.g. "May 1st, 1992" (note the "1st"). For non-English locales,
 * format a date with given options (and no ordinal);
 */
function formatLocalizedDateWithOrdinal(
  locale: Locale,
  date: Date,
  options: DateFormatOptions = { includeYear: false }
) {
  if (locale.toLowerCase().startsWith("en")) {
    return formatEnglishDateWithOrdinal(date, options);
  }

  return formatNonEnglishDate(locale, date, options);
}

function formatEnglishDateWithOrdinal(
  date: Date,
  { includeYear }: DateFormatOptions
): string {
  const month: string = date.toLocaleDateString("en", { month: "long" });

  const day: string = getOrdinal(date.getDate());

  let formatted = `${month} ${day}`;

  if (includeYear) {
    formatted += `, ${date.getFullYear()}`;
  }

  return formatted;
}

function formatNonEnglishDate(
  locale: Locale,
  date: Date,
  { includeYear }: DateFormatOptions
): string {
  const options: Intl.DateTimeFormatOptions = { day: "numeric", month: "long" };

  if (includeYear) {
    options.year = "numeric";
  }

  return date.toLocaleDateString(locale, options);
}

function getOrdinal(n: number): string {
  // From https://community.shopify.com/c/Shopify-Design/Ordinal-Number-in-javascript-1st-2nd-3rd-4th/m-p/72156
  const s = ["th", "st", "nd", "rd"];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

function millisecondsToSeconds(milliseconds: number): number {
  return Math.floor(milliseconds * MILLISECONDS_TO_SECONDS);
}

function getDateTimeComponents(timestamp: number): DateTimeComponents {
  const components: DateTimeComponents = {
    years: 0,
    months: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  let remaining: number = timestamp;

  // years
  components.years = Math.floor(remaining / SECONDS_IN_YEAR);

  remaining -= components.years * SECONDS_IN_YEAR;

  // months
  components.months = Math.floor(remaining / SECONDS_IN_MONTH);

  remaining -= components.months * SECONDS_IN_MONTH;

  // days
  components.days = Math.floor(remaining / SECONDS_IN_DAY);

  remaining -= components.days * SECONDS_IN_DAY;

  // hours
  components.hours = Math.floor(remaining / SECONDS_IN_HOUR);

  remaining -= components.hours * SECONDS_IN_HOUR;

  // minutes
  components.minutes = Math.floor(remaining / SECONDS_IN_MINUTE);

  remaining -= components.minutes * SECONDS_IN_MINUTE;

  // seconds
  components.seconds = remaining;

  return components;
}

export default humanFriendlyDate;
