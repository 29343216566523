import { Typography } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import React from "react";

import Applicant from "@higher/models/Applicant";
import Role from "@higher/models/Role";
import { FormatCurrency } from "@higher/payments/currency";

import FAQs from "@app/components/FAQs";

type Props = {
  applicant: Applicant;
  role: Role;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      marginBottom: theme.spacing(4),
    },
  })
);

const Endorsements = FAQs<Props>([
  {
    title: "Why do I need endorsements?",
    summary: ({ applicant, role }) => `
Strong endorsements are the best way to be taken seriously for the role.
`,
    content: ({ applicant, role }) => `
${
  role.employer
} don't know you yet, and no matter how experienced and talented you are, taking on an unknown employee is a big risk. 

The smartest and quickest way for ${
      role.employer
    } to mitigate that risk is to base their hiring decisions on trusted recommendations.
Endorsements via Higher shortcut the trust-building process, which means that you'll get hired more quickly.

Higher endorsement are more useful to ${
      role.employer
    } than traditional references because they're *collateralised*. In other words, your
endorsers will put down between ${FormatCurrency(
      role.currency,
      role.minStake
    )} and ${FormatCurrency(
      role.currency,
      role.maxStake
    )} of their own money, which they will lose if you fail probation.

When you, ${
      role.employer
    } and your endorsers all have skin in the game, you can trust each other without knowing each other. The more
endorsements you have, the more confidence ${
      role.employer
    } will have to seriously consider you for the role.
`,
  },
  {
    title: "What do endorsers get in return?",
    summary: ({ applicant, role }) => `
They make a 100% return on their money. They'll double whatever they put in.
`,
    content: ({ applicant, role }) => `
Assuming you are hired and pass probation, anyone who endorses you will get a return in two parts:

1. We'll refund their original payment to the original payment method.
2. We'll pay the same amount to them via bank transfer.

The refund will be made as soon as your probation ends. 

The payment will be made as soon as we have their bank details. We'll ask you for those the day after your probation ends.
`,
  },

  {
    title: "How much endorsement do I need?",
    summary: ({ applicant, role }) => `
At least one endorsement is required, but the more the better!
`,
    content: ({ applicant, role }) => `
${role.employer} expect to receive a lot of applications, most of which will not progress to the interview stage. In order to filter out the noise, they'll only be following up on applications with at least one endorsement.

Though endorsements are anonymous, ${role.employer} will see a list of candidates sorted by the number of endorsers and the total value of their endorsements. If you want to at the top of their list, more endorsements will help!
`,
  },
  {
    title: "Who should I ask to endorse me?",
    summary: ({ applicant, role }) => `
Anyone who's qualified to judge your professional abilities.
`,
    content: ({ applicant, role }) => `
References are usually given by employers, colleagues, clients, teachers or certification bodies. Almost anyone who has a good sense of your work and a reasonable grasp of your industry should be able to endorse you. You can also endorse yourself.

All endorsements are anonymous (unless the endorser chooses otherwise) and endorsers can choose the amount of money they put down. There are no bad endorsements: each one is a strong signal to ${role.employer} that you're the sort of person for the role.
`,
  },

  {
    title: "Are endorsements anonymous?",
    summary: ({ applicant, role }) => `
Yes, though of course endorsers are free to reveal their endorsements if they want to.
`,
    content: ({ applicant, role }) => `
During the endorsement process, we only ask for the minimum identifying information required by financial processors and regulatory law. There is no public display of who has endorsed whom, or what payments have been made. 

Individual endorsers may, at their own discretion, choose to disclose their endorsements either privately or publicly. 

${role.employer} will see a list of candidates sorted by the number of endorsers and the total value of their endorsements, but they won't know who the endorsers are. When we pay endorsement rewards, we don't publicly disclose any of the details.

We take privacy very seriously. Please see our [privacy statement](/privacy) for more details.
`,
  },

  {
    title: "What's wrong with traditional recommendations and references?",
    summary: ({ applicant, role }) => `They're weak signals.`,
    content: ({ applicant, role }) => `
Smart employers like ${role.employer} don't base their hiring decisions on untrusted recommendations. 

It's normal to recommend friends and colleagues because you wish them well.
It's easy to exaggerate their abilities, and there are no consequences for
doing so. It's even possible to fabricate references, and it's often
prohibitively time-consuming to verify them.  

Higher endorsements should not be given flippantly. They are strong signals of confidence in your abilities, which ${role.employer} can use in the hiring process.
`,
  },

  {
    title: "Can I endorse myself?",
    summary: ({ applicant, role }) => `
Yes, absolutely.
`,
    content: ({ applicant, role }) => `
Your endorsement of yourself is as valuable as anyone else's. It's a strong, clear signal of confidence in your own abilities.

If you do you endorse yourself, you stand to make the same return as any other endorser &mdash; and if you're hired and fail probation, you'll lose your payment.
`,
  },

  {
    title: "Can endorsements be withdrawn?",
    summary: ({ applicant, role }) => `Yes, until ${role.employer} hire you.`,
    content: ({ applicant, role }) => `
Any endorser may cancel anytime until you are hired. After that, their money will be held in escrow until you pass probation, fail probation, or your employment is terminated.
`,
  },
]);

const Process = FAQs<Props>([
  {
    title: "What happens if I withdraw from the application process?",
    summary: ({ applicant, role }) => `
We'll refund any endorsement payments.
`,
    content: ({ applicant, role }) => `
If you decide that you don't want to pursue the role for any reason, you may withdraw at any time without penalty.
	
If you have endorsements, we'll refund all endorsement payments.
`,
  },
  {
    title: "What happens when I get hired?",
    summary: ({ applicant, role }) => `
All endorsement payments will be placed in escrow, and you'll begin probation.
`,
    content: ({ applicant, role }) => `
Once ${role.employer} have made an offer, we'll put all endorsement payments into escrow. 

You'll have 30 days to negotiate at start date with ${role.employer}. If you haven't arranged to start work by then, we'll assume you're not planning on working with ${role.employer} unless you or ${role.employer} tell us otherwise.

Your probation at ${role.employer} begins on your first day of work. The probation period for this role is **${role.probation} days**.

**If you complete probation** we'll refund all endorsers' payments and pay them their endorsement rewards. They'll get a 100% return.

**If you fail probation due to a lack of skills or experience** we'll donate all endorsement payments to charity.

**If ${role.employer} let you go for any other reason** we'll refund all endorsement payments and pay endorsers a partial endorsement reward.

**If you quit before probation ends** we'll refund all endorsement payments.
`,
  },
  {
    title: "What happens if I don't get hired?",
    summary: ({ applicant, role }) => `
We'll refund all endorsement payments.
`,
    content: ({ applicant, role }) => `
If ${role.employer} decide to hire someone else, or decide they don't want to hire anyone for the role, we'll refund all endorsement payments. This may happen before or after any interviews.
`,
  },
  {
    title: "What happens if I fail probation?",
    summary: ({ applicant, role }) => `
We'll donate all endorsement payments to charity, unless you successfully appeal the termination.
`,
    content: ({ applicant, role }) => `
If ${role.employer} decide to fail you during probation due to a lack of skills or experience, they'll have to tell us why and provide evidence. We'll tell you the reason, and if you don't agree then you may appeal the decision with Higher.

**If you don't appeal or the appeal is overturned** we'll donate all endorsement payments to charity.

**If you successfully appeal the termination** we'll refund all endorsement payments, and we'll either pay all endorsers a full or partial reward. 

`,
  },
  {
    title: "What happens if I quit before the end of probation?",
    summary: ({ applicant, role }) => `
We'll refund all endorsement payments.
`,
    content: ({ applicant, role }) => `
There's more to work than appropriate qualifications and experience. If you want to resign your position for any reason, you're free to do so without penalty.

If you do resign, we'll refund all endorsement payments.
`,
  },
]);

const Payments = FAQs<Props>([
  {
    title: "Do endorsers have to make a payment immediately?",
    summary: ({ applicant, role }) => `
Yes.
`,
    content: ({ applicant, role }) => `
Endorsements only carry weight if the endorser has skin in the game. ${
      role.employer
    } need strong, clear signals of confidence &mdash; and that means endorsers putting something valuable at risk. 

For this role, endorsers must put down between ${FormatCurrency(
      role.currency,
      role.minStake
    )} and ${FormatCurrency(role.currency, role.maxStake)} of their own money.

We take payment via Stripe, and we accept all major payment methods. We'll issue a payment receipt, and endorsers may cancel their endorsement any time before you are hired.

If you are hired, then you'll enter probation. We'll put all endorsement funds into escrow until the process is complete.
`,
  },

  {
    title: "How long will endorsement payments be tied up?",
    summary: ({ applicant, role }) => `
Assuming you are hired and passes probation, **${role.probation + 10}-${
      role.probation + 70
    } days**.
`,
    content: ({ applicant, role }) => `
It depends on what happens with you and ${role.employer}.

**If you are not hired**, either because ${
      role.employer
    } hire someone else or don't hire anyone, then all money will be refunded immediately.

**If you are hired**, then all endorsement payments will be held in escrow until your probation ends or either party terminate the employment early.

There are four factors that will influence the total duration of your money being held:

1. The interviewing process takes some time. Higher makes things much quicker, but we'd still expect it to take about **10 days** on average.
1. Once hired, you and ${
      role.employer
    } have up **30 days** to negotiate a start date.
2. If you have to give notice at your current place of employment. This varies, but it's typically around **30 days**.
3. The probation period for this role is **${
      role.probation
    } days**, which will begin on your start date.

Adding that all up, if someone endorses you today, they're looking at a potential **100% return** in **${
      role.probation + 10
    } - ${role.probation + 70} days**, assuming you pass probation.

**If you fail probation** all endorsement payments will be donated to charity.

**If either party terminates the employment early and it's not your fault**, then we'll refund all endorsement payments immediately, and we may pay endorsers a pro-rata reward.
`,
  },
  {
    title: "Where will endorsement payments be held during escrow?",
    summary: ({ applicant, role }) => `
We use Stripe as our escrow provider.
`,
    content: ({ applicant, role }) => `
**If ${role.employer} hire you** then we'll put all endorsement payments into escrow. We will use [Stripe Payments Europe Ltd](http://www.stripe.com), a regulated third-party service, as our escrow provider.
`,
  },

  {
    title: "What happens if I don't agree with a termination?",
    summary: ({ applicant, role }) => `
If you're hired and fail probation, we'll give you the chance to appeal.
`,
    content: ({ applicant, role }) => `
If ${role.employer} decide to fail you during probation due to a lack of skills or experience, they'll have to tell us why and provide evidence. We'll tell you the reason, and if you don't agree then you may appeal the decision.

We can't do much about you failing probation, but we will give all your endorsers their money back if we find that the firing was unjustified. We may also pay them a pro-rata reward.
`,
  },
  {
    title: "Is there a pro-rata return?",
    summary: ({ applicant, role }) => `
Yes, if ${role.employer} end your probation early or unjustly.
`,
    content: ({ applicant, role }) => `
We'll pay you a partial reward if you have worked at ${
      role.employer
    } for more than 14 days and either:

- ${role.employer} end your employment early and it's not your fault, or
- ${
      role.employer
    } decide to fail you during probation and you successfully appeal the termination

For example, let's say someone endorses you for £100. If ${
      role.employer
    } end your employment after ${
      role.probation / 2
    } days (half way through probation) we'll pay the endorser a partial endorsement reward of £50.
`,
  },
  {
    title: "Which charities do you give to?",
    summary: ({ applicant, role }) => `
We give to the Effective Altruism Funds.
`,
    content: ({ applicant, role }) => `
**If you fail probation at ${role.employer} due to a lack of skills and/or experience** we'll donate your payment to charity.

We'll split the money between the 4 main [Effective Altruism Funds](https://app.effectivealtruism.org/funds). 

Effective Altruism Funds operate like a mutual funds, except they maximise the effectiveness of  donations, rather than investment returns. The programme is administered by the  [Centre for Effective Altruism](https://www.centreforeffectivealtruism.org/), a 501(c)(3) tax-exempt organisation in the US, and a registered charity in England and Wales.
`,
  },
]);

const CombinedFAQs: React.FC<Props> = (props) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.section}>
        <Typography variant="h6" component="h6" paragraph>
          Endorsements
        </Typography>
        <Endorsements {...props} />
      </div>

      <div className={classes.section}>
        <Typography variant="h6" component="h6" paragraph>
          Process
        </Typography>
        <Process {...props} />
      </div>

      <div className={classes.section}>
        <Typography variant="h6" component="h6" paragraph>
          Payments
        </Typography>
        <Payments {...props} />
      </div>
    </>
  );
};

export default CombinedFAQs;
