export enum ApplicationStatus {
  Pending = "pending", // Waiting for employeer to view
  Withdrawn = "withdrawn", // Application has been withdraw by applicant
  Removed = "removed", // Application has been removed by employer
}

export enum RemovalReason {
  Unresponsive = "unresponsive",
  NotQualified = "not qualified",
  FailedInterview = "failed interview",
  DidntShowUp = "didn't show up",
  NotACulturalFit = "not a cultural fit",
  AcceptedAnotherOffer = "candidate accepted another offer",
  NotInterested = "not interested",
  BadTiming = "bad timing",
  Quit = "candidate quit",
  FiredForMisconduct = "misconduct",
  NotARealPerson = "not a real person",
  PartedWays = "mutually agreed to part ways",
}

export type Connection = {
  label: string;
  url: string;
};

export type Applicant = {
  id: string;
  status: ApplicationStatus;
  removalReason?: RemovalReason;
  roleId: string;
  organisationId: string;
  endorsements: number;
  rank: number;
  name: string;
  firstName: string;
  avatar: string;
  socialSharingImage: string;
  location: string;
  history?: Array<string>;
  connections?: Array<Connection>;
  shortLink?: string;
  stageId: string;
  stageIndex?: number;
};

export const UnknownApplicant: Applicant = {
  id: "",
  status: ApplicationStatus.Pending,
  roleId: "",
  organisationId: "",
  endorsements: 0,
  stageId: "applied",
  rank: 0,
  name: "unknown",
  avatar: "",
  socialSharingImage: "",
  firstName: "unknown",
  location: "unknown",
  history: ["unknown @ unknown"],
  connections: [{ label: "unknown", url: "https://unknown.com" }],
};

export default Applicant;
