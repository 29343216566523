import { Typography } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";
import React from "react";

import TrackedSection from "@app/components/TrackedSection";

type Props = {
  title: string;
  hideTitle?: boolean;
  noPadding?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(4),
      padding: theme.spacing(2),
    },
    noMarginTop: {
      marginTop: theme.spacing(0),
    },
    noPadding: {
      padding: 0,
    },
  })
);

export const Section: React.FC<Props> = ({
  title,
  hideTitle,
  noPadding,
  children,
}) => {
  const classes = useStyles();
  const classNames = [classes.root];
  if (hideTitle) {
    classNames.push(classes.noMarginTop);
  }
  if (noPadding) {
    classNames.push(classes.noPadding);
  }
  return (
    <TrackedSection trackingId={title}>
      <section className={classNames.join(" ")}>
        {!hideTitle && (
          <Typography variant="h5" component="h1" paragraph>
            {title}
          </Typography>
        )}
        {children}
      </section>
    </TrackedSection>
  );
};

export const SectionSkeleton: React.FC<Props> = ({
  title,
  hideTitle,
  noPadding,
  children,
}) => {
  const classes = useStyles();
  const classNames = [classes.root];
  if (hideTitle) {
    classNames.push(classes.noMarginTop);
  }
  if (noPadding) {
    classNames.push(classes.noPadding);
  }
  return (
    <TrackedSection trackingId={title}>
      <section className={classNames.join(" ")}>
        {!hideTitle && (
          <Skeleton variant="rect">
            <Typography variant="h5" component="h1" paragraph>
              {title}
            </Typography>
          </Skeleton>
        )}
        {children}
      </section>
    </TrackedSection>
  );
};

export default Section;
