import {
  Container,
  Collapse,
  Checkbox,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  IconButton,
  TextField,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import React from "react";
import NumberFormat from "react-number-format";
import ScrollToBottom from "react-scroll-to-bottom";

import Applicant, { UnknownApplicant } from "@higher/models/Applicant";
import Role, { UnknownRole } from "@higher/models/Role";
import { Currency, FormatCurrency } from "@higher/payments/currency";
import { PaymentMethod } from "@higher/payments/methods";

import { Keyword, Images, UpArrow } from "@app/brand";
import DialogForm, {
  MessageTree,
  useQuestionState,
} from "@app/components/DialogForm";
import SpeechBubble, {
  SpeechBubbleSkeleton,
} from "@app/components/SpeechBubble";
import { YesNo, YesOrNo, YesNoOrUndefined } from "@app/helpers/YesNo";
import useMountEffect from "@app/hooks/useMountEffect";
import useTracking from "@app/hooks/useTracking";
import { MakeDemoPaymentFn } from "@app/payments/demo";
import { MakeCandidateEndorsementPaymentFn } from "@app/payments/stripe";

import ApplicantCard from "./ApplicantCard";
import CoinbaseCard from "./CoinbaseCard";
import HeadingCard, { HeadingCardSkeleton } from "./HeadingCard";
import MetaTags from "./MetaTags";
import PaymentCard from "./PaymentCard";
import PaymentCardDemo from "./PaymentCardDemo";
import RoleCard from "./RoleCard";
import TermsCard from "./TermsCard";

type Props = {
  applicant: Applicant;
  role: Role;
  self?: boolean;
  emailPrefill?: string;
};

type PropsWithState = Props & {
  makeCandidateEndorsementPayment: MakeCandidateEndorsementPaymentFn;
  makeDemoPayment: MakeDemoPaymentFn;
  next: (id: string) => void;
};

type QuestionProps<T = string> = Props & {
  value: T | undefined;
  setValue: (valid: T) => void;
  self?: boolean;
};

type WithAmount<T> = T & {
  amount: number;
};

type SelfSwitchProps = {
  self?: boolean;
  isSelf: JSX.Element;
  notSelf: JSX.Element;
};

const useStyles = makeStyles((theme: Theme) => {
  const arrows = Images.BrandarrowsBackgroundImageTrans;
  const leftPositionX = `calc( ((100vw - ${theme.breakpoints.values.md}px)/2) - 75vh )`;
  const rightPositionX = `calc( ((100vw - ${theme.breakpoints.values.md}px)/2) + ${theme.breakpoints.values.md}px )`;

  return createStyles({
    root: {},
    content: {
      paddingTop: "2em",
      paddingBottom: "2em",
    },
    contentWrapper: {
      backgroundImage: `url(${arrows}),url(${arrows})`,
      backgroundSize: "75vh auto",
      backgroundRepeat: "no-repeat",
      backgroundPositionX: `${leftPositionX},${rightPositionX}`,
      backgroundPositionY: `${theme.spacing(8)}px, ${theme.spacing(18)}px`,
      minHeight: "calc(var(--vh, 1vh) * 100)",
    },
    scrollContainer: {
      position: "fixed",
      height: "calc(var(--vh, 1vh) * 100)",
    },
    header: {
      color: "white",
      lineHeight: "1.3em",
    },
    radio: {
      color: "white",
    },
    customConfidence: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      borderColor: "white",
      "& input, & p": {
        color: "white",
      },

      "& label": {
        color: "#ddd",
      },
    },
    emailInput: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      borderColor: "white",
      "& input, & p": {
        color: "white",
      },

      "& label": {
        color: "#ddd",
      },
    },
    emailInputAutofill: {
      "&:-webkit-autofill": {
        WebkitBoxShadow: `0 0 0 1000px ${theme.palette.primary.light} inset`,
        WebkitTextFillColor: "white",
        borderRadius: 0,
      },
    },

    iconButton: {
      color: "white",
      "& img": {
        height: "1em",
      },
    },
    input: {
      backgroundColor: "white",
      width: "100%",
      padding: "0.5em",
      paddingLeft: "1em",
      borderRadius: "0.5em",
    },
    error: {
      marginTop: "1em",
    },
    cta: {
      marginTop: "3em",
    },
  });
});

const SelfSwitch: React.FC<SelfSwitchProps> = ({ self, isSelf, notSelf }) =>
  self ? isSelf : notSelf;

const IntroBubble: React.FC<Props> = ({ applicant, role, self }) => {
  const classes = useStyles();
  const questionCount = self ? 4 : 5;
  return (
    <SpeechBubble header>
      <SelfSwitch
        self={self}
        isSelf={
          <Typography paragraph className={classes.header} variant="h6">
            Let's get you endorsed to <Keyword>{role.employer}</Keyword>!
          </Typography>
        }
        notSelf={
          <Typography paragraph className={classes.header} variant="h6">
            Let's get <Keyword>{applicant.firstName}</Keyword> hired at{" "}
            <Keyword>{role.employer}</Keyword>!
          </Typography>
        }
      />

      <Typography variant="body1">
        We have {questionCount} questions for you, then we'll ask for a payment.
      </Typography>
    </SpeechBubble>
  );
};

const DoYouKnowApplicant: React.FC<QuestionProps<YesOrNo>> = ({
  applicant,
  value,
  setValue,
}) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value as YesOrNo);
  };
  return (
    <>
      <SpeechBubble
        fromHigher
        attachment={<ApplicantCard applicant={applicant} />}
      >
        <Typography variant="body1">
          First, do you know {applicant.firstName}?
        </Typography>
      </SpeechBubble>

      <SpeechBubble>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="do-you-know"
            name="do-you-know"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel
              value={YesNo.Yes}
              control={<Radio className={classes.radio} />}
              label="Yes"
            />
            <FormControlLabel
              value={YesNo.No}
              control={<Radio className={classes.radio} />}
              label="No"
            />
          </RadioGroup>
        </FormControl>
      </SpeechBubble>
    </>
  );
};

const DontKnowApplicant: React.FC<Props> = ({ applicant }) => {
  const classes = useStyles();
  return (
    <Alert className={classes.error} severity="warning">
      <Typography paragraph>
        You shouldn't endorse someone you don't know in a professional capacity.
      </Typography>
      <Typography>
        If you don't know {applicant.firstName} well enough to judge their
        ability to be successful in a new role, then you shouldn't continue with
        the endorsement process.
      </Typography>
    </Alert>
  );
};

const DoTheyhaveTheSkills: React.FC<QuestionProps<YesOrNo>> = ({
  applicant,
  role,
  value,
  setValue,
  self,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value as YesOrNo);
  };
  const classes = useStyles();
  return (
    <>
      <SpeechBubble
        fromHigher
        attachment={<RoleCard open applicant={applicant} role={role} />}
      >
        <SelfSwitch
          self={self}
          isSelf={
            <Typography variant="body1">
              Do you have the skills and experience for this position?
            </Typography>
          }
          notSelf={
            <Typography variant="body1">
              Does {applicant.firstName} have the skills and experience for this
              position?
            </Typography>
          }
        />
      </SpeechBubble>
      <SpeechBubble>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="Do they have the skills?"
            name="do-they-have-the-skills"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel
              value={YesNo.Yes}
              control={<Radio className={classes.radio} />}
              label="Yes"
            />
            <FormControlLabel
              value={YesNo.No}
              control={<Radio className={classes.radio} />}
              label="No"
            />
          </RadioGroup>
        </FormControl>
      </SpeechBubble>
    </>
  );
};

const ApplicantDoesntHaveSkills: React.FC<Props> = ({ applicant, self }) => {
  const classes = useStyles();
  return (
    <Alert className={classes.error} severity="warning">
      <SelfSwitch
        self={self}
        isSelf={
          <Typography>
            You shouldn't continue with the application process, or this
            endorsement, if you're not qualified for the role.
          </Typography>
        }
        notSelf={
          <>
            <Typography paragraph>
              You shouldn't endorse someone who isn't qualified.
            </Typography>
            <Typography>
              If {applicant.firstName} doesn't have the skills and experience
              for this position, then you shouldn't continue with the
              endorsement process.
            </Typography>
          </>
        }
      />
    </Alert>
  );
};

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

type NumberFormatFn = (props: NumberFormatCustomProps) => any;

const NumberFormatCustom =
  (currency: Currency): NumberFormatFn =>
  (props: NumberFormatCustomProps) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
        allowNegative={false}
        decimalScale={2}
        prefix={FormatCurrency(currency)}
      />
    );
  };

const HowConfidentAreYou: React.FC<QuestionProps<number>> = ({
  role,
  applicant,
  value,
  setValue,
  self,
}) => {
  const classes = useStyles();
  const [customValue, setCustomValue] = React.useState<number | undefined>(
    value
  );
  const [error, setError] = React.useState<string | undefined>(undefined);
  const inputComponent = React.useRef(NumberFormatCustom(role.currency) as any);
  const roundToNearest5 = (x: number) => Math.round(x / 5) * 5;

  const ranges = [
    { amount: role.minStake, label: "Fairly confident" },
    {
      amount: roundToNearest5(
        role.minStake + (role.maxStake - role.minStake) / 2
      ),
      label: "Very confident",
    },
    { amount: role.maxStake, label: "Completely confident" },
  ];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(parseInt((event.target as HTMLInputElement).value));
  };

  const handleCustomValueChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setError(undefined);
    setCustomValue(parseInt((event.target as HTMLInputElement).value));
  };

  const setFinalValue = () => {
    if (!customValue) {
      return;
    }
    if (customValue < ranges[0].amount) {
      setError(
        `Minimum endorsement is ${FormatCurrency(
          role.currency,
          ranges[0].amount
        )}`
      );
      return;
    }
    if (customValue > ranges[2].amount) {
      setError(
        `Maximum endorsement is ${FormatCurrency(
          role.currency,
          ranges[2].amount
        )}`
      );
      return;
    }

    inputRef.current?.blur();
    setValue(customValue);
    setError(undefined);
  };

  const inputRef = React.useRef<HTMLInputElement>();

  return (
    <>
      <SpeechBubble fromHigher>
        <Typography paragraph={!self} variant="body1">
          How confident are you?
        </Typography>
        {!self && (
          <Typography variant="body1">
            The amount you're willing to stake should reflect your confidence in{" "}
            {applicant.firstName}'s abilities.
          </Typography>
        )}
      </SpeechBubble>
      <SpeechBubble>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="how-confident-are-you"
            name="how-confident-are-you"
            value={`${value}`}
            onChange={handleChange}
          >
            {ranges.map((range) => (
              <FormControlLabel
                value={`${range.amount}`}
                control={<Radio className={classes.radio} />}
                label={`${FormatCurrency(role.currency, range.amount)} - ${
                  range.label
                }`}
              />
            ))}
          </RadioGroup>
          <TextField
            inputRef={inputRef}
            color="secondary"
            value={customValue}
            className={classes.customConfidence}
            onChange={handleCustomValueChange}
            onBlur={setFinalValue}
            onKeyPress={(ev) => {
              if (ev.key === "Enter") {
                setFinalValue();
                ev.preventDefault();
              }
            }}
            InputProps={{
              inputComponent: inputComponent.current,
              endAdornment: (
                <IconButton
                  type="submit"
                  className={classes.iconButton}
                  onClick={setFinalValue}
                  aria-label="search"
                >
                  <img
                    src={UpArrow.ArrowRightWhite}
                    alt="Continue with this amount"
                  />
                </IconButton>
              ),
            }}
            label="Custom confidence"
            variant="filled"
            error={!!error}
            helperText={error}
          />
        </FormControl>
      </SpeechBubble>
    </>
  );
};

const DoYouUnderstandTheTerms: React.FC<WithAmount<QuestionProps<boolean>>> = ({
  applicant,
  role,
  amount,
  value,
  setValue,
  self,
}) => {
  const classes = useStyles();
  const handleChange = (event: React.ChangeEvent<{}>, checked: boolean) => {
    setValue(checked);
  };

  const attachment = (
    <TermsCard applicant={applicant} role={role} amount={amount} self={self} />
  );
  return (
    <>
      <SpeechBubble fromHigher attachment={attachment}>
        <SelfSwitch
          self={self}
          notSelf={
            <>
              <Typography paragraph variant="body1">
                By paying{" "}
                <Keyword>{FormatCurrency(role.currency, amount)}</Keyword> now
                to endorse {applicant.firstName}, you will receive{" "}
                <Keyword>{FormatCurrency(role.currency, amount * 2)}</Keyword>{" "}
                if {applicant.firstName} is hired and passes probation.
              </Typography>
              <Typography paragraph variant="body1">
                If {applicant.firstName} is hired and fails probation, your
                payment of{" "}
                <Keyword>{FormatCurrency(role.currency, amount)}</Keyword> will
                be donated to charity.
              </Typography>
              <Typography variant="body1">
                If {applicant.firstName} is not hired then your payment of{" "}
                <Keyword>{FormatCurrency(role.currency, amount)}</Keyword> will
                be refunded.
              </Typography>
            </>
          }
          isSelf={
            <>
              <Typography paragraph variant="body1">
                By paying{" "}
                <Keyword>{FormatCurrency(role.currency, amount)}</Keyword> now
                to endorse yourself, you will receive{" "}
                <Keyword>{FormatCurrency(role.currency, amount * 2)}</Keyword>{" "}
                if you are hired and pass probation.
              </Typography>
              <Typography paragraph variant="body1">
                If you are hired and fail probation, your payment of{" "}
                <Keyword>{FormatCurrency(role.currency, amount)}</Keyword> will
                be donated to charity.
              </Typography>
              <Typography variant="body1">
                If you are not hired then your payment of{" "}
                <Keyword>{FormatCurrency(role.currency, amount)}</Keyword> will
                be refunded.
              </Typography>
            </>
          }
        />
      </SpeechBubble>
      <SpeechBubble>
        <FormControl component="fieldset">
          <FormControlLabel
            control={<Checkbox className={classes.radio} checked={value} />}
            label="I accept the terms and conditions"
            onChange={handleChange}
          />
        </FormControl>
      </SpeechBubble>
    </>
  );
};

const validEmail =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const WhatIsYourEmail: React.FC<QuestionProps<string | undefined>> = ({
  applicant,
  value,
  setValue,
  self,
  emailPrefill,
}) => {
  const classes = useStyles();
  const [isValid, setIsValid] = React.useState(!!emailPrefill);
  const [isError, setIsError] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [current, setCurrent] = React.useState(value || emailPrefill);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const v = (event.target as HTMLInputElement).value;
    setCurrent(v);
    if (validEmail.test(v)) {
      setShowError(false);
      setIsValid(true);
      setIsError(false);
    } else {
      setIsError(true);
      setValue(undefined);
      setIsValid(false);
    }
  };

  const handleComplete = () => {
    if (isValid) {
      inputRef.current?.blur();
      setValue(current);
      return;
    }
    setShowError(true);
  };

  const inputRef = React.useRef<HTMLInputElement>();

  return (
    <>
      <SpeechBubble fromHigher>
        <Typography paragraph variant="body1">
          What's your email address?
        </Typography>
        <SelfSwitch
          self={self}
          notSelf={
            <>
              <Typography variant="body1">
                We need your email address so we can keep you up to date on{" "}
                {applicant.firstName}'s progress. We'll also use this to send
                you a payment receipt.
              </Typography>
            </>
          }
          isSelf={
            <Typography variant="body1">
              We need your email address so we can send you a payment receipt.
            </Typography>
          }
        />
      </SpeechBubble>
      <SpeechBubble>
        <TextField
          inputRef={inputRef}
          type="email"
          color="secondary"
          name="email"
          className={classes.emailInput}
          inputProps={{ className: classes.emailInputAutofill }}
          value={current}
          variant="filled"
          onBlur={handleComplete}
          onKeyPress={(ev) => {
            if (ev.key === "Enter") {
              handleComplete();
              ev.preventDefault();
            }
          }}
          onChange={handleChange}
          label="Your email address"
          InputProps={{
            endAdornment: (
              <IconButton
                type="submit"
                className={classes.iconButton}
                onClick={handleComplete}
                aria-label="search"
              >
                <img
                  src={UpArrow.ArrowRightWhite}
                  alt="Continue with this email address"
                />
              </IconButton>
            ),
          }}
          error={isError && showError}
          helperText={
            isError && showError
              ? "Please enter a valid email address"
              : undefined
          }
        />
      </SpeechBubble>
    </>
  );
};

type CheckoutFormProps = WithAmount<PropsWithState> & {
  email: string;
};

const CheckoutForm: React.FC<CheckoutFormProps> = ({
  applicant,
  role,
  amount,
  email,
  makeCandidateEndorsementPayment,
  makeDemoPayment,
  next,
}) => {
  const [radioValue, setRadioValue] = React.useState<PaymentMethod | undefined>(
    role.paymentMethods && role.paymentMethods.length === 1
      ? role.paymentMethods[0]
      : undefined
  );

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    setRadioValue((event.target as HTMLInputElement).value as PaymentMethod);
  };

  const isDemo = applicant.id === "demo";

  const paymentButton = isDemo ? (
    <PaymentCardDemo
      applicant={applicant}
      amount={amount}
      makeDemoPayment={makeDemoPayment}
      next={next}
    />
  ) : (
    <PaymentCard
      applicant={applicant}
      role={role}
      amount={amount}
      email={email}
      makeCandidateEndorsementPayment={makeCandidateEndorsementPayment}
      next={next}
    />
  );

  return (
    <>
      <SpeechBubble fromHigher>
        <Typography paragraph variant="body1">
          Almost done!
        </Typography>
        <Typography variant="body1">
          Now we just need to take payment for{" "}
          <Keyword>{FormatCurrency(role.currency, amount)}</Keyword>.
        </Typography>
      </SpeechBubble>

      <SpeechBubble fullWidth>
        <RadioGroup
          aria-label="Choose payment method"
          name="choose-payment-methods"
          value={radioValue}
          onChange={handleRadioChange}
        >
          {role.paymentMethods.includes(PaymentMethod.Stripe) && (
            <>
              <FormControlLabel
                value={PaymentMethod.Stripe}
                control={
                  <Radio checked={radioValue === PaymentMethod.Stripe} />
                }
                label="Pay with card"
              />
              <Collapse in={radioValue === PaymentMethod.Stripe}>
                {paymentButton}
              </Collapse>
            </>
          )}

          {role.paymentMethods.includes(PaymentMethod.Coinbase) && (
            <>
              <FormControlLabel
                value={PaymentMethod.Coinbase}
                control={
                  <Radio checked={radioValue === PaymentMethod.Coinbase} />
                }
                label="Pay with crypto"
              />
              <Collapse in={radioValue === PaymentMethod.Coinbase}>
                <CoinbaseCard
                  applicant={applicant}
                  role={role}
                  email={email}
                  amount={amount}
                />
              </Collapse>
            </>
          )}
        </RadioGroup>
      </SpeechBubble>
    </>
  );
};

enum QuestionID {
  DoYouKnowApplicant = 0,
  DoTheyhaveTheSkills,
  HowConfidentAreYou,
  DoYouUnderstandTheTerms,
  WhatIsYourEmail,
  Payment,
}

const QuestionsChat: React.FC<PropsWithState> = ({
  applicant,
  role,
  self,
  emailPrefill,
  makeCandidateEndorsementPayment,
  makeDemoPayment,
  next,
}) => {
  const classes = useStyles();
  const { tracking, logCheckoutStep } = useTracking();
  useMountEffect(() => {
    tracking.logEvent("begin_checkout", {
      currency: role.currency,
      items: [
        {
          id: applicant.id,
          name: applicant.name,
        },
      ],
    });
  });

  const [knowsApplicant, setKnowsApplicant] = React.useState<
    YesOrNo | undefined
  >(undefined);
  const [hasSkills, setHasSkills] = React.useState<YesOrNo | undefined>(
    undefined
  );
  const [amount, setAmount] = React.useState<number | undefined>(undefined);
  const [terms, setTerms] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string | undefined>(undefined);

  const allQuestions: Array<MessageTree<QuestionID>> = [
    {
      id: QuestionID.DoYouKnowApplicant,
      question: (
        <DoYouKnowApplicant
          applicant={applicant}
          role={role}
          value={knowsApplicant}
          setValue={setKnowsApplicant}
        />
      ),
      invalidResponse: <DontKnowApplicant applicant={applicant} role={role} />,
    },
    {
      id: QuestionID.DoTheyhaveTheSkills,
      question: (
        <DoTheyhaveTheSkills
          applicant={applicant}
          role={role}
          value={hasSkills}
          setValue={setHasSkills}
          self={self}
        />
      ),
      invalidResponse: (
        <ApplicantDoesntHaveSkills
          applicant={applicant}
          role={role}
          self={self}
        />
      ),
      skeleton: {
        from: true,
        attachment: true,
        to: true,
      },
    },
    {
      id: QuestionID.HowConfidentAreYou,
      question: (
        <HowConfidentAreYou
          applicant={applicant}
          role={role}
          value={amount}
          setValue={setAmount}
          self={self}
        />
      ),
    },
    {
      id: QuestionID.DoYouUnderstandTheTerms,
      question: (
        <DoYouUnderstandTheTerms
          applicant={applicant}
          role={role}
          value={terms}
          setValue={setTerms}
          amount={amount || 0}
          self={self}
        />
      ),
      skeleton: {
        from: true,
        attachment: true,
        to: true,
      },
    },
    {
      id: QuestionID.WhatIsYourEmail,
      question: (
        <WhatIsYourEmail
          applicant={applicant}
          role={role}
          value={email}
          setValue={setEmail}
          self={self}
          emailPrefill={emailPrefill}
        />
      ),
    },
    {
      id: QuestionID.Payment,
      question: (
        <CheckoutForm
          applicant={applicant}
          role={role}
          amount={amount || 0}
          email={email || ""}
          self={self}
          makeCandidateEndorsementPayment={makeCandidateEndorsementPayment}
          makeDemoPayment={makeDemoPayment}
          next={next}
        />
      ),
      skeleton: {
        from: true,
        to: true,
        button: true,
      },
    },
  ];

  let questions: Array<MessageTree<QuestionID>>;

  if (self) {
    const exclude = [QuestionID.DoYouKnowApplicant];
    questions = allQuestions.filter((v) => {
      return !exclude.includes(v.id);
    });
  } else {
    questions = allQuestions;
  }

  const [states, , updateStates] = useQuestionState<QuestionID>(questions);

  React.useEffect(() => {
    if (knowsApplicant === YesNo.Yes) {
      logCheckoutStep(QuestionID.DoYouKnowApplicant + 1);
    }

    updateStates(
      YesNoOrUndefined(knowsApplicant),
      QuestionID.DoYouKnowApplicant
    );
  }, [knowsApplicant]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (hasSkills === YesNo.Yes) {
      logCheckoutStep(QuestionID.DoTheyhaveTheSkills + 1);
    }

    updateStates(YesNoOrUndefined(hasSkills), QuestionID.DoTheyhaveTheSkills);
  }, [hasSkills]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (amount) {
      logCheckoutStep(QuestionID.HowConfidentAreYou + 1, amount);
    }
    updateStates(!!amount, QuestionID.HowConfidentAreYou);
  }, [amount]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (terms) {
      logCheckoutStep(QuestionID.DoYouUnderstandTheTerms + 1);
    }
    updateStates(terms, QuestionID.DoYouUnderstandTheTerms);
  }, [terms]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (email) {
      logCheckoutStep(QuestionID.WhatIsYourEmail + 1);
    }
    updateStates(!!email, QuestionID.WhatIsYourEmail);
  }, [email]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <MetaTags applicant={applicant} role={role} />
      <div className={classes.root}>
        <ScrollToBottom className={classes.scrollContainer}>
          <div className={classes.contentWrapper}>
            <HeadingCard applicant={applicant} role={role} />
            <DialogForm
              questions={questions}
              questionsState={states}
              className={classes.content}
            >
              <IntroBubble applicant={applicant} role={role} self={self} />
            </DialogForm>
          </div>
        </ScrollToBottom>
      </div>
    </>
  );
};

export const QuestionsSkeleton: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <HeadingCardSkeleton />
      <Container maxWidth="md" className={classes.content}>
        <SpeechBubbleSkeleton header from to>
          <IntroBubble applicant={UnknownApplicant} role={UnknownRole} />
          <DoYouKnowApplicant
            applicant={UnknownApplicant}
            role={UnknownRole}
            value={YesNo.No}
            setValue={() => {}}
          />
        </SpeechBubbleSkeleton>
      </Container>
    </div>
  );
};

export default QuestionsChat;
