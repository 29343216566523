import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  MenuItem,
  Paper,
  Select,
  Tooltip,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import { Alert, Skeleton } from "@material-ui/lab";
import copy from "copy-to-clipboard";
import { useSnackbar } from "notistack";
import React from "react";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import "react-multi-email/style.css";
import { useRecoilValue } from "recoil";

import Organisation, { AccessLevel } from "@higher/models/Organisation";

import resendInviteEmail from "@app/api/functions/resendInviteEmail";
import asyncComponent from "@app/components/asyncComponent";
import useAuth from "@app/hooks/useAuth";
import useFirestoreValue from "@app/hooks/useFirestoreValue";
import InvitationsByOrganisation from "@app/state/InvitationsByOrganisation";
import UpdateInvitations, {
  createInvitation,
} from "@app/state/UpdateInvitations";
import UpdateOrganisations from "@app/state/UpdateOrganisations";
import UserById from "@app/state/UserById";

import Section, { SectionSkeleton } from "./Section";
import { TimeAgo } from "./Timeline";

type Props = {
  organisation: Organisation;
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {},
    memberCell: {
      display: "flex",
      alignItems: "center",
      width: "fit-content",
    },
    memberAvatar: {
      marginRight: theme.spacing(2),
    },
    timeAgo: {
      borderBottom: "1px dotted #aaa",
      cursor: "pointer",
    },
    ownerWarning: {
      marginTop: theme.spacing(1),
    },
  });
});

const Team: React.FC<Props> = ({ organisation }) => {
  return (
    <Container maxWidth="lg">
      <IfUserIsAdmin organisation={organisation}>
        <Invitations organisation={organisation} />
      </IfUserIsAdmin>
      <TeamMembers organisation={organisation} />
    </Container>
  );
};

type CopyLinkButtonProps = {
  url: string;
};

const CopyLinkButton: React.FC<CopyLinkButtonProps> = ({ url }) => {
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const copyShortLink = () => {
    copy(window.location.protocol + "//" + window.location.host + url);
    setTooltipOpen(true);
  };

  const handleOnTooltipClose = () => setTooltipOpen(false);

  return (
    <Tooltip
      title="Link copied"
      open={tooltipOpen}
      placement="bottom"
      leaveDelay={1000}
      onClose={handleOnTooltipClose}
      arrow
    >
      <Button onClick={copyShortLink}>Copy link</Button>
    </Tooltip>
  );
};

const IfUserIsAdmin: React.FC<Props> = ({ organisation, children }) => {
  const { user, isLoading } = useAuth();
  if (
    isLoading ||
    !user ||
    !organisation.members[user.uid] ||
    organisation.members[user.uid] !== AccessLevel.Owner
  ) {
    return null;
  }
  return <>{children}</>;
};

const TeamMembers: React.FC<Props> = ({ organisation }) => {
  const onChangeMembership = async (id: string, lvl: AccessLevel) => {
    await UpdateOrganisations()(({ update }) => {
      const members = { ...organisation.members };
      members[id] = lvl;
      update(organisation.id, { members: members });
    });
  };

  const onDelete = async (id: string) => {
    if (!window.confirm("Are you sure? This can't be undone")) {
      return;
    }
    await UpdateOrganisations()(({ update }) => {
      const members = { ...organisation.members };
      delete members[id];
      update(organisation.id, { members: members });
    });
  };

  return (
    <Section title="Team members">
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableBody>
            {Object.keys(organisation.members).map((id) => (
              <TableRow key={id}>
                <TableCell component="th" scope="row">
                  <MemberCell id={id} />
                </TableCell>
                <IfUserIsAdmin organisation={organisation}>
                  <TableCell align="right">
                    {organisation.members[id] !== AccessLevel.Owner && (
                      <>
                        <Select
                          labelId="select-stage"
                          id="select-stage"
                          value={organisation.members[id]}
                          onChange={(event) =>
                            onChangeMembership(
                              id,
                              event.target.value as AccessLevel
                            )
                          }
                        >
                          <MenuItem value={AccessLevel.Owner}>Owner</MenuItem>
                          <MenuItem value={AccessLevel.Member}>Member</MenuItem>
                        </Select>
                        <IconButton
                          aria-label="delete"
                          onClick={() => onDelete(id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </>
                    )}
                    {organisation.members[id] === AccessLevel.Owner && (
                      <Typography>Owner</Typography>
                    )}
                  </TableCell>
                </IfUserIsAdmin>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Section>
  );
};

const Invitations = asyncComponent<Props>(
  ({ organisation }) => {
    const invites = useRecoilValue(InvitationsByOrganisation(organisation));
    const [resent, setResent] = React.useState<Array<string>>(["A", "B"]);
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();

    const onDelete = async (id: string) => {
      if (!window.confirm("Are you sure? This can't be undone")) {
        return;
      }
      await UpdateInvitations()(({ remove }) => {
        remove(id);
      });
    };

    const onChangeMembership = async (id: string, lvl: AccessLevel) => {
      await UpdateInvitations()(({ update }) => {
        update(id, { accessLevel: lvl });
      });
    };

    const onResendEmail = async (id: string) => {
      await resendInviteEmail(id);
      setResent([...resent, id]);
      enqueueSnackbar(`Invitation re-sent`, { variant: "success" });
    };

    return (
      <Section title="Invitations">
        <InviteUsers organisation={organisation} />
        {invites.length > 0 && <br />}
        <TableContainer component={Paper}>
          <Table aria-label="Invitations">
            <TableBody>
              {invites.map((invite) => (
                <TableRow key={invite.id}>
                  <TableCell component="th" scope="row">
                    {invite.email}
                  </TableCell>
                  <TableCell>
                    Invited by <UserName id={invite.createdBy} />{" "}
                    {invite.date && (
                      <TimeAgo
                        date={invite.date.toDate()}
                        className={classes.timeAgo}
                      />
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <Select
                      labelId="select-stage"
                      id="select-stage"
                      value={invite.accessLevel}
                      onChange={(event) =>
                        onChangeMembership(
                          invite.id,
                          event.target.value as AccessLevel
                        )
                      }
                    >
                      <MenuItem value={AccessLevel.Owner}>Owner</MenuItem>
                      <MenuItem value={AccessLevel.Member}>Member</MenuItem>
                    </Select>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      disabled={resent.includes(invite.id)}
                      onClick={() => onResendEmail(invite.id)}
                    >
                      Re-send email
                    </Button>
                    <CopyLinkButton url={`/invite/${invite.id}`} />
                    <IconButton
                      aria-label="delete"
                      onClick={() => onDelete(invite.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Section>
    );
  },
  () => (
    <Section title="Invitations">
      <InviteUsersSkeleton />
    </Section>
  )
);

type UserNameProps = {
  id: string;
};

const UserName = asyncComponent<UserNameProps>(
  ({ id }) => {
    const [member] = useFirestoreValue(UserById(id));
    return <span>{member.displayName}</span>;
  },
  () => (
    <Skeleton>
      <span>someone</span>
    </Skeleton>
  )
);

const InviteUsers: React.FC<Props> = ({ organisation }) => {
  const classes = useStyles();
  const [emails, setEmails] = React.useState<Array<string>>([]);
  const [accessLevel, setAccessLevel] = React.useState<AccessLevel>(
    AccessLevel.Member
  );
  const { user } = useAuth();

  const handleClick = async () => {
    if (!emails.length || !user) {
      return;
    }

    await UpdateInvitations()(({ create }) => {
      emails.forEach((email) =>
        createInvitation(create, email, organisation.id, user.uid, accessLevel)
      );
    });

    setEmails([]);
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={9}>
          <ReactMultiEmail
            placeholder="Invite new users by email"
            emails={emails}
            onChange={(_emails: string[]) => {
              setEmails(_emails);
            }}
            validateEmail={(email) => {
              return isEmail(email); // return boolean
            }}
            getLabel={(
              email: string,
              index: number,
              removeEmail: (index: number) => void
            ) => {
              return (
                <div data-tag key={index}>
                  {email}
                  <span data-tag-handle onClick={() => removeEmail(index)}>
                    ×
                  </span>
                </div>
              );
            }}
          />
        </Grid>
        <Grid item style={{ textAlign: "right" }}>
          <Select
            labelId="select-stage"
            id="select-stage"
            value={accessLevel}
            onChange={(event) =>
              setAccessLevel(event.target.value as AccessLevel)
            }
          >
            <MenuItem value={AccessLevel.Owner}>Owner</MenuItem>
            <MenuItem value={AccessLevel.Member}>Member</MenuItem>
          </Select>
        </Grid>
        <Grid item style={{ textAlign: "right" }}>
          <Button variant="contained" color="secondary" onClick={handleClick}>
            Invite
          </Button>
        </Grid>
      </Grid>
      {accessLevel === AccessLevel.Owner && (
        <Alert className={classes.ownerWarning} severity="warning">
          <strong>Careful!</strong> Owners can invite new users to your
          organisation, and they can manage existing users.
        </Alert>
      )}
    </div>
  );
};

const InviteUsersSkeleton: React.FC = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={9}>
        <Skeleton variant="rect" width="100%">
          <ReactMultiEmail
            placeholder="Invite new users by email"
            getLabel={() => null}
          />
        </Skeleton>
      </Grid>
      <Grid xs={3} item style={{ textAlign: "right" }}>
        <Skeleton variant="rect" width="100%" height="100%">
          <Select labelId="select-stage" id="select-stage">
            <MenuItem value={AccessLevel.Owner}>Owner</MenuItem>
            <MenuItem value={AccessLevel.Member}>Member</MenuItem>
          </Select>
        </Skeleton>
      </Grid>
    </Grid>
  );
};

type MemberProps = {
  id: string;
};

const MemberCell = asyncComponent<MemberProps>(
  ({ id }) => {
    const member = useRecoilValue(UserById(id));
    const classes = useStyles();
    const { user } = useAuth();
    if (!user || !member) {
      return <p>Error: missing record</p>;
    }

    return (
      <>
        <Box className={classes.memberCell}>
          <Avatar
            className={classes.memberAvatar}
            src={member.photoURL || undefined}
          />
          <div>
            <Typography>
              {member.displayName}
              {user.uid === member.id && <> (that's you)</>}
            </Typography>
            <Typography color="textSecondary" variant="body2">
              {member.email}
            </Typography>
          </div>
        </Box>
      </>
    );
  },
  () => {
    const classes = useStyles();
    return (
      <Box className={classes.memberCell}>
        <Skeleton variant="circle" className={classes.memberAvatar}>
          <Avatar />
        </Skeleton>
        <div>
          <Skeleton>
            <Typography>Name</Typography>
          </Skeleton>
          <Skeleton>
            <Typography color="textSecondary" variant="body2">
              email@email.com
            </Typography>
          </Skeleton>
        </div>
      </Box>
    );
  },
  () => <p>Uh oh! That didn't work</p>
);

export const TeamSkeleton: React.FC = () => {
  return (
    <Container maxWidth="lg">
      <SectionSkeleton title="Team members">
        <Skeleton variant="rect"></Skeleton>
        <Skeleton variant="rect"></Skeleton>
        <Skeleton variant="rect"></Skeleton>
      </SectionSkeleton>
    </Container>
  );
};

export default Team;
