import {
  Avatar,
  Container,
  Dialog,
  Divider,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Fade,
  Card,
  CardHeader,
  Drawer,
  Grid,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  makeStyles,
  Theme,
  createStyles,
  lighten,
} from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import UnarchiveIcon from "@material-ui/icons/Archive";
import ReviewIcon from "@material-ui/icons/AssignmentTurnedIn";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CommentIcon from "@material-ui/icons/Comment";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import MenuIcon from "@material-ui/icons/Menu";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import EndorsementIcon from "@material-ui/icons/VerifiedUser";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import copy from "copy-to-clipboard";
import { useSnackbar } from "notistack";
import React from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  ResponderProvided,
  DroppableProvided,
} from "react-beautiful-dnd";
import { useRecoilState, useRecoilValueLoadable } from "recoil";

import Applicant, {
  ApplicationStatus,
  RemovalReason,
} from "@higher/models/Applicant";
import ApplicantMetadata from "@higher/models/ApplicantMetadata";
import Role from "@higher/models/Role";
import Stage, {
  FixedStageID,
  IsFixedStageID,
  ArchivedStageId,
} from "@higher/models/Stage";
import User from "@higher/models/User";

import { Accent, Button } from "@app/brand";
import asyncComponent from "@app/components/asyncComponent";
import plural from "@app/helpers/plural";
import Slaask from "@app/helpers/slaask";
import useAuth from "@app/hooks/useAuth";
import useFirestoreValue from "@app/hooks/useFirestoreValue";
import useLoadable from "@app/hooks/useLoadable";
import useMountEffect from "@app/hooks/useMountEffect";
import ApplicantById from "@app/state/ApplicantById";
import ApplicantMetadataState from "@app/state/ApplicantMetadata";
import RoleById from "@app/state/RoleById";
import ShowArchivedCandidates from "@app/state/ShowArchivedCandidates";
import StagesByRole from "@app/state/StagesByRole";
import { UpdateApplicantsFn } from "@app/state/UpdateApplicants";
import {
  UpdateEventsFn,
  createApplicantStageMoveEvent,
  createApplicantRemoveEvent,
  createApplicantRestoreEvent,
} from "@app/state/UpdateEvents";
import { UpdateStagesFn } from "@app/state/UpdateStages";
import UserById from "@app/state/UserById";

import AddCandidateDialog from "./AddCandidateDialog";
import CandidateDialog from "./CandidateDialog";
import { LayoutConfig } from "./Layout";
import Section from "./Section";
import StateUpdateContext from "./StateUpdateContext";

type Props = {
  role: Role;
  stages: Array<Stage>;
  applicants: Array<Applicant>;
  focusApplicantId?: string;
};

type IndexProps = {
  index: number;
};

type StageProps = {
  updateStages: UpdateStagesFn;
  handleRemoveStage: () => void;
  stage: Stage;
  list?: Array<Applicant>;
  disabled?: boolean;
};

type ApplicantDialogProps = {
  openCandidateDialog: (app: Applicant) => void;
};

type CreateStageAtIndexProps = {
  createNewStage: (index: number) => void;
};

type RoleControlsProps = {
  roleId: string;
};

type RemovedCandidatesDrawerProps = ApplicantDialogProps & {
  role: Role;
  applicants?: Array<Applicant>;
};

const boardId = "board";

class ApplicantsByStageId extends Map<string, Array<Applicant>> {}

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      overflowX: "auto",
    },
    board: {
      display: "inline-flex",
      height: "calc(100vh - 192px)",
    },
    droppable: {
      height: `calc(100% - ${theme.spacing(6)}px)`,
    },
    droppableArchive: {
      height: `100%`,
    },
    droppableDragging: {
      backgroundColor: "#dfdfdf88",
      borderRadius: theme.spacing(1),
      transition: "backgroundColor 2s",
    },
    stage: {
      width: `calc(25vw - ${theme.spacing(2.5)}px)`,
      maxWidth: "400px",
      minWidth: "300px",
      backgroundColor: "#f1f1f1",
      padding: theme.spacing(1.5),
      marginRight: theme.spacing(2),
      overflowY: "auto",
      border: "1px solid #dfdfdf88",
      borderRadius: theme.spacing(0.5),
      position: "relative",
    },
    stageSkeleton: {
      height: "100%",
    },
    addNewStage: {
      width: `calc(25vw - ${theme.spacing(2.5)}px)`,
      maxWidth: "400px",
      minWidth: "300px",
      border: "1px dotted #dfdfdf",
      borderRadius: theme.spacing(0.5),
    },
    stageMenu: {
      position: "absolute",
      right: theme.spacing(0),
      top: theme.spacing(0),
    },

    // Candidate cards
    rank: {
      paddingLeft: theme.spacing(1.5),
      color: "#aaa",
      "& span": {
        color: theme.palette.secondary.dark,
        fontWeight: "bold",
      },
    },
    firstPlace: {
      backgroundSize: "auto 35%, cover",
    },
    secondPlace: {
      backgroundSize: "auto 35%, cover",
    },
    thirdPlace: {},
    unendorsedCandidate: {
      opacity: 0.75,
    },
    negativeRankCandidate: {
      "& svg": {
        color: theme.palette.warning.main,
      },
    },
    positiveRankCandidate: {
      "& svg": {
        color: theme.palette.success.light,
      },
    },
    cardSource: {
      position: "absolute",
      top: 0,
      right: 0,
      padding: "4px 6px",
      backgroundColor: "#fafafa",
      borderBottomLeftRadius: 4,
      opacity: 0.8,
      boxShadow: "inset 1px -1px 3px -1px rgb(0 0 0 / 25%)",
    },

    smallIcon: {
      width: theme.spacing(2.0),
      color: "#bbb",
      marginRight: theme.spacing(0.5),
    },
    smallIconLabel: {
      marginRight: theme.spacing(2),
      fontSize: "1em",
    },

    // global controls
    primaryControlButton: {
      margin: 0,
      fontSize: theme.spacing(1.5),
    },

    controlButton: {
      textTransform: "none",
      padding: 0,
      margin: 0,
      fontSize: theme.spacing(1.75),
      color: theme.palette.text.secondary,
      transition: "color 0.25s ease",
      "&:hover": {
        color: theme.palette.secondary.dark,
        backgroundColor: "transparent",
      },
      "& span": {
        justifyContent: "space-between",
      },
      "& .MuiButton-startIcon": {
        marginRight: 0,
      },
      "& svg": {
        marginRight: theme.spacing(1),
        width: theme.spacing(2),
        height: theme.spacing(2),
      },
    },
    archivedCandidatesDrawer: {
      flexShrink: 0,
      maxWidth: "400px",
      "& li": {
        listStyle: "none",
      },
    },
    archivedCandidatesDrawerOpen: {
      width: `calc(25vw)`,
    },
    archivedCandidates: {
      width: `calc(25vw)`,
      maxWidth: "400px",
      height: `calc(100vh - ${LayoutConfig.MainHeadingSize}px)`,
      boxShadow: theme.shadows[1],
      top: LayoutConfig.MainHeadingSize,
      backgroundColor: lighten(theme.palette.warning.light, 0.75),
      color: theme.palette.warning.contrastText,
    },
    listItem: {
      backgroundColor: lighten(theme.palette.warning.light, 0.5),
      "& svg": {
        color: theme.palette.warning.dark,
      },
    },
    listItemIcon: {
      color: "rgba(0, 0, 0, 0.32)",
      minWidth: 0,
      marginRight: theme.spacing(1),
    },
    archiveContainer: {
      padding: theme.spacing(2),
      height: "100%",
    },
  });
});

const sortApplicants = (app1: Applicant, app2: Applicant): number => {
  const app1HasIndex = app1.stageIndex !== undefined;
  const app2HasIndex = app1.stageIndex !== undefined;

  if (app1HasIndex && !app2HasIndex) {
    return 1;
  }
  if (!app1HasIndex && app2HasIndex) {
    return -1;
  }
  if (app1.stageIndex !== undefined && app2.stageIndex !== undefined) {
    return app1.stageIndex > app2.stageIndex ? 1 : -1;
  }
  return app1.rank < app2.rank ? 1 : -1;
};

const reorder = (
  updateApplicants: UpdateApplicantsFn,
  list: Array<Applicant>,
  startIndex: number,
  endIndex: number
): Array<Applicant> => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  updateApplicants(({ update }) => {
    result.forEach((app, index) => {
      update(app.id, { stageIndex: index });
    });
  });

  return result;
};

const reorderColumns = (
  updateStages: UpdateStagesFn,
  list: Array<Stage>,
  startIndex: number,
  endIndex: number
): Array<Stage> => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  updateStages(({ update }) => {
    result.forEach((stage, index) => {
      update(stage.id, { index });
    });
  });
  return result;
};

const move = (
  user: User,
  updateApplicants: UpdateApplicantsFn,
  updateEvents: UpdateEventsFn,
  source: Array<Applicant>,
  destination: Array<Applicant>,
  sourceIndex: number,
  destIndex: number,
  stageId: string,
  removalReason?: RemovalReason
): [Array<Applicant>, Array<Applicant>] => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(sourceIndex, 1);

  destClone.splice(destIndex, 0, removed);

  const originalStatus = removed.status;

  updateApplicants(({ update }) => {
    const newData: Partial<Applicant> = {
      stageId,
      status:
        stageId === ArchivedStageId
          ? ApplicationStatus.Removed
          : ApplicationStatus.Pending,
    };

    update(removed.id, newData);

    sourceClone.forEach((app, index) => update(app.id, { stageIndex: index }));
    destClone.forEach((app, index) => update(app.id, { stageIndex: index }));
  });

  // Code debt: this splitting of the batches is inefficient.
  // See https://github.com/Higher-labs/operations/issues/25
  updateEvents(({ create }) => {
    if (stageId === ArchivedStageId) {
      createApplicantRemoveEvent(create, removed, user, removalReason);
    } else {
      if (originalStatus === ApplicationStatus.Removed) {
        createApplicantRestoreEvent(create, removed, user);
      }
      createApplicantStageMoveEvent(create, removed, user, stageId);
    }
  });

  return [sourceClone, destClone];
};

const insertStage = (
  updateStages: UpdateStagesFn,
  source: Array<Stage>,
  insert: Omit<Stage, "id">,
  index: number
): Array<Stage> => {
  const clone = Array.from(source);
  clone.splice(index, 0, insert as Stage);

  updateStages(({ create, update }) => {
    create(insert);
    source.forEach((stage, i) => {
      if (stage.index && stage.index >= index) {
        update(stage.id, { index: stage.index + 1 });
      }
    });
  });
  return clone;
};

const removeStage = (
  updateStages: UpdateStagesFn,
  source: Array<Stage>,
  toRemove: Stage
): Array<Stage> => {
  const index = source.indexOf(toRemove);
  const clone = Array.from(source);
  clone.splice(index, 1);

  updateStages(({ remove, update }) => {
    remove(toRemove.id);
    clone.forEach((stage, i) => {
      if (stage.index && toRemove.index && stage.index > toRemove.index) {
        update(stage.id, { index: stage.index - 1 });
      }
    });
  });
  return clone;
};

const buildStagesMatrix = (
  stages: Array<Stage>,
  applicants: Array<Applicant>
): ApplicantsByStageId => {
  const output = new ApplicantsByStageId();

  stages.forEach((stage) => {
    output.set(
      stage.id,
      applicants
        .filter(
          (app) =>
            app.stageId === stage.id && app.status === ApplicationStatus.Pending
        )
        .sort(sortApplicants)
    );
  });

  // handle applicants with no stage ID
  output.set(
    FixedStageID.Applied,
    applicants
      .filter((app) => !app.stageId || app.stageId === FixedStageID.Applied)
      .sort(sortApplicants)
  );

  // handle removed applicants
  output.set(
    FixedStageID.Archive,
    applicants
      .filter((app) => app.status === ApplicationStatus.Removed)
      .sort(sortApplicants)
  );

  return output;
};

const getApplicantStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: "none",
  margin: `0 0 10px 0`,
  ...draggableStyle,
});

const RoleView: React.FC<Props> = ({
  role,
  applicants,
  stages,
  focusApplicantId,
}) => {
  const classes = useStyles();
  const { user, updateApplicants, updateEvents, updateStages } =
    React.useContext(StateUpdateContext);
  const [computedStages, setStages] = React.useState(stages);
  const [candidates, setCandidates] = React.useState(
    buildStagesMatrix(computedStages, [...applicants])
  );
  const [showCandidate, setShowCandidate] = React.useState<
    Applicant | undefined
  >(undefined);
  const [showNewStageDialog, setShowNewStageDialog] = React.useState(false);
  const [newDialogIndex, setNewDialogIndex] = React.useState(0);
  const focusApplicantLoadable = useRecoilValueLoadable(
    ApplicantById(focusApplicantId)
  );

  useMountEffect(async () => {
    const focusApplicant = await focusApplicantLoadable.toPromise();
    if (focusApplicant) {
      setShowCandidate(focusApplicant);
    }
  });

  // TODO:  Pause duing drags
  React.useEffect(() => {
    setStages(stages);
    setNewDialogIndex(stages.length);
    setCandidates(buildStagesMatrix(stages, [...applicants]));
  }, [stages, applicants]);

  const archivedCandidates = candidates.get(FixedStageID.Archive);

  const openCandidateDialog = (app: Applicant) => {
    window.history.pushState(
      null,
      app.name,
      `/dashboard/roles/${app.roleId}/${app.id}`
    );
    setShowCandidate(app);
  };

  const closeCandidateDialog = () => {
    window.history.pushState(null, role.title, `/dashboard/roles/${role.id}`);
    setShowCandidate(undefined);
  };

  const handleCreateNewStage = (index: number) => {
    setShowNewStageDialog(true);
    setNewDialogIndex(index);
  };

  const createNewStage = (title: string) => {
    const newStage = {
      title,
      index: newDialogIndex,
      applicantCount: 0,
    };
    setStages(
      insertStage(updateStages, computedStages, newStage, newDialogIndex)
    );
    setShowNewStageDialog(false);
  };

  const stageRemoveHandlerFactory = (stage: Stage) => () => {
    removeStage(updateStages, computedStages, stage);
  };

  const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    const sInd = source.droppableId;
    const dInd = destination.droppableId;

    // handle whole column movements
    if (sInd === boardId && dInd === boardId) {
      setStages(
        reorderColumns(
          updateStages,
          computedStages,
          source.index,
          destination.index
        )
      );

      return;
    }

    if (sInd === dInd) {
      const existing = candidates.get(sInd);
      if (!existing) {
        return;
      }
      const items = reorder(
        updateApplicants,
        existing,
        source.index,
        destination.index
      );
      const newState = new Map(candidates);
      newState.set(sInd, items);
      setCandidates(newState);
    } else {
      const xSource = candidates.get(sInd);
      const xDest = candidates.get(dInd);
      if (!xSource || !xDest) {
        return;
      }

      const [newSrc, newDest] = move(
        user,
        updateApplicants,
        updateEvents,
        xSource,
        xDest,
        source.index,
        destination.index,
        destination.droppableId
      );

      const newState = new Map(candidates);
      newState.set(sInd, newSrc);
      newState.set(dInd, newDest);
      setCandidates(newState);
    }
  };

  const moveApplicantToStage = (
    applicant: Applicant,
    stageId: string,
    removalReason?: RemovalReason
  ) => {
    if (!applicant.stageId) {
      return;
    }

    const xSource = candidates.get(applicant.stageId);
    const xDest = candidates.get(stageId);
    if (!xSource || !xDest) {
      return;
    }

    const sourceIndex = xSource.findIndex((a) => a.id === applicant.id);

    const [newSrc, newDest] = move(
      user,
      updateApplicants,
      updateEvents,
      xSource,
      xDest,
      sourceIndex,
      xDest.length,
      stageId,
      removalReason
    );

    const newState = new Map(candidates);
    newState.set(applicant.stageId, newSrc);
    newState.set(stageId, newDest);
    setCandidates(newState);
  };

  return (
    <main style={{ display: "flex" }}>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className={classes.root}>
          <Section title="Candidates" hideTitle>
            <Droppable
              droppableId={boardId}
              type="COLUMN"
              direction="horizontal"
            >
              {(provided: DroppableProvided) => (
                <div
                  className={classes.board}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {computedStages.map((stage, index) => (
                    <DraggableColumn
                      updateStages={updateStages}
                      handleRemoveStage={stageRemoveHandlerFactory(stage)}
                      createNewStage={handleCreateNewStage}
                      stage={stage}
                      list={candidates.get(stage.id)}
                      index={index}
                      key={stage.id}
                      openCandidateDialog={openCandidateDialog}
                    />
                  ))}
                  {provided.placeholder}
                  <div className={classes.addNewStage}>
                    <Button
                      secondary
                      startIcon={<AddIcon />}
                      onClick={() =>
                        handleCreateNewStage(computedStages.length)
                      }
                    >
                      Add new stage
                    </Button>
                  </div>
                </div>
              )}
            </Droppable>
          </Section>
          <NewColumnDialog
            open={showNewStageDialog}
            close={() => setShowNewStageDialog(false)}
            callback={createNewStage}
          />
          <CandidateDialog
            open={!!showCandidate}
            close={closeCandidateDialog}
            applicant={showCandidate}
            stages={stages}
            moveToStage={moveApplicantToStage}
          />
        </div>
        <RemovedCandidatesDrawer
          role={role}
          applicants={archivedCandidates}
          openCandidateDialog={openCandidateDialog}
        />
      </DragDropContext>
    </main>
  );
};

const RemovedCandidatesDrawer = asyncComponent<RemovedCandidatesDrawerProps>(
  ({ applicants, role, openCandidateDialog }) => {
    const classes = useStyles();
    const [archiveOpen, setArchiveOpen] = useRecoilState(
      ShowArchivedCandidates
    );

    if (!applicants) {
      return null;
    }

    return (
      <Drawer
        anchor="right"
        variant="persistent"
        open={archiveOpen}
        className={clsx({
          [classes.archivedCandidatesDrawer]: true,
          [classes.archivedCandidatesDrawerOpen]: archiveOpen,
        })}
        classes={{
          paper: classes.archivedCandidates,
        }}
      >
        <ListItem divider className={classes.listItem}>
          <ListItemIcon className={classes.listItemIcon}>
            <UnarchiveIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body2">Removed candidates</Typography>
            }
          />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="close"
              onClick={() => setArchiveOpen(false)}
            >
              <ChevronRightIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <Container maxWidth={false} className={classes.archiveContainer}>
          <ArchivedCandidates
            role={role}
            openCandidateDialog={openCandidateDialog}
            applicants={applicants}
          />
        </Container>
      </Drawer>
    );
  },
  () => null
);

type ArchivedCandidatesProps = ApplicantDialogProps & {
  role: Role;
  applicants: Array<Applicant> | undefined;
};

const ArchivedCandidates: React.FC<ArchivedCandidatesProps> = ({
  role,
  applicants,
  openCandidateDialog,
}) => {
  const classes = useStyles();

  if (!applicants) {
    return null;
  }

  return (
    <Droppable droppableId={ArchivedStageId}>
      {(provided, snapshot) => {
        return (
          <div
            className={
              snapshot.isDraggingOver
                ? classes.droppableDragging
                : classes.droppableArchive
            }
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {applicants.map((applicant, index) => (
              <Draggable
                draggableId={applicant.id}
                index={index}
                key={applicant.id}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getApplicantStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <ApplicantView
                      applicant={applicant}
                      openCandidateDialog={openCandidateDialog}
                    />
                  </div>
                )}
              </Draggable>
            ))}

            {provided.placeholder}
          </div>
        );
      }}
    </Droppable>
  );
};

const DraggableColumn: React.FC<
  StageProps & CreateStageAtIndexProps & IndexProps & ApplicantDialogProps
> = ({
  updateStages,
  handleRemoveStage,
  createNewStage,
  stage,
  list,
  index,
  disabled,
  openCandidateDialog,
}) => {
  const classes = useStyles();
  return (
    <Draggable draggableId={stage.id} index={index} isDragDisabled={disabled}>
      {(provided, snapshot) => (
        <div
          className={classes.stage}
          key={stage.id}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <ColumnInner
            updateStages={updateStages}
            handleRemoveStage={handleRemoveStage}
            createNewStage={() => createNewStage(index + 1)}
            stage={stage}
            list={list}
            openCandidateDialog={openCandidateDialog}
          />
        </div>
      )}
    </Draggable>
  );
};

type CreateStageProps = {
  createNewStage: () => void;
};

const ColumnInner: React.FC<
  StageProps & CreateStageProps & ApplicantDialogProps
> = ({
  updateStages,
  handleRemoveStage,
  createNewStage,
  stage,
  list,
  openCandidateDialog,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [renameOpen, setRenameOpen] = React.useState(false);

  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickRename = () => {
    setRenameOpen(true);
    handleClose();
  };

  const handleRename = async (newTitle: string) => {
    if (newTitle === stage.title) {
      setRenameOpen(false);
      return;
    }

    await updateStages(({ update }) => update(stage.id, { title: newTitle }));
    setRenameOpen(false);
  };

  const handleDelete = () => {
    handleClose();
    if (!window.confirm("Are you sure? This can't be undone")) {
      return;
    }
    handleRemoveStage();
  };

  const handleCreateNewStage = () => {
    handleClose();
    createNewStage();
  };

  return (
    <>
      <RenameColumnsDialog
        open={renameOpen}
        close={() => setRenameOpen(false)}
        title={stage.title}
        callback={handleRename}
      />
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClickRename}>Edit stage</MenuItem>
        <MenuItem
          disabled={IsFixedStageID(stage.id) || (list && list.length > 0)}
          onClick={handleDelete}
        >
          Delete stage
        </MenuItem>
        <Divider light />
        <MenuItem onClick={handleCreateNewStage}>Add new stage...</MenuItem>
      </Menu>

      <Typography paragraph variant="body2">
        <Accent>{stage.title}</Accent>{" "}
        {list && list.length > 3 && <span>({list?.length})</span>}
      </Typography>
      <IconButton className={classes.stageMenu} onClick={handleMenuClick}>
        <MoreHorizIcon />
      </IconButton>
      <Droppable droppableId={stage.id}>
        {(provided, snapshot) => {
          if (!list) {
            return <></>;
          }

          return (
            <div
              className={
                snapshot.isDraggingOver
                  ? classes.droppableDragging
                  : classes.droppable
              }
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {list.map((applicant, index) => (
                <Draggable
                  draggableId={applicant.id}
                  index={index}
                  key={applicant.id}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getApplicantStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <ApplicantView
                        applicant={applicant}
                        openCandidateDialog={openCandidateDialog}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          );
        }}
      </Droppable>
    </>
  );
};

type DialogProps = {
  open: boolean;
  close: () => void;
};

type RenameColumnsDialogProps = {
  title: string;
  callback: (title: string) => void;
};

const RenameColumnsDialog: React.FC<DialogProps & RenameColumnsDialogProps> = ({
  open,
  close,
  title,
  callback,
}) => {
  const [current, setCurrent] = React.useState(title);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const v = (event.target as HTMLInputElement).value;
    setCurrent(v);
  };

  const handleSubmit = () => {
    callback(current);
  };

  return (
    <Dialog
      onClose={close}
      aria-labelledby="rename-stage"
      open={open}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Edit stage</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          label="Stage title"
          fullWidth
          value={current}
          color="secondary"
          type="text"
          onChange={handleChange}
          error={!current.length}
          onKeyPress={(ev) => {
            if (ev.key === "Enter") {
              handleSubmit();
              ev.preventDefault();
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          secondary
          small
          disabled={!current.length}
          onClick={handleSubmit}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type NewColumnDialogProps = {
  callback: (title: string) => void;
};

const NewColumnDialog: React.FC<DialogProps & NewColumnDialogProps> = ({
  open,
  close,
  callback,
}) => {
  const [current, setCurrent] = React.useState<string | undefined>(undefined);
  const [submitted, setSubmitted] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const v = (event.target as HTMLInputElement).value;
    setCurrent(v || "");
  };

  const handleSubmit = () => {
    setSubmitted(true);
    if (!current) {
      return;
    }
    callback(current);
    setCurrent(undefined);
    setSubmitted(false);
  };

  return (
    <Dialog
      onClose={close}
      aria-labelledby="rename-stage"
      open={open}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Create a new stage</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          label="Stage title"
          fullWidth
          value={current}
          color="secondary"
          type="text"
          onChange={handleChange}
          error={submitted && (current === undefined || !current.length)}
          onKeyPress={(ev) => {
            if (ev.key === "Enter") {
              handleSubmit();
              ev.preventDefault();
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          secondary
          small
          disabled={!current || !current.length}
          onClick={handleSubmit}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type ApplicantProps = {
  applicant: Applicant;
};

export const ApplicantViewSkeleton: React.FC = () => {
  const classes = useStyles();
  return (
    <Card>
      <CardHeader
        avatar={
          <Skeleton variant="circle">
            <Avatar>C</Avatar>
          </Skeleton>
        }
        title={
          <Skeleton>
            <Typography variant="subtitle1">Loading candidate...</Typography>
          </Skeleton>
        }
        subheader={
          <Skeleton>
            <Grid container alignItems="center">
              <EndorsementIcon className={classes.smallIcon} />
              <Typography
                variant="body2"
                className={classes.smallIconLabel}
                color="textSecondary"
              >
                0
              </Typography>

              <ReviewIcon className={classes.smallIcon} />
              <Typography
                variant="body2"
                className={classes.smallIconLabel}
                color="textSecondary"
              >
                0
              </Typography>

              <CommentIcon className={classes.smallIcon} />
              <Typography
                variant="body2"
                className={classes.smallIconLabel}
                color="textSecondary"
              >
                0
              </Typography>
            </Grid>
          </Skeleton>
        }
        disableTypography
      />
    </Card>
  );
};

type IconAndTextProps = {
  icon: JSX.Element;
  text: string | number;
  tooltip?: string;
  textClass?: string;
};

const IconAndText: React.FC<IconAndTextProps> = ({
  icon,
  text,
  tooltip,
  textClass,
}) => {
  return (
    <Tooltip arrow title={tooltip || ""} aria-label={tooltip || ""}>
      <div>
        <Grid container alignItems="center">
          {icon}
          <Typography
            variant="body2"
            className={textClass}
            color="textSecondary"
          >
            {text}
          </Typography>
        </Grid>
      </div>
    </Tooltip>
  );
};

const ApplicantView = asyncComponent<ApplicantProps & ApplicantDialogProps>(
  ({ applicant, openCandidateDialog }) => {
    const classes = useStyles();
    const [meta] = useLoadable(ApplicantMetadataState(applicant));
    if (!meta) {
      return <ApplicantViewSkeleton />;
    }

    let cardClass: string | undefined = classes.unendorsedCandidate;

    if (applicant.rank) {
      switch (applicant.rank) {
        case 1:
          cardClass = classes.firstPlace;
          break;

        case 2:
          cardClass = classes.secondPlace;
          break;

        case 3:
          cardClass = classes.thirdPlace;
          break;

        default:
          cardClass = undefined;
      }
    }

    if (meta.rankIndex < 0) {
      cardClass = [cardClass, classes.negativeRankCandidate].join(" ");
    } else if (meta.rankIndex > 0) {
      cardClass = [cardClass, classes.positiveRankCandidate].join(" ");
    }

    return (
      <Card
        onClick={() => openCandidateDialog(applicant)}
        className={cardClass}
        style={{ position: "relative" }}
      >
        <Grid container alignItems="center">
          {applicant.rank && (
            <Typography className={classes.rank}>
              #<span>{applicant.rank}</span>
            </Typography>
          )}
          <CardHeader
            avatar={<Avatar src={applicant.avatar} alt={applicant.name} />}
            title={
              <Typography variant="subtitle1">{applicant.name}</Typography>
            }
            subheader={
              <>
                <Grid container alignItems="center">
                  <IconAndText
                    icon={<EndorsementIcon className={classes.smallIcon} />}
                    text={applicant.endorsements}
                    tooltip={`${applicant.endorsements} endorsement${plural(
                      applicant.endorsements
                    )}`}
                    textClass={classes.smallIconLabel}
                  />
                  <IconAndText
                    icon={<ReviewIcon className={classes.smallIcon} />}
                    text={meta.reviewCount}
                    tooltip={`${meta.reviewCount} team review${plural(
                      meta.reviewCount
                    )}`}
                    textClass={classes.smallIconLabel}
                  />
                  <IconAndText
                    icon={<CommentIcon className={classes.smallIcon} />}
                    text={meta.commentCount}
                    tooltip={`${meta.commentCount} comment${plural(
                      meta.commentCount
                    )}`}
                    textClass={classes.smallIconLabel}
                  />
                </Grid>
                <Source meta={meta} />
              </>
            }
            disableTypography
          />
        </Grid>
      </Card>
    );
  },
  ApplicantViewSkeleton
);

type SourceTextProps = {
  meta: ApplicantMetadata;
};

export const Source: React.FC<SourceTextProps> = ({ meta }) => {
  const classes = useStyles();

  if (!meta.source || meta.source === "") {
    return null;
  }

  const inner = meta.addedByUserId ? (
    <Username id={meta.addedByUserId} />
  ) : (
    <>{formatSource(meta.source)}</>
  );

  return (
    <div className={classes.cardSource}>
      <Typography color="textSecondary" variant="body2">
        {inner}
      </Typography>
    </div>
  );
};

type UsernameProps = {
  id: string;
};

const formatSource = (url: string): string => {
  try {
    const { hostname } = new URL(url);
    return hostname.replace(/^www\./, "").replace(/\.com$/, "");
  } catch (e) {
    return url;
  }
};

const Username = asyncComponent<UsernameProps>(
  ({ id }) => {
    const [user] = useFirestoreValue(UserById(id));
    return <>{user ? user.displayName : "Someone"}</>;
  },
  () => (
    <Skeleton>
      <>Someone</>
    </Skeleton>
  ),
  () => <>manual</>
);

export const RoleSkeleton: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Section title="Candidates" hideTitle>
        <div className={classes.board}>
          <Skeleton
            variant="rect"
            className={[classes.stage, classes.stageSkeleton].join(" ")}
          />
          <Skeleton
            variant="rect"
            className={[classes.stage, classes.stageSkeleton].join(" ")}
          />
          <Skeleton
            variant="rect"
            className={[classes.stage, classes.stageSkeleton].join(" ")}
          />
          <Skeleton
            variant="rect"
            className={[classes.stage, classes.stageSkeleton].join(" ")}
          />
          <Skeleton
            variant="rect"
            className={[classes.stage, classes.stageSkeleton].join(" ")}
          />
        </div>
      </Section>
    </div>
  );
};

export const RoleControls = asyncComponent<RoleControlsProps>(
  ({ roleId }) => {
    const classes = useStyles();
    const { user } = useAuth();
    const [role, roleLoading] = useFirestoreValue(RoleById(roleId));
    const [stages, stagesLoading] = useFirestoreValue(StagesByRole(role));
    const [archiveOpen, setArchiveOpen] = useRecoilState(
      ShowArchivedCandidates
    );
    const { enqueueSnackbar } = useSnackbar();
    const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
      null
    );
    const [addCandidateOpen, setAddCandidateOpen] = React.useState(false);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setMenuAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setMenuAnchorEl(null);
    };

    const addCandidate = () => {
      setAddCandidateOpen(true);
    };

    const onCreateCandidate = (id: string) => {
      enqueueSnackbar(`Candidate added!`, {
        variant: "success",
      });
    };

    const copyLink = () => {
      // FIXME: proper URL
      copy(`https://gethigher.io/apply/${role.id}`);
      enqueueSnackbar(`Application link copied to clipboard`, {
        variant: "success",
      });
    };

    if (roleLoading || stagesLoading || !user) {
      return null;
    }

    return (
      <>
        <Fade in={!archiveOpen}>
          <Grid container justify="flex-end" alignItems="center" spacing={2}>
            <Grid item>
              <Button
                onClick={addCandidate}
                className={classes.primaryControlButton}
                startIcon={<AddIcon />}
                tertiary
                small
              >
                Add a candidate
              </Button>
            </Grid>
            <Grid item>
              <IconButton
                aria-controls="role-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Fade>
        <Menu
          id="simple-menu"
          anchorEl={menuAnchorEl}
          keepMounted
          open={Boolean(menuAnchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose}>
            <Button
              onClick={copyLink}
              className={classes.controlButton}
              startIcon={<FileCopyIcon />}
              secondary
              small
              compact
            >
              Copy application link
            </Button>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Button
              className={classes.controlButton}
              startIcon={<UnarchiveIcon />}
              secondary
              small
              compact
              onClick={() => setArchiveOpen(true)}
            >
              Removed candidates
            </Button>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <Button
              onClick={() => Slaask()}
              className={classes.controlButton}
              startIcon={<LiveHelpIcon />}
              secondary
              small
              compact
            >
              Support
            </Button>
          </MenuItem>
        </Menu>
        <AddCandidateDialog
          open={addCandidateOpen}
          close={() => setAddCandidateOpen(false)}
          stages={stages}
          role={role}
          user={user}
          onCreateCandidate={onCreateCandidate}
        />
      </>
    );
  },
  () => null
);

export default RoleView;
