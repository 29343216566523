import { Chip, Tooltip } from "@material-ui/core";
import DeliveredIcon from "@material-ui/icons/Done";
import OpenedIcon from "@material-ui/icons/DoneAll";
import UnknownIcon from "@material-ui/icons/ErrorOutline";
import PendingIcon from "@material-ui/icons/Timelapse";
import React from "react";

import { Status } from "@higher/models/Message";

import asyncComponent from "@app/components/asyncComponent";
import useFirestoreValue from "@app/hooks/useFirestoreValue";
import MessageByOrgAndId from "@app/state/MessageByOrgAndId";

type Props = {
  orgId: string;
  messageId: string;
};

const icon = (status: Status): JSX.Element => {
  switch (status) {
    case Status.Sent:
      return <PendingIcon />;

    case Status.Delivered:
      return <DeliveredIcon />;

    case Status.Opened:
      return <OpenedIcon />;
  }
  return <UnknownIcon />;
};

export const MessageDeliveryStatus = asyncComponent<Props>(
  ({ orgId, messageId }) => {
    const [message] = useFirestoreValue(
      MessageByOrgAndId({
        organisationId: orgId,
        id: messageId,
      })
    );

    if (!message.status) {
      return null;
    }

    const dateString = message.statusDate
      ? `${message.statusDate.toDate().toDateString()} at ${message.statusDate
          .toDate()
          .toLocaleTimeString("en", {
            hour: "numeric",
            minute: "2-digit",
          })}`
      : "unknown";

    return (
      <Tooltip
        arrow
        placement="left"
        title={dateString}
        aria-label={dateString}
      >
        <div>
          <Chip
            icon={icon(message.status)}
            label={message.status}
            variant="outlined"
            size="small"
            disabled
          />
        </div>
      </Tooltip>
    );
  },
  () => null
);

export default MessageDeliveryStatus;
