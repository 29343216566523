import { Functions } from "@app/Firebase";

export const withdrawApplication = async (
  dashboardId: string
): Promise<void> => {
  const fn = Functions.httpsCallable("withdrawApplication");
  await fn({
    dashboardId,
  });
};

export default withdrawApplication;
