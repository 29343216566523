import { Container, Fade } from "@material-ui/core";
import React from "react";

import {
  SkeletonProps,
  SpeechBubbleSkeleton,
} from "@app/components/SpeechBubble";

export type QuestionsState = boolean | undefined;

type Props = {
  questions: Array<MessageTree<any>>;
  questionsState: Array<QuestionsState>;
  className?: string;
};

export type MessageTree<QuestionIDType> = {
  id: QuestionIDType;
  question: JSX.Element;
  invalidResponse?: JSX.Element;
  skeleton?: SkeletonProps;
};

export const useQuestionState = <T,>(
  questions: Array<MessageTree<T>>
): [
  Array<QuestionsState>,
  (value: Array<QuestionsState>) => void,
  (value: QuestionsState, key: T) => void
] => {
  const [states, setStates] = React.useState<Array<QuestionsState>>(
    questions.map((v) => undefined)
  );

  const updateStates = React.useCallback(
    (value: QuestionsState, key: T) => {
      const newStates = states.map((v, index) => {
        if (questions[index].id === key) {
          return value;
        }
        return v;
      });

      setStates(newStates);
    },
    [questions, states]
  );

  return [states, setStates, updateStates];
};

type TransitionProps = {
  animate?: boolean;
  skeleton?: SkeletonProps;
};

const Transition: React.FC<TransitionProps> = ({
  animate,
  children,
  skeleton,
}) => {
  const [loading, setLoading] = React.useState(true);

  const skelProps = skeleton || { from: true, to: true };

  setTimeout(() => {
    setLoading(false);
  }, 750);

  if (animate) {
    return loading ? (
      <SpeechBubbleSkeleton {...skelProps}>{children}</SpeechBubbleSkeleton>
    ) : (
      <div>{children}</div>
    );
  }

  return <>{children}</>;
};

export const DialogForm: React.FC<Props> = ({
  questions,
  questionsState,
  className,
  children,
}) => {
  const questionDepth = React.useRef(0);
  const messages = new Array<JSX.Element | undefined>();

  let i = 0;
  for (; i < questionsState.length; i++) {
    const prev = i === 0 ? true : questionsState[i - 1];

    if (!prev) {
      break;
    }

    const animate = i > 0 && i >= questionDepth.current;

    messages.push(
      <Transition animate={animate} skeleton={questions[i].skeleton}>
        {questions[i].question}
      </Transition>
    );

    if (questionsState[i] === false && questions[i].invalidResponse) {
      messages.push(<Fade in>{questions[i].invalidResponse}</Fade>);
    }
  }

  if (i - 1 >= questionDepth.current) {
    questionDepth.current = i - 1;
  }

  return (
    <Container maxWidth="md" className={className}>
      {children}
      {messages}
    </Container>
  );
};

export default DialogForm;
