import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ShortVersionIcon from "@material-ui/icons/Timer";

import Markdown from "@app/components/Markdown";

import useTracking from "@app/hooks/useTracking";

export type Question<T> = {
  title: string;
  summary?: (arg0: T) => string;
  content: (arg0: T) => string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alertTitle: {
      fontWeight: "bold",
    },
    accordionDetails: {
      display: "block",
      "& li": {
        fontSize: "1.1em",
        marginBottom: "0.5em",
      },
    },
    alert: {
      borderRadius: 0,
      backgroundColor: theme.palette.primary.light,
      marginBottom: theme.spacing(2),
      color: "white",
      "& .MuiAlert-icon": {
        color: "white",
      },
      "& p": {
        marginBottom: 0,
      },
    },
    questionTitle: {
      fontSize: "1rem",
      fontWeight: "bold",
      color: theme.palette.primary.main,
    },
  })
);

type Props = {
  title: string;
  content: string;
  summary?: string;
};

const FAQ: React.FC<Props> = ({ title, content, summary }) => {
  const classes = useStyles();
  const { logFAQOpen, logFAQClose } = useTracking();

  const handleChange = (event: object, expanded: boolean) => {
    expanded ? logFAQOpen(title) : logFAQClose(title);
  };

  return (
    <Accordion onChange={handleChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography
          variant="h6"
          component="h4"
          className={classes.questionTitle}
        >
          {title}
        </Typography>
      </AccordionSummary>
      {summary && (
        <Alert
          className={classes.alert}
          severity="info"
          icon={<ShortVersionIcon />}
        >
          <AlertTitle className={classes.alertTitle}>Short answer</AlertTitle>
          <Markdown source={summary} />
        </Alert>
      )}

      <AccordionDetails className={classes.accordionDetails}>
        <Markdown source={content} />
      </AccordionDetails>
    </Accordion>
  );
};

function FAQs<T>(questions: Array<Question<T>>): React.FC<T> {
  return (props) => (
    <>
      {questions.map((q) => (
        <FAQ
          title={q.title}
          content={q.content(props)}
          summary={q.summary ? q.summary(props) : undefined}
        />
      ))}
    </>
  );
}

export default FAQs;
