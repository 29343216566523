import React from "react";

import { GitHub, LinkedIn, Link as LinkIcon } from "@material-ui/icons";

type Props = {
  url: string;
};

export const ConnectionIcon: React.FC<Props> = ({ url }) => {
  if (url.match(/github\.com/)) {
    return <GitHub />;
  }

  if (url.match(/linkedin\.com/)) {
    return <LinkedIn />;
  }

  return <LinkIcon />;
};

export default ConnectionIcon;
