import {
  Avatar,
  Badge,
  Chip,
  Container,
  Dialog,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import {
  makeStyles,
  Theme,
  createStyles,
  useTheme,
} from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/AddCircle";
import FileIcon from "@material-ui/icons/AttachFile";
import CloseIcon from "@material-ui/icons/Close";
import EditIcon from "@material-ui/icons/Edit";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import ImageIcon from "@material-ui/icons/Image";
import LinkIcon from "@material-ui/icons/Link";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import LocationIcon from "@material-ui/icons/LocationOn";
import MailIcon from "@material-ui/icons/Mail";
import MessageIcon from "@material-ui/icons/Message";
import PersonIcon from "@material-ui/icons/Person";
import YesIcon from "@material-ui/icons/SentimentSatisfiedAlt";
import StrongYesIcon from "@material-ui/icons/SentimentVerySatisfied";
import AssetIcon from "@material-ui/icons/WebAsset";
import WorkIcon from "@material-ui/icons/Work";
import { Alert } from "@material-ui/lab";
import React from "react";
import { FileUploader } from "react-drag-drop-files";

import { ApplicantSource } from "@higher/models/ApplicantMetadata";
import { Rating } from "@higher/models/Review";
import Role from "@higher/models/Role";
import Stage, { FixedStageID } from "@higher/models/Stage";

import firebase from "@app/Firebase";
import addCandidate from "@app/api/functions/addCandidate";
import { Button } from "@app/brand";
import EditMarkdown from "@app/components/EditMarkdown";
import { createApplicantCommentEvent } from "@app/state/UpdateEvents";
import UpdateEvents from "@app/state/UpdateEvents";
import { createReview } from "@app/state/UpdateReviews";
import UpdateReviews from "@app/state/UpdateReviews";

type Props = {
  open: boolean;
  close: () => void;
  stages: Array<Stage>;
  role: Role;
  user: firebase.User;
  onCreateCandidate: (id: string) => void;
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      minHeight: `calc(100vh - ${theme.spacing(4)}px)`,
      marginBottom: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    rootGrid: {
      height: "100%",
      minHeight: `calc(100vh - ${theme.spacing(4)}px)`,
    },
    windowControls: {
      position: "absolute",
      right: theme.spacing(0.5),
      top: theme.spacing(0.5),

      "& button": {
        padding: theme.spacing(0.5),
      },

      "& svg": {
        width: theme.spacing(2.5),
        height: theme.spacing(2.5),
      },
    },
    controlBarSection: {
      marginBottom: theme.spacing(3),
    },
    controlBarSectionPaper: {
      marginTop: theme.spacing(1),
      padding: theme.spacing(1.5),
    },
    controls: {
      backgroundColor: "#efefef88",
      borderLeft: "1px solid #efefef",
      paddingTop: theme.spacing(8),
    },
    content: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    input: {},
    iconRadioContainer: {
      margin: "0 auto",
      paddingRight: theme.spacing(2),
      marginTop: theme.spacing(2),
      "& .MuiChip-root": {
        marginRight: theme.spacing(2),
      },
    },
    fileUpload: {
      border: `1px dashed ${theme.palette.secondary.main} !important`,
      height: "auto !important",
    },
    avatar: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    primaryCTA: {
      marginBottom: theme.spacing(2),
      textAlign: "right",
    },
    select: {
      width: "100%",
      color: theme.palette.secondary.dark,
      "& svg": {
        color: theme.palette.secondary.dark,
      },
    },
    valignCentre: {
      display: "flex",
      alignContent: "center",
    },
    markdownContainer: {
      marginTop: theme.spacing(4),
    },
    alert: {
      marginBottom: theme.spacing(2),
    },
    progress: {
      position: "absolute",
      zIndex: 1000,
      width: "100%",
      bottom: 0,
      borderBottomLeftRadius: theme.spacing(0.5),
      borderBottomRightRadius: theme.spacing(0.5),
    },
  });
});

const AddCandidateDialog: React.FC<Props> = ({
  open,
  close,
  stages,
  role,
  user,
  onCreateCandidate,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [fullScreen, setFullScreen] = React.useState(false);
  const [name, setName] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [location, setLocation] = React.useState<string>("");
  const [title, setTitle] = React.useState<string>("");
  const [profileImage, setProfileImage] = React.useState<string | undefined>(
    undefined
  );
  const [profileImageLink, setProfileImageLink] = React.useState<
    string | undefined
  >(undefined);
  const [linkedInLink, setLinkedInLink] = React.useState<string | undefined>(
    undefined
  );
  const [cvFile, setCvFile] = React.useState<string | undefined>(undefined);
  const [cvFileName, setCvFileName] = React.useState<string | undefined>(
    undefined
  );
  const [portfolioLink, setPortfolioLink] = React.useState<string | undefined>(
    undefined
  );
  const [markdown, setMarkdown] = React.useState<string | undefined>(undefined);
  const [rating, setRating] = React.useState<Rating | undefined>(undefined);
  const [stageId, setStageId] = React.useState<string>(FixedStageID.Endorsed);
  const [processing, setProcessing] = React.useState<boolean>(false);

  const updateEvents = UpdateEvents(role.organisationId);
  const updateReviews = UpdateReviews(role);

  const fullScreenIcon = fullScreen ? (
    <FullscreenExitIcon />
  ) : (
    <FullscreenIcon />
  );

  const toggleFullScrren = () => {
    setFullScreen(!fullScreen);
  };

  const clearForm = () => {
    setName("");
    setEmail("");
    setLocation("");
    setTitle("");
    setProfileImage(undefined);
    setProfileImageLink(undefined);
    setLinkedInLink(undefined);
    setCvFile(undefined);
    setCvFileName(undefined);
    setPortfolioLink(undefined);
    setMarkdown(undefined);
    setRating(undefined);
    setStageId(FixedStageID.Endorsed);
  };

  const color = (chipRating: Rating | undefined) => {
    return chipRating === rating ? "secondary" : undefined;
  };

  const ratingsFn = (chipRating: Rating | undefined) => {
    return () => setRating(chipRating);
  };

  const handleProfileImageChange = async (file: File) => {
    const reader = new FileReader();
    reader.onload = (evt: any) => {
      setProfileImage(evt.target.result);
    };
    await reader.readAsDataURL(file);
  };

  const handleFileChange = async (file: File) => {
    const reader = new FileReader();
    reader.onload = (evt: any) => {
      setCvFile(evt.target.result);
      setCvFileName(file.name);
    };
    await reader.readAsDataURL(file);
  };

  const handleChangeStage = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStageId(event.target.value as string);
  };

  const handleSubmit = async () => {
    setProcessing(true);
    const response = await addCandidate({
      organisationId: role.organisationId,
      roleId: role.id,
      name,
      email,
      avatar: profileImage,
      avatarURL: profileImageLink,
      location,
      linkedIn: linkedInLink,
      history: title ? [title] : [],
      cvFile,
      cvUrl: portfolioLink,
      source: ApplicantSource.ManuallyAdded,
      stageId: stageId,
    });

    const applicant = { id: response.applicantId };

    if (markdown) {
      await updateEvents(({ create }) =>
        createApplicantCommentEvent(create, applicant, user, markdown)
      );
    }

    if (rating) {
      await updateReviews(({ create }) =>
        createReview(create, applicant, user, rating)
      );
    }

    close();
    setProcessing(false);
    clearForm();
    onCreateCandidate(applicant.id);
  };

  return (
    <Dialog
      open={open}
      fullScreen={isMobile || fullScreen}
      onClose={close}
      maxWidth="lg"
      fullWidth
      scroll="body"
      classes={{
        paper: classes.root,
      }}
    >
      <Grid container className={classes.rootGrid}>
        <Grid xs={12} md={8} className={classes.content}>
          <Container>
            <Grid spacing={2} container alignItems="center">
              <Grid item className={classes.valignCentre}>
                <AddIcon color="primary" />
              </Grid>
              <Grid item>
                <Typography variant="h5">Add a candidate</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6}>
                <TextInput
                  label="Name"
                  description="The candidate's full name"
                  value={name}
                  set={setName}
                  autoFocus
                  icon={
                    <Badge
                      color="secondary"
                      variant="dot"
                      anchorOrigin={{ horizontal: "left", vertical: "top" }}
                    >
                      <PersonIcon color="action" />
                    </Badge>
                  }
                  disabled={processing}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label="Email"
                  description="This will only be used to message the candidate directly"
                  value={email}
                  set={setEmail}
                  icon={
                    <Badge
                      color="secondary"
                      variant="dot"
                      anchorOrigin={{ horizontal: "left", vertical: "top" }}
                    >
                      <MailIcon color="action" />
                    </Badge>
                  }
                  disabled={processing}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label="Location"
                  description="Optional. Usually a city or region"
                  value={location}
                  set={setLocation}
                  icon={<LocationIcon color="disabled" />}
                  disabled={processing}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label="Current job title"
                  description="Optional. You can use this to help you identify the candidate"
                  value={title}
                  set={setTitle}
                  icon={<WorkIcon color="disabled" />}
                  disabled={processing}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  label="LinkedIn profile"
                  description="Optional. This is only for your internal use"
                  value={linkedInLink}
                  set={setLinkedInLink}
                  icon={<LinkedInIcon color="disabled" />}
                  disabled={processing}
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              alignItems="flex-start"
              className={classes.markdownContainer}
            >
              <Grid item>
                <MessageIcon color="disabled" />
              </Grid>
              <Grid item xs>
                <EditMarkdown
                  markdown={markdown}
                  setMarkdown={setMarkdown}
                  label="Add an optional comment and review"
                  disabled={processing}
                />
                <Grid container justify="space-between">
                  <div className={classes.iconRadioContainer}>
                    <Chip
                      variant="outlined"
                      label="No review"
                      color={color(undefined)}
                      onClick={ratingsFn(undefined)}
                      disabled={processing}
                    />
                    <Chip
                      variant="outlined"
                      icon={<YesIcon />}
                      label="Yes"
                      color={color(Rating.Yes)}
                      onClick={ratingsFn(Rating.Yes)}
                      disabled={processing}
                    />
                    <Chip
                      variant="outlined"
                      icon={<StrongYesIcon />}
                      label="Strong yes"
                      color={color(Rating.StrongYes)}
                      onClick={ratingsFn(Rating.StrongYes)}
                      disabled={processing}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <Grid xs={12} md={4} className={classes.controls}>
          <div className={classes.windowControls}>
            <IconButton onClick={toggleFullScrren}>{fullScreenIcon}</IconButton>
            <IconButton onClick={close} disabled={processing}>
              <CloseIcon />
            </IconButton>
          </div>
          <Container>
            <ControlBarSection title="Profile image" noPadding>
              <TabPanel
                tabs={[
                  {
                    title: "Link to image",
                    icon: <AssetIcon color="disabled" />,
                    content: (
                      <Grid container spacing={2} alignItems="center">
                        <Grid item>
                          <Avatar
                            className={classes.avatar}
                            alt={name}
                            src={profileImageLink}
                          />
                        </Grid>
                        <Grid item xs>
                          <LinkInput
                            label="URL"
                            description=""
                            value={profileImageLink}
                            set={setProfileImageLink}
                            disabled={processing}
                          />
                        </Grid>
                      </Grid>
                    ),
                  },

                  {
                    title: "File upload",
                    icon: <ImageIcon color="disabled" />,
                    content: (
                      <FileUploader
                        handleChange={handleProfileImageChange}
                        name="file"
                        types={["JPG", "JPEG", "PNG", "GIF"]}
                        classes={classes.fileUpload}
                        disabled={processing}
                      >
                        <Grid container spacing={2} alignItems="center">
                          <Grid item>
                            <Avatar
                              className={classes.avatar}
                              alt={name}
                              src={profileImage}
                            />
                          </Grid>
                          <Grid item xs>
                            <Typography variant="body1" color="textSecondary">
                              Drag a profile image here, or click to upload one.
                            </Typography>
                          </Grid>
                        </Grid>
                      </FileUploader>
                    ),
                  },
                ]}
                disabled={processing}
              />
            </ControlBarSection>

            <ControlBarSection title="CV / resume or portfolio" noPadding>
              <TabPanel
                tabs={[
                  {
                    title: "File upload",
                    icon: <FileIcon color="disabled" />,
                    content: (
                      <FileUploader
                        handleChange={handleFileChange}
                        name="file"
                        classes={classes.fileUpload}
                        disabled={processing}
                      >
                        <Grid
                          container
                          spacing={1}
                          alignItems="center"
                          style={{ height: 48 }}
                        >
                          <Grid item className={classes.valignCentre}>
                            <FileIcon color="disabled" />
                          </Grid>
                          <Grid item xs>
                            {cvFileName && (
                              <Typography variant="body1" color="textSecondary">
                                {cvFileName}
                              </Typography>
                            )}
                            {!cvFileName && (
                              <Typography variant="body1" color="textSecondary">
                                Drag a file here, or click to upload
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </FileUploader>
                    ),
                  },
                  {
                    title: "Portfolio link",
                    icon: <LinkIcon color="disabled" />,
                    content: (
                      <LinkInput
                        autoFocus
                        label="URL"
                        description=""
                        value={portfolioLink}
                        set={setPortfolioLink}
                        disabled={processing}
                      />
                    ),
                  },
                ]}
                disabled={processing}
              />
            </ControlBarSection>

            <ControlBarSection title="Application stage">
              <Select
                labelId="select-stage"
                id="select-stage"
                className={classes.select}
                value={stageId}
                onChange={handleChangeStage}
                disabled={processing}
              >
                {stages.map((stage) => (
                  <MenuItem value={stage.id}>{stage.title}</MenuItem>
                ))}
              </Select>
            </ControlBarSection>

            <div className={classes.primaryCTA}>
              <Button
                cta
                smallText
                disabled={name === "" || email === "" || processing}
                onClick={handleSubmit}
              >
                Add candidate
                {processing && (
                  <LinearProgress
                    className={classes.progress}
                    color="secondary"
                  />
                )}
              </Button>
            </div>
            <Alert severity="info" className={classes.alert}>
              This candidate will <strong>not</strong> receive a welcome email,
              or any other automated update emails.
            </Alert>
          </Container>
        </Grid>
      </Grid>
    </Dialog>
  );
};

type TabDescriptor = {
  title: string;
  icon: JSX.Element;
  content: JSX.Element;
};

type TabPanelProps = {
  tabs: Array<TabDescriptor>;
  disabled?: boolean;
};

const TabPanel: React.FC<TabPanelProps> = ({ tabs, disabled }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState<number>(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Tabs value={value} onChange={handleChange} style={{ marginBottom: 16 }}>
        {tabs.map(({ title, icon }) => {
          return (
            <Tab
              disabled={disabled}
              label={
                <Grid spacing={1} container alignItems="center">
                  <Grid item className={classes.valignCentre}>
                    {icon}
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">{title}</Typography>
                  </Grid>
                </Grid>
              }
            />
          );
        })}
      </Tabs>
      {tabs.map(({ content }, index) => {
        return <>{index === value && content}</>;
      })}
    </>
  );
};

type ControlBarSectionProps = {
  title: string;
  noPadding?: boolean;
};

const ControlBarSection: React.FC<ControlBarSectionProps> = ({
  title,
  noPadding,
  children,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.controlBarSection}>
      <Typography variant="subtitle2" color="textSecondary">
        {title}
      </Typography>
      <Paper
        className={classes.controlBarSectionPaper}
        style={noPadding ? { paddingTop: 0 } : {}}
      >
        {children}
      </Paper>
    </div>
  );
};

type InputProps<T> = {
  label: string;
  description: string;
  value?: T;
  set: (v: T) => void;
  autoFocus?: boolean;
  icon?: JSX.Element;
  disabled?: boolean;
};

const TextInput: React.FC<InputProps<string>> = ({
  label,
  description,
  value,
  set,
  autoFocus,
  icon,
  disabled,
}) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const v = (event.target as HTMLInputElement).value;
    set(v || "");
  };

  return (
    <Grid container spacing={2} alignItems="center" className={classes.input}>
      <Grid item>{icon || <EditIcon />}</Grid>
      <Grid item xs>
        <TextField
          autoFocus={autoFocus}
          label={label}
          helperText={description}
          fullWidth
          value={value}
          color="secondary"
          type="text"
          onChange={handleChange}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
};

const LinkInput: React.FC<InputProps<string>> = ({
  label,
  description,
  value,
  set,
  autoFocus,
  icon,
  disabled,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const v = (event.target as HTMLInputElement).value;
    set(v || "");
  };

  return (
    <TextField
      autoFocus={autoFocus}
      label={label}
      fullWidth
      value={value}
      color="secondary"
      type="text"
      variant="filled"
      onChange={handleChange}
      disabled={disabled}
    />
  );
};

export default AddCandidateDialog;
