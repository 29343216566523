import { createMuiTheme } from "@material-ui/core/styles";

export const colours = {
  navy: {
    main: "#0F3460",
    dark: "#16213E",
    light: "#304e74",
    black: "#1A1A2E",
  },
  pink: {
    main: "#D625A8",
    dark: "#D625A8",
    light: "#FF00C8",
  },
  grey: {
    dark: "#51545C",
    light: "#D6D6D6",
  },
};

const theme = createMuiTheme({
  typography: {
    fontFamily: ["'Source Sans Pro'"].join(","),
    h1: {
      fontFamily: "Inter",
      fontWeight: "bold",
      textTransform: "uppercase",
      color: colours.navy.main,
    },
    h2: {
      fontFamily: "Inter",
      fontWeight: "bold",
      color: colours.navy.main,
    },
    h3: {
      fontFamily: "Inter",
      fontWeight: "lighter",
      color: colours.navy.main,
    },
    h4: {
      fontFamily: "Inter",
      fontWeight: "lighter",
      color: colours.navy.main,
    },
    h5: {
      fontFamily: "Inter",
      fontWeight: "lighter",
      color: colours.navy.main,
    },
  },
  spacing: 8,
  palette: {
    primary: colours.navy,
    secondary: colours.pink,
  },
  overrides: {
    MuiRadio: {
      root: {
        color: colours.pink.dark,
      },
      colorSecondary: {
        "&$checked": {
          color: colours.pink.dark,
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: colours.pink.dark,
      },
      colorSecondary: {
        "&$checked": {
          color: colours.pink.dark,
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        minWidth: "200px",
      },
    },
    MuiCssBaseline: {
      "@global": {
        ".firebase-emulator-warning": {
          display: "none",
        },
      },
    },
  },
});

Object.assign(theme.overrides, {
  MUIRichTextEditor: {
    root: {
      backgroundColor: "#fdfdfd",
      border: "1px solid #efefef",
      borderRadius: 2,
      minHeight: theme.spacing(32),
    },
    container: {
      marginTop: 0,
      fontSize: "1em !important",
    },
    placeHolder: {
      height: "200px",
      overflow: "auto",
      paddingTop: 8,
      paddingLeft: 16,
      paddingRight: 16,
      color: "#aaa",
    },
    editor: {
      height: "200px",
      overflow: "auto",
      paddingTop: 8,
      paddingLeft: 16,
      paddingRight: 16,
    },
    editorContainer: {
      minHeight: 170,
    },
    toolbar: {
      borderBottom: "1px solid #efefef",
      "& button svg": {
        fontSize: "1.2rem",
      },
    },
    anchorLink: {
      textDecoration: "underline",
    },
  },
});

export default theme;
