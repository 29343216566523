import React from "react";
import { FormatCurrency } from "@higher/payments/currency";
import Applicant from "@higher/models/Applicant";
import Role from "@higher/models/Role";
import Share from "./Share";

type Props = {
  applicant: Applicant;
  role: Role;
  fullWidth?: boolean;
};

export const ShareLink: React.FC<Props> = ({ applicant, role, fullWidth }) => {
  return (
    <>
      {applicant.shortLink && (
        <Share
          url={applicant.shortLink}
          title={`Could you help me get hired at ${role.employer}?`}
          description={`I'm applying to be a ${role.title} at ${
            role.employer
          } and I need your support. There's a reward of ${FormatCurrency(
            role.currency,
            role.maxStake
          )} for you if I end up getting hired, too.`}
          fullWidth={fullWidth}
          image={applicant.socialSharingImage}
        />
      )}
    </>
  );
};

export default ShareLink;
