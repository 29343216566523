import React from "react";
import { RouteComponentProps } from "react-router-dom";

import Applicant from "@higher/models/Applicant";

import WrapErrorSuspense from "@app/api/WrapErrorSuspense";
import useFirestoreValue from "@app/hooks/useFirestoreValue";
import ErrorFallback from "@app/modules/Error";
import ApplicantByIdState from "@app/state/ApplicantById";
import RoleState from "@app/state/RoleById";

import Social, { SocialSkeleton } from "./Social.stateless";

type Props = {
  applicant: Applicant;
};

const SocialView: React.FC<Props> = ({ applicant }) => {
  const [role, isLoading] = useFirestoreValue(RoleState(applicant.roleId));

  if (isLoading) {
    return <SocialSkeleton />;
  }

  return <Social applicant={applicant} role={role} />;
};

type DispatcherProps = RouteComponentProps<{
  id: string;
}>;

const Dispatcher: React.FC<DispatcherProps> = (props) => {
  const [applicant, isLoading] = useFirestoreValue(
    ApplicantByIdState(props.match.params.id)
  );

  if (isLoading) {
    return <SocialSkeleton />;
  }

  return <SocialView applicant={applicant} />;
};

export default WrapErrorSuspense<DispatcherProps>(
  Dispatcher,
  SocialSkeleton,
  ErrorFallback
);
