import Firebase from "@app/Firebase";

export type Updater<T> = (id: string, value: Partial<T>) => void;
export type Deleter = (id: string) => void;
export type Creator<T> = (data: Omit<T, "id">) => void;
export type CreateUpdateDeleter<T> = {
  create: Creator<T>;
  update: Updater<T>;
  remove: Deleter;
};
export type UpdateCallback<T> = (args: CreateUpdateDeleter<T>) => void;
export type BatchUpdateFn<T> = (callback: UpdateCallback<T>) => void;

// Code debt: only handling a single type is inefficient.
// See https://github.com/Higher-labs/operations/issues/25

export const batchUpdate = async <
  T,
  U extends UpdateCallback<T> = UpdateCallback<T>
>(
  collection: string,
  callback: U
) => {
  const db = Firebase.firestore();
  const batch = db.batch();

  const create: Creator<T> = (data) => {
    const ref = db.collection(collection).doc();
    batch.set(ref, data);
  };

  const update: Updater<T> = (id, value) => {
    const ref = db.collection(collection).doc(id);
    batch.update(ref, value);
  };

  const remove: Deleter = (id) => {
    const ref = db.collection(collection).doc(id);
    batch.delete(ref);
  };

  const operations = {
    create,
    update,
    remove,
  };

  callback(operations);

  await batch.commit();
};

export default batchUpdate;
