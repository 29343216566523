import Stage from "@higher/models/Stage";
import Role from "@higher/models/Role";

import queryStateSubscriptionByFunc from "./queryStateSubscriptionByFunc";

export default queryStateSubscriptionByFunc<Stage, Role>(
  "stagesByRole",
  (role) => `organisations/${role.organisationId}/roles/${role.id}/stages`,
  (query, role) => {
    return query.orderBy("index", "asc");
  }
);
