import { Avatar, Tooltip, Typography } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Skeleton,
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from "@material-ui/lab";
import React from "react";
import { useRecoilValue } from "recoil";

import Event, { EventType, EventUserType } from "@higher/models/Event";

import { Accent } from "@app/brand";
import asyncComponent from "@app/components/asyncComponent";
import humanFriendlyDate from "@app/helpers/humanFriendlyDate";
import useFirestoreValue from "@app/hooks/useFirestoreValue";
import ApplicantById from "@app/state/ApplicantById";
import StageByOrgRoleAndId, {
  StageAddress,
} from "@app/state/StageByOrgRoleAndId";
import UserById from "@app/state/UserById";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      alignItems: "flex-start",
      paddingLeft: 0,
      paddingRight: 0,
    },
    timelineItem: {
      padding: 0,
      width: "100%",
      "&::before": {
        display: "none",
      },
    },
    timeAgo: {
      color: "#aaa",
    },
    timelineDot: {
      padding: 0,
      boxShadow: theme.shadows[0],
      border: "none",
      color: "white",
    },
    timelineAvatar: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    timelineContent: {
      paddingTop: theme.spacing(1.6),
      paddingRight: 0,
    },
  });
});

export type EventProps = {
  event: Event;
};

export type EventContentViewProps = {
  AddedView: React.FC<EventProps>;
  ApplicationView: React.FC<EventProps>;
  EndorsementView: React.FC<EventProps>;
  CommentView: React.FC<EventProps>;
  StageChangeView: React.FC<EventProps>;
  RankChangeView: React.FC<EventProps>;
  WithdrawlView: React.FC<EventProps>;
  ReviewView: React.FC<EventProps>;
  IncomingMessageView: React.FC<EventProps>;
  OutgoingMessageView: React.FC<EventProps>;
  RemovalMessageView: React.FC<EventProps>;
  RestorationMessageView: React.FC<EventProps>;
};
export type EventViewProps = EventProps & {
  EventContentView: React.FC<EventProps>;
  isLast?: boolean;
};

type Props = EventContentViewProps & {
  events: Array<Event>;
};

export const HigherTimeline: React.FC<Props> = ({
  events,
  AddedView,
  ApplicationView,
  EndorsementView,
  CommentView,
  StageChangeView,
  RankChangeView,
  WithdrawlView,
  ReviewView,
  IncomingMessageView,
  OutgoingMessageView,
  RemovalMessageView,
  RestorationMessageView,
}) => {
  const classes = useStyles();
  return (
    <Timeline className={classes.root}>
      {events.map((event, i, arr) => (
        <EventView
          event={event}
          isLast={arr.length - 1 === i}
          EventContentView={EventContent({
            AddedView,
            ApplicationView,
            EndorsementView,
            CommentView,
            StageChangeView,
            RankChangeView,
            WithdrawlView,
            ReviewView,
            IncomingMessageView,
            OutgoingMessageView,
            RemovalMessageView,
            RestorationMessageView,
          })}
        />
      ))}
    </Timeline>
  );
};

export const EventView: React.FC<EventViewProps> = ({
  event,
  isLast,
  EventContentView,
}) => {
  const classes = useStyles();

  return (
    <TimelineItem key={event.id} className={classes.timelineItem}>
      <TimelineSeparator>
        <TimelineDot className={classes.timelineDot}>
          <EventAvatar event={event} />
        </TimelineDot>
        {!isLast && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent className={classes.timelineContent}>
        <EventContentView event={event} />
      </TimelineContent>
    </TimelineItem>
  );
};

const EventContent =
  ({
    ApplicationView,
    AddedView,
    EndorsementView,
    CommentView,
    StageChangeView,
    RankChangeView,
    WithdrawlView,
    ReviewView,
    IncomingMessageView,
    OutgoingMessageView,
    RemovalMessageView,
    RestorationMessageView,
  }: EventContentViewProps): React.FC<EventProps> =>
  ({ event }) => {
    switch (event.type) {
      case EventType.Added:
        return <AddedView event={event} />;

      case EventType.Application:
        return <ApplicationView event={event} />;

      case EventType.Endorsement:
        return <EndorsementView event={event} />;

      case EventType.Comment:
        return <CommentView event={event} />;

      case EventType.StageChange:
        return <StageChangeView event={event} />;

      case EventType.RankChange:
        return <RankChangeView event={event} />;

      case EventType.Withdrawl:
        return <WithdrawlView event={event} />;

      case EventType.Review:
        return <ReviewView event={event} />;

      case EventType.IncomingMessage:
        return <IncomingMessageView event={event} />;

      case EventType.OutgoingMessage:
        return <OutgoingMessageView event={event} />;

      case EventType.CandidateRemoval:
        return <RemovalMessageView event={event} />;

      case EventType.CandidateRestoration:
        return <RestorationMessageView event={event} />;

      default:
        return (
          <Typography variant="body2" color="textSecondary">
            Unknown event type '{event.type}'
          </Typography>
        );
    }
  };

export const EventAvatarUnknown: React.FC = () => {
  const classes = useStyles();
  return <Avatar className={classes.timelineAvatar} />;
};

export const EventAvatar: React.FC<EventProps> = ({ event }) => {
  switch (event.initiatorType) {
    case EventUserType.Applicant:
      return <EventAvatarForApplicant event={event} />;

    case EventUserType.User:
      return <EventAvatarForUser event={event} />;

    default:
      return <EventAvatarUnknown />;
  }
};

export const EventAvatarForApplicant: React.FC<EventProps> = ({ event }) => {
  const classes = useStyles();
  const [applicant, isLoading] = useFirestoreValue(
    ApplicantById(event.initiatorId)
  );
  if (isLoading) {
    return <EventAvatarUnknown />;
  }
  return (
    <Avatar
      className={classes.timelineAvatar}
      alt={applicant.name}
      src={applicant.avatar}
    />
  );
};

export const EventAvatarForUser: React.FC<EventProps> = ({ event }) => {
  const classes = useStyles();
  const [user, isLoading] = useFirestoreValue(UserById(event.initiatorId));
  if (isLoading) {
    return <EventAvatarUnknown />;
  }
  return (
    <Avatar
      className={classes.timelineAvatar}
      alt={user.displayName || undefined}
      src={user.photoURL || undefined}
    />
  );
};

type UsernameProps = {
  id: string;
};

export const Username = asyncComponent<UsernameProps>(
  ({ id }) => {
    const user = useRecoilValue(UserById(id));
    return <Accent>{user ? user.displayName : "Someone"}</Accent>;
  },
  () => (
    <Skeleton>
      <Accent>Someone</Accent>
    </Skeleton>
  ),
  () => <Accent>Someone</Accent>
);

export const StageName = asyncComponent<StageAddress>(
  (address) => {
    const stage = useRecoilValue(StageByOrgRoleAndId(address));
    return <Accent>{stage ? stage.title : "A deleted stage"}</Accent>;
  },
  () => (
    <Skeleton>
      <Accent>Unknown</Accent>
    </Skeleton>
  ),
  () => <Accent>Unknown</Accent>
);

export type TimeAgoProps = {
  date?: Date;
  className?: string;
};

export const TimeAgo: React.FC<TimeAgoProps> = ({ date, className }) => {
  const classes = useStyles();
  if (!date) {
    return null;
  }
  const dateString = `${date.toDateString()} at ${date.toLocaleTimeString(
    "en",
    {
      hour: "numeric",
      minute: "2-digit",
    }
  )}`;
  return (
    <Tooltip arrow placement="right" title={dateString} aria-label={dateString}>
      <span className={className || classes.timeAgo}>
        {humanFriendlyDate(date)}
      </span>
    </Tooltip>
  );
};

export default HigherTimeline;
