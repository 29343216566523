import React from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";

import Role from "@higher/models/Role";

import Firebase from "@app/Firebase";
import WrapErrorSuspense from "@app/api/WrapErrorSuspense";
import useFirestoreValue from "@app/hooks/useFirestoreValue";
import ErrorFallback from "@app/modules/Error";
import RoleByIdState from "@app/state/RoleById";

import Landing, { LandingSkeleton as Skeleton } from "./Landing.stateless";
import usePreFill from "./Prefill";

type Props = {
  role: Role;
};

const View: React.FC<Props> = ({ role }) => {
  const history = useHistory();
  const [, setPrefill] = usePreFill(role.id);

  const applyWithLinkedIn = async () => {
    const provider = new Firebase.auth.OAuthProvider("linkedin.com");
    provider.addScope("r_emailaddress");
    provider.addScope("r_liteprofile");
    try {
      const result = await Firebase.auth().signInWithPopup(provider);
      if (result.user) {
        setPrefill({
          name: result.user.displayName,
          email: result.user.email,
          avatar: result.user.photoURL,
        });
      }
      next();
    } catch (e) {
      console.log(e);
    }
  };

  const applyWithGoogle = async () => {
    const provider = new Firebase.auth.GoogleAuthProvider();
    try {
      const result = await Firebase.auth().signInWithPopup(provider);
      if (result.user) {
        setPrefill({
          name: result.user.displayName,
          email: result.user.email,
          avatar: result.user.photoURL,
        });
      }
      next();
    } catch (e) {
      console.log(e);
    }
  };

  const applyWithNothing = () => {
    setPrefill({});
    next();
  };

  const next = async () => {
    history.push(`/apply/form/${role.id}`);
  };

  return (
    <Landing
      role={role}
      applyWithLinkedIn={applyWithLinkedIn}
      applyWithGoogle={applyWithGoogle}
      applyWithNothing={applyWithNothing}
    >
      Hi
    </Landing>
  );
};

type DispatcherProps = RouteComponentProps<{
  id: string;
}>;

const Dispatcher: React.FC<DispatcherProps> = (props) => {
  const [role, isLoading] = useFirestoreValue(
    RoleByIdState(props.match.params.id)
  );

  if (isLoading) {
    return <Skeleton />;
  }

  return <View role={role} />;
};

export default WrapErrorSuspense<DispatcherProps>(
  Dispatcher,
  Skeleton,
  ErrorFallback
);
