import React from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";

import Applicant, { ApplicationStatus } from "@higher/models/Applicant";

import WrapErrorSuspense from "@app/api/WrapErrorSuspense";
import useFirestoreValue from "@app/hooks/useFirestoreValue";
import ErrorFallback from "@app/modules/Error";
import demoPayment from "@app/payments/demo";
import candidatepayment from "@app/payments/stripe";
import ApplicantByIdState from "@app/state/ApplicantById";
import RoleState from "@app/state/RoleById";

import Questions, { QuestionsSkeleton } from "./Questions.stateless";
import TooManyEndorsements from "./TooManyEndorsements";

type Props = {
  applicant: Applicant;
};

const View: React.FC<Props> = ({ applicant }) => {
  const [role, isLoading] = useFirestoreValue(RoleState(applicant.roleId));
  const history = useHistory();

  if (isLoading) {
    return <QuestionsSkeleton />;
  }

  const next = (id: string) => {
    history.replace(`/endorse/complete/${id}`);
  };

  if (applicant.status !== ApplicationStatus.Pending) {
    throw new Error("That application has been withdrawn");
  }

  if (role.maxEndorsers && applicant.endorsements >= role.maxEndorsers) {
    return <TooManyEndorsements applicant={applicant} role={role} />;
  }

  return (
    <Questions
      applicant={applicant}
      role={role}
      makeCandidateEndorsementPayment={candidatepayment}
      makeDemoPayment={demoPayment}
      next={next}
    />
  );
};

type DispatcherProps = RouteComponentProps<{
  id: string;
}>;

const Dispatcher: React.FC<DispatcherProps> = (props) => {
  const applicant = useRecoilValue(ApplicantByIdState(props.match.params.id));

  if (!applicant) {
    throw new Error("That application doesn't exist");
  }
  return <View applicant={applicant} />;
};

export default WrapErrorSuspense<DispatcherProps>(
  Dispatcher,
  QuestionsSkeleton,
  ErrorFallback
);
