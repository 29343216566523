import React from "react";
import {
  fade,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import { Avatar } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import { UpArrow } from "@app/brand";

import Applicant from "@higher/models/Applicant";
import Role from "@higher/models/Role";

type Props = {
  applicant: Applicant;
  role: Role;
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    avatarGroup: {
      marginBottom: theme.spacing(4),
      display: "flex",
      alignContent: "center",
      justifyContent: "center",

      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(3),
      },
    },
    largeAvatar: {
      width: "4rem",
      height: "4rem",
      boxShadow: theme.shadows[2],

      [theme.breakpoints.down("sm")]: {
        width: "3.5rem",
        height: "3.5rem",
      },

      [theme.breakpoints.up("xl")]: {
        width: "5rem",
        height: "5rem",
      },
    },
    arrow: {
      width: "2rem",
      height: "2rem",
      justifySelf: "center",
      alignSelf: "center",
      marginLeft: "-0.5rem",
      marginRight: "-0.5rem",
      transform: "rotate(45deg)",
      background: `url(${UpArrow.Arrow})`,
      backgroundSize: "1rem",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "43% 57%",
      backgroundColor: "white",
      borderRadius: "50%",
      zIndex: 1,
      boxShadow: theme.shadows[1],

      [theme.breakpoints.down("sm")]: {
        width: "1.5rem",
        height: "1.5rem",
        backgroundSize: "0.75rem",
      },

      [theme.breakpoints.up("xl")]: {
        width: "2.5rem",
        height: "2.5rem",
        backgroundSize: "1.25rem",
      },
    },
    arrowSkeleton: {
      background: fade(
        theme.palette.text.primary,
        theme.palette.type === "light" ? 0.11 : 0.13
      ),
      boxShadow: "none",
      visibility: "hidden",
    },
  });
});

const ParticipantAvatars: React.FC<Props> = ({ applicant, role }) => {
  const classes = useStyles();
  return (
    <div className={classes.avatarGroup}>
      <Avatar
        alt={applicant.name}
        src={applicant.avatar}
        className={classes.largeAvatar}
      />

      <div className={classes.arrow} />
      <Avatar
        alt={role.employer}
        src={role.avatar}
        className={classes.largeAvatar}
      />
    </div>
  );
};

export const ParticipantAvatarsSkeleton: React.FC<Props> = ({
  applicant,
  role,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.avatarGroup}>
      <Skeleton variant="circle" className={classes.largeAvatar} />
      <Skeleton
        variant="circle"
        className={[classes.arrow, classes.arrowSkeleton].join(" ")}
      />
      <Skeleton variant="circle" className={classes.largeAvatar} />
    </div>
  );
};

export default ParticipantAvatars;
