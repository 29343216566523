import { useMediaQuery } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import React from "react";

export type ResponsiveViews<Props> = {
  small?: React.FC<Props>;
  large?: React.FC<Props>;
};

export function ResponsiveFC<Props = {}>(
  fcs: ResponsiveViews<Props>
): React.FC<Props> {
  const R: React.FC<Props> = (props) => {
    let C: React.FC<Props> = () => null;

    const isLarge = useMediaQuery<Theme>(
      (theme) => theme.breakpoints.up("lg"),
      {
        noSsr: true,
      }
    );
    if (fcs.small && !isLarge) {
      C = fcs.small;
    } else if (fcs.large && isLarge) {
      C = fcs.large;
    }
    return <C {...props} />;
  };

  return R;
}

export default ResponsiveFC;
