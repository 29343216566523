import Organisation from "@higher/models/Organisation";

import batchUpdate, { BatchUpdateFn, UpdateCallback } from "./batchUpdate";

export type UpdateInvitationsFn = BatchUpdateFn<Organisation>;

export const UpdateOrganisations =
  () => (callback: UpdateCallback<Organisation>) =>
    batchUpdate<Organisation>(`organisations`, callback);

export default UpdateOrganisations;
