import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Container,
  Dialog,
  DialogTitle,
  DialogActions,
  Grid,
  Slider,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import Cropper from "react-easy-crop";

import { Button } from "@app/brand";

type Props = {
  image: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  setImage: (img: string) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "relative",
      width: "100vw",
      height: "100vw",
      [theme.breakpoints.up("lg")]: {
        width: theme.breakpoints.values.sm,
        height: theme.breakpoints.values.sm,
      },
    },
    cta: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(-1.2),

      [theme.breakpoints.up("lg")]: {
        right: theme.spacing(1.5),
      },
    },

    controls: {
      padding: theme.spacing(3),
      paddingTop: theme.spacing(4),
    },
  })
);

const ImageCropperModal: React.FC<Props> = ({
  image,
  open,
  setOpen,
  setImage,
}) => {
  const classes = useStyles();
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = async (
    croppedArea: any,
    newCroppedAreaPixels: any
  ) => {
    setCroppedAreaPixels(newCroppedAreaPixels);
  };

  const done = async () => {
    const croppedImage = await getCroppedImg(
      image,
      croppedAreaPixels,
      rotation
    );
    setImage(croppedImage);
    setOpen(false);
  };

  const onZoomChange = (z: number | Array<number>) => {
    const value: number = Array.isArray(z) ? z[0] : z;
    setZoom(value);
  };

  const onRotationChange = (z: number | Array<number>) => {
    const value: number = Array.isArray(z) ? z[0] : z;
    setRotation(value);
  };

  return (
    <Dialog open={open} fullScreen={isSmall}>
      <DialogTitle disableTypography>
        <Typography>Frame your image</Typography>
      </DialogTitle>
      <Container className={classes.container}>
        <Cropper
          image={image}
          crop={crop}
          zoom={zoom}
          rotation={rotation}
          aspect={1}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          cropShape="round"
        />
      </Container>
      <Container className={classes.controls}>
        <Grid container spacing={isSmall ? 1 : 6}>
          <Grid xs={12} md={6} item>
            <Typography gutterBottom>Zoom</Typography>
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e, zoom) => onZoomChange(zoom)}
            />
          </Grid>
          <Grid xs={12} md={6} item>
            <Typography gutterBottom>Rotation</Typography>
            <Slider
              value={rotation}
              min={0}
              max={360}
              step={10}
              aria-labelledby="Rotation"
              onChange={(e, r) => onRotationChange(r)}
            />
          </Grid>
        </Grid>
      </Container>

      <DialogActions>
        <Button small cta secondary onClick={() => done()}>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const createImage = (url: string): any =>
  new Promise<any>((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

const getRadianAngle = (degreeValue: number): number => {
  return (degreeValue * Math.PI) / 180;
};

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
export async function getCroppedImg(
  imageSrc: string,
  pixelCrop: any,
  rotation: number
): Promise<string> {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("failed to create context");
  }

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
  );

  // As Base64 string
  return canvas.toDataURL("image/jpeg");

  // As a blob
  /*
  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      resolve(URL.createObjectURL(file))
    }, 'image/png')
  })
	*/
}

export default ImageCropperModal;
