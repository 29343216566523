import {
  CoinbaseChargePayload,
  CoinbaseChargeResponse,
} from "@higher/api/coinbase";

import { Functions } from "@app/Firebase";

const createCharge = async (
  payload: CoinbaseChargePayload
): Promise<CoinbaseChargeResponse> => {
  const fn = Functions.httpsCallable("coinbaseCharge");
  const result = await fn(payload);
  return result.data as CoinbaseChargeResponse;
};

export default createCharge;
