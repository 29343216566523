import Organisation, { AccessLevel } from "@higher/models/Organisation";

import queryStateSubscriptionByFunc from "./queryStateSubscriptionByFunc";

const collection = "organisations";

export default queryStateSubscriptionByFunc<Organisation, string>(
  "organisationsByUserID",
  collection,
  (query, id) => {
    return query.where(`members.${id}`, "in", [
      AccessLevel.Member,
      AccessLevel.Owner,
    ]);
  }
);
