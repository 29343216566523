import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Avatar, CircularProgress } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import { UpArrow } from "@app/brand";

type Props = {
  fromHigher?: boolean;
  header?: boolean;
  attachment?: JSX.Element;
  progress?: number;
  fullWidth?: boolean;
};

const responseColour = "#426187";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
      marginTop: "2em",
      margin: "1em",

      [theme.breakpoints.down("sm")]: {
        marginTop: "1.5em",
      },
    },
    alignRight: {
      display: "flex",
      justifyContent: "flex-end",
    },
    dialog: {
      width: "85%",
      color: "white",
      padding: "1.5em",
      paddingLeft: "2em",

      [theme.breakpoints.down("sm")]: {
        padding: "1.4em 1.2em 1.4em 1.2em",
      },
    },
    centered: {
      width: "70%",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "0em",
      marginBottom: "3em",

      [theme.breakpoints.down("sm")]: {
        width: "75%",
      },
    },
    fullWidth: {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    header: {
      background: theme.palette.primary.main,
      borderRadius: "1em 1em 1em 0em",
      [theme.breakpoints.down("sm")]: {
        borderRadius: "0.75em 0.75em 0.75em 0em",
      },
    },
    fromHigher: {
      background: theme.palette.primary.main,
      borderRadius: "1em 1em 1em 0em",
      [theme.breakpoints.down("sm")]: {
        borderRadius: "0.75em 0.75em 0.75em 0em",
      },
    },
    toHigher: {
      background: responseColour,
      borderRadius: "1em 1em 0em 1em",
      [theme.breakpoints.down("sm")]: {
        borderRadius: "0.75em 0.75em 0em 0.75em",
        paddingTop: "0.75em",
        paddingBottom: "0.75em",
      },
    },
    tailLeft: {
      width: "7px",
      height: "8px",
      position: "absolute",
      left: "-5px",
      bottom: "0px",
      overflow: "hidden",
    },
    tailLeftInner: {
      position: "absolute",
      border: `8px solid ${theme.palette.primary.main}`,
      borderRadius: "50%",
      width: "16px",
      height: "16px",
      right: "-6px",
      bottom: "-7px",
      boxSizing: "unset",
    },
    tailLeftLarge: {
      width: "0px",
      height: "0px",
      position: "absolute",
      borderRight: "10px solid transparent",
      borderLeft: `10px solid ${theme.palette.primary.main}`,
      borderTop: `10px solid ${theme.palette.primary.main}`,
      borderBottom: "10px solid transparent",
      left: "0px",
      bottom: "-19px",
    },

    tailRight: {
      width: "7px",
      height: "8px",
      position: "absolute",
      right: "-5px",
      bottom: "0px",
      overflow: "hidden",
    },
    tailRightInner: {
      position: "absolute",
      border: `8px solid ${responseColour}`,
      borderRadius: "50%",
      width: "16px",
      height: "16px",
      right: "-19px",
      bottom: "-7px",
      boxSizing: "unset",
    },

    avatarContainer: {
      position: "relative",
      top: "-20px",
      left: "-0.75em",
    },
    avatar: {
      position: "absolute",
      boxShadow: "0 3px 5px 1px rgba(0,0,0,.17)",
      background: theme.palette.primary.main,
      height: "2em",
      width: "2em",
      top: "0",
      left: "0em",
      border: `5px solid ${theme.palette.primary.main}`,
    },
    avatarProgress: {
      position: "absolute",
      top: "0px",
      left: "0px",
      color: theme.palette.secondary.dark,
      zIndex: 100,
    },

    attachment: {
      paddingLeft: "1em",
      width: "80%",
      [theme.breakpoints.down("sm")]: {
        width: "83%",
      },
    },
    attachmentTo: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: theme.spacing(2),
    },

    skeletonRoot: {
      height: "auto",
      position: "relative",
      "& > *": {
        visibility: "hidden",
      },
    },
    bubbleSkeletonContainer: {
      position: "absolute",
      visibility: "visible",
      top: "0",
      left: "0",
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    bubbleSkeleton: {
      height: "100%",
      width: "80%",
      margin: "0.5em 1em",
      borderRadius: "1em 1em 1em 1em",
      [theme.breakpoints.down("sm")]: {
        borderRadius: "0.75em 0.75em 0.75em 0.75em",
      },
    },
    bubbleSkeletonHeader: {
      width: "70%",
      marginLeft: "auto",
      marginRight: "auto",
      [theme.breakpoints.down("sm")]: {
        width: "75%",
      },
    },
    bubbleSkeletonAttachment: {
      width: "70%",
    },
    bubbleSkeletonTo: {
      marginLeft: "20%",
    },
    bubbleSkeletonButton: {
      width: "100%",
    },
  })
);

export type SkeletonProps = {
  header?: boolean;
  from?: boolean;
  attachment?: boolean;
  to?: boolean;
  button?: boolean;
};

export const SpeechBubbleSkeleton: React.FC<SkeletonProps> = ({
  header,
  children,
  from,
  attachment,
  to,
  button,
}) => {
  const classes = useStyles();

  const headerClass = [
    classes.bubbleSkeleton,
    classes.bubbleSkeletonHeader,
  ].join(" ");
  const fromClass = [classes.bubbleSkeleton].join(" ");
  const attachClass = [
    classes.bubbleSkeleton,
    classes.bubbleSkeletonAttachment,
  ].join(" ");

  const toClass = [classes.bubbleSkeleton, classes.bubbleSkeletonTo].join(" ");
  const buttonClass = [
    classes.bubbleSkeleton,
    classes.bubbleSkeletonButton,
  ].join(" ");

  return (
    <div className={classes.skeletonRoot}>
      {children}
      <div className={classes.bubbleSkeletonContainer}>
        {header && <Skeleton className={headerClass} variant="rect" />}
        {from && <Skeleton className={fromClass} variant="rect" />}
        {attachment && <Skeleton className={attachClass} variant="rect" />}
        {to && <Skeleton className={toClass} variant="rect" />}
        {button && <Skeleton className={buttonClass} variant="rect" />}
      </div>
    </div>
  );
};

const SpeechBubble: React.FC<Props> = ({
  children,
  fromHigher,
  header,
  attachment,
  progress,
  fullWidth,
}) => {
  const classes = useStyles();
  const dialogClasses = [classes.dialog];
  const rootClasses = [classes.root];
  const attachClasses = [classes.attachment];
  let tailClass = fromHigher || header ? classes.tailLeft : classes.tailRight;
  let tailClassInner =
    fromHigher || header ? classes.tailLeftInner : classes.tailRightInner;

  if (header) {
    rootClasses.push(classes.centered);
    dialogClasses.push(classes.header);
    tailClass = classes.tailLeftLarge;
    tailClassInner = "";
  } else {
    dialogClasses.push(fromHigher ? classes.fromHigher : classes.toHigher);
  }

  if (!fromHigher && !header) {
    rootClasses.push(classes.alignRight);
    attachClasses.push(classes.attachmentTo);
  }

  if (fullWidth) {
    dialogClasses.push(classes.fullWidth);
  }

  return (
    <>
      <div className={rootClasses.join(" ")}>
        {fromHigher && (
          <div className={classes.avatarContainer}>
            <Avatar
              alt="Higher"
              src={UpArrow.ArrowMargin}
              className={classes.avatar}
            />
            {progress && (
              <CircularProgress
                className={classes.avatarProgress}
                variant="determinate"
                thickness={3}
                value={progress}
              />
            )}
          </div>
        )}

        <div className={dialogClasses.join(" ")}>{children}</div>
        <div className={tailClass}>
          <div className={tailClassInner} />
        </div>
      </div>
      {attachment && (
        <div className={attachClasses.join(" ")}>{attachment}</div>
      )}
    </>
  );
};

export default SpeechBubble;
