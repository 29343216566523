export enum Currencies {
  GBP = "GBP",
  USD = "USD",
}

export type Currency = Currencies.GBP | Currencies.USD;

export const FormatCurrency = (currency: Currency, amount?: number): string => {
  switch (currency) {
    case Currencies.GBP:
      return `£${amount || " "}`;

    case Currencies.USD:
    default:
      return `$${amount || " "}`;
  }
};

export default Currency;
