import {
  Avatar,
  Card,
  CardHeader,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Link,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  AssignmentTurnedIn as RoleIcon,
  ExitToApp as EndorseSelfIcon,
  QuestionAnswer as HelpIcon,
  Share as ShareIcon,
  Block as WithdrawIcon,
  Chat as BetaIcon,
} from "@material-ui/icons";
import { Alert, AlertTitle, Skeleton } from "@material-ui/lab";
import React from "react";
import { useHistory } from "react-router-dom";

import Applicant from "@higher/models/Applicant";
import Role from "@higher/models/Role";

import { Accent, UpArrow } from "@app/brand";
import { Sitemap, LinkProps } from "@app/configs/navigation";
import { ResponsiveFC } from "@app/helpers/responsiveness";
import { openSlassk } from "@app/helpers/slaask";
import SmallMenu from "@app/modules/header/NavMenu.small";

import CopyLink, { CopyLinkSkeleton } from "./CopyLink";
import HowEndorsementsworkPopup from "./HowEndorsementsWorkPopup";
import RolePopup from "./RolePopup";
import WithdrawApplicationPopup from "./WithdrawApplicationPopup";

type Props = {
  applicant: Applicant;
  role: Role;
  selfEndorse: () => void;
  withdrawApplication: () => void;
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    higherLogo: {
      cursor: "pointer",
    },
    headerCard: {
      boxShadow: theme.shadows[0],
      padding: 0,
      marginBottom: theme.spacing(4),
    },
    cardHeader: {
      padding: 0,
      margin: 0,
      "& .MuiCardHeader-action": {
        marginTop: -2,
        opacity: 0.8,
      },
    },
    avatarContainer: {
      marginLeft: "auto",
      alignSelf: "center",
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
      margin: "auto",
    },
    avatar: {
      width: "2em",
      height: "2em",
    },
    arrow: {
      width: "1.5rem",
      height: "1.5rem",
      justifySelf: "center",
      alignSelf: "center",
      marginLeft: "-0.5rem",
      marginRight: "-0.5rem",
      transform: "rotate(45deg)",
      background: `url(${UpArrow.Arrow})`,
      backgroundSize: "0.75rem",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundColor: "white",
      borderRadius: "50%",
      zIndex: 1,
      boxShadow: theme.shadows[1],
    },

    avatarSkeleton: {
      border: "none",
    },
    arrowSkeleton: {
      boxShadow: "none",
      visibility: "hidden",
    },
    listItemIcon: {
      minWidth: theme.spacing(5),
    },
    listItemIconIcon: {
      color: "rgba(0, 0, 0, 0.32)",
      width: theme.spacing(2.5),
    },

    openBeta: {
      borderRadius: 0,
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
    },
  });
});

const HeaderMenuLarge: React.FC<Props> = ({ applicant, role }) => {
  const classes = useStyles();
  return (
    <section className={classes.avatarContainer}>
      <Avatar
        alt={applicant.name}
        src={applicant.avatar}
        className={classes.avatar}
      />
      <div className={classes.arrow} />
      <Avatar
        alt={role.employer}
        src={role.avatar}
        className={classes.avatar}
      />
    </section>
  );
};

const useNavigation = (
  applicant: Applicant,
  role: Role,
  selfEndorse: () => void,
  withdrawApplication: () => void
): [Array<LinkProps>, React.FC] => {
  const [roleOpen, setRoleOpen] = React.useState(false);
  const [endorsementsOpen, setEndorsementsOpen] = React.useState(false);
  const [withdrawnOpen, setWithdrawnOpen] = React.useState(false);

  let Navigation = [
    {
      title: "Role details",
      link: "/",
      icon: RoleIcon,
      onClick: () => {
        setRoleOpen(true);
      },
    },
    {
      title: "How this works",
      link: "/",
      icon: ShareIcon,
      onClick: () => {
        setEndorsementsOpen(true);
      },
    },
    {
      title: "Endorse yourself",
      link: "/",
      icon: EndorseSelfIcon,
      onClick: () => {
        selfEndorse();
      },
    },
    {
      title: "Withdraw your application",
      link: "/",
      icon: WithdrawIcon,
      onClick: () => {
        setWithdrawnOpen(true);
      },
    },
    {
      title: "Support",
      link: "/",
      icon: HelpIcon,
      onClick: openSlassk,
    },
  ];

  if (role.maxEndorsers && applicant.endorsements >= role.maxEndorsers) {
    Navigation = Navigation.filter((n) => {
      return n.title !== "Endorse yourself";
    });
  }

  return [
    Navigation,
    () => (
      <>
        <RolePopup
          applicant={applicant}
          role={role}
          open={roleOpen}
          setOpen={setRoleOpen}
        />
        <HowEndorsementsworkPopup
          role={role}
          applicant={applicant}
          selfEndorse={selfEndorse}
          open={endorsementsOpen}
          setOpen={setEndorsementsOpen}
        />
        <WithdrawApplicationPopup
          role={role}
          applicant={applicant}
          open={withdrawnOpen}
          setOpen={setWithdrawnOpen}
          withdrawApplication={withdrawApplication}
        />
      </>
    ),
  ];
};

const HeaderMenuSmall: React.FC<Props> = ({
  applicant,
  role,
  selfEndorse,
  withdrawApplication,
}) => {
  const [links, Popups] = useNavigation(
    applicant,
    role,
    selfEndorse,
    withdrawApplication
  );
  return (
    <>
      <Popups />
      <SmallMenu
        links={links}
        header={
          <HeaderMenuLarge
            applicant={applicant}
            role={role}
            selfEndorse={selfEndorse}
            withdrawApplication={withdrawApplication}
          />
        }
      />
    </>
  );
};

export const LeftMenu: React.FC<Props> = ({
  applicant,
  role,
  selfEndorse,
  withdrawApplication,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [links, Popups] = useNavigation(
    applicant,
    role,
    selfEndorse,
    withdrawApplication
  );

  const navigate = (to: string) => {
    history.push(to);
  };

  const feedback = (event: React.SyntheticEvent) => {
    event.preventDefault();
    openSlassk();
  };

  return (
    <>
      <Popups />
      <Card className={classes.headerCard}>
        <CardHeader
          className={classes.cardHeader}
          avatar={
            <Avatar
              alt="Higher"
              className={classes.higherLogo}
              onClick={() => navigate("/")}
              src={UpArrow.ArrowMargin}
            />
          }
          title={`${applicant.endorsements} endorsement${
            applicant.endorsements === 1 ? "" : "s"
          }`}
          subheader={applicant.shortLink}
          action={
            <CopyLink url={applicant.shortLink || ""} placement="bottom" />
          }
        />
      </Card>

      <List dense component="nav" aria-label="Dashboard navigation">
        {links.map((item) => {
          const Icon = item.icon ? item.icon : HelpIcon;
          const onClick = item.onClick
            ? item.onClick
            : () => navigate(item.link);
          return (
            <ListItem button onClick={onClick}>
              <ListItemIcon className={classes.listItemIcon}>
                <Icon className={classes.listItemIconIcon} />
              </ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItem>
          );
        })}
      </List>

      <List dense component="nav" aria-label="Higher navigation">
        {Sitemap.map((item) => {
          const Icon = item.icon ? item.icon : HelpIcon;
          const onClick = item.onClick
            ? item.onClick
            : () => navigate(item.link);
          return (
            <ListItem dense button onClick={onClick}>
              <ListItemIcon className={classes.listItemIcon}>
                <Icon className={classes.listItemIconIcon} />
              </ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItem>
          );
        })}
      </List>

      <Alert
        icon={<BetaIcon fontSize="inherit" />}
        severity="info"
        className={classes.openBeta}
      >
        <AlertTitle>
          Higher is in <Accent>open beta</Accent>
        </AlertTitle>
        All{" "}
        <Link underline="always" onClick={feedback} href="#">
          feedback
        </Link>{" "}
        is appreciated.
      </Alert>
    </>
  );
};

export const LeftMenuSkeleton: React.FC = () => {
  const classes = useStyles();
  return (
    <>
      <Card className={classes.headerCard}>
        <CardHeader
          className={classes.cardHeader}
          avatar={<Avatar alt="Higher" src={UpArrow.ArrowMargin} />}
          title={<Skeleton width="70%" />}
          subheader={<Skeleton width="50%" />}
          action={<CopyLinkSkeleton />}
        />
      </Card>

      <List dense component="nav" aria-label="Higher navigation">
        <ListItem dense button>
          <Skeleton width="60%">
            <ListItemText primary="some title" />
          </Skeleton>
        </ListItem>
        <ListItem dense button>
          <Skeleton width="70%">
            <ListItemText primary="some title" />
          </Skeleton>
        </ListItem>
        <ListItem dense button>
          <Skeleton width="40%">
            <ListItemText primary="some title" />
          </Skeleton>
        </ListItem>
        <ListItem dense button>
          <Skeleton width="50%">
            <ListItemText primary="some title" />
          </Skeleton>
        </ListItem>
      </List>
    </>
  );
};

export const HeaderMenuSkeletonLarge: React.FC = () => {
  const classes = useStyles();
  return (
    <section className={classes.avatarContainer}>
      <Skeleton variant="circle" className={classes.avatarSkeleton}>
        <Avatar className={classes.avatar} />
      </Skeleton>
      <Skeleton
        variant="circle"
        className={[classes.arrow, classes.arrowSkeleton].join(" ")}
      />

      <Skeleton variant="circle" className={classes.avatarSkeleton}>
        <Avatar className={classes.avatar} />
      </Skeleton>
    </section>
  );
};

export const HeaderMenuSkeletonSmall: React.FC = () => null;

export const HeaderMenuSkeleton = ResponsiveFC({
  small: HeaderMenuSkeletonSmall,
  large: HeaderMenuSkeletonLarge,
});

export default ResponsiveFC<Props>({
  small: HeaderMenuSmall,
  large: HeaderMenuLarge,
});
