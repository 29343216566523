import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Avatar, Card, CardContent } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

import Applicant from "@higher/models/Applicant";
import Role from "@higher/models/Role";
import { Logos, UpArrow } from "@app/brand";

type Props = {
  applicant: Applicant;
  role: Role;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: theme.spacing(8),
      boxShadow: "none",
      borderRadius: 0,
    },
    content: {
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      paddingBottom: `${theme.spacing(2)}px !important`,
      paddingTop: `${theme.spacing(1.5)}px !important`,
      height: theme.spacing(8),
    },
    logo: {
      height: "2em",
      alignSelf: "center",
      justifySelf: "center",
    },
    avatarContainer: {
      marginLeft: "auto",
      alignSelf: "center",
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
    },

    avatar: {
      width: "2em",
      height: "2em",
    },
    arrow: {
      width: "1.5rem",
      height: "1.5rem",
      justifySelf: "center",
      alignSelf: "center",
      marginLeft: "-0.5rem",
      marginRight: "-0.5rem",
      transform: "rotate(45deg)",
      background: `url(${UpArrow.Arrow})`,
      backgroundSize: "0.75rem",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundColor: "white",
      borderRadius: "50%",
      zIndex: 1,
      boxShadow: theme.shadows[1],
    },

    avatarSkeleton: {
      border: "none",
    },
    arrowSkeleton: {
      boxShadow: "none",
      visibility: "hidden",
    },
  })
);

export const HeadingCard: React.FC<Props> = ({ applicant, role }) => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <img
          className={classes.logo}
          src={Logos.PrimaryCenteredOpenbeta}
          alt="Higher"
        />
        <section className={classes.avatarContainer}>
          <Avatar
            alt={applicant.name}
            src={applicant.avatar}
            className={classes.avatar}
          />
          <div className={classes.arrow} />
          <Avatar
            alt={role.employer}
            src={role.avatar}
            className={classes.avatar}
          />
        </section>
      </CardContent>
    </Card>
  );
};

export const HeadingCardSkeleton: React.FC = () => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <img
          className={classes.logo}
          src={Logos.PrimaryCentered}
          alt="Higher"
        />
        <section className={classes.avatarContainer}>
          <Skeleton variant="circle" className={classes.avatarSkeleton}>
            <Avatar className={classes.avatar} />
          </Skeleton>
          <Skeleton
            variant="circle"
            className={[classes.arrow, classes.arrowSkeleton].join(" ")}
          />

          <Skeleton variant="circle" className={classes.avatarSkeleton}>
            <Avatar className={classes.avatar} />
          </Skeleton>
        </section>
      </CardContent>
    </Card>
  );
};

export default HeadingCard;
