export enum Rating {
  StrongYes = "strong yes",
  Yes = "yes",
  No = "no",
  StrongNo = "strong no",
}

export const IsPositive = (rating: Rating): boolean => {
  return rating === Rating.StrongYes || rating === Rating.Yes;
};

export interface FirebaseTimestamp {
  toDate: () => Date;
}

/*eslint-disable*/
export type Review = {
  id: string;
  date: FirebaseTimestamp | any;
  applicantId: string;
  reviewerId: string;
  rating: Rating;
};

export default Review;
