import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  SwipeableDrawer,
} from "@material-ui/core";
import { Help as HelpIcon, Menu as MenuIcon } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

import { Images } from "@app/brand";
import {
  LinkProps,
  LinkListProps,
  Navigation,
  NavigationProps,
  Sitemap,
} from "@app/configs/navigation";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      backgroundColor: "#eee",
      height: theme.spacing(8),
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
    },
    navigationContainer: {
      paddingTop: theme.spacing(6),
      backgroundColor: "white",
      position: "relative",
      "&::after": {
        content: "''",
        display: "block",
        height: theme.spacing(8),
        width: theme.spacing(8),
        top: -theme.spacing(4),
        position: "absolute",
        left: `calc(50% - ${theme.spacing(4)}px)`,
        backgroundImage: `url(${Images.OpenBeta})`,
        backgroundSize: "100% 100%",
      },
    },

    inner: {
      padding: "0",
      width: "75vw",
    },
    listIcon: {
      color: "rgba(0, 0, 0, 0.32)",
    },
  })
);

export const VerticalLinkList: React.FC<LinkListProps> = ({
  navigate,
  items,
}) => {
  const classes = useStyles();
  return (
    <List component="nav" aria-label="Higher navigation">
      {items.map((item) => {
        const Icon = item.icon ? item.icon : HelpIcon;
        const onClick = item.onClick ? item.onClick : () => navigate(item.link);
        return (
          <ListItem button onClick={onClick}>
            <ListItemIcon>
              <Icon className={classes.listIcon} />
            </ListItemIcon>
            <ListItemText primary={item.title} />
          </ListItem>
        );
      })}
    </List>
  );
};

type ExplicitLinkProps = {
  header?: JSX.Element;
  links?: Array<LinkProps>;
};

type InnerProps = NavigationProps & ExplicitLinkProps;

export const Inner: React.FC<InnerProps> = ({ navigate, links, header }) => {
  const classes = useStyles();

  const topLinks = links || Navigation;
  const headerEl = <div className={classes.header}>{header}</div>;

  return (
    <>
      {headerEl}
      <div className={header ? "" : classes.navigationContainer}>
        <VerticalLinkList navigate={navigate} items={topLinks} />
        <Divider />
        <VerticalLinkList navigate={navigate} items={Sitemap} />
      </div>
    </>
  );
};

const SmallNavMenu: React.FC<ExplicitLinkProps> = (props) => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);
  const history = useHistory();

  const navigate = (to: string) => {
    setDrawerOpen(false);
    history.push(to);
  };

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  return (
    <div>
      <IconButton edge="start" color="primary" aria-label="menu">
        <MenuIcon onClick={toggleDrawer(true)} />
      </IconButton>
      <SwipeableDrawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <div className={classes.inner}>
          <Inner navigate={navigate} {...props} />
        </div>
      </SwipeableDrawer>
    </div>
  );
};

export default SmallNavMenu;
