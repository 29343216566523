import queryString from "query-string";
import React from "react";
import { RouteComponentProps, useLocation, useHistory } from "react-router-dom";
import { useRecoilValue } from "recoil";

import Dashboard from "@higher/models/Dashboard";

import WrapErrorSuspense from "@app/api/WrapErrorSuspense";
import executeWithdrawApplication from "@app/api/functions/withdrawApplication";
import useFirestoreValue from "@app/hooks/useFirestoreValue";
import ErrorFallback from "@app/modules/Error";
import ApplicantByIdState from "@app/state/ApplicantById";
import DashboardByIdState from "@app/state/DashboardById";
import RoleState from "@app/state/RoleById";

import Home, { HomeSkeleton } from "./Home.stateless";

type ViewProps = {
  dashboard: Dashboard;
};

const View: React.FC<ViewProps> = ({ dashboard }) => {
  const [applicant, isApplicantLoading] = useFirestoreValue(
    ApplicantByIdState(dashboard.applicantId)
  );
  const [role, isRoleLoading] = useFirestoreValue(RoleState(applicant?.roleId));

  const location = useLocation();
  const history = useHistory();

  if (isApplicantLoading || isRoleLoading) {
    return <HomeSkeleton />;
  }

  const selfEndorse = () => {
    history.push(`/endorse/self/${dashboard.id}`);
  };

  const withdrawApplication = async () => {
    await executeWithdrawApplication(dashboard.id);
  };

  const qs = queryString.parse(location.search);

  return (
    <Home
      applicant={applicant}
      role={role}
      selfEndorse={selfEndorse}
      selfEndorsementComplete={qs.endorsed ? true : undefined}
      withdrawApplication={withdrawApplication}
    />
  );
};

type DispatcherProps = RouteComponentProps<{
  id: string;
}>;

const Dispatcher: React.FC<DispatcherProps> = (props) => {
  const dashboard = useRecoilValue(DashboardByIdState(props.match.params.id));

  if (!dashboard) {
    throw new Error("Dashboard doesn't exist");
  }
  return <View dashboard={dashboard} />;
};

export default WrapErrorSuspense<DispatcherProps>(
  Dispatcher,
  HomeSkeleton,
  ErrorFallback
);
