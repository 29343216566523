import React from "react";

import { WrapAuthRequired } from "@app/components/AuthRequired";
import CurrentOrganisation from "@app/state/CurrentOrganisation";
import MessageThreadsByOrganisation from "@app/state/MessageThreadsByOrganisation";
import useFirestoreValue from "@app/hooks/useFirestoreValue";

import Conversations, {
  ConversationsSkeleton,
  NoConversations,
} from "./Conversations.stateless";
import Layout from "./Layout";

const View: React.FC = () => {
  const [currentOrg, isOrgLoading] = useFirestoreValue(CurrentOrganisation);
  const [threads, threadsLoading] = useFirestoreValue(
    MessageThreadsByOrganisation(currentOrg)
  );

  if (isOrgLoading || threadsLoading) {
    return <ConversationsSkeleton />;
  }

  if (!threads.length) {
    return <NoConversations />;
  }

  return <Conversations threads={threads} />;
};

const Dispatcher: React.FC = (props) => {
  return (
    <Layout loading={ConversationsSkeleton}>
      <View />
    </Layout>
  );
};

export default WrapAuthRequired(Dispatcher);
