import Applicant, { ApplicationStatus } from "@higher/models/Applicant";
import Role from "@higher/models/Role";

import queryStateSubscriptionByFunc from "./queryStateSubscriptionByFunc";

const collection = "applicants";

export default queryStateSubscriptionByFunc<Applicant, Role>(
  "applicantsByRoleID",
  collection,
  (query, role) => {
    return query
      .where("organisationId", "==", role.organisationId)
      .where("status", "!=", ApplicationStatus.Withdrawn)
      .where("roleId", "==", role.id);
  }
);
