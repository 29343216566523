import { Grid, Typography, LinearProgress } from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import React from "react";
import { Zoom } from "react-awesome-reveal";

import Applicant from "@higher/models/Applicant";
import Role from "@higher/models/Role";

import { Button } from "@app/brand";
import useTracking from "@app/hooks/useTracking";
import createCharge from "@app/payments/coinbase";

const Icons = {
  BTC: { white: require("cryptocurrency-icons/svg/white/btc.svg").default },
  ETH: { white: require("cryptocurrency-icons/svg/white/eth.svg").default },
  LTC: { white: require("cryptocurrency-icons/svg/white/ltc.svg").default },
  BCH: { white: require("cryptocurrency-icons/svg/white/bch.svg").default },
  USDC: { white: require("cryptocurrency-icons/svg/white/usdc.svg").default },
  DAI: { white: require("cryptocurrency-icons/svg/white/dai.svg").default },
  DOGE: { white: require("cryptocurrency-icons/svg/white/doge.svg").default },
};

export type Props = {
  applicant: Applicant;
  role: Role;
  amount: number;
  email: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      boxShadow: theme.shadows[1],
      marginTop: theme.spacing(1),
    },
    cardContent: {
      "&:last-child": {
        paddingBottom: theme.spacing(2),
      },
    },
    progress: {
      position: "absolute",
      zIndex: 1000,
      width: "100%",
      bottom: 0,
      borderBottomLeftRadius: theme.spacing(0.5),
      borderBottomRightRadius: theme.spacing(0.5),
    },
    error: {
      marginTop: theme.spacing(2),
    },
    coins: {
      marginTop: theme.spacing(2),
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",

      [theme.breakpoints.up("md")]: {
        width: "50%",
      },
      "& img": {
        width: "100%",
      },
    },
  })
);

const CoinbaseCard: React.FC<Props> = ({ applicant, role, amount, email }) => {
  const classes = useStyles();
  const { tracking } = useTracking();

  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(
    undefined
  );
  const [processing, setProcessing] = React.useState(false);

  const handleSubmit = async () => {
    setProcessing(true);

    try {
      const response = await createCharge({
        applicantId: applicant.id,
        amount: amount,
        email: email,
      });

      if (role.id !== "demo") {
        tracking.logEvent("purchase", {
          value: amount,
          currency: role.currency,
          transaction_id: response.coinbaseId,
        });
      }

      setProcessing(false);
      window.location.replace(response.coinbaseUrl);
    } catch (error) {
      setErrorMessage(error.toString());
      setProcessing(false);
    }
  };

  return (
    <>
      {errorMessage && (
        <Alert className={classes.error} severity="warning">
          <Typography>{errorMessage}</Typography>
        </Alert>
      )}

      <Button
        cta
        disabled={processing}
        onClick={handleSubmit}
        trackingId="Confirm and pay (coinbase)"
      >
        Continue to payment
        {processing && (
          <LinearProgress className={classes.progress} color="secondary" />
        )}
      </Button>

      <Grid container spacing={1} className={classes.coins}>
        <Grid item xs>
          <Zoom duration={250} triggerOnce>
            <img src={Icons.BTC.white} alt="Bitcoin" />
          </Zoom>
        </Grid>
        <Grid item xs>
          <Zoom duration={250} delay={50} triggerOnce>
            <img src={Icons.ETH.white} alt="Ethereum" />
          </Zoom>
        </Grid>
        <Grid item xs>
          <Zoom duration={250} delay={100} triggerOnce>
            <img src={Icons.LTC.white} alt="Litecoin" />
          </Zoom>
        </Grid>
        <Grid item xs>
          <Zoom duration={250} delay={150} triggerOnce>
            <img src={Icons.BCH.white} alt="Bitcoin Cash" />
          </Zoom>
        </Grid>
        <Grid item xs>
          <Zoom duration={250} delay={200} triggerOnce>
            <img src={Icons.USDC.white} alt="UDSC" />
          </Zoom>
        </Grid>
        <Grid item xs>
          <Zoom duration={250} delay={250} triggerOnce>
            <img src={Icons.DAI.white} alt="DAI" />
          </Zoom>
        </Grid>
        <Grid item xs>
          <Zoom duration={250} delay={300} triggerOnce>
            <img src={Icons.DOGE.white} alt="Dogecoin" />
          </Zoom>
        </Grid>
      </Grid>
    </>
  );
};

export default CoinbaseCard;
