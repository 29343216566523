import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core";

import { Applicant } from "@higher/models/Applicant";
import ExpandableCard from "@app/components/ExpandableCard";
import ConnectionIcon from "@app/components/ConnectionIcon";
import openInNewTab from "@app/helpers/openInNewTab";

type Props = {
  applicant: Applicant;
  focused?: boolean;
  open?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      [theme.breakpoints.up("lg")]: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        padding: 0,
      },
    },
    listItem: {
      [theme.breakpoints.up("lg")]: {
        flexBasis: "33%",
        flexGrow: 1,
      },
    },
  })
);

const ApplicantCard: React.FC<Props> = ({ applicant, focused, open }) => {
  const classes = useStyles();

  return (
    <ExpandableCard
      title={applicant.name}
      subtitle={applicant.location}
      avatar={applicant.avatar}
      open={open}
      focused={focused}
    >
      {applicant.history &&
        applicant.history.map((item, index) => (
          <Typography
            variant="body2"
            color="primary"
            paragraph={
              applicant.history && index === applicant.history.length - 1
            }
          >
            {item}
          </Typography>
        ))}

      {applicant.connections && (
        <List dense disablePadding className={classes.list}>
          {applicant.connections.map((conn) => (
            <ListItem
              button
              disableGutters
              onClick={() => openInNewTab(conn.url)}
              className={classes.listItem}
            >
              <ListItemAvatar>
                <Avatar>
                  <ConnectionIcon url={conn.url} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={conn.label}
                secondary={conn.url
                  .trim()
                  .replace(/(^\w+:|^)\/\/(www\.)?/, "")
                  .replace(/\/$/, "")}
              />
            </ListItem>
          ))}
        </List>
      )}
    </ExpandableCard>
  );
};

export default ApplicantCard;
