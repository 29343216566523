import Invitation from "@higher/models/Invitation";
import { AccessLevel } from "@higher/models/Organisation";

import Firebase from "@app/Firebase";

import batchUpdate, {
  BatchUpdateFn,
  Creator,
  UpdateCallback,
} from "./batchUpdate";

export type UpdateInvitationsFn = BatchUpdateFn<Invitation>;
export type RawInvitation = Omit<Invitation, "id" | "date">;

export const UpdateInitations = () => (callback: UpdateCallback<Invitation>) =>
  batchUpdate<Invitation>(`invitations`, callback);

export const createInvitation = (
  create: Creator<Invitation>,
  email: string,
  orgId: string,
  userId: string,
  accessLevel: AccessLevel
) => {
  create({
    date: Firebase.firestore.FieldValue.serverTimestamp(),
    email: email,
    organisationId: orgId,
    createdBy: userId,
    accessLevel: accessLevel,
    expired: false,
  });
};

export default UpdateInitations;
