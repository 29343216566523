import Nessage from "@higher/models/Message";

import stateSubscriptionByObject from "./stateSubscriptionByObject";

export type MessageAddress = {
  organisationId: string;
  id: string;
};

export default stateSubscriptionByObject<Nessage, MessageAddress>(
  "messageByMessageAddress",
  (address) => {
    return `organisations/${address.organisationId}/messages`;
  }
);
