import { ResponsiveFC } from "@app/helpers/responsiveness";

import LargeNavMenu from "./NavMenu.large";
import SmallNavMenu from "./NavMenu.small";

const NavMenu = ResponsiveFC({
  small: SmallNavMenu,
  large: LargeNavMenu,
});

export default NavMenu;
