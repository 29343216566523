import Applicant from "@higher/models/Applicant";
import ApplicantMetaData from "@higher/models/ApplicantMetadata";

import stateSubscriptionByObject from "./stateSubscriptionByObject";

export default stateSubscriptionByObject<ApplicantMetaData, Applicant>(
  "applicantMetadata",
  (applicant) => {
    return `organisations/${applicant.organisationId}/roles/${applicant.roleId}/applicantMetadata`;
  }
);
