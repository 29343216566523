import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Container, TextField, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import copy from "copy-to-clipboard";

import Page from "@app/layouts/Page";
import Endorsement from "@higher/models/Endorsement";
import Applicant, { UnknownApplicant } from "@higher/models/Applicant";
import Role, { UnknownRole } from "@higher/models/Role";

import { Keyword, Accent, Images, Button } from "@app/brand";
import { FormatCurrency } from "@higher/payments/currency";

import TrackedSection from "@app/components/TrackedSection";

import useTracking from "@app/hooks/useTracking";
import useMountEffect from "@app/hooks/useMountEffect";

import EndorsementTimeline from "./Timeline";

const useStyles = makeStyles((theme: Theme) => {
  const curve = Images.CurveTop;
  const arrows = Images.BrandarrowsBackgroundImageTrans;
  const arrowsWhite = Images.BrandarrowsBackgroundImageWhite;
  const leftPositionX = `calc( ((100vw - ${theme.breakpoints.values.md}px)/2) - 75vh )`;
  const rightPositionX = `calc( ((100vw - ${theme.breakpoints.values.md}px)/2) + ${theme.breakpoints.values.md}px )`;
  const curveWidth = `calc( 100vw - (((100vw - ${theme.breakpoints.values.md}px)/2) - 75vh ))`;

  return createStyles({
    topContainer: {
      backgroundImage: `url(${Images.CurveTop})`,
      backgroundSize: `auto auto`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "bottom right",
      marginBottom: "-1px",
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(12),

      [theme.breakpoints.up("lg")]: {
        backgroundImage: ` url(${arrows}), url(${arrows}), url(${curve})`,
        backgroundSize: `75vh auto,75vh auto, ${curveWidth} auto`,
        backgroundRepeatX: "no-repeat",
        backgroundRepeat: "no-repeat, repeat, no-repeat",
        backgroundPositionX: ` ${leftPositionX}, ${rightPositionX}, right`,
        backgroundPositionY: `2px, 100px, bottom`,
        marginBottom: "0px",
        paddingTop: 0,
        paddingBottom: 0,
        height: `calc( (var(--vh, 1vh) * 100) - ${theme.spacing(8)}px)`,
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
      },
    },
    topSection: {
      alignSelf: "center",
      justifySelf: "center",
      width: "100%",
    },
    secondSection: {
      backgroundColor: theme.palette.primary.main,
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),

      [theme.breakpoints.up("lg")]: {
        backgroundImage: ` url(${arrowsWhite}), url(${arrowsWhite}), url(${Images.CurveBottom})`,
        backgroundSize: `75vh auto, 75vh auto, ${curveWidth}`,
        backgroundRepeatX: "no-repeat",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: `${leftPositionX}, ${rightPositionX}, right`,
        backgroundPositionY: `2px, center, top`,
      },
    },
    thirdSection: {
      backgroundColor: theme.palette.primary.light,
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
      textAlign: "center",
    },

    title: {
      textAlign: "center",
      display: "flex",
      height: "auto",
      paddingBottom: theme.spacing(8),
    },
    whatHappensNext: {
      padding: 0,
    },
    whatHappensNextTitle: {
      textAlign: "center",
      color: "white",
    },
    mainHeader: {
      marginBottom: theme.spacing(4),
      fontWeight: "bold",
      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(3),
        fontSize: "1.5rem",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "1.55rem",
      },
    },
    masthead: {
      width: "50%",
      height: "auto",
      maxWidth: "200px",
      marginBottom: theme.spacing(5),

      [theme.breakpoints.up("lg")]: {
        width: "30%",
      },
    },
    mastheadSkeleton: {
      height: "200px",
      width: "200px",
      margin: "0 auto",
      marginBottom: theme.spacing(5),
    },
    skeleton: {
      margin: "0 auto",
    },
    textField: {
      border: `1px solid ${theme.palette.secondary.main}`,
      marginTop: theme.spacing(2),
      borderRadius: theme.spacing(0.5),
      backgroundColor: theme.palette.primary.main,
      "& input": {
        color: "white",
      },
    },
  });
});

type Props = {
  applicant: Applicant;
  role: Role;
  endorsement: Endorsement;
};

const Complete: React.FC<Props> = ({ applicant, role, endorsement }) => {
  const classes = useStyles();
  const { tracking } = useTracking();
  useMountEffect(() => {
    tracking.logEvent("page_view", {});
  });

  const copyShortLink = () => applicant.shortLink && copy(applicant.shortLink);

  return (
    <Page showScrollDown>
      <TrackedSection trackingId="endorsement complete intro">
        <Container maxWidth={false} className={classes.topContainer}>
          <Container maxWidth="sm" className={classes.title}>
            <section className={classes.topSection}>
              <img
                src={Images.Success}
                className={classes.masthead}
                alt="Endorsement complete!"
              />
              <Typography
                variant="h4"
                component="h1"
                paragraph
                className={classes.mainHeader}
              >
                Endorsement complete! Your work here is done.
              </Typography>
              <Typography variant="h5" component="h2" paragraph>
                <Accent>{applicant.firstName}</Accent> is more visible to{" "}
                <Accent>{role.employer}</Accent>, and you stand to make{" "}
                <Keyword>
                  {FormatCurrency(role.currency, endorsement.amount)}
                </Keyword>
                .
              </Typography>
            </section>
          </Container>
        </Container>
      </TrackedSection>
      <TrackedSection trackingId="what happens next">
        <Container maxWidth={false} className={classes.secondSection}>
          <Container maxWidth="md" className={classes.whatHappensNext}>
            <Typography
              variant="h5"
              component="h3"
              paragraph
              className={classes.whatHappensNextTitle}
            >
              What happens next?
            </Typography>
            <EndorsementTimeline
              applicant={applicant}
              role={role}
              endorsement={endorsement}
            />
          </Container>
        </Container>
      </TrackedSection>
      {applicant.shortLink && (
        <TrackedSection trackingId="share link">
          <Container maxWidth={false} className={classes.thirdSection}>
            <Container maxWidth="sm" className={classes.whatHappensNext}>
              <Typography
                variant="h5"
                component="h3"
                paragraph
                className={classes.whatHappensNextTitle}
              >
                Do you know anyone else who can endorse {applicant.firstName}?
              </Typography>
              <Typography
                variant="h6"
                component="h4"
                paragraph
                className={classes.whatHappensNextTitle}
              >
                {applicant.firstName}'s chances of being hired increase with
                more endorsements... and each endorser can profit from their
                knowledge.
              </Typography>

              <TextField
                value={applicant.shortLink}
                color="secondary"
                variant="outlined"
                className={classes.textField}
                type="readonly"
                disabled
                InputProps={{
                  endAdornment: (
                    <Button
                      small
                      compact
                      onClick={copyShortLink}
                      trackingId="Copy share link"
                    >
                      Copy
                    </Button>
                  ),
                }}
              />
            </Container>
          </Container>
        </TrackedSection>
      )}
    </Page>
  );
};

export const CompleteSkeleton: React.FC = () => {
  const classes = useStyles();
  const applicant = UnknownApplicant;
  const role = UnknownRole;
  return (
    <Page>
      <Container maxWidth={false} className={classes.topContainer}>
        <Container maxWidth="sm" className={classes.title}>
          <section className={classes.topSection}>
            <Skeleton
              variant="circle"
              className={[classes.masthead, classes.mastheadSkeleton].join(" ")}
            />

            <Skeleton variant="rect" className={classes.skeleton}>
              <Typography
                variant="h4"
                component="h1"
                paragraph
                className={classes.mainHeader}
              >
                Endorsement complete!
              </Typography>
            </Skeleton>

            <Skeleton variant="rect" className={classes.skeleton}>
              <Typography variant="h5" component="h2" paragraph>
                <Accent>{applicant.firstName}</Accent> is more visible to{" "}
                <Accent>{role.employer}</Accent>, and you stand to make{" "}
                <Keyword>{FormatCurrency(role.currency, 0)}</Keyword>.
              </Typography>
            </Skeleton>
          </section>
        </Container>
      </Container>
      <Container maxWidth={false} className={classes.secondSection}>
        <Container maxWidth="md" className={classes.whatHappensNext}>
          <Typography
            variant="h5"
            component="h3"
            paragraph
            className={classes.whatHappensNextTitle}
          >
            What happens next?
          </Typography>
        </Container>
      </Container>
    </Page>
  );
};

export default Complete;
