import useLocalStorage from "@app/hooks/useLocalStorage";

export type Prefill = {
  name?: string | null;
  email?: string | null;
  avatar?: string | null;
};

export const usePreFill = (roleId: string) =>
  useLocalStorage<Prefill>(roleId, {});

export default usePreFill;
