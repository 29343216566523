import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Hidden,
  Typography,
} from "@material-ui/core";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import React from "react";
import { Link } from "react-router-dom";

import { Logos, Images, Keyword, Icons, UpArrow } from "@app/brand";
import { openSlassk } from "@app/helpers/slaask";

import Footer from "./Footer";

const Screenshots = {
  dashboard: require("@app/screenshots/dashboard.png").default,
  endorser: require("@app/screenshots/endorser.png").default,
};

const useStyles = makeStyles((theme: Theme) => {
  const darkBlueGradient = Images.DarkBlueGradient;
  const lightBlueGradient = Images.LightBlueGradient;
  const hillsWithNotch = Images.HillsWithWhiteNotch;
  const hillsWithNotchLarge = Images.HillsNotchExtended;

  const balloon = Images.BrandballoonPrimary;

  return createStyles({
    root: {},
    mastheadSection: {
      backgroundImage: `url(${hillsWithNotch}), url(${darkBlueGradient})`,
      backgroundSize: `100% auto, cover`,
      backgroundPosition: `bottom left, top left`,
      backgroundRepeat: `no-repeat`,
      paddingTop: theme.spacing(3),
      paddingBottom: `calc(100vh / 4)`,
      minHeight: "33vh",
      marginBottom: -2,

      [theme.breakpoints.up("md")]: {
        backgroundImage: `url(${hillsWithNotchLarge}), url(${balloon}), url(${darkBlueGradient})`,
        backgroundPosition: `bottom left, calc(70vw - ((100vw / 5)/2)) 32px, top left`,
        backgroundSize: `calc(100%) auto, calc(100vw / 4) auto, cover`,
        paddingBottom: `calc(100vh / 3.5)`,

        "@media (orientation: portrait)": {
          paddingBottom: `calc(100vh / 5.5)`,
        },
      },

      [theme.breakpoints.up("lg")]: {
        backgroundImage: `url(${hillsWithNotchLarge}), url(${balloon}), url(${darkBlueGradient})`,
        backgroundPosition: `bottom left, calc(55vw - ((100vw / 5)/2)) 32px, top left`,
        backgroundSize: `calc(100%) auto, calc(100vw / 4) auto, cover`,
        paddingBottom: `calc(100vh / 3.5)`,
      },
      [theme.breakpoints.up("xl")]: {
        backgroundPosition: `bottom left, calc(57vw - ((100vw / 5)/2)) 32px, top left`,
        backgroundSize: `100% auto, calc(100vw / 5) auto, cover`,
        paddingBottom: `calc(100vh / 2.75)`,
      },
    },
    logo: {
      width: "50%",
      marginBottom: theme.spacing(6),
      [theme.breakpoints.up("lg")]: {
        marginBottom: theme.spacing(9),
        marginTop: theme.spacing(2),
      },
      [theme.breakpoints.up("xl")]: {
        marginTop: theme.spacing(12),
      },
    },
    h1: {
      color: "white",
      fontSize: theme.spacing(6),
      lineHeight: 1.5,
    },
    h2: {
      color: "white",
      fontSize: theme.spacing(2.5),
      lineHeight: 1.5,
    },
    h4: {
      color: "white",
      fontSize: theme.spacing(3),
      lineHeight: 1.5,
    },
    h4blue: {
      color: theme.palette.primary.main,
      fontSize: theme.spacing(3),
      lineHeight: 1.5,
    },
    h5: {
      color: theme.palette.secondary.main,
      fontSize: theme.spacing(2.5),
      fontWeight: "bold",
      lineHeight: 1.5,
    },
    h5blue: {
      color: theme.palette.primary.main,
      fontSize: theme.spacing(2.5),
      lineHeight: 1.5,
    },

    primaryCTA: {
      fontSize: theme.spacing(2.2),
      height: theme.spacing(8),
      marginTop: theme.spacing(2),
      width: "100%",
      "& img": {
        height: "1em",
      },

      [theme.breakpoints.up("md")]: {
        width: "auto",
      },
    },

    secondaryCTA: {
      fontSize: theme.spacing(2.2),
      height: theme.spacing(8),
      marginTop: theme.spacing(2),
      width: "100%",
      "& img": {
        height: "1em",
      },
      "& .MuiButton-endIcon": {
        width: "1em",
      },
    },

    featuresSection: {
      backgroundColor: "white",
      paddingTop: theme.spacing(2),

      [theme.breakpoints.up("lg")]: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
      },
    },
    feature: {
      textAlign: "center",
      padding: theme.spacing(4),

      "& img": {
        width: "50%",
        marginBottom: theme.spacing(2),
      },
    },

    socialProofSection: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
      [theme.breakpoints.up("lg")]: {
        background: "linear-gradient(90deg, #c6199a 50%, transparent 50%)",
      },
    },
    pullquote: {
      color: "white",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundImage: `url(${Images.HomepagePullquote})`,
      backgroundSize: "auto 85%",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
    },
    quote: {
      padding: theme.spacing(2),
      [theme.breakpoints.up("lg")]: {
        padding: theme.spacing(6),
        minHeight: 400,
      },
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },

    demoSection: {
      backgroundColor: theme.palette.primary.main,
      color: "white",
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
      textAlign: "center",
    },
    gridCentered: {
      display: "flex",
      height: "100%",
      flexDirection: "column",
      justifyContent: "center",
      alignContent: "center",
      "& img": {
        margin: "0 auto",
      },
      paddingLeft: "2em",
      paddingRight: "2em",
    },

    gridTwoUp: {
      marginTop: theme.spacing(8),
    },
    gridTwoUpPrimary: {
      order: 1,
      textAlign: "left",
      "& img": {
        width: "100%",
      },
      [theme.breakpoints.down("sm")]: {
        "&:nth-of-type(2)": {
          order: 2,
        },
      },
    },
    gridTwoUpSecondary: {
      order: 2,
      textAlign: "left",
      "& img": {
        width: "100%",
      },
    },
    screenshot: {
      boxShadow: theme.shadows[8],
      borderRadius: theme.spacing(0.5),
    },

    moreReasonsSection: {
      backgroundColor: "white",
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
      textAlign: "center",
    },
    secondaryFeature: {
      textAlign: "center",
      padding: theme.spacing(2),

      "& img": {
        width: "80%",
      },

      [theme.breakpoints.up("lg")]: {
        padding: theme.spacing(8),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        "& img": {
          width: "75%",
        },
      },
    },

    getStartedSection: {
      background: `url(${lightBlueGradient})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      paddingTop: theme.spacing(12),
      textAlign: "center",
      marginBottom: -6,
    },
    footerImage: {
      marginTop: theme.spacing(10),
      width: "100%",
      marginBottom: 0,
    },
  });
});

const Home: React.FC = () => {
  const classes = useStyles();
  const getAQuote = () => openSlassk();

  return (
    <div className={classes.root}>
      <section className={classes.mastheadSection}>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={12} lg={6}>
              <img
                src={Logos.PrimaryReversedNoMargin}
                alt="Higher"
                className={classes.logo}
              />
              <Typography
                component="h1"
                variant="h2"
                className={classes.h1}
                paragraph
              >
                Hiring Evolved
              </Typography>
              <Typography
                component="h2"
                variant="h3"
                className={classes.h2}
                paragraph
              >
                Bring certainty, efficiency and altruism into your{" "}
                <Hidden smDown>
                  <br />
                </Hidden>
                hiring process with the world’s leanest ATS.
              </Typography>
              <Button
                color="secondary"
                variant="contained"
                endIcon={<img src={UpArrow.ArrowRightWhite} alt="Go" />}
                className={classes.primaryCTA}
                size="large"
                onClick={getAQuote}
              >
                Arrange a demo
              </Button>
            </Grid>
          </Grid>
        </Container>
      </section>
      <section className={classes.featuresSection}>
        <Container maxWidth="lg">
          <Grid container justify="center">
            <Grid item xs={12} md={6} lg={4} className={classes.feature}>
              <img src={Images.BeCertain} alt="Be certain" />
              <Typography component="h3" variant="h4" paragraph>
                Be certain
              </Typography>
              <Typography paragraph>
                Imagine every candidate being suitable for your role, and all
                you have to do is assess culture and fit.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4} className={classes.feature}>
              <img src={Images.BeEfficient} alt="Be efficient" />
              <Typography component="h3" variant="h4" paragraph>
                Be efficient
              </Typography>
              <Typography paragraph>
                Say goodbye to phone screening, qualifying and referencing. All
                your candidates ranked from best to better.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4} className={classes.feature}>
              <img src={Images.BeHuman} alt="Be human" />
              <Typography component="h3" variant="h4" paragraph>
                Be human
              </Typography>
              <Typography paragraph>
                Move beyond just DEI and do social good. Join us in recycling
                excess system resources into good for the world.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </section>
      <section className={classes.socialProofSection}>
        <Container maxWidth="lg">
          <Grid container style={{ marginTop: -1 }}>
            <Hidden mdDown>
              <Grid item xs={12} lg={6} className={classes.pullquote}></Grid>
            </Hidden>
            <Grid item xs={12} lg={6} className={classes.quote}>
              <div>
                <Card>
                  <CardContent>
                    <Typography component="p" variant="h6">
                      “<strong>It feels like a superpower</strong>. We had 150
                      applicants apply for 1 role. With no screening and using
                      only Higher’s ranking we spoke to just 2 candidates... and
                      hired them both. <strong>It took less than 7 days</strong>
                      .”
                    </Typography>
                  </CardContent>
                  <CardHeader
                    avatar={
                      <Avatar src="https://firebasestorage.googleapis.com/v0/b/higher-5e33a.appspot.com/o/avatars%2Fapplicants%2FcvssyplmmXV9AU17zPCy.jpg?alt=media&token=cvssyplmmXV9AU17zPCy">
                        Adrian Duke, CEO
                      </Avatar>
                    }
                    title={
                      <Typography component="p" variant="h5">
                        Adrian Duke, CEO
                      </Typography>
                    }
                  />
                </Card>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>
      <section className={classes.demoSection}>
        <Container maxWidth="lg">
          <Typography component="h4" variant="h4" className={classes.h4}>
            See <Keyword>Higher</Keyword> in action
          </Typography>

          <Grid container spacing={4} className={classes.gridTwoUp}>
            <Grid item xs={12} md={6} className={classes.gridTwoUpPrimary}>
              <div className={classes.gridCentered}>
                <Link to="/dashboard/demo">
                  <img
                    src={Screenshots.dashboard}
                    className={classes.screenshot}
                    alt="Candidate dashboard"
                    width="100%"
                  />
                </Link>
              </div>
            </Grid>
            <Grid item xs={12} md={6} className={classes.gridTwoUpSecondary}>
              <div className={classes.gridCentered}>
                <Typography
                  variant="h5"
                  component="h5"
                  className={classes.h5}
                  paragraph
                >
                  Candidate dashboard
                </Typography>

                <Typography variant="body1" component="p" paragraph>
                  New and existing candidates are frictionlessly funelled into
                  their <strong>dashboard</strong>, where they can quickly and
                  easily seek endorsements from employers and peers.
                </Typography>
                <Typography variant="body1" component="p" paragraph>
                  If you use an Applicant Tracking System, then this process is
                  seamlessly integrated into your existing flow.
                </Typography>
                <Link to="/dashboard/demo" style={{ textDecoration: "none" }}>
                  <Button
                    color="secondary"
                    variant="contained"
                    endIcon={<img src={UpArrow.ArrowRightWhite} alt="Go" />}
                    className={classes.secondaryCTA}
                    size="large"
                  >
                    Dashboard demo
                  </Button>
                </Link>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={4} className={classes.gridTwoUp}>
            <Grid item xs={12} md={6} className={classes.gridTwoUpSecondary}>
              <div className={classes.gridCentered}>
                <Link to="/endorse/demo">
                  <img
                    className={classes.screenshot}
                    src={Screenshots.endorser}
                    alt="Endorsement flow"
                    width="100%"
                  />
                </Link>
              </div>
            </Grid>
            <Grid item xs={12} md={6} className={classes.gridTwoUpPrimary}>
              <div className={classes.gridCentered}>
                <Typography
                  variant="h5"
                  component="h5"
                  className={classes.h5}
                  paragraph
                >
                  Endorsement flow
                </Typography>

                <Typography variant="body1" component="p" paragraph>
                  Higher replaces weak references with{" "}
                  <strong>strong endorsements</strong>. Endorsers put down a
                  reasonable but significant sum of their own money in support
                  of the candidate's application to your role.
                </Typography>
                <Typography variant="body1" component="p" paragraph>
                  The endorsement flow guides endorsers through a short,
                  painless process and accepts an endorsement payment. It's
                  fully anonymous and private by default.
                </Typography>
                <Link to="/endorse/demo" style={{ textDecoration: "none" }}>
                  <Button
                    color="secondary"
                    variant="contained"
                    endIcon={<img src={UpArrow.ArrowRightWhite} alt="Go" />}
                    className={classes.secondaryCTA}
                    size="large"
                  >
                    Endorsement flow demo
                  </Button>
                </Link>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>
      <section className={classes.moreReasonsSection}>
        <Container maxWidth="lg">
          <Typography component="h4" variant="h4" className={classes.h4blue}>
            More reasons to get <Keyword>Higher</Keyword>
          </Typography>
          <Grid container justify="center">
            <Grid item xs={6} md={4} className={classes.secondaryFeature}>
              <img
                src={Icons.AutomatedShorlisting}
                alt="Automated shortlisting"
              />
              <Typography paragraph>Automated shortlisting</Typography>
            </Grid>
            <Grid item xs={6} md={4} className={classes.secondaryFeature}>
              <img
                src={Icons.CandidatesInformed}
                alt="Candidates informed every step of the process"
              />
              <Typography paragraph>
                Candidates informed every step of the process
              </Typography>
            </Grid>
            <Grid item xs={6} md={4} className={classes.secondaryFeature}>
              <img
                src={Icons.RealTimeCollaboration}
                alt="Collaborate in real time behind the scenes"
              />
              <Typography paragraph>
                Collaborate in real time behind the scenes
              </Typography>
            </Grid>
            <Grid item xs={6} md={4} className={classes.secondaryFeature}>
              <img
                src={Icons.Customisation}
                alt="Customise the application process to fit your needs"
              />
              <Typography paragraph>
                Customise the application process to fit your needs
              </Typography>
            </Grid>
            <Grid item xs={6} md={4} className={classes.secondaryFeature}>
              <img
                src={Icons.ReachOutDirectly}
                alt="Reach out directly to your candidates’ inboxes"
              />
              <Typography paragraph>
                Reach out directly to your candidates’ inboxes
              </Typography>
            </Grid>
            <Grid item xs={6} md={4} className={classes.secondaryFeature}>
              <img
                src={Icons.LinkedinIntegration}
                alt="LinkedIn integration<"
              />
              <Typography paragraph>LinkedIn integration</Typography>
            </Grid>
            <Grid item xs={6} md={4} className={classes.secondaryFeature}>
              <img src={Icons.GDPRCompliant} alt="Fully GDPR Compliant" />
              <Typography paragraph>Fully GDPR Compliant</Typography>
            </Grid>
            <Grid item xs={6} md={4} className={classes.secondaryFeature}>
              <img src={Icons.SecureByDefault} alt="Secure by default" />
              <Typography paragraph>Secure by default</Typography>
            </Grid>
            <Grid item xs={6} md={4} className={classes.secondaryFeature}>
              <img
                src={Icons.LeanestFeesPossible}
                alt="Leanest fees possible"
              />
              <Typography paragraph>Leanest fees possible</Typography>
            </Grid>
          </Grid>
        </Container>
      </section>
      <section className={classes.getStartedSection}>
        <Container maxWidth="sm">
          <Typography
            component="h4"
            variant="h4"
            className={classes.h4blue}
            paragraph
          >
            Get started changing the world
          </Typography>

          <Typography
            component="h5"
            variant="h5"
            className={classes.h5blue}
            paragraph
          >
            Whether you’re an upcoming startup or a booming international
            company, we can help you hire at scale. Request a demo today to see
            how we can evolve your business.
          </Typography>

          <Typography
            component="h5"
            variant="h5"
            className={classes.h4}
            paragraph
          >
            <Keyword>Don't just hire. Get Higher.</Keyword>
          </Typography>

          <Button
            color="secondary"
            variant="contained"
            endIcon={<img src={UpArrow.ArrowRightWhite} alt="Go" />}
            className={classes.primaryCTA}
            size="large"
            onClick={getAQuote}
          >
            Arrange a demo
          </Button>
        </Container>
        <img
          className={classes.footerImage}
          src={Images.HillsNoNotch}
          alt="Higher"
        />
      </section>
      <Footer />
    </div>
  );
};

export default Home;
