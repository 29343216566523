export enum Direction {
  FromApplicant = "from applicant",
  ToApplicant = "to applicant",
}

export interface FirebaseTimestamp {
  toDate: () => Date;
}

export enum Status {
  Sent = "sent",
  Delivered = "delivered",
  Opened = "opened",
}

/*eslint-disable*/
export type Message = {
  id: string;
  organisationId: string;
  date: FirebaseTimestamp | any;
  direction: Direction;
  applicantId: string;
  userId?: string;
  content: string;
  externalId?: string;
  status?: Status;
  statusDate: FirebaseTimestamp | any;
};

export default Message;
