import React, { FC } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    emphasis: {
      fontWeight: 300,
      fontStyle: "normal",
    },
  })
);

const Emphasis: FC = ({ children }) => {
  const classes = useStyles();
  return <em className={classes.emphasis}>{children}</em>;
};

export default Emphasis;
