import {
  AddCandidateRequest,
  AddCandidateResponse,
} from "@higher/api/addCandidate";

import { Functions } from "@app/Firebase";

export const addCandidate = async (input: AddCandidateRequest) => {
  const fn = Functions.httpsCallable("addCandidate");
  const result = await fn(input);
  return result.data as AddCandidateResponse;
};

export default addCandidate;
