import routerBreadcrumbs from "use-react-router-breadcrumbs";

import { RoleBreadcrumb } from "./Role";

export const routes = [
  { path: "/dashboard/roles/:id", breadcrumb: RoleBreadcrumb },
  { path: "/dashboard/roles/:id/:id2", breadcrumb: null },
];

type Breadcrumb = {
  label: any;
  link: string;
};

export const useBreadcrumbs = (): Array<Breadcrumb> => {
  const crumbs = routerBreadcrumbs(routes);
  return crumbs
    .filter(({ match }) => match.url !== "/" && match.url !== "/dashboard")
    .map<Breadcrumb>(({ breadcrumb, match }) => {
      return {
        label: breadcrumb,
        link: match.url,
      };
    });
};

export default useBreadcrumbs;
