import {
  Avatar,
  Box,
  Card,
  CardHeader,
  CircularProgress,
  Chip,
  Container,
  Dialog,
  Grid,
  IconButton,
  Link,
  LinearProgress,
  Menu,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import {
  makeStyles,
  Theme,
  createStyles,
  useTheme,
} from "@material-ui/core/styles";
import ArchivedIcon from "@material-ui/icons/Archive";
import CancelIcon from "@material-ui/icons/Cancel";
import CloseIcon from "@material-ui/icons/Close";
import DescriptionIcon from "@material-ui/icons/Description";
import EditIcon from "@material-ui/icons/Edit";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import LocationIcon from "@material-ui/icons/LocationOn";
import MailIcon from "@material-ui/icons/Mail";
import StrongNoIcon from "@material-ui/icons/NotInterested";
import YesIcon from "@material-ui/icons/SentimentSatisfiedAlt";
import NoIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import StrongYesIcon from "@material-ui/icons/SentimentVerySatisfied";
import { Alert, AlertTitle, Skeleton } from "@material-ui/lab";
import copy from "copy-to-clipboard";
import React from "react";
import { useRecoilValue } from "recoil";

import Applicant, {
  ApplicationStatus,
  RemovalReason,
} from "@higher/models/Applicant";
import { Rating } from "@higher/models/Review";
import Stage, { FixedStageID, ArchivedStageId } from "@higher/models/Stage";

import { Accent, Button, Emphasis } from "@app/brand";
import ConnectionIcon from "@app/components/ConnectionIcon";
import EditMarkdown from "@app/components/EditMarkdown";
import Markdown from "@app/components/Markdown";
import asyncComponent from "@app/components/asyncComponent";
import openInNewTab from "@app/helpers/openInNewTab";
import plural from "@app/helpers/plural";
import useFirestoreValue from "@app/hooks/useFirestoreValue";
import useLoadable from "@app/hooks/useLoadable";
import ApplicantById from "@app/state/ApplicantById";
import ApplicantMetadataState from "@app/state/ApplicantMetadata";
import DashboardForApplicant from "@app/state/DashboardForApplicant";
import EventsByApplicant from "@app/state/EventsByApplicant";
import {
  createApplicantCommentEvent,
  createApplicantMessageEvent,
} from "@app/state/UpdateEvents";
import { createReview } from "@app/state/UpdateReviews";

import MessageDeliveryStatus from "./MessageDeliveryStatus";
import StateUpdateContext from "./StateUpdateContext";
import Timeline, {
  EventProps,
  EventContentViewProps,
  TimeAgo,
  Username,
  StageName,
} from "./Timeline";

type Props = {
  applicant: Applicant;
};

type StageProps = {
  stages: Array<Stage>;
};

type MoveToStageProps = {
  moveToStage: (
    a: Applicant,
    stageId: string,
    removalReadon?: RemovalReason
  ) => void;
};

type PropsWithMoveToStage = Props & MoveToStageProps;

type StageManagementProps = StageProps & MoveToStageProps;

type PropsWithStages = Props & StageManagementProps;

type DialogProps = StageManagementProps & {
  open: boolean;
  close: () => void;
  applicant?: Applicant;
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      minHeight: `calc(100vh - ${theme.spacing(4)}px)`,
      marginBottom: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
    rootGrid: {
      height: "100%",
      minHeight: `calc(100vh - ${theme.spacing(4)}px)`,
    },
    windowControls: {
      position: "absolute",
      right: theme.spacing(0.5),
      top: theme.spacing(0.5),

      "& button": {
        padding: theme.spacing(0.5),
      },

      "& svg": {
        width: theme.spacing(2.5),
        height: theme.spacing(2.5),
      },
    },
    avatar: {
      width: theme.spacing(6),
      height: theme.spacing(6),
    },
    controls: {
      backgroundColor: "#efefef88",
      borderLeft: "1px solid #efefef",
    },
    controlPanel: {
      paddingTop: theme.spacing(3.5),
    },
    controlPanelHeader: {
      paddingTop: theme.spacing(5),
    },
    rankBreakdown: {
      paddingBottom: "8px !important",
    },
    rankBreakdownBar: {
      marginBottom: theme.spacing(1.5),
    },
    alert: {
      marginBottom: theme.spacing(2),
    },
    rankBreakdownBarSkeleton: {
      height: 4,
      marginBottom: theme.spacing(1.5),
    },
    swatch: {
      width: "1em",
      height: "1em",
      backgroundColor: theme.palette.primary.main,
      borderRadius: "50%",
      marginRight: theme.spacing(0.5),
    },
    swatchSecondary: {
      backgroundColor: "rgb(163, 177, 194)",
    },
    legend: {
      marginBottom: theme.spacing(1),
    },
    select: {
      width: "100%",
      color: theme.palette.secondary.dark,
      "& svg": {
        color: theme.palette.secondary.dark,
      },
    },
    nextStageButton: {
      fontSize: "1em",
      textTransform: "none",
      width: "100%",
      marginTop: 0,
    },
    rejectButton: {
      fontSize: "1em",
      textTransform: "none",
      width: "100%",
      marginTop: 0,
    },
    rejectIcon: {
      marginRight: theme.spacing(1),
    },
    shortlinkButton: {
      textTransform: "none",
      width: "100%",
      padding: 0,
      margin: 0,
      fontSize: theme.spacing(2),
      borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
      borderRadius: 0,
      "& span": {
        justifyContent: "space-between",
      },
      "& svg": {
        marginRight: theme.spacing(1),
        width: theme.spacing(2),
        height: theme.spacing(2),
      },
    },
    applicantDetails: {
      boxShadow: theme.shadows[0],
      height: theme.spacing(10),
      marginBottom: theme.spacing(1.5),
    },
    applicantLinks: {
      margin: 0,
      alignSelf: "center",
      padding: 0,
      paddingRight: 4,
      "& a": {
        color: "#aaa",
        marginLeft: theme.spacing(1),

        "&:hover": {
          color: theme.palette.secondary.dark,
          transition: "color 0.5s ease",
        },
      },
    },
    metadata: {
      marginLeft: theme.spacing(-0.4),
      "& svg": {
        color: "#aaa",
        marginRight: theme.spacing(0.5),
        width: theme.spacing(2),
        height: theme.spacing(2),
      },
    },
    email: {
      height: theme.spacing(10),
      display: "flex",
      alignItems: "center",
      justifyContent: "left",
      cursor: "pointer",

      "& svg": {
        color: "#aaa",
        marginRight: theme.spacing(1),
      },
      "&:hover svg": {
        color: theme.palette.secondary.dark,
        transition: "color 0.5s ease",
      },
    },

    // Left panel
    leftLoadingSpinnerContainer: {
      height: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    // Comment form
    tabs: {
      // This is because of a gruesome bug in Material:
      // https://github.com/mui-org/material-ui/issues/9337
      "& .MuiTabs-indicator": {
        minWidth: 160,
      },
    },
    commentContainer: {
      marginBottom: theme.spacing(2),
    },
    editMarkdownContainer: {
      minHeight: theme.spacing(40),
    },
    iconRadioContainer: {
      display: "flex",
      flexGrow: 1,
      paddingRight: theme.spacing(2),
      marginTop: theme.spacing(2),
      alignItems: "center",
      "& .MuiChip-root": {
        marginRight: theme.spacing(2),
      },
    },
    warningContainer: {
      display: "flex",
      flexGrow: 1,
      paddingRight: theme.spacing(2),
      marginTop: theme.spacing(2),
      alignItems: "center",
      height: theme.spacing(5),
    },

    progress: {
      position: "absolute",
      zIndex: 1000,
      width: "100%",
      bottom: 0,
      borderBottomLeftRadius: theme.spacing(0.5),
      borderBottomRightRadius: theme.spacing(0.5),
    },

    // Timeline
    timelineContainer: {
      marginTop: theme.spacing(2),
      overflowX: "auto",
    },
    timelineCommentRoot: {
      paddingTop: theme.spacing(1),
    },
    timelineComment: {
      border: "1px solid #efefef",
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(1.5),
      marginBottom: theme.spacing(2),
      width: "100%",
      backgroundColor: "#fdfdfd",

      "& p": {
        fontSize: "1em",
        "&:last-of-type": {
          marginBottom: 0,
        },
      },
      "& ol, ul": {
        marginBottom: theme.spacing(1.5),
      },
    },
    timelineCommentMenu: {
      position: "absolute",
      right: theme.spacing(0),
      top: theme.spacing(1.5),
      "& svg": {
        fontSize: "1.2rem",
      },
    },
  });
});

const CandidateDialog: React.FC<DialogProps> = ({
  open,
  close,
  applicant,
  stages,
  moveToStage,
}) => {
  if (!applicant) {
    return null;
  }

  return (
    <CandidateDialogInner
      open={open}
      close={close}
      applicant={applicant}
      stages={stages}
      moveToStage={moveToStage}
    />
  );
};

const CandidateDialogInner: React.FC<Required<DialogProps>> = ({
  open,
  close,
  applicant,
  stages,
  moveToStage,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [fullScreen, setFullScreen] = React.useState(false);
  const [liveApplicant] = useLoadable(ApplicantById(applicant.id), applicant);

  const fullScreenIcon = fullScreen ? (
    <FullscreenExitIcon />
  ) : (
    <FullscreenIcon />
  );

  const toggleFullScrren = () => {
    setFullScreen(!fullScreen);
  };

  return (
    <Dialog
      open={open}
      fullScreen={isMobile || fullScreen}
      onClose={close}
      maxWidth="lg"
      fullWidth
      scroll="body"
      classes={{
        paper: classes.root,
      }}
    >
      <Grid container className={classes.rootGrid}>
        <Grid xs={12} md={8}>
          <ApplicantCard applicant={liveApplicant} />
          <ActivityAndCommentPanel applicant={applicant} />
        </Grid>
        <Grid xs={12} md={4} className={classes.controls}>
          <div className={classes.windowControls}>
            <IconButton onClick={toggleFullScrren}>{fullScreenIcon}</IconButton>
            <IconButton onClick={close}>
              <CloseIcon />
            </IconButton>
          </div>
          <Controls
            applicant={liveApplicant}
            stages={stages}
            moveToStage={moveToStage}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

const LeftLoadingSpinner: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.leftLoadingSpinnerContainer}>
      <CircularProgress />
    </div>
  );
};

const ActivityAndCommentPanel = asyncComponent<Props>(({ applicant }) => {
  const classes = useStyles();
  return (
    <>
      <Container maxWidth={false}>
        <Metadata applicant={applicant} />
        <div className={classes.timelineContainer}>
          <Activity applicant={applicant} />
        </div>
      </Container>
      <Container maxWidth={false} className={classes.commentContainer}>
        <NewCommentOrMessage applicant={applicant} />
      </Container>
    </>
  );
}, LeftLoadingSpinner);

enum CommentOrMessage {
  Comment = "comment",
  Message = "message",
}

const NewCommentOrMessage: React.FC<Props> = ({ applicant }) => {
  const classes = useStyles();
  const [tab, setTab] = React.useState<CommentOrMessage>(
    CommentOrMessage.Comment
  );
  const [markdown, setMarkdown] = React.useState<string | undefined>(undefined);

  const handleChange = (
    event: React.ChangeEvent<{}>,
    newValue: CommentOrMessage
  ) => {
    setTab(newValue);
  };

  const newComment = (
    <NewComment
      applicant={applicant}
      markdown={markdown}
      setMarkdown={setMarkdown}
    />
  );
  const newMessage = (
    <NewMessage
      applicant={applicant}
      markdown={markdown}
      setMarkdown={setMarkdown}
    />
  );

  return (
    <>
      <Tabs
        value={tab}
        onChange={handleChange}
        indicatorColor="secondary"
        className={classes.tabs}
      >
        <Tab
          label="Comment and review"
          value={CommentOrMessage.Comment}
          disableRipple
        />
        <Tab
          label="Message to candidate"
          value={CommentOrMessage.Message}
          disableRipple
        />
      </Tabs>
      <div className={classes.editMarkdownContainer}>
        {tab === CommentOrMessage.Comment && newComment}
        {tab === CommentOrMessage.Message && newMessage}
      </div>
    </>
  );
};

type CommentProps = Props & {
  markdown?: string;
  setMarkdown: (value: string | undefined) => void;
};

const NewComment: React.FC<CommentProps> = ({
  applicant,
  markdown,
  setMarkdown,
}) => {
  const classes = useStyles();
  const [processing, setProcessing] = React.useState(false);
  const [rating, setRating] = React.useState<Rating | undefined>(undefined);

  const { user, updateEvents, updateReviews } =
    React.useContext(StateUpdateContext);

  const onClick = async () => {
    setProcessing(true);
    await updateEvents(({ create }) =>
      createApplicantCommentEvent(create, applicant, user, markdown)
    );

    if (rating) {
      await updateReviews(({ create }) =>
        createReview(create, applicant, user, rating)
      );
    }
    setProcessing(false);
    setMarkdown(undefined);
    setRating(undefined);
  };

  const color = (chipRating: Rating | undefined) => {
    return chipRating === rating ? "secondary" : undefined;
  };

  const ratingsFn = (chipRating: Rating | undefined) => {
    return () => setRating(chipRating);
  };

  return (
    <>
      <EditMarkdown markdown={markdown} setMarkdown={setMarkdown} />
      <Grid container justify="space-between">
        <div className={classes.iconRadioContainer}>
          <Chip
            variant="outlined"
            icon={<StrongNoIcon />}
            label="Strong no"
            color={color(Rating.StrongNo)}
            onClick={ratingsFn(Rating.StrongNo)}
          />
          <Chip
            variant="outlined"
            icon={<NoIcon />}
            label="No"
            color={color(Rating.No)}
            onClick={ratingsFn(Rating.No)}
          />
          <Chip
            variant="outlined"
            label="No review"
            color={color(undefined)}
            onClick={ratingsFn(undefined)}
          />
          <Chip
            variant="outlined"
            icon={<YesIcon />}
            label="Yes"
            color={color(Rating.Yes)}
            onClick={ratingsFn(Rating.Yes)}
          />
          <Chip
            variant="outlined"
            icon={<StrongYesIcon />}
            label="Strong yes"
            color={color(Rating.StrongYes)}
            onClick={ratingsFn(Rating.StrongYes)}
          />
        </div>
        <Button
          small
          cta
          disabled={processing || !markdown || markdown === ""}
          onClick={onClick}
        >
          Comment
          {processing && (
            <LinearProgress className={classes.progress} color="secondary" />
          )}
        </Button>
      </Grid>
    </>
  );
};

const NewMessage: React.FC<CommentProps> = ({
  applicant,
  markdown,
  setMarkdown,
}) => {
  const classes = useStyles();
  const [processing, setProcessing] = React.useState(false);

  const { user, updateEvents } = React.useContext(StateUpdateContext);

  const onClick = async () => {
    if (!markdown) {
      return;
    }

    setProcessing(true);
    await updateEvents(({ create }) => {
      createApplicantMessageEvent(create, applicant, user, markdown);
    });

    setProcessing(false);
    setMarkdown(undefined);
  };

  return (
    <>
      <EditMarkdown
        markdown={markdown}
        setMarkdown={setMarkdown}
        label="Write a message"
      />
      <Grid container justify="space-between">
        <div className={classes.warningContainer}>
          <Alert severity="warning">
            Be careful,{" "}
            <Accent>this will be sent to {applicant.firstName}!</Accent>
          </Alert>
        </div>
        <Button
          small
          cta
          disabled={processing || !markdown || markdown === ""}
          onClick={onClick}
        >
          Send to candidate
          {processing && (
            <LinearProgress className={classes.progress} color="secondary" />
          )}
        </Button>
      </Grid>
    </>
  );
};

const Spacer: React.FC = () => {
  return <div style={{ width: 24 }} />;
};

const Metadata: React.FC<Props> = ({ applicant }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      alignItems="center"
      justify="flex-start"
      direction="row"
      className={classes.metadata}
    >
      {applicant.location && (
        <>
          <LocationIcon />
          <Typography variant="body2" color="textSecondary">
            {applicant.location}
          </Typography>
          <Spacer />
        </>
      )}
      <MailIcon />
      <Typography variant="body2" color="textSecondary">
        <EmailAddress applicant={applicant} />
      </Typography>
      <CV applicant={applicant} />
    </Grid>
  );
};

const EmailAddress = asyncComponent<Props>(
  ({ applicant }) => {
    const dashboard = useRecoilValue(DashboardForApplicant(applicant));
    return (
      <Link color="textSecondary" href={`mailto:${dashboard[0].email}`}>
        {dashboard[0].email}
      </Link>
    );
  },
  () => <>...</>
);

const CV = asyncComponent<Props>(
  ({ applicant }) => {
    const dashboards = useRecoilValue(DashboardForApplicant(applicant));
    if (!dashboards?.length) {
      return null;
    }

    const dashboard = dashboards[0];

    if (!dashboard.cv) {
      return null;
    }
    return (
      <>
        <Spacer />
        <DescriptionIcon />
        <Typography variant="body2" color="textSecondary">
          <Link
            href={dashboard.cv}
            color="textSecondary"
            rel="noopener noreferrer"
            target="_blank"
          >
            CV
          </Link>
        </Typography>
      </>
    );
  },
  () => null
);

const ApplicantCard: React.FC<Props> = ({ applicant }) => {
  const classes = useStyles();

  const connections = applicant.connections ? (
    <>
      {applicant.connections.map((conn) => (
        <Link
          href={conn.url}
          title={conn.label}
          onClick={(e: any) => {
            e.preventDefault();
            openInNewTab(conn.url);
          }}
        >
          <ConnectionIcon url={conn.url} />
        </Link>
      ))}
    </>
  ) : null;

  return (
    <Card className={classes.applicantDetails}>
      <CardHeader
        avatar={
          <Avatar
            className={classes.avatar}
            alt={applicant.name}
            src={applicant.avatar}
          />
        }
        title={<Typography variant="h6">{applicant.name}</Typography>}
        subheader={
          <Typography variant="body2">
            {applicant.history ? applicant.history[0] : undefined}
          </Typography>
        }
        action={connections}
        classes={{
          action: classes.applicantLinks,
        }}
        disableTypography
      />
    </Card>
  );
};

const EventContentAdded: React.FC<EventProps> = ({ event }) => {
  const [applicant] = useFirestoreValue(ApplicantById(event.subjectId));
  return (
    <Typography variant="body2" color="textSecondary">
      <Username id={event.initiatorId} /> <Accent>manually added</Accent>{" "}
      <Accent>{applicant.name}</Accent>{" "}
      {event.date && <TimeAgo date={event.date.toDate()} />}
    </Typography>
  );
};

const EventContentApplication: React.FC<EventProps> = ({ event }) => {
  const [applicant] = useFirestoreValue(ApplicantById(event.subjectId));
  return (
    <Typography variant="body2" color="textSecondary">
      <Accent>{applicant.name}</Accent> applied for this role{" "}
      {event.date && <TimeAgo date={event.date.toDate()} />}
    </Typography>
  );
};

const EventContentEndorsement: React.FC<EventProps> = ({ event }) => {
  const [applicant] = useFirestoreValue(ApplicantById(event.subjectId));
  return (
    <Typography variant="body2" color="textSecondary">
      <Accent>{applicant.name}</Accent> received an endorsement{" "}
      {event.date && <TimeAgo date={event.date.toDate()} />}
    </Typography>
  );
};

const EventContentComment: React.FC<EventProps> = ({ event }) => {
  const classes = useStyles();
  const [markdown, setMarkdown] = React.useState<string | undefined>(
    event.content
  );
  const [editing, setEditing] = React.useState(false);
  const [processing, setProcessing] = React.useState(false);

  const { user, updateEvents } = React.useContext(StateUpdateContext);

  React.useEffect(() => {
    setMarkdown(event.content);
  }, [event]);

  const handleUpdate = async () => {
    setProcessing(true);
    await updateEvents(({ update }) =>
      update(event.id, {
        content: markdown,
      })
    );
    setProcessing(false);
    setEditing(false);
  };

  const icon = editing ? <CancelIcon /> : <EditIcon />;

  const display = editing ? (
    <div>
      <EditMarkdown markdown={markdown} setMarkdown={setMarkdown} />
      <Grid container justify="flex-end">
        <Button
          small
          secondary
          onClick={() => setEditing(false)}
          disabled={processing || !markdown || markdown === ""}
        >
          Cancel
        </Button>
        <Button
          cta
          small
          disabled={processing || !markdown || markdown === ""}
          onClick={handleUpdate}
        >
          Update comment
          {processing && (
            <LinearProgress className={classes.progress} color="secondary" />
          )}
        </Button>
      </Grid>
    </div>
  ) : (
    <Container maxWidth={false} className={classes.timelineComment}>
      {" "}
      <Markdown source={event.content || ""} />
    </Container>
  );

  const switchEditMode = () => setEditing(!editing);

  return (
    <>
      <Typography variant="body2" color="textSecondary">
        <Username id={event.initiatorId} /> commented{" "}
        {event.date && <TimeAgo date={event.date.toDate()} />}
      </Typography>
      <div className={classes.timelineCommentRoot}>
        {user?.uid && user.uid === event.initiatorId && (
          <IconButton
            className={classes.timelineCommentMenu}
            size="small"
            onClick={switchEditMode}
          >
            {icon}
          </IconButton>
        )}
        {display}
      </div>
    </>
  );
};

const EventContentStageChange = asyncComponent<EventProps>(
  ({ event }) => {
    const [applicant] = useFirestoreValue(ApplicantById(event.subjectId));

    return (
      <Typography variant="body2" color="textSecondary">
        <Username id={event.initiatorId} /> moved {applicant.firstName} to{" "}
        {event.content ? (
          <StageName
            organisationId={applicant.organisationId}
            roleId={applicant.roleId}
            id={event.content}
          />
        ) : (
          "Unknown stage"
        )}{" "}
        {event.date && <TimeAgo date={event.date.toDate()} />}
      </Typography>
    );
  },
  () => null
);

const EventContentRankChange = asyncComponent<EventProps>(
  ({ event }) => {
    const [applicant] = useFirestoreValue(ApplicantById(event.subjectId));

    if (!event.content || event.content === "undefined") {
      return (
        <Typography variant="body2" color="textSecondary">
          {applicant.name} <Accent>lost their rank</Accent>{" "}
          {event.date && <TimeAgo date={event.date.toDate()} />}
        </Typography>
      );
    }

    return (
      <Typography variant="body2" color="textSecondary">
        {applicant.name} was <Accent>ranked #{event.content}</Accent>{" "}
        {event.date && <TimeAgo date={event.date.toDate()} />}
      </Typography>
    );
  },
  () => null
);

const EventContentWithdrawl = asyncComponent<EventProps>(
  ({ event }) => {
    const [applicant] = useFirestoreValue(ApplicantById(event.subjectId));

    return (
      <Typography variant="body2" color="textSecondary">
        {applicant.name} <Accent>withdrew their application</Accent>{" "}
        {event.date && <TimeAgo date={event.date.toDate()} />}
      </Typography>
    );
  },
  () => null
);

const EventContentReview = asyncComponent<EventProps>(
  ({ event }) => {
    const [applicant] = useFirestoreValue(ApplicantById(event.subjectId));

    return (
      <Typography variant="body2" color="textSecondary">
        <Username id={event.initiatorId} /> gave {applicant.name} a{" "}
        <Accent>{event.content}</Accent>{" "}
        {event.date && <TimeAgo date={event.date.toDate()} />}
      </Typography>
    );
  },
  () => null
);

const EventIncomingMessage = asyncComponent<EventProps>(
  ({ event }) => {
    const classes = useStyles();
    const [applicant] = useFirestoreValue(ApplicantById(event.subjectId));

    return (
      <>
        <Typography variant="body2" color="textSecondary">
          {applicant.firstName} <Accent>sent a message</Accent>{" "}
          {event.date && <TimeAgo date={event.date.toDate()} />}
        </Typography>
        <div className={classes.timelineCommentRoot}>
          <Container maxWidth={false} className={classes.timelineComment}>
            {" "}
            <Markdown source={event.content || ""} />
          </Container>
        </div>
      </>
    );
  },
  () => null
);

const EventOutgoingMessage = asyncComponent<EventProps>(
  ({ event }) => {
    const classes = useStyles();
    const [applicant] = useFirestoreValue(ApplicantById(event.subjectId));

    return (
      <>
        <Typography variant="body2" color="textSecondary">
          <Username id={event.initiatorId} /> <Accent>sent a message</Accent> to{" "}
          {applicant.firstName}{" "}
          {event.date && <TimeAgo date={event.date.toDate()} />}
        </Typography>
        <div className={classes.timelineCommentRoot}>
          <Container maxWidth={false} className={classes.timelineComment}>
            {" "}
            <Markdown source={event.content || ""} />
          </Container>
        </div>
        {event.entityId && (
          <div style={{ position: "absolute", right: 0, top: 8 }}>
            <MessageDeliveryStatus
              orgId={applicant.organisationId}
              messageId={event.entityId}
            />
          </div>
        )}
      </>
    );
  },
  () => null
);

const EventRemoval = asyncComponent<EventProps>(
  ({ event }) => {
    const [applicant] = useFirestoreValue(ApplicantById(event.subjectId));

    return (
      <Typography variant="body2" color="textSecondary">
        <Username id={event.initiatorId} /> <Accent>removed</Accent>{" "}
        {applicant.firstName}{" "}
        {event.content && (
          <>
            (Reason: <Accent>{event.content}</Accent>)
          </>
        )}{" "}
        {event.date && <TimeAgo date={event.date.toDate()} />}
      </Typography>
    );
  },
  () => null
);

const EventRestoration = asyncComponent<EventProps>(
  ({ event }) => {
    const [applicant] = useFirestoreValue(ApplicantById(event.subjectId));

    return (
      <Typography variant="body2" color="textSecondary">
        <Username id={event.initiatorId} /> <Accent>re-added</Accent>{" "}
        {applicant.firstName}{" "}
        {event.date && <TimeAgo date={event.date.toDate()} />}
      </Typography>
    );
  },
  () => null
);

const TimelineContentViews: EventContentViewProps = {
  AddedView: EventContentAdded,
  ApplicationView: EventContentApplication,
  EndorsementView: EventContentEndorsement,
  CommentView: EventContentComment,
  StageChangeView: EventContentStageChange,
  RankChangeView: EventContentRankChange,
  WithdrawlView: EventContentWithdrawl,
  ReviewView: EventContentReview,
  IncomingMessageView: EventIncomingMessage,
  OutgoingMessageView: EventOutgoingMessage,
  RemovalMessageView: EventRemoval,
  RestorationMessageView: EventRestoration,
};

const Activity: React.FC<Props> = ({ applicant }) => {
  const events = useRecoilValue(EventsByApplicant(applicant));
  return <Timeline events={events} {...TimelineContentViews} />;
};

const Controls: React.FC<PropsWithStages> = (props) => {
  if (props.applicant.status === ApplicationStatus.Pending) {
    return <ControlsForActiveCandidate {...props} />;
  }
  return <ControlsForRemovedCandidate {...props} />;
};

const ControlsForActiveCandidate: React.FC<PropsWithStages> = ({
  applicant,
  stages,
  moveToStage,
}) => {
  const classes = useStyles();
  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  const nextStage = stages.find(
    (stage, idx, all) => idx > 0 && all[idx - 1].id === applicant.stageId
  );

  const moveToNextStage = () => {
    if (!nextStage) {
      return;
    }
    moveToStage(applicant, nextStage.id);
  };

  const handleChangeStage = (event: React.ChangeEvent<{ value: unknown }>) => {
    moveToStage(applicant, event.target.value as string);
  };

  const copyShortLink = () => {
    if (!applicant.shortLink) {
      return;
    }
    copy(applicant.shortLink);
    setTooltipOpen(true);
  };

  const handleOnTooltipClose = () => setTooltipOpen(false);

  const rankDesc = applicant.rank ? (
    <>
      Ranked <Accent>#{applicant.rank}</Accent>
    </>
  ) : (
    "Not ranked"
  );

  return (
    <>
      <Container maxWidth={false} className={classes.controlPanel}>
        <Typography paragraph variant="h5">
          {rankDesc}
        </Typography>
        <RankBreakdown applicant={applicant} />
      </Container>
      <Container maxWidth={false} className={classes.controlPanel}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            {nextStage && (
              <Button
                className={classes.nextStageButton}
                cta
                small
                onClick={moveToNextStage}
              >
                <span>
                  Move to <Accent>{nextStage.title}</Accent>
                </span>
              </Button>
            )}
          </Grid>
          <Grid item xs={4}>
            <RemoveControl applicant={applicant} moveToStage={moveToStage} />
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth={false} className={classes.controlPanel}>
        <Typography variant="subtitle2" color="textSecondary">
          Stage
        </Typography>
        <Select
          labelId="select-stage"
          id="select-stage"
          value={applicant.stageId}
          className={classes.select}
          onChange={handleChangeStage}
        >
          {stages.map((stage) => (
            <MenuItem value={stage.id}>{stage.title}</MenuItem>
          ))}
        </Select>
      </Container>
      <Tooltip
        title="Link copied"
        open={tooltipOpen}
        placement="bottom"
        leaveDelay={1000}
        onClose={handleOnTooltipClose}
        arrow
      >
        <Container maxWidth={false} className={classes.controlPanel}>
          <Typography variant="subtitle2" color="textSecondary">
            Endorsement URL
          </Typography>

          <Button
            secondary
            icon={<FileCopyIcon />}
            className={classes.shortlinkButton}
            onClick={copyShortLink}
          >
            {applicant.shortLink}
          </Button>
        </Container>
      </Tooltip>
    </>
  );
};

const ControlsForRemovedCandidate: React.FC<PropsWithStages> = ({
  applicant,
  stages,
  moveToStage,
}) => {
  const classes = useStyles();

  const handleChangeStage = (event: React.ChangeEvent<{ value: unknown }>) => {
    moveToStage(applicant, event.target.value as string);
  };

  const allStages = [
    {
      id: ArchivedStageId,
      title: "Archived",
      applicantCount: 0,
    },
    ...stages,
  ];

  return (
    <>
      <Container maxWidth={false} className={classes.controlPanelHeader}>
        <Alert severity="warning" icon={<ArchivedIcon />}>
          <AlertTitle>
            This candidate has been <Accent>removed</Accent>
          </AlertTitle>
          {applicant.endorsements > 0 && (
            <>
              They have <Accent>{applicant.endorsements} endorsements</Accent>,
              which will be refunded in <Accent>24 hours</Accent>, unless the
              candidate is re-added.
            </>
          )}
        </Alert>
      </Container>
      <Container maxWidth={false} className={classes.controlPanel}>
        <Typography variant="body1" paragraph>
          Re-add candidate
        </Typography>
        <Typography variant="body2" color="textSecondary" paragraph>
          You can put {applicant.firstName} back into the process, but bear in
          mind it might be confusing for them!
        </Typography>
        <Typography variant="overline" color="textSecondary">
          Restore to stage
        </Typography>
        <Select
          labelId="select-stage"
          id="select-stage"
          value={applicant.stageId}
          className={classes.select}
          onChange={handleChangeStage}
        >
          {allStages.map((stage) => (
            <MenuItem value={stage.id}>{stage.title}</MenuItem>
          ))}
        </Select>
      </Container>
    </>
  );
};

const removalReasons = (stageId: string): Map<RemovalReason, string> => {
  if (stageId === FixedStageID.Probation || stageId === FixedStageID.Hired) {
    return new Map<RemovalReason, string>([
      [RemovalReason.NotQualified, "Not qualified"],
      [RemovalReason.DidntShowUp, "Didn't show up for work"],
      [RemovalReason.NotACulturalFit, "Not a cultural fit"],
      [RemovalReason.Quit, "Quit before probation ended"],
      [RemovalReason.FiredForMisconduct, "Fired for misconduct"],
      [RemovalReason.PartedWays, "Parted ways by mutual agreement"],
    ]);
  }

  return new Map<RemovalReason, string>([
    [RemovalReason.Unresponsive, "Unresponsive"],
    [RemovalReason.NotQualified, "Not qualified"],
    [RemovalReason.FailedInterview, "Failed interview"],
    [RemovalReason.DidntShowUp, "Didn't show up"],
    [RemovalReason.NotACulturalFit, "Not a cultural fit"],
    [RemovalReason.AcceptedAnotherOffer, "Accepted another offer"],
    [RemovalReason.NotInterested, "Not interested"],
    [RemovalReason.BadTiming, "Bad timing"],
    [RemovalReason.NotARealPerson, "Bot or potential scam"],
  ]);
};

const RemoveControl: React.FC<PropsWithMoveToStage> = ({
  applicant,
  moveToStage,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event?: React.MouseEvent<HTMLButtonElement>) => {
    if (!event) {
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const clickFunc = (reason: RemovalReason) => {
    return () => {
      handleClose();
      moveToStage(applicant, ArchivedStageId, reason);
    };
  };

  const reasons = removalReasons(applicant.stageId);

  return (
    <>
      <Button
        className={classes.rejectButton}
        secondary
        small
        onClick={handleClick}
      >
        Remove
      </Button>
      <Menu
        id="remove-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem disabled onClick={handleClose}>
          <StrongNoIcon fontSize="small" className={classes.rejectIcon} />
          Remove {applicant.firstName}
        </MenuItem>
        <MenuItem />
        {Array.from(reasons.entries()).map((reason) => (
          <MenuItem onClick={clickFunc(reason[0])}>{reason[1]}</MenuItem>
        ))}
      </Menu>
    </>
  );
};

const RankBreakdown = asyncComponent<Props>(
  ({ applicant }) => {
    const classes = useStyles();
    const [metadata] = useLoadable(ApplicantMetadataState(applicant));

    if (!metadata.reviewCount && !applicant.endorsements) {
      return null;
    }
    return (
      <>
        {metadata.rankIndex < 0 && (
          <Alert severity="warning" className={classes.alert}>
            This candidate has a <strong>negative rank</strong>
          </Alert>
        )}
        <LinearProgress
          variant="determinate"
          value={metadata.rankContributionFromEndorsements}
          className={classes.rankBreakdownBar}
        />
        <Grid
          container
          alignItems="center"
          justify="flex-start"
          direction="row"
          className={classes.legend}
        >
          <Box color="primary" className={classes.swatch} />
          <Typography variant="body2">
            <Accent>{metadata.rankContributionFromEndorsements}%</Accent>{" "}
            <Emphasis>from</Emphasis>{" "}
            <Accent>
              {applicant.endorsements} endorsement
              {plural(applicant.endorsements)}
            </Accent>
          </Typography>
        </Grid>
        <Grid
          container
          alignItems="center"
          justify="flex-start"
          direction="row"
          className={classes.legend}
        >
          <Box
            color="primary"
            className={[classes.swatch, classes.swatchSecondary].join(" ")}
          />
          <Typography variant="body2">
            <Accent>{metadata.rankContributionFromReviews}%</Accent>{" "}
            <Emphasis>from</Emphasis>{" "}
            <Accent>
              {metadata.reviewCount} team review{plural(metadata.reviewCount)}
            </Accent>
          </Typography>
        </Grid>
      </>
    );
  },
  () => {
    const classes = useStyles();
    return (
      <>
        <Skeleton variant="rect" className={classes.rankBreakdownBarSkeleton} />
        <Grid
          container
          alignItems="center"
          justify="flex-start"
          direction="row"
          className={classes.legend}
        >
          <Skeleton variant="circle" className={classes.swatch} />
          <Skeleton>
            <Typography variant="body2">XX% from U endorsements</Typography>
          </Skeleton>
        </Grid>
        <Grid
          container
          alignItems="center"
          justify="flex-start"
          direction="row"
          className={classes.legend}
        >
          <Skeleton
            variant="circle"
            className={[classes.swatch, classes.swatchSecondary].join(" ")}
          />
          <Skeleton>
            <Typography variant="body2">YY% from V team reviews</Typography>
          </Skeleton>
        </Grid>
      </>
    );
  }
);

export default CandidateDialog;
