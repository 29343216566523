import { Skeleton } from "@material-ui/lab";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { WrapAuthRequired } from "@app/components/AuthRequired";
import asyncComponent from "@app/components/asyncComponent";
import useAuth from "@app/hooks/useAuth";
import useFirestoreValue from "@app/hooks/useFirestoreValue";
import ApplicantsByRole from "@app/state/ApplicantsByRole";
import RoleById from "@app/state/RoleById";
import StagesByRole from "@app/state/StagesByRole";
import UpdateApplicants from "@app/state/UpdateApplicants";
import UpdateEvents from "@app/state/UpdateEvents";
import UpdateReviews from "@app/state/UpdateReviews";
import UpdateStages from "@app/state/UpdateStages";

import Layout from "./Layout";
import Role, { RoleSkeleton, RoleControls } from "./Role.stateless";
import StateUpdateContext from "./StateUpdateContext";

type Props = {
  roleId: string;
  applicantId?: string;
};

const LandingView: React.FC<Props> = ({ roleId, applicantId }) => {
  const [role, roleLoading] = useFirestoreValue(RoleById(roleId));
  const applicants = useRecoilValue(ApplicantsByRole(role));
  const stages = useRecoilValue(StagesByRole(role));
  const { user } = useAuth();

  if (roleLoading || !user) {
    return <RoleSkeleton />;
  }

  return (
    <StateUpdateContext.Provider
      value={{
        user,
        updateApplicants: UpdateApplicants,
        updateEvents: UpdateEvents(role.organisationId),
        updateStages: UpdateStages(role),
        updateReviews: UpdateReviews(role),
      }}
    >
      <Role
        role={role}
        applicants={applicants}
        stages={stages}
        focusApplicantId={applicantId}
      />
    </StateUpdateContext.Provider>
  );
};

type DispatcherProps = RouteComponentProps<{
  id: string;
  applicantId?: string;
}>;

const Dispatcher: React.FC<DispatcherProps> = ({ match }) => {
  return (
    <Layout
      loading={RoleSkeleton}
      controls={<RoleControls roleId={match.params.id} />}
    >
      <LandingView
        roleId={match.params.id}
        applicantId={match.params.applicantId}
      />
    </Layout>
  );
};

export default WrapAuthRequired<DispatcherProps>(Dispatcher);

export const RoleBreadcrumb = asyncComponent<DispatcherProps>(
  ({ match }) => {
    const [role] = useFirestoreValue(RoleById(match.params.id));
    return <span>{role.title}</span>;
  },
  () => (
    <Skeleton>
      <span>Loading...</span>
    </Skeleton>
  )
);
